import {useEffect, useCallback} from 'react'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import { useUserAction } from '../../../action/user-action'
import TopMenu from '../../../components/basic/top-menu'
import variables from '../../../scss/variables.module.scss'
import SVGIcon from '../../../components/svg'
import BottomMenu from '../../../components/basic/bottom-menu'
import Queries from '../../../params/queries'
import { authIdAtom } from '../../../state/auth'
import Mutations from '../../../params/mutations'
import { messageToUserAtom } from '../../../state/pages/message'
import { setSeparateState } from '../../../helpers/utils'
import { useNavigate } from 'react-router-dom'

const MessageToUser = ({ paramsUrl }) => {
  const [{toUser, text }, setMessageToUser] = useRecoilState(messageToUserAtom);
  const setToUser = setSeparateState(setMessageToUser, "toUser")
  const setText = setSeparateState(setMessageToUser, "text")

  const fetchAction = useFetchApiAction();
  const setSubscriptionPopup = useSetRecoilState(activePopupState('subscription'));
  const userAction = useUserAction(false);
  const authId = useRecoilValue(authIdAtom);
  const setTipsPopup = useSetRecoilState(activePopupState('tips'));

  const navigate = useNavigate()

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageToUser = useResetRecoilState(messageToUserAtom)
  useEffect(() => {
    resetStateAction.add(resetMessageToUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    userAction.getProfile(paramsUrl.username).then(res => {
      if (!res.profile) {
        navigate('/messages/inbox');
        return;
      }
      setToUser(res.profile)

      fetchAction(Queries.Messages.Thread, { user: res.profile.id }).then(res => {
        if (res.messenger_thread?.id) {
          navigate('/messages/inbox/' + res.messenger_thread?.id, {replace: true});
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.username])

  const send = () => {
    fetchAction(Queries.Subscription.Check, { from: authId, to: toUser.id }).then(res => {
      if (res.subscription?.id) {
        sendMessage();
      } else {
        setSubscriptionPopup({ active: true, profile: toUser })
      }
    })
  }

  const sendMessage = useCallback(() => {
    if (text !== '') {
      const message = text.substr(0, 512);
      setText('');
      fetchAction(Mutations.Messages.Add, {
        "to": toUser?.id,
        "data": {
          type: "text",
          value: message
        }
      }).then(res => {
        navigate('/messages/inbox/' + res.newMessage?.thread.id, {replace: true});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.thread, fetchAction, text, setText, toUser])

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={toUser?.fullname}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page jf-messages-inbox-page'}>
    </div>
    <div className='jf-popup-comment-footer jf-messages-inbox-footer jf-write-box'>

      <input
        className='jf-write-box-input jf-ml-15px'
        placeholder={'Type a message'}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            send()
          }
        }}
        value={text} onChange={(e) => setText(e.target.value)}
      />
      {text && <button className={'jf-no-style-btn jf-ml-15px'} onClick={send}>
        <SVGIcon name={'send-fill-icon'} fill={variables['colors-jf-primary']} width={22} height={24}/>
      </button>}
      <button className={'jf-no-style-btn jf-ml-15px'} onClick={() => setTipsPopup({
        active: true, profile: toUser
      })}>
        <SVGIcon name={'money-icon'} fill={variables['colors-jf-primary']} width={14.58} height={26.26}/>
      </button>
    </div>
    <BottomMenu/>
  </>
}

export default MessageToUser
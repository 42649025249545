import React from 'react';
import { useRecoilValue } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import SVGIcon from '../../svg'
import Button from '../../basic/button'
import { TextField } from '../../basic/text-field'
import { usePaidAction } from '../../../action/paid-action'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Avatar from '../../basic/avatar'
import variables from '../../../scss/variables.module.scss'

const schema = yup.object({
  message: yup.string(),
  amount: yup.number().typeError('Tips Amount must be a number').transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  }).required()
    .label('Tips Amount').min(3,' '),
}).required();

const Tips = ({hide}) => {
  const info = useRecoilValue(activePopupState('tips'));
  const profile = info.profile || null;
  const paidAction = usePaidAction();
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    data.user = profile.id;
    paidAction.addTip(data).then(res => {
      if (!res.errors) {
        hide();
      }
    });
  }
  return <>
    <Avatar
      image={profile && profile.avatar}
      text={profile && profile.fullname}
      width={110}
      height={110}
    />
    <div className={'jf-popup-tips-title'}>
      {profile && profile.fullname}
    </div>
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
    <div className={'jf-popup-tips-content'}>
      <TextField
        type={'money'}
        register={register("amount")}
        error={errors.amount?.message}
        iconLeft={<SVGIcon name={'money-icon'} fill={variables['colors-jf-gray']} width={16} height={28}/>}
        placeholder={'Tips Amount'}/>
      <div className={'jf-popup-tips-note '+
      (errors.amount && errors.amount.type === 'min' ? 'jf-error' : '')}>Minimum $3</div>
      <TextField placeholder={'Message (optional)'}
                 register={register("message")}/>
    </div>
    <div className={'jf-mb-20px'}>
      <Button className={'medium jf-m-auto'}>Send tip</Button>
    </div>
    </form>
  </>;
}

export default Tips;
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import { authIdAtom } from '../../../state/auth'
import { formatDuration } from '../../../helpers/utils'
import { Row, Col, setConfiguration } from 'react-grid-system';
import Countdown from 'react-countdown';
import { Virtuoso } from 'react-virtuoso';
import variables from '../../../scss/variables.module.scss'
import { Puff } from 'react-loader-spinner'

setConfiguration({ gutterWidth: 0 });

export const checkVisibility = (item, authId, profile) => {
  if (profile && authId === profile.id) {
    return true;
  }
  if (item.shop > 0) {
    return false;
  }
  if (item.visibility === "free") {
    return true;
  }

  if (item.visibility === "subscription") {
    if (profile && (profile.authUserSubscription !== null && profile.authUserSubscription !== undefined)) {
      return true;
    }
  }
  return false;
}

const CoverDiv = React.memo(({type, item, authId, profile, goToMedia}) => {
  if (!checkVisibility(item, authId, profile || item.user) && item.shop > 0) {
    return <div className={"jf-media-list-item-cover subscription"}
                onClick={() => goToMedia(item)}>
      <div className={'note'}>${item.shop}</div>
    </div>
  }
  return <div className={"jf-media-list-item-cover " +
  (!checkVisibility(item, authId, profile || item.user) ? item.visibility : '')}
              onClick={() => goToMedia(item)}>
    {!checkVisibility(item, authId, profile || item.user) ?
      <div className={'note'}>SUBSCRIBERS<br/>ONLY</div> : null}
  </div>;
})

const LabelDiv = ({item}) => {
  return item.processed?.status === false ? <div className={"jf-media-list-item-label"}>
    PROCESSING
  </div> : item.approved === false && item.verified === true ? <div className={"jf-media-list-item-label"}>
      REJECTED
    </div> :
    <Countdown
      date={item.available_date}
      renderer={({completed,...other}) => {
        let text = '';
        const list = ['days', 'hours', 'minutes', 'seconds'];
        for (let i = 0; i< list.length; i++ ){
          if (other[list[i]]) {
            text = other[list[i]] + ' ' +(other[list[i]] > 2 ? list[i] : list[i].slice(0, -1))
            break;
          }
        }
        return completed ? null :
          <div className={"jf-media-list-item-label"}>Available in {text}</div>
      }}
    />
}

const ListTab = ({medias, loading, profile, onClick, type, IconInnerItem, onLoadMore, loadMore}) => {
  const navigate = useNavigate();
  const authId = useRecoilValue(authIdAtom);

  const goToMedia = (item) => {
    if (checkVisibility(item, authId, profile || item.user)) {
      if (onClick) {
        onClick(item);
      } else {
        navigate(['', item.user.username, type, item.id].join('/'))
      }
    } else if (authId === null || authId === undefined) {
      navigate('/signin')
    }
  }
  let list = [];
  if (!loading && medias && medias.length > 0) {
    let temp2Col = [];
    medias.forEach((item, i) => {
      let info = item.info ? JSON.parse(item.info) : [];
      info = info.find(find => !!find.width);

      const comp = item.type === 'video' ? <div className="jf-media-list-item" key={'jf-media-list-video-' + item.id}>
          <img alt={''} src={item.thumbnail.grid || item.thumbnail.original}
               className={info?.width <= info?.height? "" : "landscape"}/>
          <div className="jf-media-list-item-detail">
            {formatDuration(info?.duration || 0)}
          </div>
          <CoverDiv type={type} item={item} authId={authId} profile={profile} goToMedia={goToMedia}/>
          <LabelDiv item={item}/>
          {IconInnerItem ? IconInnerItem(item) : ''}
        </div> :
        <div className="jf-media-list-item" key={'jf-media-list-photo-' + item.id}>
          <img alt={''} src={item.thumbnail.grid || item.thumbnail.original}
               className={info?.width <= info?.height? "" : "landscape"}/>
          <CoverDiv type={type} item={item} authId={authId} profile={profile} goToMedia={goToMedia}/>
          <LabelDiv item={item}/>
          {IconInnerItem ? IconInnerItem(item) : ''}
        </div>;

      if (info?.width <= info?.height) {

        if (temp2Col.length < 1) {
          temp2Col.push(comp);
        } else {
          temp2Col.push(comp);
          list.push(temp2Col);
          temp2Col = [];
        }
      } else {
        list.push([comp]);
      }
    })
    if (temp2Col.length > 0) {
      temp2Col.push("")
      list.push(temp2Col);
    }
  }
  if (onLoadMore){
    return list.length > 0 ? <Virtuoso
      data={list}
      height={'100%'}
      overscan={200}
      components={{
        Footer: () => (
          loadMore ? <div style={{ textAlign: "center", padding: "1rem" }}>
            <Puff
              wrapperStyle={{justifyContent: 'center'}}
              color={variables['colors-jf-secondary']}
              height={30}
              width={30}
            />
          </div> : null
        ),
        ScrollSeekPlaceholder: ({ height, width, index }) => {
          return (
            <Row key={'jf-media-tab-row'} className={'jf-media-tab-row'}>{list[index].map((colItem, j) =>
              <Col key={'jf-media-tab-col-' + j}
                   className={'jf-seek-placeholder'}
                   style={{ height }}><Puff
                wrapperStyle={{justifyContent: 'center'}}
                color={variables['colors-jf-secondary']}
                height={30}
                width={30}
              /></Col>)}
            </Row>
          )
        },
      }}
      scrollSeekConfiguration={{
        enter: velocity => Math.abs(velocity) > 300,
        exit: velocity => Math.abs(velocity) < 100,
      }}
      endReached={onLoadMore}
      itemContent={(i, rowItems) =>
        <Row key={'jf-media-tab-row-' + i} className={'jf-media-tab-row'}>{rowItems.map((colItem, j) =>
          <Col key={'jf-media-tab-col-' + j + '-' + j}>{colItem}</Col>)}</Row>}
    /> :
    <div className={'jf-media-list-note'}>There is nothing here yet</div>
  }

  return (
    list.length > 0 ? list.map((rowItems, i) =>
        <Row key={'jf-media-tab-row-' + i} className={'jf-media-tab-row'}>{rowItems.map((colItem, j) =>
          <Col key={'jf-media-tab-col-' + j + '-' + j}>{colItem}</Col>)}</Row>) :
      <div className={'jf-media-list-note'}>There is nothing here yet</div>
  )
}

export default ListTab;
import React, {useEffect} from 'react';
import TopMenu from '../../../components/basic/top-menu'
import { useNavigate } from 'react-router-dom'
import FollowItem from '../../../components/follow-item'
import { useRecoilValue } from 'recoil'
import { useRelationAction } from '../../../action/relation-action'
import { followingAtom } from '../../../state/relations'

const Following = () => {
  const navigate = useNavigate();
  const following = useRecoilValue(followingAtom);
  const relationAction = useRelationAction();

  useEffect(() => {
    relationAction.getFollowing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <TopMenu
      title={'Following'}
      icon={'greater-icon'}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page'}>
      {following.map((user, i) =>
        <FollowItem
          key={'jf-followers-page-item-' + i}
          user={user}
          type={'following'}
        />)}
    </div>
  </>
}

export default Following;
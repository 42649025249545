import ItemMenu from '../../../components/basic/item-menu';
import BottomMenu from '../../../components/basic/bottom-menu';
import TopMenu from '../../../components/basic/top-menu';
import {useNavigate} from "react-router-dom";

const About = ({history}) => {

  const navigate = useNavigate();

  const list = [
    { path: "/about/terms-of-service", title: "Terms of Service"},
    { path: "/about/privacy-policy", title: "Privacy Policy" },
    { path: "/about/cookie-policy", title: "Cookie Policy" },
    { path: "/about/dmca", title: "DMCA" },
    { path: "/about/refund-policy", title: "Refund Policy" }
  ];
  return <>
    <TopMenu
      icon={'greater-icon'}
      title={'About'}
      action={() => navigate("/menu")}
    />
    <div className={'jf-basic-page'} style={{padding: 0}}>
    {list.map((item, i) =>
      <ItemMenu key={"jf-about-" + i}
                text={item.title}
                icon={'greater-icon'}
                styleText={{fontSize: 20, lineHeight: '27px'}}
                path={item.path}
      />)}
    </div>
    <BottomMenu/>
  </>
}

export default About;
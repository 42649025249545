import React, { useRef, useState, useCallback } from 'react';
import ReactTags from 'react-tag-autocomplete'
import { suggestionTagsAtom } from '../../state'
import {useRecoilValue} from 'recoil'

const InputTags = ({onBlur, setValue, defaultValue, ...props}) => {
  const [tags, setTags] = useState(defaultValue || [])
  const [error, setError] = useState(false)
  const suggestions = useRecoilValue(suggestionTagsAtom)

  const reactTags = useRef()

  const onDelete = useCallback((tagIndex) => {
    setValue(tags.filter((_, i) => i !== tagIndex).map(tag => tag.name))
    setTags(tags.filter((_, i) => i !== tagIndex))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  const onAddition = useCallback((newTag) => {
    const newList = [...tags, newTag].map(tag => tag.name).filter((value, index, self) =>{
      return self.indexOf(value) === index;
    });

    setValue(newList)
    setTags(newList.map(name=> ({name})))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  const onValidate = (newTag) => {
    if(/^[A-Za-z0-9\-\s~]*$/.test(newTag.name) && newTag.name.length >= 2 && newTag.name.length <= 20) {

      setError(false)
      return true;
    }
    setError(true)
    return false;
  }

  return (
    <>
      <ReactTags
        allowNew
        ref={reactTags}
        tags={tags}
        placeholderText={""}
        suggestions={suggestions}
        onDelete={onDelete}
        onAddition={onAddition}
        onValidate={onValidate}
        onBlur={onBlur}
        delimiters={['Enter', ',']}
      />
      {props.children}
      {!props.noShowNote && <div className={'jf-font-16-22 jf-mt-20px'}>
        <span className={error ? 'jf-text-field-msg error' : 'jf-color-gray'}>Tags may only contain alphanumeric characters as well as hyphens. Minimum length is 2 chars, maximum -20.</span>
      </div>}
    </>
  )
}
export default InputTags
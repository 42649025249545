import React from 'react';
const ShowPasswordIcon = ({
  style = {},
  fill = '#BEBFCE',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '30',
  className = '',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="view" transform="translate(0)">
        <g id="visible">
          <rect id="Rectangle_1154" data-name="Rectangle 1154" width={width} height={width} fill="rgba(255,255,255,0)" />
          <g id="visibility" transform="translate(0 6.25)">
            <g id="Group_1790" data-name="Group 1790">
              <path id="Path_6236" data-name="Path 6236" d="M29.889,115.065c-.236-.342-5.914-8.394-14.888-8.394-7.7,0-14.564,8-14.852,8.346a.624.624,0,0,0,0,.807c.289.341,7.151,8.346,14.852,8.346s14.564-8,14.852-8.346A.627.627,0,0,0,29.889,115.065ZM15,120.421a5,5,0,1,1,5-5A5,5,0,0,1,15,120.421Z" transform="translate(0 -106.671)" fill={fill} />
            </g>
          </g>
        </g>
        <g id="unvisible">
          <rect id="Rectangle_1155" data-name="Rectangle 1155" width={width} height={width} fill="rgba(255,255,255,0)" />
          <g id="visible-2" data-name="visible" transform="translate(0 3.75)">
            <g id="Group_1793" data-name="Group 1793" transform="translate(0 2.5)">
              <g id="Group_1792" data-name="Group 1792" transform="translate(0 0)">
                <path id="Path_6237" data-name="Path 6237" d="M15,106.673c-7.7,0-14.564,8-14.852,8.346a.624.624,0,0,0,0,.807,31.283,31.283,0,0,0,6.332,5.465l3.917-3.917a5,5,0,0,1,6.554-6.554l3.221-3.221A15.267,15.267,0,0,0,15,106.673Z" transform="translate(0 -106.673)" fill={fill} />
              </g>
            </g>
            <g id="Group_1795" data-name="Group 1795" transform="translate(3.752)">
              <g id="Group_1794" data-name="Group 1794" transform="translate(0 0)">
                <path id="Path_6238" data-name="Path 6238" d="M90.164,74.894a22.916,22.916,0,0,0-7.275-6.374l3.454-3.454a.625.625,0,0,0-.884-.884L64.21,85.433a.625.625,0,0,0,0,.884.619.619,0,0,0,.441.184.628.628,0,0,0,.442-.183L69.012,82.4A14.4,14.4,0,0,0,75.276,84c7.7,0,14.564-8,14.852-8.346A.627.627,0,0,0,90.164,74.894ZM75.276,80.25a4.956,4.956,0,0,1-3.05-1.066L79.21,72.2a4.973,4.973,0,0,1-3.934,8.05Z" transform="translate(-64.027 -64)" fill={fill} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>



  </>
);
export default ShowPasswordIcon;
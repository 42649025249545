import React from 'react';
const CloudPlusIcon = ({
  style = {},
  fill = "rgba(108,117,125,0.2)",
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '35',
  height = '24.063',
  viewBox = '0 0 35 24.063'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
  <g id="cloud-plus" transform="translate(0 -2)">
    <path id="Path_6317" data-name="Path 6317" d="M10.969,5.5a1.094,1.094,0,0,1,1.094,1.094V9.875h3.281a1.094,1.094,0,0,1,0,2.187H12.062v3.281a1.094,1.094,0,1,1-2.187,0V12.062H6.594a1.094,1.094,0,1,1,0-2.187H9.875V6.594A1.094,1.094,0,0,1,10.969,5.5Z" transform="translate(6.531 4.156)" fill={fill} fillRule="evenodd"/>
    <path id="Path_6318" data-name="Path 6318" d="M9.638,4.936A12.1,12.1,0,0,1,17.5,2,11.236,11.236,0,0,1,28.8,12.017a7.05,7.05,0,0,1-1.048,14.046H8.271A8.166,8.166,0,0,1,0,18.008a8.109,8.109,0,0,1,6.436-7.86A9.2,9.2,0,0,1,9.638,4.936Zm1.428,1.656a6.478,6.478,0,0,0-2.522,4.5v.98l-.973.107a5.949,5.949,0,0,0-5.383,5.832,5.977,5.977,0,0,0,6.083,5.867H27.753a4.876,4.876,0,1,0,0-9.745H26.659V13.036A9,9,0,0,0,17.5,4.187a9.909,9.909,0,0,0-6.433,2.406Z" fill={fill}/>
  </g>
</svg>

export default CloudPlusIcon

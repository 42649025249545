import React from 'react';
const AddIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '29',
  className = '',
  viewBox = '0 0 29 29'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
        <path id="add" d="M6.057,3.023A3.029,3.029,0,0,0,3.023,6.057V24.943a3.029,3.029,0,0,0,3.034,3.034H24.943a3.029,3.029,0,0,0,3.034-3.034V6.057a3.029,3.029,0,0,0-3.034-3.034ZM1,6.057A5.052,5.052,0,0,1,6.057,1H24.943A5.052,5.052,0,0,1,30,6.057V24.943A5.052,5.052,0,0,1,24.943,30H6.057A5.052,5.052,0,0,1,1,24.943ZM6.394,15.5a1.011,1.011,0,0,1,1.011-1.011h7.08V7.406a1.011,1.011,0,1,1,2.023,0v7.08h7.08a1.011,1.011,0,0,1,0,2.023h-7.08v7.08a1.011,1.011,0,1,1-2.023,0v-7.08H7.406A1.011,1.011,0,0,1,6.394,15.5Z" transform="translate(-1 -1)" fill={fill} fillRule="evenodd" opacity="0.8"/>
    </svg>
  </>
);
export default AddIcon;
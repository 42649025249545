import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {profileAtom} from '../../../state/users'
import TopMenu from '../../../components/basic/top-menu'
import {ProfileDetail} from './profile-detail'
import {useUserAction} from '../../../action/user-action';
import {useRelationAction} from '../../../action/relation-action'
import MediaTabs from '../../../components/complex/media-tabs'
import SVGIcon from '../../../components/svg/index'
import BottomMenu from '../../../components/basic/bottom-menu'
import {useNavigate, useParams} from 'react-router-dom'
import {getTab, setSeparateState, uniqueList} from '../../../helpers/utils'
import {activeBottomPopupState, activePopupState} from '../../../state/components/popup'
import Constants from '../../../params/constants'
import InfiniteScroll from '../../../components/complex/infinite-scroll'
import {useMediaAction} from '../../../action/media-action'
import {useResetStateWhenChangeUrlAction, useSubscriptionLiveUserAction} from '../../../action/main-action'
import {authIdAtom} from '../../../state/auth'
import {userProfileAtom} from '../../../state/pages/user-profile'

const UserProfile = ({from, ...props}) => {
    const [{media, loading, loadingMore, page, activeTab}, setUserProfile] = useRecoilState(userProfileAtom);
    const navigate = useNavigate();
    const [profile, setProfile] = useRecoilState(profileAtom);
    const userAction = useUserAction();
    const relationAction = useRelationAction();
    const setSharePopup = useSetRecoilState(activeBottomPopupState('share'));
    const mediaAction = useMediaAction(false);
    const limit = Constants.MediaListLimit;
    const subscriptionLiveUserAction = useSubscriptionLiveUserAction();
    const authId = useRecoilValue(authIdAtom);
    const setReportAndBlockPopup = useSetRecoilState(activePopupState('report_block'));
    const resetUserProfile = useResetRecoilState(userProfileAtom);
    const resetStateAction = useResetStateWhenChangeUrlAction()
    const setMedia = setSeparateState(setUserProfile, "media");
    const setLoading = setSeparateState(setUserProfile, "loading");
    const setLoadingMore = setSeparateState(setUserProfile, "loadingMore");
    const setPage = setSeparateState(setUserProfile, "page");
    const setActiveTab = setSeparateState(setUserProfile, "activeTab");
    const paramsUrl = {...props.paramsUrl, ...useParams()}

    useEffect(() => {
        if (from !== "dashboard_media") {
            resetStateAction.add(resetUserProfile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClick = () => {
        if (!profile.isFollowing) {
            relationAction.upFollowing(profile.id).then()
        }
    }
    useEffect(() => {
        let isCancelled = false;
        if (!paramsUrl.username) {
            setMedia({
                videos: [],
                shop: [],
                photos: [],
            });
            setLoading(true)
            setProfile(null);
            return;
        }
        setLoading(true);
        const mediaType = getTab(activeTab);
        userAction.getProfile(paramsUrl.username, mediaType).then((res) => {
            if (res && res.profile && !isCancelled) {

                subscriptionLiveUserAction(res.profile.id).then()
                setMedia((old) => {
                    return {
                        ...old, [mediaType]: res[mediaType].map(item => {
                            item.user = res.profile;
                            return item;
                        })
                    }
                })
                setLoading(false);
                setLoadingMore(res[mediaType].length === limit);
                setPage(1);
            }
        })
        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsUrl.username, activeTab]);

    useEffect(() => {
        const key = getTab(activeTab || 'video');
        if (!loadingMore || page < 2 || !media[key] || !profile) return;
        let isCanceled = false;
        setLoadingMore(true);
        const params = {
            limit: {order: false, skip: media[key].length},
            filter: {username: profile.username}, withLike: false
        }
        mediaAction.getMedias(activeTab, params).then(res => {
            if (!res || isCanceled) {
                setLoadingMore(false);
                return;
            }
            setMedia((old) => {
                return {...old, [key]: uniqueList(old[key].concat(res))}
            })
            setLoadingMore(res.length === limit);
        })
        return () => {
            isCanceled = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, profile, activeTab]);

    return <>
        <TopMenu
            className={'jf-user-profile-page-top-menu'}
            icon={'greater-icon'}
            title={'Profile'}
            action={() => {
                if (media.videos && media.videos.length > 0) {
                    navigate(['', profile.username, 'video', media.videos[0].id].join('/'));
                } else {
                    navigate(-1);
                }
            }}
            rightContent={<>
                {profile?.id === authId && <div style={{width: 40}}/>}
                <button className={'jf-no-style-btn'} onClick={() => {
                    setSharePopup({
                        active: true,
                        url: Constants.Site.URL + '/' + profile.username
                    })
                }}><SVGIcon name={'share-icon'} width={31}/></button>
                {profile?.id !== authId && <button className={'jf-no-style-btn'} onClick={() => {
                    setReportAndBlockPopup({
                        active: true,
                        user: profile
                    })
                }}><SVGIcon name={'three-dots-icon'}/></button>
                }
            </>}
        />
        <div className={'jf-user-profile-page'}>
            {profile && <ProfileDetail isOfAuth={false} onClick={onClick}/>}
            {profile && <InfiniteScroll loadingMore={loadingMore} setPage={setPage}>{(loadDiv) => <MediaTabs
                loading={loading}
                href={(tab) => {
                    setActiveTab(tab);
                    setPage(0);
                }}
                navigate={navigate}
                media={media}
                profile={profile}
                loadDiv={loadDiv}
                classContain={loadingMore ? 'jf-mb-50px' : ""}
                activeTab={activeTab}/>}
            </InfiniteScroll>
            }
        </div>
        <BottomMenu/>
    </>;
}

export default UserProfile;
import { fetchApi } from '../helpers/fetch-graphql'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState,useSetRecoilState } from 'recoil'
import { authIdAtom, authProfileAtom } from '../state/auth'
import { DCookie } from '../helpers/utils'
import { profileAtom } from '../state/users'
import { subscriptionLiveUser } from '../helpers/live-grahpql'
import { resetStateActionAtom } from '../state'

export function useFetchApiAction (orgReturn = false) {
  const navigate = useNavigate();
  const location = useLocation();
  const [authId, setAuthId] = useRecoilState(authIdAtom);

  return (query, variables) => {
    return fetchApi(query, variables).then(res => {

      if (res?.errors && res.errors[0].status === 401) {
        setAuthId(null);
        navigate('/signin', {state: {prevPath: location}});
      } else {
        if (!authId && DCookie.getItem('auth_id')) {
          setAuthId(authId);
        }
        if (orgReturn) {
          return res;
        }

        return !res || res.errors ? {errors: res?.errors[0].state || res?.errors || true} : res.data;
      }
    })
  }
}

export function useSubscriptionLiveUserAction () {
  const setProfile = useSetRecoilState(profileAtom);
  const setAuthProfile = useSetRecoilState(authProfileAtom);

  return (user, callBack) => {
    return subscriptionLiveUser.run(user, (result) => {
      setProfile((old) => {
        if (old?.id !== user) {
          return old
        }
        return {...old, ...result.data.live.account, status: result.data.live.status }
      })
      setAuthProfile((old) => {
        if (old?.id !== user) {
          return old
        }
        return {...old, ...result.data.live.account, status: result.data.live.status }
      })
      if (callBack) callBack(result)
    })
  }
}

export function useResetStateWhenChangeUrlAction () {
  const [resetStateAction, setResetStateAction] = useRecoilState(resetStateActionAtom)

  return {
    add: (fn) => {
      setResetStateAction(old=> [...old, fn]);
    },
    run: () => {
      if (resetStateAction.length > 0) {
        resetStateAction.forEach(fn => {
          fn()
        });
        setResetStateAction([]);
      }
    }
  }
}

import React, {useEffect} from 'react';
import BottomMenu from '../../../components/basic/bottom-menu'
import { useNavigate } from 'react-router-dom'
import TopMenu from '../../../components/basic/top-menu'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { authProfileAtom } from '../../../state/auth'
import SelectTab from '../../../components/basic/select-tab'
import AutoTab from './tab/auto-tab'
import InboxTab from './tab/inbox-tab'
import MassTab from './tab/mass-tab'
import { messageAtom } from '../../../state/pages/message'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'

const Messages = ({paramsUrl}) => {
  const navigate = useNavigate();
  const authProfile = useRecoilValue(authProfileAtom);

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessage = useResetRecoilState(messageAtom)
  useEffect(() => {
    resetStateAction.add(resetMessage)

    if (paramsUrl.tab === 'inbox') {
    } else if (authProfile?.role.creator) {
    } else if (authProfile) {
      navigate('/messages/inbox');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.tab]);


  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"Messages"}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page jf-messages-page'}>
      {authProfile?.role.creator === true && <SelectTab
        style={{ height: 60 }}
        liClassName={'jf-font-r'}
        tabs={[
          { id: "inbox", name: "Inbox" },
          { id: "mass", name: "Mass" },
          { id: "auto", name: "Automated" },
        ]}
        tab={{ id: paramsUrl.tab }}
        setTab={(tab) => {
          navigate('/messages/' + tab.id)
        }}
      />}
      <div style={{ flexGrow: 1 }} className="jf-flex-col">
        {paramsUrl.tab === "inbox" && <InboxTab/>}
        {paramsUrl.tab === "mass" && <MassTab/>}
        {paramsUrl.tab === "auto" && <AutoTab/>}
      </div>

    </div>
    <BottomMenu/>
  </>
}

export default Messages;
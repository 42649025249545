const StopIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '20',
  height = '35',
  viewBox = '0 0 20 23.018'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
  <g id="power" transform="translate(-1.999 -1)">
  <path id="Path_6322" data-name="Path 6322" d="M7.5,1V12.666H9.167V1Z" transform="translate(3.666 0)" fill={fill}/>
  <path id="Path_6323" data-name="Path 6323" d="M3.667,12.311a8.331,8.331,0,0,1,4.3-7.291L7.155,3.563A10,10,0,1,0,17,3.651l-.835,1.442a8.333,8.333,0,1,1-12.5,7.218Z" transform="translate(0 1.708)" fill={fill}/>
  </g>
</svg>


export default StopIcon;
import React from 'react';
const SyncIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '20',
  height = '15.547',
  viewBox = '0 0 20 15.547'
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g id="arrow-repeat" transform="translate(-0.284 -2.002)" opacity={fillOpacity}>
      <path id="Path_6313" data-name="Path 6313" d="M14.864,7.255h5.1a.324.324,0,0,1,.249.531l-2.548,3.059a.324.324,0,0,1-.5,0L14.615,7.786a.324.324,0,0,1,.249-.531ZM.608,9.847H5.7a.324.324,0,0,0,.249-.531L3.4,6.257a.324.324,0,0,0-.5,0L.359,9.315a.324.324,0,0,0,.249.531Z" transform="translate(0 1.225)" fill={fill}/>
      <path id="Path_6314" data-name="Path 6314" d="M9.752,3.3a6.465,6.465,0,0,0-5,2.356.648.648,0,1,1-1-.824A7.779,7.779,0,0,1,17.42,8.48H16.1A6.483,6.483,0,0,0,9.752,3.3ZM3.4,11.072A6.483,6.483,0,0,0,14.75,13.9a.648.648,0,1,1,1,.824A7.779,7.779,0,0,1,2.083,11.072Z" transform="translate(0.533 0)" fill={fill} fillRule="evenodd"/>
    </g>
  </svg>
);
export default SyncIcon;

import { atom } from 'recoil'

export const swiperMediaDetailAtom = atom({
  key: 'swiperMediaDetail',
  default: {
    'loading': false
  }
});

export const swiperMediaLoopAtom = atom({
  key: 'swiperMediaLoop',
  default: {
    mediaType: null,
    activeIndex: 0,
    loading: true,
    moveIndex: -1,
    lastItem: null,
    restart: false
  }
});

export const swiperMediaDashboardAtom = atom({
  key: 'swiperMediaDashboard',
  default: {
    activeIndex: 0,
    loading: true,
    moveIndex: -1,
    filter: null,
    restart: false
  }
});
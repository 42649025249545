
import OvenLiveKit from 'ovenlivekit'

const OvenLivekitTool = {
  instance: null,
  config: {},
  init: async (videoElement) => {
    let oven = OvenLiveKit.create();
    oven.attachMedia(videoElement,{ callbacks: OvenLivekitTool.config});

    const constrains = {
      audio: true,
      video: true
    };
    await oven.getUserMedia(constrains);

    OvenLivekitTool.instance = oven
  },
  remove: () => {
    if (OvenLivekitTool.instance) {
      OvenLivekitTool.instance.remove();
      OvenLivekitTool.instance = null;
    }
  }
}

export default OvenLivekitTool;
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "../../../components/basic/text-field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useFetchApiAction,
  useResetStateWhenChangeUrlAction,
} from "../../../action/main-action";
import TopMenu from "../../../components/basic/top-menu.js";
import Mutations from "../../../params/mutations.js";
import { useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";
import { authProfileAtom } from "../../../state/auth";
import BottomMenu from "../../../components/basic/bottom-menu";
import { accountCreatorRequestAtom } from "../../../state/pages/account";
import { setSeparateState } from "../../../helpers/utils";
import Queries from "../../../params/queries";

const schema = yup
  .object({
    instagram: yup.string().required("you need to set your instagram id"),
    twitter: yup.string().required("you need to set your twitter id"),
  })
  .required();

function CreatorRequest() {
  const navigate = useNavigate();
  const fetchAction = useFetchApiAction();
  const authProfile = useRecoilValue(authProfileAtom);
  const isCreator = !!authProfile?.role?.creator;

  const [{ msg, isLoading, isSuccess, hasPendingRequest }, setCreatorRequest] =
    useRecoilState(accountCreatorRequestAtom);
  const resetCreatorRequest = useResetRecoilState(accountCreatorRequestAtom);
  const setMsg = setSeparateState(setCreatorRequest, "msg");
  const setIsLoading = setSeparateState(setCreatorRequest, "isLoading");
  const setIsSuccess = setSeparateState(setCreatorRequest, "isSuccess");
  const setHasPendingRequest = setSeparateState(
    setCreatorRequest,
    "hasPendingRequest"
  );
  const resetStateAction = useResetStateWhenChangeUrlAction();

  useEffect(() => {
    resetStateAction.add(resetCreatorRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreator) {
      navigate("/studio");
    }
  }, [isCreator, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isCreator) {
      getCreatorRequest();
    }
  }, [isCreator]);

  const getCreatorRequest = () => {
    setIsLoading(true);

    fetchAction(Queries.Creator.CreatorRequest)
      .then((res) => {
        const data = res?.creator_request;
        if (data && data.status === "none") {
          setHasPendingRequest(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setIsSuccess(false);
    setMsg("");

    fetchAction(Mutations.CreatorSignup, data).then((res) => {
      console.log(res);
      if (res && res.creator_signup && res.creator_signup.user) {
        setIsSuccess(true);
      } else if (res.errors && res.errors[0]) {
        setMsg(res.errors[0].message);
      }

      setIsLoading(false);
    });
  };

  return (
    <>
      <TopMenu
        title={"Creator Request"}
        icon={"greater-icon"}
        action={() => navigate(-1)}
      />
      <div className="jf-basic-page">
        <h1 className="jf-title">Become a creator</h1>
        {!isLoading && isSuccess ? (
          <div className={"jf-text-field-msg success"}>
            Creator request submitted. A person in our stuff will look at your
            application and we'll notify you about approval status.
          </div>
        ) : !isLoading && hasPendingRequest ? (
          <div className={"jf-text-field-msg error"}>
            We're currently reviewing your request. We'll notify you about status changes.
          </div>
        ) : (
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <div className="jf-input-boxs">
              <TextField
                placeholder={"Instagram"}
                error={errors.instagram?.message}
                register={register("instagram")}
              />
              <TextField
                placeholder={"Twitter"}
                error={errors.twitter?.message}
                register={register("twitter")}
              />
            </div>
            {msg && <div className={"jf-text-field-msg error"}>{msg}</div>}
            <ul className="jf-btn-ul">
              <li className="jf-btn-ul-li">
                <button
                  className="jf-btn jf-basic-btn jf-mt-20px"
                  type={"submit"}
                >
                  Submit request
                </button>
              </li>
            </ul>
          </form>
        )}
      </div>
      <BottomMenu />
    </>
  );
}

export default CreatorRequest;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";

import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { setSeparateState } from '../../../helpers/utils'
import { accountEmailVerifyAtom } from "../../../state/pages/account";
import Mutations from "../../../params/mutations";
import TopMenu from "../../../components/basic/top-menu";
import variables from "../../../scss/variables.module.scss";

function EmailVerify() {
  const navigate = useNavigate();
  const fetchAction = useFetchApiAction();
  const [{isLoading}, setAccountEmailVerify] = useRecoilState(accountEmailVerifyAtom);
  const setIsLoading = setSeparateState(setAccountEmailVerify, "isLoading");
  const setIsInvalidToken = setSeparateState(setAccountEmailVerify, "isInvalidToken");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetAccountEmailVerify = useResetRecoilState(accountEmailVerifyAtom);

  useEffect(() => {
    resetStateAction.add(resetAccountEmailVerify)

    const params = new URLSearchParams(window.location.search);
    const hash = params.get("hash");

    if (!hash) {
      navigate('/');
      return setIsInvalidToken(true);
    }

    setIsLoading(true);
    fetchAction(Mutations.Profile.VerifyEmail, { hash }).then(
      (res) => {
        if (res.verify_email) {
          navigate('/account');
        } else {
          navigate('/');
          setIsInvalidToken(true);
        }
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopMenu
        title={"Email Verify"}
        icon={"greater-icon"}
        action={() => navigate(-1)}
      />
      <div className="jf-basic-page">
        <h1 className="jf-title">Email Verify</h1>
        {isLoading && (
          <div className="jf-spin-container">
            <Puff
              wrapperStyle={{justifyContent: 'center'}}
              color={variables["colors-jf-secondary"]}
              height={50}
              width={50}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default EmailVerify;

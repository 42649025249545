import variables from '../../../scss/variables.module.scss'

const CloseCircleIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillCircle= variables['colors-jf-primary'],
  fillNone = 'none',
  width = '25',
  height = '25',
  viewBox = '0 0 25 25'
}) =><svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
  <g id="Group_2072" data-name="Group 2072" transform="translate(-1885 -225)">
    <circle id="Ellipse_12" data-name="Ellipse 12" cx="12.5" cy="12.5" r="12.5" transform="translate(1885 225)" fill={fillCircle}/>
    <g id="Group_2056" data-name="Group 2056" transform="translate(1890 230)">
      <rect id="Rectangle_1094" data-name="Rectangle 1094" width="15" height="15" fill="rgba(255,255,255,0)" opacity="0.3"/>
      <g id="Group_1081" data-name="Group 1081" transform="translate(2 2.5)">
        <rect id="Rectangle_1095" data-name="Rectangle 1095" width="2" height="13.422" rx="1" transform="translate(9.491 -0.5) rotate(45)" fill={fill}/>
        <rect id="Rectangle_1096" data-name="Rectangle 1096" width="2" height="13.422" rx="1" transform="translate(0.001 0.914) rotate(-45)" fill={fill}/>
      </g>
    </g>
  </g>
</svg>

export default CloseCircleIcon;
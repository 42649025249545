import GreaterIcon from './greater-icon'
import CheckIcon from './check-icon'
import CloseIcon from './close-icon'
import HidePasswordIcon from './hide-password-icon'
import ShowpasswordIcon from './show-password-icon'
import CopyIcon from './copy-icon'
import SendIcon from './send-icon'
import SendFillIcon from './send-fill-icon'
import JunefoxLogoIcon from './junefox-logo-icon'
import HideshowIcon from './hideshow-icon'
import ThreeDotsIcon from './three-dots-icon'
import SoundIcon from './sound-icon'
import MoneyIcon from './money-icon'
import CheckcircleIcon from './checkcircle-icon'
import UploadIcon from './upload-icon'
import VideoRecordIcon from './video-record-icon'
import LiveStreamingIcon from './live-streaming-icon'
import WarningIcon from './warning-icon'
import CopyFillIcon from './copy-fill-icon'
import SyncIcon from './sync-icon'
import VideoCameraIcon from './video-camera-icon'
import CheckmarkRoundIcon from './check-mark-round-icon'
import BackCloseIcon from './back-close-icon'
import SoundStopIcon from './sound-stop-icon'
import SoundPlayIcon from './sound-play-icon'
import LikeBorderIcon from './like-border-icon'
import StopIcon from './stop-icon'
import ClockIcon from './clock-icon'
import PhotoFillIcon from './photo-fill-icon'
import CloudPlusIcon from './cloud-plus-icon'
import CloseCircleIcon from './close-circle-icon'
import PhotoCameraIcon from './photocamera-icon'
import ChatIcon from './chat-icon';
import CookieIcon from './cookie-icon'
import BurgerMenuIcon from "./burger-menu-icon";
import JuneFoxIcon2 from "./junefox-icon";
import ShareIcon2 from "./share-icon";

const icons = {
    'greater-icon': GreaterIcon,
    'check-icon': CheckIcon,
    'close-icon': CloseIcon,
    'hide-password-icon': HidePasswordIcon,
    'show-password-icon': ShowpasswordIcon,
    'copy-icon': CopyIcon,
    'send-icon': SendIcon,
    'send-fill-icon': SendFillIcon,
    'junefox-logo-icon': JunefoxLogoIcon,
    'hideshow-icon': HideshowIcon,
    'three-dots-icon': ThreeDotsIcon,
    'sound-icon': SoundIcon,
    'money-icon': MoneyIcon,
    'check-circle-icon': CheckcircleIcon,
    'upload-icon': UploadIcon,
    'video-record-icon': VideoRecordIcon,
    'live-streaming-icon': LiveStreamingIcon,
    'warning-icon': WarningIcon,
    'copy-fill-icon': CopyFillIcon,
    'sync-icon': SyncIcon,
    'video-camera-icon': VideoCameraIcon,
    'check-mark-round-icon': CheckmarkRoundIcon,
    'back-close-icon': BackCloseIcon,
    'sound-stop-icon': SoundStopIcon,
    'sound-play-icon': SoundPlayIcon,
    'like-border-icon': LikeBorderIcon,
    'stop-icon': StopIcon,
    'clock-icon': ClockIcon,
    'photo-fill-icon': PhotoFillIcon,
    'cloud-plus-icon': CloudPlusIcon,
    'close-circle-icon': CloseCircleIcon,
    'photo-camera-icon': PhotoCameraIcon,
    'chat-icon': ChatIcon,
    'cookie-icon': CookieIcon,
    'burger-menu-icon': BurgerMenuIcon,
    'junefox-icon2': JuneFoxIcon2,
    'share-icon2': ShareIcon2
}

export default icons;
import React from 'react';
const PhotoCameraIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '40',
  height = '32.465',
  className = '',
  viewBox = '0 0 40 32.465'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <g id="photo-camera" transform="translate(0 -46.15)">
        <g id="Group_1147" data-name="Group 1147" transform="translate(0 46.15)">
          <path id="Path_5629" data-name="Path 5629" d="M0,56.085V73.734a4.884,4.884,0,0,0,4.882,4.882H35.118A4.884,4.884,0,0,0,40,73.734V56.085a4.648,4.648,0,0,0-4.645-4.645h-6.5l-.155-.678A5.934,5.934,0,0,0,22.89,46.15H17.1a5.947,5.947,0,0,0-5.812,4.612l-.155.678H4.645A4.653,4.653,0,0,0,0,56.085ZM11.935,53.44a1,1,0,0,0,.971-.776l.335-1.453A3.949,3.949,0,0,1,17.1,48.15H22.89a3.948,3.948,0,0,1,3.861,3.061l.335,1.453a1,1,0,0,0,.971.776h7.29a2.644,2.644,0,0,1,2.645,2.645V73.734a2.881,2.881,0,0,1-2.882,2.882H4.882A2.881,2.881,0,0,1,2,73.734V56.085A2.644,2.644,0,0,1,4.645,53.44h7.29Z" transform="translate(0 -46.15)" fill={fill}/>
          <circle id="Ellipse_95" data-name="Ellipse 95" cx="1.339" cy="1.339" r="1.339" transform="translate(5.429 10.22)" fill={fill}/>
          <path id="Path_5630" data-name="Path 5630" d="M150.5,191.95a8.4,8.4,0,1,0-8.4-8.4A8.414,8.414,0,0,0,150.5,191.95Zm0-14.8a6.4,6.4,0,1,1-6.4,6.4A6.41,6.41,0,0,1,150.5,177.15Z" transform="translate(-130.5 -164.619)" fill={fill}/>
        </g>
      </g>
    </svg>
  </>
);
export default PhotoCameraIcon;
import React, {Component} from 'react'
import SVGIcon from './svg/index'
import {Link} from 'react-router-dom';

class Usernamepostdetail extends Component {
    render() {
        const {media, tab} = this.props;
        return (
            <>
                <div className="jf-users-detail">
                    <h3 className="jf-users-detail-name responsive-display">
                        <Link to={'/' + media.user.username} className="jf-span-username">
                            {'@' + media.user.username}
                        </Link></h3>
                    <p className="jf-users-detail-title">{media.description}</p>
                    <p className="jf-users-detail-tag">
                        {media.tags && [...new Set(media.tags)].slice(0, 3).map(tag =>
                            <Link to={'/' + tab + '/' + tag} className="jf-span-tag"
                                  key={'tag-' + tag}>{'#' + tag}</Link>
                        )}
                    </p>
                    {media.song?.name && <div className="jf-users-detail-soundtrack">
                        <div className="jf-users-detail-soundtrack-icon"><SVGIcon name="sound-icon"/></div>
                        {media.song.name}
                    </div>}
                </div>
            </>
        );
    }
}
export default Usernamepostdetail;
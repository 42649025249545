import React from 'react'

const ConsentIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '18',
  height = '22.004',
  className = '',
  viewBox = '0 0 18 22.004'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Consent"
          d="M11.14,1h7.605A2.225,2.225,0,0,1,21,3.2V20.8a2.225,2.225,0,0,1-2.25,2.2H5.245A2.225,2.225,0,0,1,3,20.8V8.96a3.263,3.263,0,0,1,.99-2.332l4.77-4.664A3.414,3.414,0,0,1,11.14,1ZM5.245,9.8v11h13.5V3.2H12V8.7A1.113,1.113,0,0,1,10.87,9.8ZM7.5,12.55v1.1a.556.556,0,0,0,.563.55h7.875a.556.556,0,0,0,.562-.55v-1.1a.556.556,0,0,0-.562-.55H8.058a.556.556,0,0,0-.562.55Zm.563,3.85h4.5a.556.556,0,0,1,.563.55v1.1a.556.556,0,0,1-.562.55h-4.5a.556.556,0,0,1-.562-.55v-1.1A.556.556,0,0,1,8.058,16.4Z"
          transform="translate(-2.995 -0.996)" fillRule="evenodd"/>
  </svg>

}

export default ConsentIcon;
import { atom } from 'recoil'

export const errPassAtom = atom({
  key: 'eitProfileErrPass',
  default: {
    min: '',
    regex: ''
  }
});

export const editProfileAtom = atom({
  key: 'editProfilePage',
  default: {
    avatar: null
  }
});

export const editProfileCompleteAtom = atom({
  key: 'editProfileComplete',
  default: {
    dataForm: {gender: null, interests: []}
  }
});
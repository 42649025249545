import { atom } from 'recoil'

export const searchCreatorAtom = atom({
  key: 'searchCreator',
  default: {
    users:[],
    queryResult: [],
    query: '',
    page: 0,
    loadingMore: false,
    searching: true,
    indexQueryResult: -1,
    inputting:false
  }
});

export const searchFavoriteAtom = atom({
  key: 'searchFavorite',
  default: {
    mediaLiked: {
      videos: [],
      photos: []
    },
    mediaPurchase: {
      videos: [],
      photos: []
    },
    query: '',
    loading: true,
    loadingMore: 2
  }
});

export const searchMediaAtom = atom({
  key: 'searchMedia',
  default: {
    media: {
      videos: [],
      photos: []
    },
    query: '',
    loading: true,
    loadingMore: 0
  }
});

export const searchTagsAtom = atom({
  key: 'searchTags',
  default: {
    media: {
      videos: [],
      photos: []
    },
    query: '',
    loading: true,
    loadingMore: 0,
    queryResult: [],
    queryTag: "",
    indexQueryResult: 0,
    inputting: false
  }
});

export const tagListsAtom = atom({
  key: 'tagList',
  default: []
});
import React, { useEffect } from 'react';
import TopMenu from '../../../components/basic/top-menu'
import { ProfileDetail } from '../user-profile/profile-detail'
import {useUserAction} from '../../../action/user-action';
import BottomMenu from '../../../components/basic/bottom-menu'
import { useNavigate } from 'react-router-dom'
import MediaTabs from '../../../components/complex/media-tabs'
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil'
import { getTab, setSeparateState, uniqueList } from '../../../helpers/utils'
import {
  authProfileAtom,
} from '../../../state/auth'
import {
  accountAuthProfileAtom
} from '../../../state/pages/account'
import InfiniteScroll from '../../../components/complex/infinite-scroll'
import { useMediaAction } from '../../../action/media-action'
import Constants from '../../../params/constants'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'

const AuthProfile = (props) => {
  const navigate = useNavigate();
  const userAction = useUserAction();
  const authProfile = useRecoilValue(authProfileAtom);
  const [{media, loading,loadingMore, page, activeTab}, setAccountAuthProfile] = useRecoilState(accountAuthProfileAtom);
  const resetAccountAuthProfile = useResetRecoilState(accountAuthProfileAtom);
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const mediaAction = useMediaAction(false);
  const limit = Constants.MediaListLimit;

  const setMedia = setSeparateState(setAccountAuthProfile, "media");
  const setLoading = setSeparateState(setAccountAuthProfile, "loading");
  const setLoadingMore = setSeparateState(setAccountAuthProfile, "loadingMore");
  const setPage = setSeparateState(setAccountAuthProfile, "page");
  const setActiveTab = setSeparateState(setAccountAuthProfile, "activeTab");

  useEffect(() => {
    resetStateAction.add(resetAccountAuthProfile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    navigate('/profile/edit');
  }

  useEffect(() => {
    let isCancelled = false;
    const mediaType = getTab(activeTab);
    userAction.getAuthProfile(mediaType).then((res) => {
      if (res && !isCancelled) {
        setMedia((old) => {
          return {...old, [mediaType]: res[mediaType].map(item => {
              item.user = res.profile;
              return item;
            })}
        })
        setLoading(false)
        setLoadingMore(res[mediaType].length === limit);
        setPage(1);
      }
    })
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, props.paramsUrl.tab]);

  useEffect(() => {
    const key = getTab(activeTab || 'video');
    if (!loadingMore || page < 2 || !media[key]) return;
    let isCanceled = false;
    setLoadingMore(true);
    const params = {
      limit: { order: false, skip: media[key].length },
      filter: { username: authProfile.username }, withLike: false
    }
    mediaAction.getMedias(activeTab, params).then(res => {
      if (!res || isCanceled) {
        setLoadingMore(false);
        return;
      }
      setMedia((old) => {
        return {...old, [key]: uniqueList(old[key].concat(res))}
      })
      setLoadingMore(res.length === limit);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return <>
    <TopMenu
      icon={'greater-icon'}
      action={() => navigate(-1)}
      title={"Profile"}
    />
    <div className={'jf-user-profile-page has-bottom-menu'}>
      <ProfileDetail isOfAuth={true} onClick={onClick}/>
      <InfiniteScroll setPage={setPage} loadingMore={loadingMore}>{
        (loadDiv) => <MediaTabs
          loading={loading}
          media={media}
          href={(tab) => {
            setActiveTab(tab);
            setPage(0);
          }}
          navigate={navigate}
          profile={authProfile}
          loadDiv={loadDiv}
          classContain={loadingMore ? 'jf-mb-50px' : ""}
          activeTab={activeTab}/>
      }
      </InfiniteScroll>
    </div>
    <BottomMenu/>
  </>;
}

export default AuthProfile;
import { atom } from 'recoil'

export const accountAuthProfileAtom = atom({
  key: 'accountAuthProfile',
  default: {
    loading: false,
    loadingMore: false,
    activeTab: "video",
    page: 0,
    media: {
      videos: [],
      shop: [],
      photos: [],
    },
  }
});

export const accountBlockedListAtom =atom({
  key: 'accountBlockedList',
  default: []
})

export const accountEarningsAtom =atom({
  key: 'accountEarnings',
  default: {
    unpaid: 0,
    lastPayment: null,
    list: [],
    loading:false,
    limited:true,
  }
})

export const accountPurchasesAtom =atom({
  key: 'accountPurchases',
  default: {
    list: [],
    cards: [],
    activeCol: "date",
    wallet: {}
  }
})

export const accountEmailNotificationAtom =atom({
  key: 'accountEmailNotification',
  default: false
})

export const accountSubscriptionAtom =atom({
  key: 'accountSubscription',
  default: {
    checked: true,
    trialChecked: true,
    loading: true,
    saving: false
  }
})

export const accountCreatorRequestAtom =atom({
  key: 'accountCreatorRequest',
  default: {
    msg: null,
    isLoading: false,
    isSuccess: false,
    hasPendingRequest: false,
  }
})

export const accountMyMediaAtom =atom({
  key: 'accountMyMedia',
  default: {
    loadingMore: 0,
    loading: false,
    data: [],
  }
})

export const accountEditMediaAtom = atom({
  key: 'accountEditMedia',
  default: {
    tab: "everyone",
    media: null,
    edit: false,
    tabRelease: "Now",
    availableDate: new Date(),

  }
})

export const accountEmailVerifyAtom = atom({
  key: 'accountEmailVerifyAtom',
  default: {
    isLoading: false,
    isInvalidToken: true,
  }
});

import React from 'react';
import { useRecoilValue } from 'recoil'
import { activePopupState } from '../../../state/components/popup'

const CameraOptions = ({hide}) => {
  const info = useRecoilValue(activePopupState('camera_options'));

  const blockBtn = (chosen) => {
    hide();
    info.setDevice(chosen)
  }

  return <>
    <div className={'jf-popup-camera_options-item'}>

      <label className="jf-radio container">Front Camera
        <input type={'radio'} name={'camera_option'}
               onClick={() => blockBtn('front')}
               defaultChecked={info.device === "front"}/>
          <span className="checkmark"/>
      </label>
    </div>
    <div className={'jf-popup-camera_options-item'}>
      <label className="jf-radio container">Back Camera
        <input type={'radio'} name={'camera_option'}
               onClick={() => blockBtn('back')}
               defaultChecked={info.device === "back"}/>
        <span className="checkmark"/>
      </label>
    </div>
  </>;
}

export default CameraOptions;
import React from 'react';
const BlockIcon = ({
  style = {},
  fill = '#707070',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1101" data-name="Group 1101" transform="translate(-40 -759)">
        <g id="Ellipse_79" data-name="Ellipse 79" transform="translate(40 759)" fill="#fff" stroke={fill} strokeWidth="1">
          <circle cx="12" cy="12" r="12" stroke={fillNone} />
          <circle cx="12" cy="12" r="11.5" fill={fillNone} />
        </g>
        <line id="Line_1" data-name="Line 1" x2="24" transform="translate(43.5 762.5) rotate(45)" fill={fillNone} stroke={fill} strokeWidth="1" />
      </g>
    </svg>
  </>
);
export default BlockIcon;
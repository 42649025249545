import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import Button from '../../basic/button'
import { TextField } from '../../basic/text-field'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { 
  isValid, 
  isExpirationDateValid, 
  isSecurityCodeValid, 
} from 'creditcard.js';
import { useFetchApiAction } from '../../../action/main-action';
import Mutations from '../../../params/mutations';
import { accountPurchasesAtom } from '../../../state/pages/account';
import { setSeparateState } from '../../../helpers/utils';


const schema = yup.object({
  card_name: yup.string().required().label('Card Name'),
  card_number: yup.string().required().label('Card Number'),
  expire_date: yup.string().required().label('Expire Date'),
  cvv: yup.string().required().label('Card Verification Value'),
}).required();

const AddCard = ({hide}) => {
  // const info = useRecoilValue(activePopupState('add_card'));
  const fetchAction = useFetchApiAction();
  const [{cards}, setPurchases] = useRecoilState(accountPurchasesAtom);
  const setCards = setSeparateState(setPurchases, "cards");
  const setWallet = setSeparateState(setPurchases, "wallet");

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    if (!isValid(data.card_number)) {
      return setError('card_number', {message: 'Card Number is invalid'})
    }

    const monthYearArray = data.expire_date?.split('/')
    if (!isExpirationDateValid(monthYearArray[0], monthYearArray[1])) {
      return setError('expire_date', {message: 'Expire Date is invalid'})
    }

    if (!isSecurityCodeValid(data.card_number, data.cvv)) {
      return setError('cvv', {message: 'Card Verification Value is invalid'})
    }

    const updatedCards = Object.assign([], cards)

    updatedCards.push({
      name: data.card_name,
      number: data.card_number,
      cvv: data.cvv,
      date: {
        month: monthYearArray[0],
        year: monthYearArray[1]
      }
    })

    fetchAction(Mutations.UpdateWallet, { cards: updatedCards }).then((res) => {
      if (res && res.updateWallet) {
        setCards(res.updateWallet.cards || [])
        setWallet(res.updateWallet)
        hide()
      } else if (res.errors && res.errors[0]) {
        // setMsg(res.errors[0].message);
      }
    });
  }
  return <>
    <div className={'jf-popup-report-title'}>
      Add new card
    </div>
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={'jf-popup-report-content'}>
        <TextField
          styleContain={{marginBottom: 15}}
          placeholder={'Name on card'}
          register={register("card_name")}
          error={errors.card_name?.message}
        />

        <TextField
          styleContain={{marginBottom: 15}}
          placeholder={'Card number'}
          register={register("card_number")}
          error={errors.card_number?.message}
        />
        <div className={'jf-flex-row jf-mb-15px'} style={{gap: 10}}>
          <TextField
            placeholder={'MM/YY'}
            register={register("expire_date")}
            error={errors.expire_date?.message}
          />
          <TextField
            placeholder={'CVV'}
            register={register("cvv")}
            error={errors.cvv?.message}
          />
        </div>
      </div>
      <div className={'jf-mb-20px'}>
        <Button className={'medium jf-m-auto'}>Add card</Button>
      </div>
    </form>
  </>;
}

export default AddCard;
import HomeIcon from './home-icon'
import CommentIcon from './comment-icon'
import AddIcon from './add-icon'
import SearchIcon from './search-icon'
import UserIcon from './user-icon'
import HomeIcon2 from './other/home-icon'
import CommentIcon2 from './other/comment-icon'
import AddIcon2 from './other/add-icon'
import SearchIcon2 from './other/search-icon'
import UserIcon2 from './other/user-icon'

const icons = {
  'home-icon': HomeIcon,
  'add-icon': AddIcon,
  'comment-icon': CommentIcon,
  'search-icon': SearchIcon,
  'user-icon': UserIcon,
  'home-icon2': HomeIcon2,
  'add-icon2': AddIcon2,
  'comment-icon2': CommentIcon2,
  'search-icon2': SearchIcon2,
  'user-icon2': UserIcon2,
};

export default icons;
import React, { useEffect } from 'react'
import Avatar from '../../../components/basic/avatar'
import { useUserAction } from '../../../action/user-action'
import SearchTextField from '../../../components/basic/search-text-field'
import {Link} from 'react-router-dom'
import InfiniteScroll from '../../../components/complex/infinite-scroll'
import {useNavigate} from 'react-router-dom'
import Constants from '../../../params/constants'
import { searchCreatorAtom } from '../../../state/pages/search'
import {useRecoilState, useResetRecoilState} from 'recoil';
import { setSeparateState, uniqueList } from '../../../helpers/utils'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'

const Creators = () => {
  const [{users, queryResult, query, page, loadingMore, searching, indexQueryResult, inputting}, setSearchCreator] = useRecoilState(searchCreatorAtom);
  const resetSearchCreator = useResetRecoilState(searchCreatorAtom);
  const setUsers = setSeparateState(setSearchCreator, "users")
  const setQueryResult = setSeparateState(setSearchCreator, "queryResult")
  const setQuery = setSeparateState(setSearchCreator, "query")
  const setPage = setSeparateState(setSearchCreator, "page")
  const setLoadingMore = setSeparateState(setSearchCreator, "loadingMore")
  const setSearching = setSeparateState(setSearchCreator, "searching")
  const setIndexQueryResult = setSeparateState(setSearchCreator, "indexQueryResult")
  const setInputting = setSeparateState(setSearchCreator, "inputting")
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const navigate = useNavigate();
  const userAction = useUserAction(false);
  const limit = Constants.ListLimit;

  useEffect(() => {
    resetStateAction.add(resetSearchCreator)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!searching) return
    let isCanceled = false;
    const params = { filter: { query }, limit: { limit: limit } }
    userAction.creators(params).then(res => {
      if (isCanceled) return;
      setUsers(res);
      setLoadingMore(res.length === limit);
      setSearching(false)
      setInputting(true)
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searching]);

  useEffect(() => {
    if (!inputting) return
    let isCanceled = false;
    if (query !== '') {
      const params = { filter: { query }, limit: { limit: 5 } }
      userAction.creators(params).then(res => {
        if (!res || isCanceled) return;
        setQueryResult(res);
        setIndexQueryResult(-1)
      })
    } else {
      setQueryResult([])
      setIndexQueryResult(-1)
    }
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (loadingMore || page < 2) return;
    let isCanceled = false;
    setLoadingMore(true);
    const params = { filter: {}, limit: { limit: limit, skip: users.length } }
    userAction.creators(params).then(res => {
      if (isCanceled) return;
      setUsers(uniqueList(users.concat(res)));
      setLoadingMore(res.length === limit);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return <>
    <SearchTextField onChange={setQuery}
                     onKeyDown={(e) => {
                       if (e === null || (e.key === 'Enter' && query !== '' && indexQueryResult === -1)) {
                         setQuery(query)
                         setQueryResult([])
                         setSearching(true)
                         return;
                       }
                       if (e && (e.keyCode === 38 || e.keyCode === 40)) {
                         e.preventDefault();
                         e.cancelBubble = true;
                         e.returnValue = false
                       }
                       if (e.key === 'Enter') {
                         navigate('/' + queryResult[indexQueryResult].username)
                       } else if (e.keyCode === 38 && indexQueryResult > -1) {
                         setIndexQueryResult(indexQueryResult - 1);
                       } else if (e.keyCode === 40 && indexQueryResult < queryResult.length - 1) {
                         setIndexQueryResult(indexQueryResult + 1);
                       }
                     }}/>
    {query !== '' && <ul className={'jf-create-result'}>
      {queryResult.map((item,i) => <li key={'jf-create-result-' + item.id}
                                   className={(i === indexQueryResult ? 'active' : '')}>
        <Link to={'/' + item.username}>
          <Avatar image={item.avatar} text={item.username} width={40} height={40}/>
          <div className="jf-fullname">{item.fullname}</div>
          <div className="jf-username">@{item.username}</div>
        </Link>
      </li>)}
    </ul>}
    <InfiniteScroll setPage={setPage} loadingMore={loadingMore}>
      {(loadDiv) =>
        <div className="jf-creatorslist">
          {users.map((item, index) => (
              <div className="jf-creatorsBox" key={index}>
                <Link to={'/' + item.username}>
                  <Avatar image={item.avatar} text={item.username} width={105} height={105}/>
                  <div className="jf-username">{item.username}</div>
                </Link>
              </div>
            )
          )}
          {loadDiv}
        </div>
      }</InfiniteScroll>
  </>
}

export default Creators;
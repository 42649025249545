import React from 'react';

const BurgerMenuIcon = ({
                            style = {},
                            fill = '#f5f5f7',
                            fillOpacity = '0.5',
                            fillNone = 'none',
                            width = '30',
                            viewBox = '0 0 30 30'
                        }) => (
    <>
        <svg width={width} height={width} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M4 18L20 18" stroke={fill} strokeWidth="2" strokeLinecap="round"/>
            <path d="M4 12L20 12" stroke={fill} strokeWidth="2" strokeLinecap="round"/>
            <path d="M4 6L20 6" stroke={fill} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    </>
);
export default BurgerMenuIcon;
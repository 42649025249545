import React from 'react';
const MoneyIcon = ({
  style = {},
  fill = "#130c18",
  fillBorder = '#afafb3',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '12.771',
  height = '23',
  className = '',
  viewBox = '0 0 12.771 23'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <path id="Path_42" data-name="Path 42" d="M188.663,131.174v-5.049c1.469.36,2.554,1.305,2.554,2.392a1.277,1.277,0,0,0,2.554,0c0-2.467-2.2-4.531-5.108-5v-1.38a1.277,1.277,0,1,0-2.554,0v1.38c-2.912.474-5.108,2.537-5.108,5s2.2,4.531,5.108,5v5.049c-1.469-.36-2.554-1.305-2.554-2.392a1.277,1.277,0,0,0-2.554,0c0,2.467,2.2,4.531,5.108,5v1.393a1.277,1.277,0,0,0,2.554,0v-1.393c2.912-.474,5.108-2.538,5.108-5S191.574,131.648,188.663,131.174Zm-5.108-2.658c0-1.086,1.085-2.031,2.554-2.392v4.783C184.64,130.548,183.554,129.6,183.554,128.517Zm5.108,10.054v-4.783c1.469.36,2.554,1.305,2.554,2.392S190.131,138.21,188.663,138.571Z" transform="translate(-181 -120.854)" fill={fill}/>
    </svg>
  </>
);
export default MoneyIcon;

import React from 'react';
const VideoCameraIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '40',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_2003" data-name="Group 2003" transform="translate(-1665 -123)">
        <rect id="Rectangle_1218" data-name="Rectangle 1218" width="40" height="40" transform="translate(1665 123)" fill={fillNone} />
        <g id="camera-reels" transform="translate(1668.333 126.333)">
          <path id="Path_6298" data-name="Path 6298" d="M12.5,6.25A6.25,6.25,0,1,1,6.25,0,6.25,6.25,0,0,1,12.5,6.25Zm-10.417,0A4.167,4.167,0,1,0,6.25,2.083,4.167,4.167,0,0,0,2.083,6.25Z" fill={fill} />
          <path id="Path_6299" data-name="Path 6299" d="M18.75,6h1.042a4.167,4.167,0,0,1,4.131,3.621L30.4,6.742a2.083,2.083,0,0,1,2.931,1.9V24.19a2.083,2.083,0,0,1-2.929,1.9l-6.481-2.879a4.167,4.167,0,0,1-4.131,3.621H4.167A4.167,4.167,0,0,1,0,22.667v-12.5A4.167,4.167,0,0,1,4.167,6Zm12.5,18.187V8.646l-7.292,3.24v9.063L31.25,24.19ZM2.083,10.167v12.5A2.083,2.083,0,0,0,4.167,24.75H19.792a2.083,2.083,0,0,0,2.083-2.083v-12.5a2.083,2.083,0,0,0-2.083-2.083H4.167A2.083,2.083,0,0,0,2.083,10.167Z" transform="translate(0 6.5)" fill={fill} />
          <path id="Path_6300" data-name="Path 6300" d="M12.25,12.5A6.25,6.25,0,1,0,6,6.25,6.25,6.25,0,0,0,12.25,12.5ZM8.083,6.25a4.167,4.167,0,1,1,4.167,4.167A4.167,4.167,0,0,1,8.083,6.25Z" transform="translate(6.5)" fill={fill} />
        </g>
      </g>
    </svg>

  </>
);
export default VideoCameraIcon;
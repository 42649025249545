import React from 'react';
import BottomMenu from '../../../components/basic/bottom-menu'
import Creators from './creators'
import Media from './media'
import Favorites from './favorites'
import Tags from './tags'
import { authIdAtom } from '../../../state/auth'
import {useRecoilValue} from 'recoil'
import SelectTab from '../../../components/basic/select-tab'
import { useNavigate, useParams } from 'react-router-dom'

const Search = (props) =>  {
  const navigate = useNavigate()
  const params = useParams()
  const tab = params.tab || 'media';
  const authId = useRecoilValue(authIdAtom);

  let list = [
    { id: "media", name: "Media" },
    { id: "tags", name: "Tags" },
    { id: "creators", name: "Creators" },
  ];

  if (authId) {
    list.push({ id: "favorites", name: "Favorites" })
  }

  return  <>
      <SelectTab
        style={{minHeight: 36}}
        className={'jf-searchtypetags'}
        tabs={list}
        tab={{id: tab}}
        setTab={(tab) =>  navigate("/s/" + tab.id)}
      />

      {tab === 'media' && <Media {...props}/>}
      {tab === 'tags' && <Tags {...props}/>}
      {tab === 'creators' && <Creators/>}
      {tab === 'favorites' && <Favorites {...props}/>}
    <BottomMenu/>
  </>
}

export default Search;
import React from 'react';
const NextArrow = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 17.604'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="17.604" viewBox={viewBox}>
      <g id="correct" transform="translate(0 -10.443)">
        <path id="Path_30" data-name="Path 30" d="M23.9,13.068,9.021,27.948a.335.335,0,0,1-.474,0L.1,19.5a.335.335,0,0,1,0-.474l2.053-2.053a.335.335,0,0,1,.474,0l6.159,6.159,12.59-12.59a.335.335,0,0,1,.474,0L23.9,12.594A.335.335,0,0,1,23.9,13.068Z" transform="translate(0)" fill={fill} />
      </g>
    </svg>


  </>
);
export default NextArrow;
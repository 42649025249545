import React from 'react';
import { useMediaAction } from '../../action/media-action'
import FavoriteButton from '../basic/favorite-button'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { updatingAtom, mediaDashboardAtom, mediaBoxAtom } from '../../state/media'
import { getTab, setSeparateState } from '../../helpers/utils'
import { likeBtnAtom } from '../../state/components'
import { searchFavoriteAtom } from '../../state/pages/search'

const LikeButton = ({item, type = 'video'}) => {
  const mediaAction = useMediaAction(false);
  const setUpdating = useSetRecoilState(updatingAtom)
  const [media, setMedia] = useRecoilState(mediaDashboardAtom);
  const [mediaBox, setMediaBox] = useRecoilState(mediaBoxAtom);
  const [{ mediaLiked }, setFavorite] = useRecoilState(searchFavoriteAtom);
  const setMediaLiked = setSeparateState(setFavorite, "mediaLiked");

  const [{ loading }, setLikeBtn] = useRecoilState(likeBtnAtom);
  const setLoading = setSeparateState(setLikeBtn, "loading")

  const size = {width: 35, height: 35};

  const clickBtn = () => {
    if (loading) return;
    setLoading(true);
    mediaAction.like({ media: item.id })
      .then(res => {
        if (typeof res === 'boolean') {
          const newList = media ? media.map((old) => {
            if(old.id === item.id) {
              return {...item, likes: item.likes+(res? 1 : -1), like: res}
            }
            return old;
          }) : []
          const newListBox = mediaBox ? mediaBox.map((old) => {
            if(old.id === item.id) {
              return {...item, likes: item.likes+(res? 1 : -1), like: res}
            }
            return old;
          }) : []
          setUpdating(true);
          setMedia([...newList]);
          setMediaBox([...newListBox]);
          updateFavorite(res)
        }
        setLoading(false);
      })
  }
  const updateFavorite = (res) => {
    const type = getTab(item.type);
    if (res) {
      const find = mediaLiked[type].find(m => m.id === item.id);
      if (!find) {
        setMediaLiked((old) => {
          return {...old, [type]: [item, ...old[type]]};
        });
      } else {
        setMediaLiked((old) => {
          return {
            ...old, [type]: old[type].map(m => {
              if (m.id === find.id) {
                return find;
              }
              return m;
            })
          };
        });
      }
    } else {
      setMediaLiked((old) => {
        return {
          ...old, [type]: old[type].filter(m => m.id !== item.id)
        }});
    }
  }

  return <div className="jf-user-pannel-likes">
    <FavoriteButton onClickCallback={clickBtn}
      count={item.likes} {...size} sameFill={true} enabled={item.like}/>
  </div>;
}

export default LikeButton;
import React from "react";

const ToggleSwitch = ({ keyId, checked, onChange }) => {
  return (
    <div className="jf-toggle-switch">
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
               checked={checked} onChange={onChange}
               name={keyId} id={keyId} />
        <label className="label" htmlFor={keyId}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;

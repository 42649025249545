import React from 'react';
const Instagramicon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.08',
  fillNone = 'none',
  width = '60',
  className = '',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox} >
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_942" data-name="Rectangle 942" width="60" height="60" rx="30" fill={fill} />
        </clipPath>
        <radialGradient id="radial-gradient" cx="0.372" cy="1.001" r="1.247" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#fd5" />
          <stop offset="0.328" stopColor="#ff543f" />
          <stop offset="0.348" stopColor="#fc5245" />
          <stop offset="0.504" stopColor="#e64771" />
          <stop offset="0.643" stopColor="#d53e91" />
          <stop offset="0.761" stopColor="#cc39a4" />
          <stop offset="0.841" stopColor="#c837ab" />
        </radialGradient>
        <radialGradient id="radial-gradient-2" cx="0.161" cy="-0.012" r="0.828" gradientTransform="translate(0 -0.004) scale(1 0.666)" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#4168c9" />
          <stop offset="0.999" stopColor="#4168c9" stopOpacity="0" />
        </radialGradient>
      </defs>
      <g id="instagram" transform="translate(-5.99 -5.99)">
        <g id="instagram-2" data-name="instagram">
          <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(5.99 5.99)" clipPath="url(#clipPath)">
            <path id="Path_3" data-name="Path 3" d="M52.677,65.958l-33.316.032A13.367,13.367,0,0,1,6.022,52.677L5.99,19.361A13.367,13.367,0,0,1,19.3,6.022L52.619,5.99A13.367,13.367,0,0,1,65.958,19.3l.032,33.316A13.363,13.363,0,0,1,52.677,65.958Z" transform="translate(-5.99 -5.99)" fill="url(#radial-gradient)" />
            <path id="Path_4" data-name="Path 4" d="M52.677,65.958l-33.316.032A13.367,13.367,0,0,1,6.022,52.677L5.99,19.361A13.367,13.367,0,0,1,19.3,6.022L52.619,5.99A13.367,13.367,0,0,1,65.958,19.3l.032,33.316A13.363,13.363,0,0,1,52.677,65.958Z" transform="translate(-5.99 -5.99)" fill="url(#radial-gradient-2)" />
          </g>
          <g id="Group_3" data-name="Group 3" transform="translate(17.99 17.99)">
            <circle id="Ellipse_1" data-name="Ellipse 1" cx="2.077" cy="2.077" r="2.077" transform="translate(26.308 5.539)" fill={fill} />
            <path id="Path_6" data-name="Path 6" d="M37.308,47H20.692A9.7,9.7,0,0,1,11,37.308V20.692A9.7,9.7,0,0,1,20.692,11H37.308A9.7,9.7,0,0,1,47,20.692V37.308A9.7,9.7,0,0,1,37.308,47ZM20.692,13.769a6.931,6.931,0,0,0-6.923,6.923V37.308a6.931,6.931,0,0,0,6.923,6.923H37.308a6.931,6.931,0,0,0,6.923-6.923V20.692a6.931,6.931,0,0,0-6.923-6.923Z" transform="translate(-11 -11)" fill={fill} />
            <path id="Path_7" data-name="Path 7" d="M26.692,36.385a9.692,9.692,0,1,1,9.692-9.692A9.7,9.7,0,0,1,26.692,36.385Zm0-16.615a6.923,6.923,0,1,0,6.923,6.923A6.931,6.931,0,0,0,26.692,19.769Z" transform="translate(-8.692 -8.692)" fill={fill} />
          </g>
        </g>
      </g>
    </svg>

  </>
);
export default Instagramicon;
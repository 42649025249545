import { atom } from 'recoil';
import { DCookie } from '../helpers/utils'

export const authIdAtom = atom({
  key: 'authId',
  default: DCookie.getItem('auth_id')
});

export const authProfileAtom = atom({
  key: 'authProfile',
  default: null
});
import React from 'react';
const MessengerIcon = ({
  style = {},
  fill = '#1483fb',
  fillOpacity = '0.08',
  fillNone = 'none',
  width = '60',
  className = '',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="messenger" transform="translate(15 15)">
        <circle id="Ellipse_74" data-name="Ellipse 74" cx="30" cy="30" r="30" transform="translate(-15 -15)" fill={fill} />
        <g id="Group_1092" data-name="Group 1092">
          <path id="Path_5560" data-name="Path 5560" d="M15,0C6.716,0,0,6.217,0,13.888A13.5,13.5,0,0,0,5.589,24.7V30L10.7,27.2a16.132,16.132,0,0,0,4.3.581c8.284,0,15-6.217,15-13.888S23.284,0,15,0Z" fill="#fff" />
          <path id="Path_5561" data-name="Path 5561" d="M100.328,179.36l-3.819-4.074-7.453,4.074,8.2-8.7,3.913,4.074,7.361-4.074Z" transform="translate(-83.838 -160.657)" fill={fill} />
        </g>
      </g>
    </svg>
  </>
);
export default MessengerIcon;
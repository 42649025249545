import React from 'react';
const SoundStopIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '30',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg id="play" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_1224" data-name="Rectangle 1224" width="30" height="30" fill={fillNone} />
      <circle id="Ellipse_139" data-name="Ellipse 139" cx="12" cy="12" r="12" transform="translate(3 3)" fill="#e6e6e6" />
      <g id="Group_2013" data-name="Group 2013" transform="translate(3)">
        <g id="Rectangle_1225" data-name="Rectangle 1225" transform="translate(9 11)" fill={fill} stroke={fill} strokeWidth="1">
          <rect width="2" height="8" rx="0.5" stroke={fillNone} />
          <rect x="0.5" y="0.5" width="1" height="7" fill={fillNone} />
        </g>
        <g id="Rectangle_1226" data-name="Rectangle 1226" transform="translate(13 11)" fill={fill} stroke={fill} strokeWidth="1">
          <rect width="2" height="8" rx="0.5" stroke={fillNone} />
          <rect x="0.5" y="0.5" width="1" height="7" fill={fillNone} />
        </g>
      </g>
    </svg>

  </>
);
export default SoundStopIcon;
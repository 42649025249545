import { atom, selector } from 'recoil';

export const usersAtom = atom({
  key: 'users',
  default: [],
});

export const userFilter = atom({
  key: 'userFilter',
  default: null
})

export const filteredUsers = selector({
  key: 'filteredUsers',
  get: ({get}) => {
    const users = get(usersAtom);
    const filter = get(userFilter);

    return users.filter((item) => filter === null || item.name.indexOf(filter) > -1);
  }
})

export const profileAtom = atom({
  key: 'profile',
  default: null,
});

export const usernameAtom = atom({
  key: 'username',
  default: null,
});

export const userCountAtom = atom({
  key: 'userCount',
  default: 0,
});

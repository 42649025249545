
export const TextAreaField = ({ type, width, label, styleContain, error, register,iconLeft,...props }) => {
  return (
    <div className={'jf-text-field ' + (error || error === ' ' ? 'has-error ' : '') + (label ? 'has-label ' : '')}
         style={{...styleContain, width: width || '100%'}}>
      {
        label && <div className='jf-text-field-label'>
          {label}
        </div>
      }
      <div className={'jf-text-field-input'}>
        <textarea
          {...register}
          {...props}
        />
      </div>
      {error && <div className={'jf-text-field-msg'}>{error}</div>}
    </div>
  );
}

import React, {useEffect, useRef}  from 'react'
import { authProfileAtom } from '../../state/auth'
import { useRecoilState } from 'recoil'
import { useMediaQuery } from 'react-responsive'
import { countFormat, minWidthScreen } from '../../helpers/utils'
import Avatar from '../basic/avatar'
import VideoPlayer from '../swiper/video-player'
import Logo from '../basic/logo'
import LiveBoxChat from './chat'
import Constants from '../../params/constants'
import OvenLivekitTool from '../../helpers/oven-live-kit'
import variables from '../../scss/variables.module.scss'
import { Puff } from 'react-loader-spinner'
import SVGIcon from '../svg'
import { liveBoxAuthAtomFamily } from '../../state/components/live-box'
import { useResetStateWhenChangeUrlAction } from '../../action/main-action'

const LiveBoxAuth = () => {
  const [profile, setProfile] = useRecoilState(authProfileAtom);
  const isBigScreen = useMediaQuery({ query: minWidthScreen })
  const [startCamera, setStartCamera] = useRecoilState(liveBoxAuthAtomFamily("startCamera"));
  const [end, setEnd] = useRecoilState(liveBoxAuthAtomFamily("end"));
  const refVideo = useRef();
  const resetStateAction = useResetStateWhenChangeUrlAction()

  useEffect(() => {
    resetStateAction.add(( ) => setEnd(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (profile && profile.status === 'stop') {
      setEnd(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  useEffect(() => {
    const func = async () => {
      try {
        OvenLivekitTool.remove();
        refVideo.current.muted = true;
        await OvenLivekitTool.init(refVideo.current);

        OvenLivekitTool.instance.startStreaming(
          Constants.WebRTCServer + profile.username + '?direction=send'
        );
        setEnd(false)

      } catch (e) {
        console.log(e);
      }
    }

    if (refVideo.current && startCamera) {
      func()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refVideo, startCamera]);

  const goLive = () => {
    OvenLivekitTool.remove();
    setStartCamera(false)
  }

  const close = () => {
    setEnd(false)
    setProfile(old => ({ ...old, status: '' }));
  }

  return (
    <>{isBigScreen ? <div className={'jf-live-box'}>
        <div className="jf-header2">
          <Logo/>
        </div>
        {profile?.live ? <>
          {profile.livestream === "RTMP" &&
          <div className="jf-dashbord" style={{ position: 'relative' }}><VideoPlayer
            play={true}
            options={{ autoplay: true }}
            sources={[{ type: 'hls', url: 'https://live.junefox.com/live/' + profile.username + '/playlist.m3u8' }]}
          />
          </div>}
        </> : <div className="jf-dashbord empty">
          <div className={'jf-dashbord-message'}>user is not live</div>
        </div>}

        <div className={"jf-dashbord " + (profile?.livestream === "WebRTC" && profile?.live ? "" : "jf-hide")}
             style={{ position: 'relative' }}>
          <video width="100%" height="100%" ref={refVideo} autoPlay playsInline muted/>
        </div>

        {profile?.live && <LiveBoxChat user={profile}/>}
      </div> :
      <div className={'jf-basic-page jf-user-live'}
           style={{ padding: 0, height: '100%' }}>
        <div className={'jf-row'} style={{ padding: '15px 20px',position: 'absolute', zIndex: 1 }}>
          <Avatar
            image={profile && profile.avatar}
            text={profile && profile.fullname}
            width={70}
            height={70}
          />
          <div className={'jf-ml-20px jf-mt-auto jf-mb-auto'} style={{ flex: 'auto', color: 'white' }}>
            <div className={'jf-font-20-22 jf-font-b '}>@{profile && profile.username}</div>
            <div className={'jf-font-16-22 jf-font-r jf-mt-3px'}>
              {profile && profile.description}
            </div>
          </div>
        </div>

        <div className={"jf-dashbord " + (!profile?.live ? "empty" : "")}
             style={{ height: '100%' }}>
          {!profile?.live && <div className={'jf-dashbord-message'}>user is not live</div>}
          {profile?.live && profile.livestream === "RTMP" && <VideoPlayer
              play={true}
              options={{ autoplay: true }}
              sources={[{ type: 'hls', url: 'https://live.junefox.com/live/' + profile.username + '/playlist.m3u8' }]}
            />}
          <video width="100%" height="100%" ref={refVideo} autoPlay playsInline muted className={ profile?.livestream === "WebRTC" && profile?.live ? "" : "jf-hide"}/>
        </div>
        {profile?.live &&
        <LiveBoxChat user={profile} otherIcon={
          () => !(profile && profile?.live && profile?.livestream === "RTMP") &&
            <>
              {!startCamera && profile?.live &&
              <div className={'jf-m-auto jf-ml-10px'}>
                <Puff
                  wrapperStyle={{justifyContent: 'center'}}
                  color={variables['colors-jf-secondary']}
                  height={50}
                  width={50}
                /></div>}
              {
                startCamera && profile?.live &&
                <button className={'jf-live-chat-input-icon-btn'} onClick={goLive} type="button">
                  <SVGIcon name={'stop-icon'} fill={variables['colors-jf-white']}/>
                </button>
              }
            </>
        }
        />}
      </div>}
      {end && <>
        <div className={'jf-live-box-end'} onClick={close}/>
        <div className={'jf-live-box-end-content'} onClick={close}>
          <label className={'title'}>Live has ended</label>
          <Avatar
            image={profile && profile.avatar}
            text={profile && profile.fullname}
            width={150}
            height={150}
          />
          <label className={'username'}>@{profile && profile.username}</label>
          <div className={'jf-row'}>
            <div><label>{countFormat(344)}</label><br/>Likes</div>
            <div><label>{countFormat(454)}</label><br/>Comments</div>
          </div>
        </div>
      </>}
    </>
  );
}

export default LiveBoxAuth
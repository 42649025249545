import { useFetchApiAction } from './main-action';
import Mutations from '../params/mutations'

export function usePaidAction () {
  const fetchApi = useFetchApiAction();

  return {
    addTip: async function (data) {
      return await fetchApi(Mutations.Tip.Add, data);
    },
  }
}

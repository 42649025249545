import React from 'react';
const Flashicon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '21.263',
  className = '',
  viewBox = '0 0 21.263 24'
}) => (
  <>
    <svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="Path_5617" data-name="Path 5617" d="M18.7,6.612l1.424-1.424a.816.816,0,0,0-1.139-1.149L17.564,5.463a10.623,10.623,0,1,0-6.93,18.674.816.816,0,1,0,0-1.623A9.009,9.009,0,1,1,19.643,13.5a.816.816,0,1,0,1.623,0A10.6,10.6,0,0,0,18.7,6.612Z" transform="translate(-0.008 -0.142)" fill={fill} />
      <path id="Path_5618" data-name="Path 5618" d="M12.148,13.685,11.1,7.818a.066.066,0,0,0-.123,0l-1.006,5.7-3.3,4.747c0,.057,0,.123.095.085l4.861-3.408h.123a1.092,1.092,0,0,0,.2-.218L12,14.634l.057-.1a.475.475,0,0,0,0-.123v-.095a.949.949,0,0,0,0-.285h0v-.085a.19.19,0,0,1,.095-.256Z" transform="translate(-0.345 -0.389)" fill={fill} />
      <path id="Path_5619" data-name="Path 5619" d="M18.033,20.456h0a2.174,2.174,0,0,0,1.2-.665,1.823,1.823,0,0,0,.418-1.2,1.491,1.491,0,0,0-.674-1.3,3.123,3.123,0,0,0-1.823-.475,4.063,4.063,0,0,0-2.44.741l.731,1.082a3.456,3.456,0,0,1,.845-.418,2.345,2.345,0,0,1,.75-.123c.712,0,1.063.275,1.063.845a.816.816,0,0,1-.4.778,2.715,2.715,0,0,1-1.272.228h-.579v1.206h.57a3.228,3.228,0,0,1,1.367.218.778.778,0,0,1,.427.75.949.949,0,0,1-.37.854,2.06,2.06,0,0,1-1.158.266,3.864,3.864,0,0,1-1.025-.142,4.747,4.747,0,0,1-.949-.361v1.348a5.487,5.487,0,0,0,2.155.4,3.608,3.608,0,0,0,2.231-.6,1.984,1.984,0,0,0,.778-1.68,1.5,1.5,0,0,0-.465-1.168,2.326,2.326,0,0,0-1.377-.589Z" transform="translate(-0.752 -0.847)" fill={fill} />
      <path id="Path_5620" data-name="Path 5620" d="M6.5,1.623h8.82a.807.807,0,0,0,.807-.807A.807.807,0,0,0,15.321,0H6.5a.816.816,0,1,0,0,1.623Z" transform="translate(-0.291 0.005)" fill={fill} />
    </svg>

  </>
);
export default Flashicon;
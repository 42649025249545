import SVGIcon from '../../svg/index'
import React, { useEffect} from 'react'
import { checkMobile, setSeparateState } from '../../../helpers/utils'
import TextClipboard from '../../basic/text-clipboard'
import { useRecoilValue, useRecoilState } from 'recoil'
import { activeBottomPopupState, sharePopupAtom } from '../../../state/components/popup'
import { Puff } from "react-loader-spinner";
import { useMediaAction } from '../../../action/media-action'
import Constants from '../../../params/constants'
import variables from  '../../../scss/variables.module.scss'
import Button from '../../basic/button'

const Share = ({hide}) => {
  const info = useRecoilValue(activeBottomPopupState('share'));
  const [{url,  loading }, setSharePopup] = useRecoilState(sharePopupAtom)
  const mediaAction = useMediaAction();
  const setUrl = setSeparateState(setSharePopup, "url");
  const setLoading = setSeparateState(setSharePopup, "loading");

  useEffect(() => {
    setLoading(true)
    if (info.url) {
      setUrl(info.url)
      setLoading(false)
      return;
    }
    let isCanceled = false
    setLoading(true)
    if (!info.media) {
      return;
    }
    mediaAction.generateShortLink(info.media).then((res) => {
      if (res && !isCanceled) {
        setUrl([Constants.ShortUrl.trim("/"), res.id].join("/"))
        setLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  const menuItems = [
    { text: 'Message', icon: 'messagered-icon', url: 'sms:?body=' + url },
    {
      text: 'Facebook', icon: 'facebook-icon',
      url: 'https://www.facebook.com/sharer/sharer.php?u=' + url,
    },
    {
      text: 'WhatsApp', icon: 'whatsapp-icon',
      url: 'https://api.whatsapp.com/send/?text=' + url,
      urlMobile: 'whatsapp://send?text=' + url
    },
    {
      text: 'Messenger', icon: 'messenger-icon',
      urlMobile: 'fb-messenger://share?link=' + url,
      url: 'https://www.facebook.com/dialog/send?link=' + url + '&Navigate_uri=' + url
    },
  ];

  return <>{loading ?
    <div className={'jf-spin-container'} style={{minHeight: 250}}><Puff
      wrapperStyle={{justifyContent: 'center'}}
      color={variables['colors-jf-secondary']}
      height={50}
      width={50}
    /></div> : <div className='jf-bottom-popup-contain-grid'>
      <div className='jf-bottom-popup-header'>{'Share to'}</div>
      <div className='jf-bottom-popup-body'>
        {menuItems.map((item, i) =>
          <div className='jf-bottom-popup-item' key={'bottom-popup-item-' + i}
               onClick={() => {
                 if (checkMobile() && item.urlMobile) {
                   window.open(item.urlMobile);
                 } else {
                   window.open(item.url, "_blank");
                 }
               }}>
            <div className={'jf-bottom-popup-item-icon ' + item.icon}>
              <SVGIcon name={item.icon}/>
            </div>
            <div className='jf-bottom-popup-item-text'>
              {item.text}
            </div>
          </div>
        )}
      </div>
      <TextClipboard text={url} width={'100%'}/>
    </div>}
    <Button className="jf-bottom-popup-cancel"
            onClick={hide}>{'Cancel'}</Button>
  </>
}

export default Share;
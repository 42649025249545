import React, { Component } from 'react';
import SVGIcon from '../svg/index';

class TopMenu extends Component {
  render() {
    return (
      <header className={"jf-top-menu " + (this.props.className || '')}>
        <div className="jf-left" >{this.props.icon &&
        <div className="jf-icon-back" onClick={this.props.action}>
          <SVGIcon name={this.props.icon}/>
        </div>}</div>
        <div className="jf-center"><span>{this.props.title}</span></div>
        <div className="jf-right">{this.props.rightContent || ''}</div>
      </header>
    );
  }
}

export default TopMenu;
import React from 'react';
const HideshowIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '40',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_40" data-name="Group 40" transform="translate(-1318 -26)">
        <rect id="Rectangle_1058" data-name="Rectangle 1058" width="40" height="40" transform="translate(1318 26)" fill="rgba(255,255,255,0)" />
        <path id="Expand_More" data-name="Expand More" d="M727.895,57.869,717.549,68.117,707.2,57.866a1.27,1.27,0,0,0-1.784,0,1.244,1.244,0,0,0,0,1.77L716.657,70.77h0a1.266,1.266,0,0,0,1.781,0l11.241-11.134a1.249,1.249,0,0,0,0-1.77A1.273,1.273,0,0,0,727.895,57.869Z" transform="translate(1274 763.547) rotate(-90)" fill={fill} />
      </g>
    </svg>
  </>
);
export default HideshowIcon;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Button extends Component {
  render () {
    const { className, children, type, typeStyle, typeHtml, ...props } = this.props;
    const Type = type === 'link' ? Link : `button`;

    return (
      <Type
        className={"jf-btn jf-basic-btn " + (className || '')}
        type={typeHtml}
        {...props}>
        {children}
      </Type>
    )
  }
}

export default Button;
import React, { useEffect, useState } from 'react'
import Header from '../../../components/header.js';
import { Link, useNavigate } from "react-router-dom";
import { TextField } from '../../../components/basic/text-field'
import { fetchSignUp } from '../../../helpers/fetch-graphql'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authIdAtom, authProfileAtom } from '../../../state/auth'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { DCookie } from '../../../helpers/utils'
import TextFieldValidation from '../../../components/basic/text-field-validation'
import { useFetchApiAction } from '../../../action/main-action'
import Queries from '../../../params/queries'
import Button from '../../../components/basic/button'
import { activeBottomPopupState, cookiePolicyAtom } from '../../../state/components/popup.js';

const schema = yup.object({
  fullname: yup.string().required().label('Name'),
  username: yup.string().required().label('Username').min(4).max(32)
    .matches(/^[A-Za-z0-9_.\-~]*$/, 'Username must only contain letters, numbers and symbols(._-~)'),
  email: yup.string().required().email().label('Email address'),
  password: yup.string().required().label('Password')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
      'Password must contain at least 6 characters and includes upper lower number letters')
}).required();

function Singup() {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const fetchAction = useFetchApiAction();
  const setAuthProfile = useSetRecoilState(authProfileAtom);
  const setAuthId = useSetRecoilState(authIdAtom);
  const cookiePolicy = useRecoilValue(cookiePolicyAtom);
  const setCookiePopup = useSetRecoilState(
    activeBottomPopupState("cookie_policy")
  );

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    fetchAction(Queries.Account.LoggedIn, {}).then(res => {
      if (res.logged_in?.account) {
        setAuthId(DCookie.getItem('auth_id'))
        setAuthProfile(res.logged_in.account)
        navigate('/');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (!cookiePolicy) {
      return setIsSubmit(true);
    }

    data.type = "email";
    fetchSignUp(data).then(res => {
      if (res.status === 200) {
        if (!res.data.error) {
          setAuthId(DCookie.getItem('auth_id'));
          navigate('/complete');
        } else {
          for (let k in res.data.errors) {
            setError(k, { type: 'server', message: res.data.errors[k] });
          }
        }
      }
    })
  }

  return (
    <>
      <div className="jf-singup-content">
        <Header/>
        <div className="jf-singup-body">
          <div style={{width: '100%'}}>
            <h1 className="jf-title">Sign Up</h1>
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="jf-input-boxs">
                <TextFieldValidation
                  placeholder={'Username'}
                  error={errors.username?.message}
                  name={'username'}
                  setError={setError}
                  register={(onCheck)=> register("username", {onBlur: onCheck })}
                />
                <TextField
                  placeholder={'Name'}
                  error={errors.fullname?.message}
                  register={register("fullname")}
                />
                <TextField
                  placeholder={'Email Address'}
                  error={errors.email?.message}
                  register={register("email")}
                />
                <TextField
                  placeholder={'Password'}
                  error={errors.password?.message}
                  type={'password'}
                  register={register("password")}
                />
              </div>
              {isSubmit && !cookiePolicy && (
                <div className="jf-text-field-msg error">
                  We use cookies for signup. Please accept our{" "}
                  <a onClick={() => setCookiePopup({ active: true })}>
                    cookies policy
                  </a>{" "}
                  before using our service
                </div>
              )}
              <ul className="jf-btn-ul">
                <li className="jf-btn-ul-li">
                  <button className="jf-btn jf-basic-btn" type={'submit'}>Sign up</button>
                </li>
                <li className="jf-btn-ul-li jf-mb-15px jf-mt-20px jf-font-r ">
                  Or
                </li>
                <li className="jf-btn-ul-li">
                  <Button type={'link'} className={'white-btn jf-font-'} typeHtml={'button'}
                          to={'/signin'} >Login</Button>
                </li>
              </ul>
              <div style={{flexGrow: 1}}></div>
            </form>
          </div>
        </div>
        <div className="jf-terms">
          By creating an account you agree with our
          <Link to="/" className="jf-terms-link">Terms of Use.</Link>
        </div>
      </div>
    </>
  );
}


export default Singup;
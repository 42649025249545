import MessageRedIcon from './messegered-icon'
import FacebookIcon from './facebook-icon'
import WhatsAppIcon from './whatsapp-icon'
import MessengerIcon from './messenges-icon'

const icons = {
  'messagered-icon': MessageRedIcon,
  'facebook-icon': FacebookIcon,
  'whatsapp-icon': WhatsAppIcon,
  'messenger-icon': MessengerIcon,
};

export default icons;
const ClockIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '30',
  height = '48',
  viewBox = '0 0 30 48'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="lock_1_" data-name="lock (1)" d="M18,3a6,6,0,0,1,6,6V21H12V9A6,6,0,0,1,18,3Zm9,18V9A9,9,0,1,0,9,9V21a6,6,0,0,0-6,6V42a6,6,0,0,0,6,6H27a6,6,0,0,0,6-6V27A6,6,0,0,0,27,21ZM9,24H27a3,3,0,0,1,3,3V42a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V27A3,3,0,0,1,9,24Z" transform="translate(-3)" fill={fill}/>
  </svg>

export default ClockIcon;

import React from 'react';
import {useRecoilState, useResetRecoilState} from 'recoil'
import {activeBottomPopupState, commentPopupAtom} from '../../../state/components/popup'
import Share from './share'
import Comment from './comment'
import CookiePolicy from './cookie-policy';
import InstallAppPopup from "./install-app-popup";

const BottomPopups = () => {
    const [activePopup, setActivePopup] = useRecoilState(activeBottomPopupState());
    const resetCommentPopup = useResetRecoilState(commentPopupAtom);
    const hide = () => {
        if (activePopup) {
            setActivePopup({...activePopup, active: false})
            switch (activePopup && activePopup.id) {
                case 'comment':
                    resetCommentPopup()
                    break;
                default:
                    return null;
            }
        }
    }
    const renderContain = () => {
        switch (activePopup && activePopup.id) {
            case 'share':
                return <Share hide={hide}/>;
            case 'comment':
                return <Comment hide={hide}/>;
            case 'cookie_policy':
                return <CookiePolicy hide={hide}/>;
            case 'install_app':
                return <InstallAppPopup hide={hide}/>
            default:
                return null;
        }
    }
    return <>
        <div className={'jf-popup-cover ' + (activePopup ? 'show' : 'hide')} onClick={hide}/>
        <div className={'jf-bottom-popup ' + (activePopup ? 'show' : 'hide') +
            (activePopup && activePopup.id ? ' jf-popup-' + activePopup.id : '') + (activePopup && activePopup.ios ? ' ios' : '')}>
            {renderContain()}
        </div>
    </>;
}

export default BottomPopups;
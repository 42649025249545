import React from 'react';
const SearchIcon = ({
  style = {},
  fill = "#7c79fa",
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '29',
  className = '',
  viewBox = '0 0 29 29'
}) => (
  <>
    <svg id="search" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
        <path id="Search" d="M39.624,37.819l-5.676-5.676a12.93,12.93,0,1,0-1.811,1.811l5.676,5.67a1.278,1.278,0,1,0,1.811-1.8ZM23.94,34.305A10.368,10.368,0,1,1,34.311,23.94,10.378,10.378,0,0,1,23.94,34.305Z" transform="translate(-11 -11)" fill={fill} opacity="0.8"/>
    </svg>

  </>
);
export default SearchIcon;
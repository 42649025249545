import React from 'react';
const CommentIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '28',
  className = '',
  viewBox = '0 0 28 28'
}) => (
  <>
    <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="message-2" data-name="message" transform="translate(4 2)">
        <path id="Path_40" data-name="Path 40" d="M0,0H28V28H0Z" transform="translate(-4 -2)" fill="none" />
        <g id="Group_37" data-name="Group 37" transform="translate(-1 -1)">
          <g id="Union_1" data-name="Union 1" transform="translate(4635 851)" fill="none">
            <path d="M-4626.857-831H-4633a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h18a2,2,0,0,1,2,2v14a2,2,0,0,1-2,2h-6.142l-2.858,4Z" stroke="none" />
            <path d="M -4624 -830.4404296875 L -4622.171875 -832.9998779296875 L -4614.99951171875 -832.9994506835938 L -4615.00048828125 -846.999755859375 L -4633 -847.0001220703125 L -4633.00048828125 -832.9998779296875 L -4625.828125 -832.9998779296875 L -4624 -830.4404296875 M -4624.00048828125 -826.99951171875 L -4626.857421875 -830.9998779296875 L -4633.00048828125 -830.9998779296875 C -4634.1044921875 -830.9998779296875 -4635 -831.8955688476562 -4635 -832.9994506835938 L -4635 -847.0001220703125 C -4635 -848.1040649414062 -4634.1044921875 -848.999755859375 -4633.00048828125 -848.999755859375 L -4615.00048828125 -848.999755859375 C -4613.8955078125 -848.999755859375 -4612.99951171875 -848.1040649414062 -4612.99951171875 -847.0001220703125 L -4612.99951171875 -832.9994506835938 C -4612.99951171875 -831.8955688476562 -4613.8955078125 -830.9998779296875 -4615.00048828125 -830.9998779296875 L -4621.142578125 -830.9998779296875 L -4624.00048828125 -826.99951171875 Z" stroke="none" fill={fill} />
          </g>
          <rect id="Rectangle_1052" data-name="Rectangle 1052" width="10" height="2" rx="1" transform="translate(6 10)" fill={fill} />
        </g>
      </g>
    </svg>


  </>
);
export default CommentIcon;
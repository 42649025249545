import React from 'react';
const PlayIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '12',
  height = '14',
  className = '',
  viewBox = '0 0 12 14'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <path id="Polygon_4" data-name="Polygon 4" d="M6.4,1.037a.7.7,0,0,1,1.209,0l5.781,9.911a.7.7,0,0,1-.6,1.053H1.219a.7.7,0,0,1-.6-1.053Z" transform="translate(12) rotate(90)" fill={fill}/>
    </svg>
  </>
);
export default PlayIcon;
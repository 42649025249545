import React, {Component} from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import canAutoPlay from 'can-autoplay';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: false,
            restart: false,
        }
        this.videoNode = React.createRef();
        this._isMounted = false;
    }

    componentDidMount() {
        this.player = videojs(this.videoNode, {
            sources: this.props.sources.map(item => ({
                src: item.url,
                type: item.type === 'hls' ? 'application/x-mpegURL' : item.type
            })),
            ...this.props.options
        }, async () => {
            const {result} = await canAutoPlay.video();

            if (this.props.timeupdate) {
                this.player.on('timeupdate', () => {
                    this.props.timeupdate(this.player)
                })
            }
            if (this.props.offAuto || !this.videoNode) {
                return;
            }

            this.player.on('play', async () => {
                const res = await canAutoPlay.video();
                if (this.videoNode && this.videoNode.muted && res.result) {
                    this.videoNode.muted = false;
                    this.videoNode.volume = 1;
                }
            });

            this.player.on('touchstart', async () => {
                this.videoNode.muted = false;
                this.videoNode.volume = 1;
            });

            this._isMounted = true;
            if(result==true){
                this.videoNode.muted = false;
                this.videoNode.volume = 1;
            }else{
                this.videoNode.muted = true;
            }

            const promise = this.videoNode.play();
            const {afterInit} = this.props;

            if (promise !== undefined) {
                promise.then(_ => {
                    if (this._isMounted) {
                        this.setState({play: true})
                        afterInit && afterInit(true);
                    }
                }).catch(error => {
                    if (this._isMounted) {
                        afterInit && afterInit(false);
                    }
                });
            }
        });
    }

    hasParentClass(child, classname) {
        if (child.className.split(' ').indexOf(classname) >= 0) return true;
        try {
            //Throws TypeError if child doesn't have parent any more
            return child.parentNode && this.hasParentClass(child.parentNode, classname);
        } catch (TypeError) {
            return false;
        }
    }

    async componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.videoNode) {
            console.log(this.state.play);
            if (this.state.play) {
                await this.player.play();
            } else {
                await this.player.pause();
            }
            //     try {
            //         if (this.state.restart) {
            //             this.videoNode.currentTime = 0;
            //             this.props.setRestart(false)
            //             return;
            //         }
            //         if (this.state.play) {
            //             if (!this.props.checkActive ||
            //                 this.hasParentClass(this.videoNode, 'swiper-slide-active')) {
            //
            //                 const promise = this.videoNode.play();
            //                 promise.then(() => {
            //                 }).catch(() => {
            //                 });
            //             }
            //         } else {
            //             this.videoNode.pause();
            //         }
            //     } catch (e) {
            //         return false;
            //     }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.play !== state.play || props.restart !== state.restart) {
            return {
                play: props.play,
                restart: props.restart,
            }
        }
        return null
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.player && this.videoNode) {
            this.player.dispose();
            this.videoNode = null;
        }
    }

    render() {
        if (this.props.type === 'normal') {
            return <div data-vjs-player>
                <video preload="auto" playsInline ref={node => this.videoNode = node} className={"video-js vjs-big-play-centered "}/>
            </div>
        }
        return (
            <>
                <div className={'jf-modal_img-cover'} onClick={this.props.togglePlay}/>
                <div data-vjs-player>
                    <video preload="auto" playsInline ref={node => this.videoNode = node} className="video-js jf-modal_img"/>
                </div>
            </>
        );
    }
}

export default VideoPlayer;
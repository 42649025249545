import Constants from '../params/constants'

export const subscriptionLiveUser = {
  subscription: null,
  user: null,
  run: async (user, callback) => {
    return await new Promise((resolve, reject) => {
      if (!user || !Constants.graphqlWSClient) resolve();
      if (subscriptionLiveUser.user !== user) {
        if(subscriptionLiveUser.subscription){
          subscriptionLiveUser.subscription();
        }
        subscriptionLiveUser.user = user;

        subscriptionLiveUser.subscription = Constants.graphqlWSClient.subscribe(
          {
            query: `
            subscription LiveUser($user:String!) { 
              live(user:$user){
                status
                stream
                type
                account{
                  username
                  live
                  livestream
                }
              }
            }
            `,
            variables: { user: user }
          },
          {
            next: (data) => callback(data),
            error: reject,
            complete: () => resolve(subscriptionLiveUser.subscription),
          },
        );
      }
      resolve(subscriptionLiveUser.subscription)
    });
  }
}

export const LiveChatMessages = {
  subscription: null,
  user: null,
  run: async (user, callback) => {
    return await new Promise((resolve, reject) => {
      if (LiveChatMessages.subscription) {
        LiveChatMessages.subscription();
      }
      if (!user || !Constants.graphqlWSClient) resolve();

      LiveChatMessages.user = user;

      LiveChatMessages.subscription = Constants.graphqlWSClient.subscribe(
        {
          query: `
              subscription LiveChatMessages($user: String!) {
                livechat(user: $user) {
                  id
                  user {fullname username avatar}
                  text
                  add_date
                }
              }
            `,
          variables: { user: user }
        },
        {
          next: (data) => callback(data),
          error: reject,
          complete: () => resolve(LiveChatMessages.subscription),
        },
      );
      resolve(LiveChatMessages.subscription)
    });
  }
}

export const LiveMessagesThread = {
  subscription: null,
  thread: null,
  run: async (thread, callback) => {
    return await new Promise((resolve, reject) => {
      if (LiveMessagesThread.subscription) {
        LiveMessagesThread.subscription();
      }
      if (!thread || !Constants.graphqlWSClient) resolve();

      LiveMessagesThread.thread = thread;

      LiveMessagesThread.subscription = Constants.graphqlWSClient.subscribe(
        {
          query: `
              subscription MessengerMessage($thread:String!) { 
                messenger_message(thread:$thread){
                  action
                  message{
                    id
                    from {
                       id fullname
                       username avatar
                    }
                    data{ type value }
                  }
                }
              }
            `,
          variables: { thread: thread }
        },
        {
          next: (data) => callback(data),
          error: reject,
          complete: () => resolve(LiveMessagesThread.subscription),
        },
      );
      resolve(LiveMessagesThread.subscription)
    });
  },
  end: () => {
    if(LiveMessagesThread.subscription){
      LiveMessagesThread.subscription();
    }
  }
}

export const LiveMessengerThread = {
  subscription: null,
  user: null,
  run: async (user, callback) => {
    return await new Promise((resolve, reject) => {
      if (LiveMessengerThread.subscription) {
        LiveMessengerThread.subscription();
      }
      if (!user || !Constants.graphqlWSClient) resolve();

      LiveMessengerThread.user = user;

      LiveMessengerThread.subscription = Constants.graphqlWSClient.subscribe(
        {
          query: `
              subscription MessengerThread($user:String!) { 
                messenger_thread(user:$user){
                  action
                  thread{
                    id
                    users{
                        id username fullname avatar
                    }
                    last_message {
                      id data{value type} add_date
                    }
                    add_date
                    unread1
                    unread2
                    last_update
                  }
                }
              }
            `,
          variables: { user: user }
        },
        {
          next: (data) => callback(data),
          error: reject,
          complete: () => resolve(LiveMessengerThread.subscription),
        },
      );
      resolve(LiveMessengerThread.subscription)
    });
  },
  end: () => {
    if(LiveMessengerThread.subscription){
      LiveMessengerThread.subscription();
    }
  }
}

export const LiveAuthMedia = {
  subscription: null,
  user: null,
  run: async (user, callback) => {
    return await new Promise((resolve, reject) => {
      if (!user || !Constants.graphqlWSClient) resolve();
      if (LiveAuthMedia.user !== user) {
        if(LiveAuthMedia.subscription){
          LiveAuthMedia.subscription();
        }
        LiveAuthMedia.user = user;

        LiveAuthMedia.subscription = Constants.graphqlWSClient.subscribe(
          {
            query: `
              subscription MediaProcess{
                media_process{
                  media
                  process{
                    original
                    teaser
                    status
                    info
                  }
                }
              }
            `,
            variables: { user: user }
          },
          {
            next: (data) => callback(data),
            error: reject,
            complete: () => resolve(LiveAuthMedia.subscription),
          },
        );
      }
      resolve(LiveAuthMedia.subscription)
    });
  },
  end: () => {
    if(LiveAuthMedia.subscription){
      LiveAuthMedia.subscription();
    }
    LiveAuthMedia.user = null;
  }
}
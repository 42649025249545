import React, { useEffect } from 'react'
import TopMenu from '../../../../components/basic/top-menu'
import { TextField } from '../../../../components/basic/text-field'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUserAction } from '../../../../action/user-action'
import { useRecoilValue } from 'recoil'
import { authProfileAtom } from '../../../../state/auth'
import TextFieldValidation from '../../../../components/basic/text-field-validation'
import BottomMenu from '../../../../components/basic/bottom-menu'
import Button from '../../../../components/basic/button'
import Constants from '../../../../params/constants'
import { useNavigate } from 'react-router-dom'

const schema = yup.object({
  fullname: yup.string().required().label('Display Name'),
  username: yup.string().required().label('Username').min(4).max(32)
    .matches(/^[A-Za-z0-9_.\-~]*$/, 'Username must only contain letters, numbers and symbols(._-~)'),
  email: yup.string().required().email().label('Email address'),
}).required();

const SettingBasic = (props) => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });
  const authProfile = useRecoilValue(authProfileAtom);
  const userAction = useUserAction();

  useEffect(() => {
    userAction.getAuthProfile().then(({profile}) => {
      if (profile) {
        ['fullname', 'username', 'email'].forEach(key => {
          setValue(key, profile[key])
        })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    userAction.upProfile(data).then(res => {
      if (res.errors) {
        for (let k in res.errors) {
          setError(k, { type: 'server', message: res.errors[k] });
        }
      } else {
        navigate( -1);
      }
    });
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Username and email'}
        action={() => navigate(-1)}
      />
      <div className={'jf-edit-profile-form jf-setting-basic-form'} style={{paddingTop: 0}}>
        <form
          style={{ width: '100%', paddingTop: 8 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={'Display Name'}
            error={errors.fullname?.message}
            register={register("fullname")}
          />
          <TextFieldValidation
            label={'Username'}
            error={errors.username?.message}
            name={'username'}
            setError={setError}
            register={(onCheck)=> register("username", {onBlur: onCheck })}
          />
          <div className={'jf-font-16-22'}>
            <span className={'jf-color-gray'}>Your public profile will be </span>
            {authProfile && (Constants.Site.URL + '/' + authProfile.username)}</div>
          <TextField
            label={'Email'}
            error={errors.email?.message}
            register={register("email")}
          />
          <div className={'jf-font-16-22'}>
            <span className={'jf-color-gray'}>Your email </span>will not
            <span className={'jf-color-gray'}> be publicly displayed</span></div>
          <Button className={'jf-account-save-btn'} type={'submit'}>Save</Button>
        </form>
      </div>
      <BottomMenu/>
    </>
  );
}

export default SettingBasic;

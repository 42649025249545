import React, {useState, useEffect} from 'react';
import SVGIcon from '../svg/index'
import variables from '../../scss/variables.module.scss'
import { fetchValidate } from '../../helpers/fetch-graphql'

const TextFieldValidation = React.memo(({type, width, name, setError, label, iconLeft,styleContain, error, register, ...props})=> {
  const [wait, setWait] = useState(false)
  const [value, setValue] = useState()
  const [valueChange, setValueChange] = useState(null)
  const [err, setErr] = useState(null)
  const onCheck = (e) => {
    if (valueChange !== null && e.target.value !== value) {
      setWait(true);
      setValue(e.target.value);
    } else if (e.target.value === value && err) {
      setWait(false);
      setTimeout(() => {
        if (!error || error === err) {
          setError(name, { type: 'server', message: err })
        }
      })
    }
  }

  useEffect(() => {
    if (wait && error === undefined && value !== '') {
      setErr(null)
      const params = {};
      params[name] = value;
      fetchValidate(params).then((res) => {
        if (res.data.error) {
          for (let k in res.data.errors) {
            setError(k, { type: 'server', message: res.data.errors[k] });
            setErr(res.data.errors[k])
          }
        }
        setWait(false);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, wait, value])

  return <div className={'jf-text-field ' +
  (error || error === ' ' ? 'has-error ' : '') + (label ? 'has-label ' : '')}
              style={{ ...styleContain, width: width || '100%' }}>
    {
      label && <div className='jf-text-field-label'>
        {label}
      </div>
    }
    <div className={'jf-text-field-input'}>
      {iconLeft}
      <input
        type={['text', 'number', 'email'].indexOf(type) > -1 ? this.state.type : 'text'}
        {...register(onCheck)}
        {...props}
        onChange={(e) => setValueChange(e.target.value)}
      />
      {!wait && !error && value && <button type={'button'} className="jf-text-field-view-btn-ex">
        <SVGIcon name={"check-circle-icon"} width={22} height={22} fillCircle={variables['colors-jf-secondary']}/>
      </button>}
    </div>
    {error && <div className={'jf-text-field-msg'}>{error}</div>}
  </div>;
})

export default TextFieldValidation;
import React from "react";
const ChatIcon = ({
  style = {},
  fill = "#fff",
  fillOpacity = "0.5",
  fillNone = "none",
  width = "24",
  height = "24",
  viewBox = "0 0 24 23.182",
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        id="Path_61"
        dataName="Path 61"
        d="M12,0C5.388,0,0,4.584,0,10.236S5.388,20.472,12,20.472v1.836a.864.864,0,0,0,1.32.744c2.148-1.344,6.324-4.092,7.8-6.156h0A9.336,9.336,0,0,0,24,10.236C24,4.584,18.66,0,12,0ZM6.18,12.612a2.028,2.028,0,1,1,2.028-2.028A2.028,2.028,0,0,1,6.18,12.612Zm5.844,0a2.058,2.058,0,1,0-.024,0Zm5.844,0A2.028,2.028,0,1,1,19.9,10.584,2.028,2.028,0,0,1,17.868,12.612Z"
        fill={fill}
      />
    </svg>
  </>
);
export default ChatIcon;

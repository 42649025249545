import React from 'react';
import { useRecoilValue } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import Button from '../../basic/button'

const Alert = ({hide}) => {
  const info = useRecoilValue(activePopupState('alert'));

  return <div className={'jf-popup-alert'}>
    <div className={'jf-popup-alert-title'}>
      {info.message}
    </div>
    <div className={'jf-popup-alert-content'}>
      {info.onOk && <Button className={'outline-btn'} onClick={() => info.onOk(hide) }>Yes</Button>}
      {info.onOk && <Button onClick={hide}>No</Button>}
    </div>
  </div>;
}

export default Alert;
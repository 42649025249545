import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL;

export async function fetchApi (query, variables) {
  const data = {
    query: query,
    variables: variables
  }

  const options = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  }

  return axios.post(apiUrl + '/api', data, options).then(res => res.data)
      .catch(res => res.response?.data)
}

export function fetchSignIn (data) {
  return axios.post(apiUrl + '/api/auth', data,{
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  }).then(res => res)
      .catch(res => res.response?.data)
}

export function fetchSignUp (data) {
  return axios.post(apiUrl + '/api/signup', data,{
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  }).then(res => res)
      .catch(res => res.response?.data)
}

export function fetchValidate (data) {
  return axios.post(apiUrl + '/api/validate', data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  }).then(res => res)
      .catch(res => res.response?.data)
}

export function fetchUploadAvatar (file) {
  const data = new FormData()
  data.append('avatar', file)

  return  axios.post(apiUrl + '/api/upload/avatar', data,{
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      "Content-Type": "multipart/form-data",
    },
  }).then(res => res)
      .catch(res => res.response?.data)
}

export function fetchSignOut () {
  return axios.get(apiUrl + '/api/signout', {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  }).then(res => res)
      .catch(res => res.response?.data)
}

export function fetchUploads (file) {
  const data = new FormData()
  data.append('upload', file)

  return  axios.post(apiUrl + '/api/uploads', data,{
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      "Content-Type": "multipart/form-data",
    },
  }).then(res => res)
    .catch(res => res.response?.data)
}

const CancelToken = axios.CancelToken
export function fetchUploadMedia (setCompleted, cancelFileUpload) {
  return function (list) {
    const data = new FormData()
    for (let i in list) {
      for (let key in list[i]) {
        if (list[i][key] instanceof File) {
          data.append(  `media_files`, list[i][key])
        } else if (Array.isArray(list[i][key])) {
          for (let j in list[i][key]) {
            data.append(`medias[${i}][${key}][]`, list[i][key][j])
          }
        } else {
          data.append(`medias[${i}][${key}]`, list[i][key])
        }
      }
    }

    return axios.post(apiUrl + '/api/upload/media', data, {
      withCredentials: true,
      cancelToken: new CancelToken(
        cancel => (cancelFileUpload.current = cancel)
      ),
      headers: {
        'Accept': 'application/json',
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (data) {
        if (setCompleted) {
          setCompleted(Math.round((100 * data.loaded) / data.total))
        }
      }
    }).then(res => res)
        .catch(res => res.response?.data)
  }
}

export function fetchUploadRecord (setCompleted, cancelFileUpload) {
  return function (params) {
    const data = new FormData()

    for (let key in params) {
      if (key === "record") {
        data.append(key, params[key], key + ".webm");
      } else if (Array.isArray(params[key])) {
        for (let j in params[key]) {
          data.append(`${key}[]`, params[key][j])
        }
      } else {
        data.append(key, params[key])
      }
    }
    return axios.post(apiUrl + '/api/upload/recording', data, {
      withCredentials: true,
      cancelToken: new CancelToken(
        cancel => (cancelFileUpload.current = cancel)
      ),
      headers: {
        'Accept': 'application/json',
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (data) {
        if (setCompleted) {
          setCompleted(Math.round((100 * data.loaded) / data.total))
        }
      }
    }).then(res => res)

  }
}
import React from 'react';
import { useRecoilValue } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import Button from '../../basic/button'
import { TextAreaField } from '../../basic/text-area-field'
import { SelectField } from '../../basic/select-field'
import { useMediaAction } from '../../../action/media-action'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Constants from '../../../params/constants'

const schema = yup.object({
  reason: yup.string(),
  details: yup.string(),
}).required();

const Report = ({hide}) => {
  const info = useRecoilValue(activePopupState('report'));
  const media = info.media;
  const mediaAction = useMediaAction();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    data.report_user = media.user.id;
    data.report_media = media.id;
    mediaAction.makeReport(data).then(res => {
      if (!res.errors) {
        hide();
      }
    });
  }
  return <>
    <div className={'jf-popup-report-title'}>
      Report
    </div>
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={'jf-popup-report-content'}>
        <SelectField
          label={'Reason'}
          setValue={(v) => setValue("reason", v)}
          error={errors.reason?.message}
          placeholder={'Reason'}
          list={Constants.ReportReason}
          value={""}
        />
        <TextAreaField
          styleContain={{marginTop: 10}}
          label={'Details'}
          register={register("details")}
          error={errors.details?.message}
          placeholder={'Please write a little about why you want to report this post'}
          />
      </div>
      <div>
        <div className={'jf-mb-20px jf-report-btns'}>
          <Button className={'medium jf-m-auto jf-report-btn'}>Report</Button>
          <button type="button" className={'jf-btn jf-basic-btn medium jf-m-auto jf-ml-15px'} onClick={hide}>Nevermind</button>
        </div>
      </div>
    </form>
  </>;
}

export default Report;
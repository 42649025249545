import React from 'react';
const VideoRecordIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '60',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1996" data-name="Group 1996" transform="translate(-1665 -123)">
        <rect id="Rectangle_1218" data-name="Rectangle 1218" width={width} height={width} transform="translate(1665 123)" fill={fillNone} />
        <g id="camera-reels" transform="translate(1670 128)">
          <path id="Path_6298" data-name="Path 6298" d="M18.75,9.375A9.375,9.375,0,1,1,9.375,0,9.375,9.375,0,0,1,18.75,9.375Zm-15.625,0a6.25,6.25,0,1,0,6.25-6.25A6.25,6.25,0,0,0,3.125,9.375Z" fill={fill} stroke={fill} strokeWidth="1" />
          <path id="Path_6299" data-name="Path 6299" d="M28.125,6h1.563a6.25,6.25,0,0,1,6.2,5.432L45.6,7.112A3.125,3.125,0,0,1,50,9.966V33.284a3.125,3.125,0,0,1-4.394,2.853l-9.722-4.319a6.25,6.25,0,0,1-6.2,5.431H6.25A6.25,6.25,0,0,1,0,31V12.25A6.25,6.25,0,0,1,6.25,6Zm18.75,27.281V9.969L35.938,14.828V28.422l10.938,4.862ZM3.125,12.25V31A3.125,3.125,0,0,0,6.25,34.125H29.688A3.125,3.125,0,0,0,32.813,31V12.25a3.125,3.125,0,0,0-3.125-3.125H6.25A3.125,3.125,0,0,0,3.125,12.25Z" transform="translate(0 12.75)" fill={fill} stroke={fill} strokeWidth="1" />
          <path id="Path_6300" data-name="Path 6300" d="M15.375,18.75A9.375,9.375,0,1,0,6,9.375,9.375,9.375,0,0,0,15.375,18.75ZM9.125,9.375a6.25,6.25,0,1,1,6.25,6.25A6.25,6.25,0,0,1,9.125,9.375Z" transform="translate(12.75)" fill={fill} stroke={fill} strokeWidth="1" />
        </g>
      </g>
    </svg>

  </>
);
export default VideoRecordIcon;
import React, {useEffect} from 'react'
import TopMenu from '../../../components/basic/top-menu'
import { TextField } from '../../../components/basic/text-field'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUserAction } from '../../../action/user-action'
import SVGIcon from '../../../components/svg/index'
import BottomMenu from '../../../components/basic/bottom-menu'
import variables from '../../../scss/variables.module.scss'
import Button from '../../../components/basic/button'
import { errPassAtom } from '../../../state/pages/edit-profile'
import {useRecoilState} from 'recoil'
import { useNavigate } from 'react-router-dom'

const passwordCheck = function (v, chckOnly) {
  let error = {
    min: v.length < 8 ? 'err' : 'ok',
    regex: !v.match(/(?=.*[a-zA-Z])/) || !v.match(/(?=.*[0-9*.!@$%^&(){}[\]:;<>,.?/~_+-=|\\])/) ? 'err' : 'ok',
  };

  return chckOnly ? error[chckOnly] === 'ok' : error;
}

const schema = yup.object({
  password_current: yup.string().required().label('Current password'),
  password: yup.string().required().label('New password')
    .test('pass-min', ' ', (v) => passwordCheck(v,'min'))
    .test('pass-regex', ' ', (v) => passwordCheck(v, 'regex'))
}).required();

const iconCheckStyle = {
  name: 'check-circle-icon',
  fill: variables['colors-jf-white'],
  fillCircle: variables['colors-jf-light'],
  width: 22,
  height: 22
}

const EditProfilePassword = (props) => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setError, getValues } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });
  const userAction = useUserAction();
  const [errPass, setErrPass] = useRecoilState(errPassAtom);

  const onSubmit = (data) => {
    data.password_flag = true;
    userAction.upProfile(data).then(res => {
      if (res.errors) {
        for (let k in res.errors) {
          setError(k, { type: 'server', message: res.errors[k] });
        }
      } else {
        navigate(-1);
      }
    });
  }

  useEffect(() => {
    if (getValues('password')) {
      setErrPass(passwordCheck(getValues('password'), false));
    } else {
      setErrPass({
        min: '',
        regex: ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.password])
  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Password'}
        action={() => navigate(-1)}
      />
      <div className={'jf-edit-profile-form jf-password-form'}>
        <form
          style={{ width: '100%', marginTop: -10 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={'Current Password'}
            placeholder={'Current Password'}
            error={errors.password_current?.message}
            type={'password'}
            register={register("password_current")}
          />
          <TextField
            label={'New Password'}
            placeholder={'New Password'}
            error={errors.password?.message}
            type={'password'}
            register={register("password")}
          />
          <div className={'pass-note'}>Your password must have:</div>
          <div className={'pass-note ' + errPass.min}>
            <SVGIcon {...iconCheckStyle}/>Minimum 8 characters
          </div>
          <div className={'pass-note ' + errPass.regex}>
            <SVGIcon {...iconCheckStyle}/>Letters, numbers or special characters
          </div>
          <Button className={'jf-account-save-btn'} type={'submit'}>Save</Button>
        </form>
      </div>
      <BottomMenu/>
    </>
  );
}

export default EditProfilePassword;

import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import React, {useEffect, useRef} from 'react'
import Button from '../../../../components/basic/button'
import StudioUploadPreviewForm from './preview-form'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { suggestionTagsAtom } from '../../../../state'
import Queries from '../../../../params/queries'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import { studioUploadPreviewAtom } from '../../../../state/pages/studio'
import { setSeparateState } from '../../../../helpers/utils'
import { useLocation, useNavigate } from 'react-router-dom'


const StudioUploadPreview = ({}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const setSuggestions = useSetRecoilState(suggestionTagsAtom)
  const fetchAction = useFetchApiAction();
  const [{result,error}, setPreview] = useRecoilState(studioUploadPreviewAtom);
  const setResult = setSeparateState(setPreview,"result");
  const setError = setSeparateState(setPreview,"error");
  const childRefs = useRef([]);
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetPreview = useResetRecoilState(studioUploadPreviewAtom);

  useEffect(() => {
    resetStateAction.add(resetPreview)

    if (!location.state?.files || location.state.files.length === 0) {
      navigate(-1)
    } else {
      const variables = { limit: { limit: 100 } }
      fetchAction(Queries.Tags, variables).then(res => {
        setSuggestions(res.tags.map(tag => ({ name: tag.tags })))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (result.length > 0 && result.length === location.state.files.length && !error) {
      navigate("/studio/upload/progress", {state: {data: result}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, error])

  const onSubmit = () => {
    setResult([])
    setError(false)
    const onOk = (i)=>(params) => {
      setResult(old =>  [params, ...old])
    }
    const onError = () => {
      setError(true)
    }
    for (var i = location.state.files.length-1; i >=0; i --) {
      childRefs.current[i].onSubmit(onOk(i), onError)
    }
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={"Preview"}
        action={() => navigate(-1)}
      />
      <div className={'jf-basic-page no-padding jf-studio-upload'}>
        {location.state.files.map((file, i) =>
          <StudioUploadPreviewForm ref={(e) => childRefs.current[i] = e}
            key={'file-' + i} navigate={navigate} file={file}/>)}
        <div className={'jf-row jf-mb-20px jf-pt-10px'}>
          <Button className={'white-btn medium'} typeHtml={'button'} onClick={() => navigate(-1)}>Cancel</Button>
          <Button className={'save-btn medium'} typeHtml={'button'} onClick={onSubmit}>Upload</Button>
        </div>
      </div>
      <BottomMenu/>
    </>
  );
}

export default StudioUploadPreview;
import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import Subscription from './subscription'
import SVGIcon from '../../svg'
import Tips from './tips'
import { authIdAtom } from '../../../state/auth'
import {useNavigate} from 'react-router-dom';
import ReportBlock from './report-block'
import Report from './report'
import Alert from './alert'
import CameraOptions from './camera-options'
import AddCard from './add-card'

const Popups = () => {
  const [activePopup, setActivePopup] = useRecoilState(activePopupState());
  const authId = useRecoilValue(authIdAtom);
  const navigate = useNavigate();

  useEffect(() => {
    const ignoreIds = ["alert", "camera_options"];
    if (!authId && activePopup && activePopup.active && ignoreIds.indexOf(activePopup.id) < 0) {
      navigate('/signin')
      hide();
    } else if (activePopup && !activePopup.allow) {
      setActivePopup({...activePopup, allow: true})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup])

  const renderContain = () => {
    switch (activePopup && activePopup.id) {
      case 'subscription':
        return <Subscription/>;
      case 'tips':
        return <Tips hide={hide}/>;
      case 'report_block':
        return <ReportBlock hide={hide}/>
      case 'report':
        return <Report hide={hide}/>
      case 'alert':
        return <Alert hide={hide}/>
      case 'add_card':
        return <AddCard hide={hide}/>

      case 'camera_options':
        return <CameraOptions hide={hide}/>
      default:
        return null;
    }
  }
  const hide = () => {
    if (activePopup) {
      setActivePopup({ ...activePopup, active: false, allow: false })
    }
  }
  return <>
      <div className={'jf-popup-cover ' + (activePopup && activePopup.allow ? 'show' : 'hide')} onClick={hide}/>
      <div className={'jf-popup ' + (activePopup && activePopup.allow ? 'show' : 'hide') +
      (activePopup && activePopup.id ? ' jf-popup-' + activePopup.id : '')}>
        <div className='jf-popup-contain'>
          <button className={'jf-no-style-btn jf-popup-close-btn'}
            onClick={hide}><SVGIcon name={'close-icon'}/></button>
          {activePopup && activePopup.allow && renderContain()}
        </div>
      </div>
  </>;
}

export default Popups;
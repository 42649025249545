import React from 'react';
const TagIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="arroba" transform="translate(-76.074 107)">
        <path id="Path_5542" data-name="Path 5542" d="M12.074,85.333a12,12,0,1,0,5.455,22.683,1.183,1.183,0,1,0-1.077-2.106,9.632,9.632,0,1,1,5.256-8.581v1.8a1.822,1.822,0,0,1-3.643,0v-1.8a6,6,0,1,0-1.657,4.13,4.187,4.187,0,0,0,7.666-2.328v-1.8a12.011,12.011,0,0,0-12-12Zm0,15.619A3.623,3.623,0,1,1,15.7,97.329,3.628,3.628,0,0,1,12.074,100.952Z" transform="translate(76 -192.333)" fill={fill}/>
      </g>
    </svg>
  </>
);
export default TagIcon;
import React, { useEffect, useCallback } from 'react'
import TopMenu from '../../../components/basic/top-menu'
import BottomMenu from '../../../components/basic/bottom-menu'
import { authProfileAtom } from '../../../state/auth'
import { useRecoilValue, useRecoilState } from 'recoil'
import SelectTab from '../../../components/basic/select-tab'
import MediaTabs from '../../../components/complex/media-tabs'
import ListTab from '../../../components/complex/media-tabs/list-tab'
import { getTab, setSeparateState, uniqueList } from '../../../helpers/utils'
import {useUserAction} from "../../../action/user-action";
import Constants from '../../../params/constants'
import { LiveAuthMedia } from '../../../helpers/live-grahpql'
import { accountMyMediaAtom } from '../../../state/pages/account'
import { useNavigate } from 'react-router-dom'

const MyMedia = ({ paramsUrl }) => {
  const navigate = useNavigate()
  const userAction = useUserAction(false)
  const authProfile = useRecoilValue(authProfileAtom)
  const [{loading, loadingMore, data}, setMyMedia] = useRecoilState(accountMyMediaAtom)
  const setLoading = setSeparateState(setMyMedia, "loading");
  const setLoadingMore = setSeparateState(setMyMedia, "loadingMore");
  const setData = setSeparateState(setMyMedia, "data");
  const limit = Constants.MediaListLimit;


  useEffect(() => {
    let isCancelled = false
    if (authProfile) {
      setLoading(true)
      const tab = paramsUrl.tab
      const type = tab === "private" ? "private" : paramsUrl.typeMedia

      const params = {
        limit: { order: false, limit: limit },
        filter: {}
      }
      params.filter.visibility = "free"
      if (paramsUrl.tab !== "private") {
        params.filter.visibility = paramsUrl.tab
      }
      params.filter.type = getTab(type, true)

      userAction.accountMedia(params).then(res => {
        if (isCancelled) return;
        setData(res);
        setLoading(false)
        setLoadingMore(res.length < limit ? 2 : 0);

        LiveAuthMedia.run(authProfile.id, (result) => {
          const live = result.data.media_process;
          setData(old => {
            if (!old) {
              return old;
            }
            const index = old.findIndex(item => item.id === live?.media)
            if (index > -1) {
              old[index].processed = live?.process
            }
            return [...old];
          });
        })
      })
    }
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.tab, paramsUrl.typeMedia, authProfile])

  const onLoadMore = useCallback(() => {
    if (loadingMore > 0) return;
    let isCanceled = false;
    setLoadingMore(1);
    const tab = paramsUrl.tab
    const type = tab === "private" ? "private" : paramsUrl.typeMedia

    const params = {
      limit: { order: false, limit: limit, skip: data?.length },
      filter: {}
    }
    params.filter.visibility = "free"
    if (paramsUrl.tab !== "private") {
      params.filter.visibility = paramsUrl.tab
    }
    params.filter.type = getTab(type, true)
    userAction.accountMedia(params).then(res => {
      if (!res || isCanceled) return;
      setData(uniqueList((data || []).concat(res)));
      setLoadingMore(res.length < limit ? 2 : 0);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.tab, paramsUrl.typeMedia, loadingMore])

  const goToEditMedia = (item) => {
    navigate('/account/media/' + paramsUrl.tab + '/' + item.type + 's/' + item.id)
  }

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"My media"}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page no-padding jf-my-meida-page'}>
      <SelectTab
        style={{height: 50, margin: 20}}
        tabs={[
          { id: "free", name: "Everyone" },
          { id: "subscription", name: "Subscription" },
          { id: "private", name: "Private" },
        ]}
        tab={{id: paramsUrl.tab}}
        setTab={(tab) => navigate("/account/media/" + tab.id)}
      />
      {
        paramsUrl.tab !== "private" ?
          <MediaTabs
            loading={loading}
            onClick={goToEditMedia}
            href={(type) => {
              setData(null);
              navigate("/account/media/" + paramsUrl.tab + "/" + getTab(type))
            }}
            profile={authProfile}
            classContain={'jf-media-overflow'}
            activeTab={getTab(paramsUrl.typeMedia, true)}
            media={{ videos: data, photos: data }}
            onLoadMore={onLoadMore}
          /> :

          <div className={'jf-media-tabs jf-media-overflow'}>
            <div className={'jf-media-tabs-list'}>
              <ListTab
                type={'private'}
                profile={authProfile}
                onClick={goToEditMedia}
                loading={loading}
                medias={data}
                onLoadMore={onLoadMore}
              />
            </div>
          </div>
      }
    </div>
    <BottomMenu/>
  </>;
}

export default MyMedia;
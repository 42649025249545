import React, { useCallback, useEffect } from 'react'
import TopMenu from '../../components/basic/top-menu'
import BottomMenu from '../../components/basic/bottom-menu'
import { authProfileAtom } from '../../state/auth'
import {useRecoilValue, useResetRecoilState} from 'recoil'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../action/main-action'
import Queries from '../../params/queries'
import Constants from '../../params/constants'
import MediaTabs from '../../components/complex/media-tabs'
import SVGIcon from '../../components/svg'
import variables from '../../scss/variables.module.scss'
import { getTab, setSeparateState, uniqueList } from '../../helpers/utils'
import { libraryAtom } from '../../state/pages/library'
import { useRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'

const Library = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fetchApi = useFetchApiAction(false)
  const [{loading, media, data, activeTab, loadingMore }, setLibrary] = useRecoilState(libraryAtom);
  const setLoading = setSeparateState(setLibrary, "loading")
  const setMedia = setSeparateState(setLibrary, "media")
  const setData = setSeparateState(setLibrary, "data")
  const setActiveTab = setSeparateState(setLibrary, "activeTab")
  const setLoadingMore = setSeparateState(setLibrary, "loadingMore")
  const authProfile = useRecoilValue(authProfileAtom);
  const resetLibrary = useResetRecoilState(libraryAtom);
  const resetStateAction = useResetStateWhenChangeUrlAction()

  useEffect(() => {
    resetStateAction.add(resetLibrary)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isCancelled = false
    setLoading(true);
    fetchApi(Queries.Account.MediaPrivate, {
      limit: { limit: Constants.MediaListLimit, order: false }, filter: {
        type: activeTab,
        processed_status: true,
        approved: true
      }
    }).then(res => {
      if (!res?.private || isCancelled) return;
      const defaultList = {
        videos: [],
        photos: [],
      }
      defaultList[activeTab + 's'] = res.private;

      setData(defaultList)
      setLoading(false);
      setLoadingMore(res.private.length ===  Constants.MediaListLimit ? 2 : 0);
    })
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const onLoadMore = useCallback(() => {
    const key = getTab(activeTab || 'video');
    if (loadingMore === 0 || !data[key]) return;

    let isCanceled = false;
    setLoadingMore(0);

    fetchApi(Queries.Account.MediaPrivate, {
      limit: { limit: Constants.MediaListLimit, skip: data[key].length }, filter: {
        type: activeTab,
        processed_status: true,
        approved: true
      }
    }).then(res => {
      if (!res || isCanceled) {
        setLoadingMore(2);
        return;
      }
      setData((old) => {
        return {...old, [key]: uniqueList(old[key].concat(res.private))}
      });
      setLoadingMore(res.private.length === Constants.MediaListLimit ? 2 : 0);
    })

    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMore, activeTab])

  const goToEditMedia = () => {
    navigate(location.state?.url, {state: {...location.state, media: media }, replace: true})
  }

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"Library"}
      action={() => navigate(-1)}
      rightContent={<div style={{ textAlign: 'end' }}>
        <button className={'jf-no-style-btn jf-font-20-22 jf-color-gray jf-font-m'}
                style={{ marginRight: 10 }}
                onClick={goToEditMedia}>Next
        </button>
      </div>}
    />
    <div style={{ flexGrow: 1, overflow: 'auto' }} className={'jf-flex-col'}>
      {authProfile && <MediaTabs
        loading={loading}
        href={(tab) => {
          setActiveTab(tab);
        }}
        navigate={navigate}
        onLoadMore={onLoadMore}
        media={data}
        profile={authProfile}
        onClick={(item) => setMedia(item)}
        classContain={'jf-flex-basic-page'}
        IconInnerItem={(item) => {
          return <button className={'jf-no-style-btn'} style={{ position: 'absolute', top: 10, right: 10 }}>
            <SVGIcon name={'check-circle-icon'}
                     fillCircle={media?.id === item.id ? variables['colors-jf-primary'] : '#fff'}/>
          </button>
        }}
        activeTab={activeTab}/>
      }
    </div>

    <BottomMenu/>
  </>;
}

export default Library;
import React from 'react';
const GreaterIcon = ({
  style = {},
  fill = '#8b8c90',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '6.597',
  height = '11.5',
  className = '',
  viewBox = '0 0 6.597 11.5'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <g id="Group_1130" data-name="Group 1130" transform="translate(-361.161 6.719)">
        <path id="Expand_More" data-name="Expand More" d="M715.1,57.662l-4.552,4.509-4.553-4.51a.559.559,0,0,0-.785,0,.547.547,0,0,0,0,.779l4.946,4.9h0a.557.557,0,0,0,.784,0l4.946-4.9a.55.55,0,0,0,0-.779A.56.56,0,0,0,715.1,57.662Z" transform="translate(303.959 709.578) rotate(-90)" fill={fill} stroke="#8b8c90" strokeWidth="0.5" />
      </g>
    </svg>
  </>
);
export default GreaterIcon;
import BottomMenu from "../../../components/basic/bottom-menu";
import TopMenu from "../../../components/basic/top-menu";
import Button from "../../../components/basic/button";
import SVGIcon from "../../../components/svg";
import variables from "../../../scss/variables.module.scss";

import { Link, useNavigate } from 'react-router-dom'
import { useState } from "react";

const Support = () => {
  const navigate = useNavigate();
  const [openParent, setOpenParent] = useState();
  const [openChild, setOpenChild] = useState();
  const list = [
    {
      title: "Account",
      items: [
        {
          title: "I forgot my password",
          description:
            'Go to the login screen and click "forgot" in the password field. If you no longer remember your email address, contact support@junefox.com',
        },
        {
          title: "How can I delete my account?",
          description: "",
        },
        {
          title: "How can I get a copy of my personal data?",
          description: "",
        },
      ],
    },
    {
      title: "Features",
      items: [
        {
          title: "Why should I subscribe?",
          description: "",
        },
      ],
    },
    {
      title: "Contact",
      items: [
        {
          title: "How do I contact Junefox support?",
          description: "",
        },
      ],
    },
    {
      title: "About Junefox",
      description:
        "For other questions chat with us or email support@junefox.com",
    },
  ];

  return (
    <>
      <TopMenu
        icon={"greater-icon"}
        title={"Support"}
        action={() => navigate(-1)}
      />
      <div className={"jf-basic-page jf-support-page"}>
        <div className="jf-support-content">
          <div className={"jf-row"}>
            <div className="jf-avatar">
              <span className="jf-avatar-june">june</span>
              <span className="jf-avatar-fox">fox</span>
              <div className="jf-avatar-verify">
                <SVGIcon
                  name="check-circle-icon"
                  width={22.4}
                  fillCircle={variables["colors-jf-secondary"]}
                />
              </div>
            </div>
            <div className={"jf-ml-20px jf-mt-auto jf-mb-auto"}>
              <div className={"jf-font-20-22 jf-font-b jf-color-black"}>
                Support
              </div>
              <div
                className={"jf-font-16-22 jf-font-r jf-color-gray jf-mt-3px"}
              >
                @Support
              </div>
            </div>
          </div>
          <Button className={"jf-mt-15px jf-mb-15px"} onClick={() => navigate("/support/contact")}>
            {" "}
            <SVGIcon
              name="chat-icon"
              width={24}
              height={24}
            />
            <span className="jf-ml-10px">
              Chat with us
            </span>
          </Button>
          <p>
            Are you a <b>Creator</b>? <Link to="/">Click here</Link> for support
          </p>
        </div>
        {list.map((item, index) => (
          <div
            className={`jf-collapse ${openParent === index ? "open" : ""}`}
            key={index}
          >
            <div
              className="jf-collapse-header"
              onClick={() => {
                setOpenParent(index !== openParent ? index : undefined);
                setOpenChild(undefined);
              }}
            >
              <p>{item.title}</p>
              <SVGIcon
                name={
                  openParent === index ? "top-arrow-icon" : "bottom-arrow-icon"
                }
                width={24}
                strokeWidth="0.5"
                fill={variables["colors-jf-gray"]}
              />
            </div>
            <div className="jf-collapse-content">
              {item.description && (
                <div className="jf-collapse-description">
                  {item.description}
                </div>
              )}
              {item.items &&
                item.items.map((subItem, subIndex) => (
                  <div
                    className={`jf-collapse jf-collapse-sub ${
                      openChild === subIndex ? "open" : ""
                    }`}
                    key={subIndex}
                  >
                    <div
                      className="jf-collapse-header"
                      onClick={() =>
                        setOpenChild(
                          subIndex !== openChild ? subIndex : undefined
                        )
                      }
                    >
                      <p>{subItem.title}</p>{" "}
                      {subIndex === openChild ? "-" : "+"}
                    </div>
                    {subItem.description && (
                      <div className="jf-collapse-content">
                        <div className="jf-collapse-description">
                          {subItem.description}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      <BottomMenu />
    </>
  );
};

export default Support;

import React from 'react';
const Reporticon = ({
  style = {},
  fill = '#707070',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 22'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="22" viewBox={viewBox}>
      <g id="Group_1100" data-name="Group 1100" transform="translate(-42 -622)">
        <g id="Polygon_3" data-name="Polygon 3" transform="translate(42 622)" fill="rgba(255,255,255,0)">
          <path d="M 22.31545829772949 21.50000953674316 L 1.684539079666138 21.50000953674316 C 1.427628993988037 21.50000953674316 1.298279047012329 21.3288402557373 1.254589080810547 21.25523948669434 C 1.210909008979797 21.18165016174316 1.12256908416748 20.98612022399902 1.245599031448364 20.76058006286621 L 11.56105899810791 1.848909735679626 C 11.68933868408203 1.613719701766968 11.91075897216797 1.588329672813416 11.99999904632568 1.588329672813416 C 12.08924865722656 1.588329672813416 12.3106689453125 1.613719701766968 12.43894863128662 1.848909735679626 L 22.75440979003906 20.76058006286621 C 22.8774299621582 20.98612022399902 22.78909873962402 21.18165016174316 22.74540901184082 21.25523948669434 C 22.70171928405762 21.3288402557373 22.57236862182617 21.50000953674316 22.31545829772949 21.50000953674316 Z" stroke="none" />
          <path d="M 11.99999904632568 2.088329315185547 L 12.00000858306885 2.088329315185547 L 1.684539794921875 21.00000953674316 L 22.31544876098633 21 L 11.99999904632568 2.088329315185547 M 12 1.088327407836914 C 12.34419441223145 1.088327407836914 12.68838882446289 1.262044906616211 12.87789916992188 1.609479904174805 L 23.19334983825684 20.52114868164062 C 23.55682945251465 21.18753051757812 23.07451820373535 22.00000953674316 22.31545829772949 22.00000953674316 L 1.684539794921875 22.00000953674316 C 0.9254798889160156 22.00000953674316 0.4431686401367188 21.18753051757812 0.8066482543945312 20.52114868164062 L 11.12210941314697 1.609479904174805 C 11.31161403656006 1.262044906616211 11.65580654144287 1.088327407836914 12 1.088327407836914 Z" stroke="none" fill={fill} />
        </g>
        <g id="Group_1084" data-name="Group 1084" transform="translate(53.52 630)">
          <g id="Rectangle_1074" data-name="Rectangle 1074" transform="translate(0 0)" fill="#fff" stroke="#707070" strokeWidth="1">
            <rect id="fill" width="0.96" height="7" rx="0.48" stroke="none" />
            <path d="M0,0.5h0.9599999785423279M0.5,0v7.000001430511475M0.9599999785423279,6.500001430511475h-0.9599999785423279M0.4599999785423279,7.000001430511475v-7.000001430511475" fill="none" clipPath="url(#clip)" />
          </g>
          <g id="Rectangle_1075" data-name="Rectangle 1075" transform="translate(0 9)" fill="#fff" stroke={fill} strokeWidth="1">
            <rect id="fill-2" width="0.96" height="1" rx="0.48" stroke="none" />
            <path d="M0,0.5h0.9599999785423279M0.5,0v1.000000238418579M0.9599999785423279,0.5000002384185791h-0.9599999785423279M0.4599999785423279,1.000000238418579v-1.000000238418579" fill={fillNone} />
          </g>
        </g>
      </g>
    </svg>

  </>
);
export default Reporticon;
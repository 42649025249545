import React, { Component } from 'react';
import SVGIcon from '../svg/index'
import variables from '../../scss/variables.module.scss'

class TextClipboard extends Component {
  copy = () => {
    const { text } = this.props;
    navigator.clipboard.writeText(text);
  }

  render () {
    const { width, text, ...props } = this.props;
    let inputWidth = 'auto';
    if (width) {
      inputWidth = isNaN(width) ? width : (width + 'px');
      inputWidth = `calc(${inputWidth} - 25px)`;
    }
    return (
      <div className='jf-text-clipboard' {...props}>
        <div className='jf-text-clipboard-text' style={{width: inputWidth}}>
          {text}
        </div>
        <button className='jf-text-clipboard-icon' onClick={this.copy} type="button">
          <SVGIcon name={'copy-icon'} fill={variables['colors-jf-black2']}/>
        </button>
      </div>
    )
  }
}

export default TextClipboard;
import React from 'react';
const WhatsAppIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.08',
  fillNone = 'none',
  width = '60',
  className = '',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="whatsapp" transform="translate(14.793 14.997)">
        <circle id="Ellipse_74" data-name="Ellipse 74" cx="30" cy="30" r="30" transform="translate(-14.793 -14.997)" fill="#6cd368" />
        <path id="Path_5554" data-name="Path 5554" d="M27.875,50.173l2.046-7.47a14.421,14.421,0,1,1,12.5,7.22H42.41a14.416,14.416,0,0,1-6.891-1.754l-7.644,2Z" transform="translate(-27.149 -20.557)" fill="#20b038" />
        <rect id="Rectangle_1071" data-name="Rectangle 1071" width="16.972" height="15.901" transform="translate(6.776 7.251)" fill={fillNone} opacity={fillOpacity} />
        <path id="Path_5555" data-name="Path 5555" d="M311.146,323.681c-.28-.622-.574-.634-.84-.645-.218-.009-.467-.009-.715-.009a1.371,1.371,0,0,0-1,.467,4.184,4.184,0,0,0-1.306,3.113,7.261,7.261,0,0,0,1.524,3.86c.187.249,2.582,4.138,6.376,5.634,3.153,1.243,3.794,1,4.479.934a3.769,3.769,0,0,0,2.519-1.774,3.117,3.117,0,0,0,.218-1.774c-.093-.156-.342-.249-.715-.435s-2.208-1.09-2.55-1.214-.591-.187-.84.187-.964,1.214-1.181,1.463-.435.281-.809.094a10.209,10.209,0,0,1-3-1.852,11.244,11.244,0,0,1-2.076-2.585c-.218-.373-.023-.575.164-.762.168-.167.373-.436.56-.654a2.558,2.558,0,0,0,.373-.622.687.687,0,0,0-.031-.654C312.2,326.265,311.478,324.419,311.146,323.681Z" transform="translate(-299.61 -314.989)" fill={fill} fillRule="evenodd" />
        <path id="Path_5556" data-name="Path 5556" d="M32.629,4.493A14.939,14.939,0,0,0,9.119,22.511L7,30.249l7.918-2.076a14.927,14.927,0,0,0,7.138,1.818h.006a14.938,14.938,0,0,0,10.566-25.5ZM22.063,27.468h0a12.4,12.4,0,0,1-6.319-1.73l-.454-.269-4.7,1.232,1.255-4.58-.3-.47a12.416,12.416,0,1,1,10.517,5.816Z" transform="translate(-6.793 -0.111)" fill={fill} />
      </g>
    </svg>

  </>
);
export default WhatsAppIcon;
import React from "react";
import BackArrow from "../svg/general-icon/backarrow-icon";
import JunefoxLogo from "./general-icon/junefox-logo-icon";
import NextArrow from "../svg/general-icon/nextarrow-icon";
import Showpassword from "./general-icon/show-password-icon";
import HideshowIcon from "./general-icon/hideshow-icon";
import Hidepassword from "./general-icon/hide-password-icon";
import SearchIcon from "../svg/footer/search-icon";
import HomeIcon from "./footer/home-icon";
import Addicon from "./footer/add-icon";
import CommentIcon from "./footer/comment-icon";
import Usericon from "./footer/user-icon";
import SearchIcon2 from "../svg/footer/other/search-icon";
import HomeIcon2 from "./footer/other/home-icon";
import Addicon2 from "./footer/other/add-icon";
import CommentIcon2 from "./userpannel/commneticon";
import Usericon2 from "./footer/other/user-icon";
import Likeicon from "./userpannel/like-icon";
import Usercomment from "./userpannel/user-comment-icon";
import ShareIcon from "./userpannel/share-icon";
import Coin from "./general-icon/coin-icon";
import Closeicon from "./shootvideoicon/close-icon";
import Flipicon from "./shootvideoicon/flip-icon";
import Timericon from "./shootvideoicon/timer-icon";
import Flashicon from "./shootvideoicon/flash-icon";
import SelectSongicon from "./shootvideoicon/selectsong-icon";
import VideocameraIcon from "./shootvideoicon/videocamera-icon";
import Imageicon from "./shootvideoicon/image-icon";
import Greterthennxt from "./general-icon/greterthenntxarrow-icon";
import Searchgreyicon from "./general-icon/searchgreyicon";
import CloseiconBlack from "./general-icon/closeiconbalck";
import Bookmark from "./general-icon/bookmark-icon";
import ThreeDots from "./general-icon/three-dots-icon";
import PluscircleIcon from './general-icon/pluscircle-icon'
import CheckcircleIcon from './general-icon/checkcircle-icon'
import TagIcon from './general-icon/tag-icon'
import EmojigrayIcon from './general-icon/emojigray-icon'
import PlayIcon from './general-icon/play-icon'
import LikeBorderIcon from './general-icon/like-border-icon'
import SendIcon from './general-icon/send-icon'
import CopyIcon from './general-icon/copy-icon'
import NextIcon from './general-icon/next-icon'
import PhotoCameraIcon from './general-icon/photocamera-icon'
import PlusIcon from './general-icon/plus-icon'
import PlayBordericon from './general-icon/playborder-icon'
import EmojiIcon from './general-icon/emoji-icon'
import Reporticon from './modelicon/report-icon'
import BlockIcon from './modelicon/block-icon'
import FacebookIcon from './modelicon/facebook-icon'
import WhatsAppicon from './modelicon/whatsapp-icon'
import MessengerIcon from './modelicon/messenges-icon'
import MessageRed from './modelicon/messegered-icon'
import Instagramicon from './modelicon/instagram-icon'
import InstagramStroy from './modelicon/instagramstory-icon'
import Videoicon from './userpannel/video-icon'
import Shopicon from './userpannel/shop-icon'
import Photoicon from './userpannel/photo-icon'
import MoneyIcon from './general-icon/money-icon'
import ThreeDotsMenuicon2 from "./userpannel/threedotsmenuicon2";
import BottomArrowicon from "./userpannel/bottom-arrow-icon";
import FullScreenIcon from "./userpannel/fullscreen-icon";
import TopArrowIcon from "./userpannel/top-arrow-icon";
import ShareIcon2 from "./userpannel/share-icon";
import DollerIcon2 from "./userpannel/dollaricon2";
import SoundIcon from "./general-icon/sound-icon";

const getPath = (name, props) => {
  switch (name) {
    case "backarrow":
      return (
        <BackArrow />
      );
    case "soundicon":
      return (
        <SoundIcon />
      );
    case "junefoxlogo":
      return (
        <JunefoxLogo />
      );
    case "nextarrow":
      return (
        <NextArrow />
      );
    case "showpassword":
      return (
        <Showpassword />
      );
    case "hidepassword":
      return (
        <Hidepassword />
      );
    case "searchicon":
      return (
        <SearchIcon />
      );
    case "homeicon":
      return (
        <HomeIcon />
      );
    case "addicon":
      return (
        <Addicon />
      );
    case "commenticon":
      return (
        <CommentIcon />
      );
    case "toparrowicon":
      return (
        <TopArrowIcon />
      );
    case "shareicon2":
      return (
        <ShareIcon2 />
      );
    case "fullscreenicon":
      return (
        <FullScreenIcon />
      );
    case "hideshowicon":
      return (
        <HideshowIcon />
      );
    case "bottomarrowicon":
      return (
        <BottomArrowicon />
      );
    case "usericon":
      return (
        <Usericon />
      );
    case "searchicon2":
      return (
        <SearchIcon2 />
      );
    case "homeicon2":
      return (
        <HomeIcon2 />
      );
    case "addicon2":
      return (
        <Addicon2 />
      );
    case "commenticon2":
      return (
        <CommentIcon2 />
      );
    case "dollaricon2":
      return (
        <DollerIcon2 />
      );
    case "usericon2":
      return (
        <Usericon2 />
      );
    case "likeicon":
      return (
        <Likeicon {...props}/>
      );
    case "usercomment":
      return (
        <Usercomment />
      );
    case "shareicon":
      return (
        <ShareIcon />
      );
    case "coin":
      return (
        <Coin />
      );
    case "closeicon":
      return (
        <Closeicon />
      );
    case "flipicon":
      return (
        <Flipicon />
      );
    case "timericon":
      return (
        <Timericon />
      );
    case "flashicon":
      return (
        <Flashicon />
      );
    case "selectsongicon":
      return (
        <SelectSongicon />
      );
    case "videocameraicon":
      return (
        <VideocameraIcon />
      );
    case "imageicon":
      return (
        <Imageicon />
      );
    case "greterthennxticon":
      return (
        <Greterthennxt />
      );
    case "searchgreyicon":
      return (
        <Searchgreyicon />
      );
    case "closeiconblack":
      return (
        <CloseiconBlack />
      );
    case "bookmarkicon":
      return (
        <Bookmark />
      );
    case "dontsmenuicon":
      return (
        <ThreeDots />
      );
    case "pluscircleicon":
      return (
        <PluscircleIcon {...props}/>
      );
    case "checkcircleicon":
      return (
        <CheckcircleIcon {...props}/>
      );
    case "tagicon":
      return (
        <TagIcon {...props}/>
      );
    case 'emojigrayicon':
      return (
        <EmojigrayIcon {...props}/>
      );
    case 'emojiicon':
      return (
        <EmojiIcon {...props}/>
      );
    case "playicon":
      return (
        <PlayIcon {...props}/>
      );
    case "likebordericon":
      return (
        <LikeBorderIcon {...props}/>
      );
    case "sendicon":
      return (
        <SendIcon {...props}/>
      );
    case "copyicon":
      return (
        <CopyIcon {...props}/>
      );
    case "nexticon":
      return (
        <NextIcon {...props}/>
      );

    case "photocameraicon":
      return (
        <PhotoCameraIcon {...props}/>
      );
    case 'plusicon':
      return <PlusIcon/>;
    case "playbordericon":
      return (
        <PlayBordericon />
      );
    case "reporticon":
      return (
        <Reporticon />
      );
    case "blockicon":
      return (
        <BlockIcon />
      );
    case "facebookicon":
      return (
        <FacebookIcon />
      );
    case "whatsappicon":
      return (
        <WhatsAppicon />
      );
    case "messengericon":
      return (
        <MessengerIcon />
      );
    case "messageredicon":
      return (
        <MessageRed />
      );
    case "instagramicon":
      return (
        <Instagramicon />
      );
    case "instagramstoryicon":
      return (
        <InstagramStroy />
      );
    case "videoicon":
      return (
        <Videoicon {...props}/>
      );
    case "shopicon":
      return (
        <Shopicon {...props}/>
      );
    case "photoicon":
      return (
        <Photoicon {...props}/>
      );
    case "moneyicon":
      return (
        <MoneyIcon {...props}/>
      );
    case "threedotsmenuicon2":
      return (
        <ThreeDotsMenuicon2 />
      );
    default:
      return <path />;
  }
};

const SVGIcon = ({
  name = "",
  fill = "#000",
  ...props
}) => (
  <>
    {getPath(name, { fill , ...props })}
  </>
);

export default SVGIcon;
import React, { useCallback, useEffect } from 'react'
import MediaTabs from '../../../components/complex/media-tabs'
import { useMediaAction } from '../../../action/media-action'
import SearchTextField from '../../../components/basic/search-text-field'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import Queries from '../../../params/queries'
import { getTab, setSeparateState, uniqueList } from '../../../helpers/utils'
import Constants from '../../../params/constants'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { searchTagsAtom, tagListsAtom } from '../../../state/pages/search'

const Tags = () => {
  const [{media, query, loading, loadingMore, queryResult, queryTag, indexQueryResult, inputting}, setSearchTags] = useRecoilState(searchTagsAtom);
  const [tags, setTags] = useRecoilState(tagListsAtom);
  const resetSearchTags = useResetRecoilState(searchTagsAtom);
  const setMedia = setSeparateState(setSearchTags, "media");
  const setQuery = setSeparateState(setSearchTags, "query");
  const setLoading = setSeparateState(setSearchTags, "loading");
  const setLoadingMore = setSeparateState(setSearchTags, "loadingMore");
  const setQueryResult = setSeparateState(setSearchTags, "queryResult");
  const setQueryTag = setSeparateState(setSearchTags, "queryTag");
  const setIndexQueryResult = setSeparateState(setSearchTags, "indexQueryResult");
  const setInputting = setSeparateState(setSearchTags, "inputting");
  const resetStateAction = useResetStateWhenChangeUrlAction()

  const navigate = useNavigate();
  const match = {params: useParams()};
  const mediaAction = useMediaAction(false);
  const fetchAction = useFetchApiAction();
  const limit = Constants.MediaListLimit;

  useEffect(() => {
    resetStateAction.add(resetSearchTags)
    let isCanceled = false;
    const variables = {limit: {limit: 5}}
    fetchAction(Queries.Tags, variables).then(res => {
      if (!res || isCanceled) return;
      setTags(res.tags || []);
    });
    return () => isCanceled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!inputting) return
    let isCanceled = false;
    const variables = {limit: {limit: 5},filter: {query: query}}
    fetchAction(Queries.Tags, variables).then(res => {
      if (!res || isCanceled) return;
      setQueryResult(res.tags || []);
      setLoadingMore(res.length === 0 ? 2 : 0);
      setIndexQueryResult(0)
    });
    return () => isCanceled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    let isCanceled = false;
    setLoading(true);
    setLoadingMore(true);
    const params = { limit: { order: false, limit: limit }, filter: {}, withLike: false }
    if (queryTag || match.params.tag) {
      params.filter.tag = queryTag || match.params.tag;
    }
    mediaAction.getMedias(match.params.subTab || 'video', params).then(res => {
      if (!res || isCanceled) return;
      setMedia((old) => {
        return {...old, [getTab(match.params.subTab || 'video')]: res}
      });
      setLoading(false);
      setLoadingMore(res.length === 0 ? 2 : 0);
      setInputting(true)
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.subTab, match.params.tag, queryTag]);

  const onLoadMore = useCallback(() => {
    if (loadingMore > 0) return;
    let isCanceled = false;
    setLoadingMore(1);
    const key = getTab(match.params.subTab || 'video');
    const params = { limit: { order: false, skip: media[key].length }, filter: { query }, withLike: false }
    if (match.params.tag) {
      params.filter.tag = match.params.tag;
    }
    mediaAction.getMedias(key, params).then(res => {
      if (isCanceled) return;
      setMedia((old) => {
        return {...old, [key]: uniqueList(old[key].concat(res))}
      });
      setLoadingMore(res.length === 0 ? 2 : 0);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMore, query])

  return <>
    <SearchTextField onChange={setQuery} onFocus={() => setInputting(true)}
                     value={query}
                     onKeyDown={(e) => {
                       if (e && (e.keyCode === 38 || e.keyCode === 40)) {
                         e.preventDefault();
                         e.cancelBubble = true;
                         e.returnValue = false
                       }
                       if (e === null) {
                         setQuery('')
                         setQueryTag('')
                         setQueryResult([])
                       } else if(e.key === 'Enter' && queryResult.length > 0) {
                         setInputting(false)
                         setQuery(queryResult[indexQueryResult].tags)
                         setQueryTag(queryResult[indexQueryResult].tags)
                         setQueryResult([])
                       } else if (e.keyCode === 38 && indexQueryResult > 0){
                         setIndexQueryResult(indexQueryResult - 1);
                       } else if (e.keyCode === 40 && indexQueryResult < queryResult.length - 1) {
                         setIndexQueryResult(indexQueryResult + 1);
                       }
                     }}
                     onBlur={() => setInputting(false)}/>
    {query !== '' && <ul className={'jf-create-result'} style={{marginTop: 115}}>
      {queryResult.map((item, i) => <li key={'jf-tag-result-' + item.id}
       className={(i === indexQueryResult ? 'active' : '')}>
        <div onClick={() => {
          setQuery(item.tags)
          setQueryTag(item.tags)
          setQueryResult([])
        }} className={'jf-pointer'}>
          <div className="jf-fullname">{item.tags}</div>
        </div>
      </li>)}
    </ul>}
    <div className="jf-tagsbox">
      {tags && tags.map((item) =>
        <Link key={'jf-tagsbox-tag-' + item.id}
              to={"/s/tags/" + (match.params.subTab || 'video') + '/' + item.tags}
              className={"jf-hashtag " + (match.params.tag === item.tags ? "jf-active" : "")}>{item.tags}</Link>
      )}
    </div>
    <MediaTabs
      href={'/s/tags/'}
      navigate={navigate}
      classContain={'jf-videos'}
      activeTab={match.params.subTab || 'video'}
      media={media}
      loading={loading}
      onLoadMore={onLoadMore}
      loadMore={loadingMore < 2}
    />
  </>
}

export default Tags;
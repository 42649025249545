import React from 'react';
const SearchIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '28',
  className = '',
  viewBox = '0 0 28 28'
}) => (
  <>
    <svg id="search" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="search-2" data-name="search" transform="translate(4 2)">
        <path id="Path_40" data-name="Path 40" d="M0,0H28V28H0Z" transform="translate(-4 -2)" fill="none" />
        <path id="Path_16" data-name="Path 16" d="M32.715,31.346,28.409,27.04a9.809,9.809,0,1,0-1.374,1.374l4.306,4.3a.97.97,0,0,0,1.374-1.369Zm-11.9-2.666a7.865,7.865,0,1,1,7.868-7.863A7.873,7.873,0,0,1,20.817,28.68Z" transform="translate(-12 -10)" fill={fill} />
      </g>
    </svg>

  </>
);
export default SearchIcon;
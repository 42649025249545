import { atom } from 'recoil'

export const messageToUserAtom = atom({
  key: 'messageToUser',
  default: {
    toUser: null,
    text: ''
  }
});

export const messageInboxAtom = atom({
  key: 'messageInbox',
  default: {
    list: [],
    loading: true,
    limited : true,
    text: '',
    toBottom: 0,
    toUser: null,
    firstItemIndex: 10000,
    uploading: false,
    threadId: ''
  }
});

export const messageMassTabAtom = atom({
  key: 'messageMassTab',
  default: {
    toUser: null,
    text: ''
  }
});

export const messageInboxTabAtom = atom({
  key: 'messageInboxTab',
  default: {
    list: [],
    loading: true,
    limited : true,
  }
});

export const messageAutoTabAtom = atom({
  key: 'messageAutoTab',
  default: {
    list: [],
    loading: true,
  }
});

export const messageNewMediaMsgAtom = atom({
  key: 'messageNewMediaMsg',
  default: {
    loading: true
  }
});

export const messageMassAtom = atom({
  key: 'messageMass',
  default: {
    tab: "everyone",
    loading: true,
    custom: false,
    users: []
  }
});

export const messageMassUserAtom = atom({
  key: 'messageMassUser',
  default: {
    users: [],
    selectedUsers: [],
    query: '',
    loadingMore: 0,
    custom: true,
    skipTabQuery: -1,
    tab: "everyone"
  }
});

export const messageAtom = atom({
  key: 'messagePage',
  default: {
    tab: null,
  }
});

export const messageAutoAtom = atom({
  key: 'messageAuto',
  default: {
    loading: false,
  }
});
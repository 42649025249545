import { Virtuoso } from 'react-virtuoso'
import Avatar from '../../../../components/basic/avatar'
import { upperFirstChar } from '../../../../helpers/utils'
import React from 'react'

const Transactions = ({list, loadMore}) => {
  return <>
    <div className={'jf-purchases-transaction jf-mt-20px'} style={{border: 'none'}}>
      <div/>
      <div className={'jf-font-b'}>Username</div>
      <div className={'jf-font-b'}>Price</div>
      <div className={'jf-font-b'}>Type</div>
      <div className={'jf-font-b'}>Date</div>
    </div>
    <div style={{ flexGrow: 1 }}>
      <Virtuoso
        data={list}
        endReached={loadMore}
        itemContent={(index, item) => {
          const add_date = new Date(item.add_date)
          return <div className={'jf-purchases-transaction'}>
            <Avatar image={item.from.avatar} width={40} height={40} text={item.from.fullname}/>
            <div>@{item.from.username}</div>
            <div>${item.value}</div>
            <div>{upperFirstChar(item.type)}</div>
            <div>{add_date.toLocaleString('en', { month: 'short', day: 'numeric', year: '2-digit' })}</div>
          </div>;
        }}
      />
    </div>
    </>
}

export default Transactions;
import TopMenu from "../../../components/basic/top-menu";
import BottomMenu from "../../../components/basic/bottom-menu";
import {useNavigate} from "react-router-dom";


export const CookiePolicy = ({history}) => {
    const navigate = useNavigate();

    return <>
        <TopMenu
            icon={'greater-icon'}
            title={'About'}
            action={() => navigate("/about")}
        />
        <div className={'jf-basic-page'} style={{padding: 0}}>
            <div style={{margin: '20px'}}>
                <h1> Cookie policy</h1>
                <div><p>
                    This Policy does not address how we deal with your personal information. To learn more about how we
                    collect and process your personal information, please see our <a
                    href="/about/privacy-policy"> Privacy Policy
                </a>.
                    This Policy explains more about how we use Cookies and your related choices.
                    By using the Service, you expressly consent to information handling
                    practices and storage and access of Cookies as described in this Policy.
                </p> <h2>
                    Definitions
                </h2>
                    <table>
                        <thead>
                        <tr>
                            <th>
                                Term
                            </th>
                            <th>
                                Definition
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                The "Policy"
                            </td>
                            <td>
                                The cookie policy as outlined in this document.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "junefox.com", "we", or "us"
                            </td>
                            <td>
                                Internet Fox SRL, trading under the name junefox.com.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                The "Service"
                            </td>
                            <td>
                                The junefox.com website, located at junefox.com, and any other websites, applications,
                                or
                                services provided, owned or operated by us that link to this Policy.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Cookies"
                            </td>
                            <td>
                                A small piece of data that is stored on your device to help websites and mobile apps
                                remember things about you. Other technologies, including Web storage and identifiers
                                associated with your device, may be used for similar purposes.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h2>
                        Modifications
                    </h2> <p>
                        We reserve the right to change this Policy at any time. If we make changes,
                        we will notify you by revising the date at the bottom of this Policy and,
                        in some cases, we may provide you with additional notice (such as adding a
                        statement to our homepage or sending you an email notification). Changes to
                        this Policy are effective at the time they are published.
                        Your continued use of the Service after the effective date of the revised Policy
                        constitutes your acceptance of the revised Policy.
                    </p> <h2>
                        How We Use Cookies
                    </h2> <p>
                        junefox.com uses Cookies, including third-party Cookies, for a number of reasons,
                        like protecting your junefox.com data and account, helping us see which features are
                        most popular, counting visitors to a page, improving our users’ experience,
                        keeping our services secure, providing relevant advertising, and
                        providing you with a better, more intuitive, and satisfying experience. The Cookies
                        we use generally fall into one of the following categories.
                    </p>
                    <table>
                        <thead>
                        <tr>
                            <th>
                                Category
                            </th>
                            <th>
                                Why we use these Cookies
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Necessary
                            </td>
                            <td>
                                We use these Cookies to run our Services, and to identify and prevent security risks.
                                For example, we may use these Cookies to store your session information.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Preferences
                            </td>
                            <td>
                                We use these Cookies to remember your settings and preferences, and to improve your
                                experience on our Services. For example, we may use these Cookies to remember your
                                language preferences.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Performance
                            </td>
                            <td>
                                We use these Cookies to collect information about how you use our Services, and monitor
                                and improve our Services performance.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Marketing
                            </td>
                            <td>
                                We use these Cookies to deliver advertisements, to make them more relevant and
                                meaningful to consumers, and to track the efficiency of our advertising campaigns.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        We may also use similar technologies such as pixel tags, clear GIFS and web beacons in
                        conjunction with Cookies to track activity on our Service.
                        Unless you disable Cookies, when you visit a web page, these technologies may tell us that
                        you’ve visited.
                        These technologies may also let us know whether you’ve received our emails.
                    </p> <h2>
                        Analytics and Advertising Providers
                    </h2> <p>
                        junefox.com may let other companies use Cookies on our Service. These companies
                        may collect information about how you use our Service over time and combine it
                        with similar information from other services and companies. This information may
                        be used to, among other things, analyze and track data, determine the popularity
                        of certain content, and better understand your online activity.
                    </p> <p>
                        We may collect information about your activity on third-party services that use
                        Cookies provided by us. We use this information to improve our advertising services,
                        including measuring the performance of ads and showing you more relevant ads.
                    </p> <h2>
                        Your Choices
                    </h2> <p>
                        Your browser may provide you with the option to refuse some or all browser Cookies.
                        You may also be able to remove Cookies from your browser. For more information
                        about how to manage or disable Cookies, please follow the instructions provided by
                        your browser or see <a rel="noreferrer" href="http://www.allaboutcookies.org/manage-cookies"
                                               target="_blank">www.allaboutcookies.org/manage-cookies</a>
                        Some features of the Service may not work as intended if you disable Cookies.
                    </p> <h2>
                        Contact
                    </h2> <p>
                        We hope that this Policy helps you understand, and feel more confident about,
                        our use of Cookies. If you have any further questions regarding this Policy
                        please contact us at <a external=""
                                                href="mailto:support@junefox.com">support@junefox.com</a>.
                    </p> <h2>
                        Language
                    </h2> <p>
                        This policy was originally written in English. To the extent any translated version of these
                        this policy conflicts with the English version, the English version controls.
                    </p> <p><b>Last modified:</b> April 2021
                    </p></div>
                <p>
                    © 2022 Internet Fox SRL unless otherwise noted. All rights reserved.
                </p></div>
        </div>
        <BottomMenu/>
    </>;
}
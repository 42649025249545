import React from 'react';
const CheckcircleIcon = ({
  style = {},
  fill = '#fff',
  fillCircle = '#23d2e2',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg id="next" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="Path_6038" data-name="Path 6038" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" transform="translate(24 24) rotate(180)" fill={fillCircle}/>
      <g id="correct" transform="translate(7.2 8.4)">
        <path id="Path_30" data-name="Path 30" d="M9.56,11.493,3.608,17.445a.134.134,0,0,1-.19,0l-3.38-3.38a.134.134,0,0,1,0-.189l.821-.821a.134.134,0,0,1,.19,0l2.464,2.464L8.55,10.482a.134.134,0,0,1,.19,0l.821.821A.134.134,0,0,1,9.56,11.493Z" transform="translate(0 -10.443)" fill={fill}/>
      </g>
    </svg>
  </>
);
export default CheckcircleIcon;
import React from 'react';

const CopyFillIcon = ({
  style = {},
  fill = '#fb3',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '25',
  className = '',
  viewBox = '0 0 25 25'
}) => (
  <>
    <svg id="pictures" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_1237" data-name="Rectangle 1237" width={width} height={width} fill="none"/>
      <path id="Path_6311" data-name="Path 6311" d="M16,6H9A3,3,0,0,0,6,9v7a1.937,1.937,0,0,1-2-2.177V6.182A1.962,1.962,0,0,1,6.182,4h7.636A1.937,1.937,0,0,1,16,6Z" transform="translate(0.333 0.333)" fill="#595d73" opacity="0.3"/>
      <path id="Path_6312" data-name="Path 6312" d="M10.182,8h7.636A1.962,1.962,0,0,1,20,10.182v7.636A1.962,1.962,0,0,1,17.818,20H10.182A1.962,1.962,0,0,1,8,17.818V10.182A1.962,1.962,0,0,1,10.182,8Z" transform="translate(0.667 0.667)" fill="#bfc0ce" fillRule="evenodd"/>
    </svg>
  </>
);
export default CopyFillIcon;

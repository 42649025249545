import React from 'react';
const PhotoFillIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '20',
  height = '17.5',
  className = '',
  viewBox = '0 0 20 17.5'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
  <g id="image" transform="translate(0 1)">
    <g id="image-2" data-name="image" transform="translate(-0.002 -2)">
      <path id="Path_6315" data-name="Path 6315" d="M6.752,5.875A1.875,1.875,0,1,1,4.877,4,1.875,1.875,0,0,1,6.752,5.875Z" transform="translate(0.75 0.75)" fill={fill}/>
      <path id="Path_6316" data-name="Path 6316" d="M2.5,1A2.5,2.5,0,0,0,0,3.5V16a2.5,2.5,0,0,0,2.5,2.5h15A2.5,2.5,0,0,0,20,16V3.5A2.5,2.5,0,0,0,17.5,1H2.5Zm15,1.25a1.25,1.25,0,0,1,1.25,1.25v8.125L14.031,9.191a.625.625,0,0,0-.721.116L8.672,13.945,5.347,11.73a.625.625,0,0,0-.787.078L1.252,14.75V3.5A1.25,1.25,0,0,1,2.5,2.25Z" fill={fill}/>
    </g>
  </g>
</svg>


export default PhotoFillIcon;
import React from 'react';
const AddIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '44',
  className = '',
  viewBox = '0 0 44 28'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="28" viewBox={viewBox}>
      <g id="plus" transform="translate(-185 -847)">
        <rect id="Rectangle_1047" data-name="Rectangle 1047" width="36" height="28" rx="5" transform="translate(193 847)" fill="#23d2e2" />
        <rect id="Rectangle_1048" data-name="Rectangle 1048" width="36" height="28" rx="5" transform="translate(185 847)" fill="#403bfa" />
        <rect id="Rectangle_1046" data-name="Rectangle 1046" width="38" height="28" rx="5" transform="translate(188 847)" fill={fill} />
        <g id="Group_35" data-name="Group 35" transform="translate(1 1)">
          <g id="Group_36" data-name="Group 36">
            <g id="Rectangle_1049" data-name="Rectangle 1049" transform="translate(200 859)" fill={fill} stroke="#130c18" strokeWidth="1">
              <rect width="12" height="2" stroke="none" />
              <rect x="0.5" y="0.5" width="11" height="1" fill="none" />
            </g>
            <g id="Rectangle_1050" data-name="Rectangle 1050" transform="translate(205 854)" fill={fill} stroke="#130c18" strokeWidth="1">
              <rect width="2" height="12" stroke="none" />
              <rect x="0.5" y="0.5" width="1" height="11" fill="none" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  </>
);
export default AddIcon;
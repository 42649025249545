import React from 'react';
const HidePasswordIcon = ({
  style = {},
  fill = '#BEBFCE',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '30',
  className = '',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="visible" transform="translate(0)">
        <rect id="Rectangle_1154" data-name="Rectangle 1154" width={width} height={width} fill="rgba(255,255,255,0)"/>
        <g id="visibility" transform="translate(0 6.25)">
          <g id="Group_1790" data-name="Group 1790">
            <path id="Path_6236" data-name="Path 6236" d="M29.889,115.065c-.236-.342-5.914-8.394-14.888-8.394-7.7,0-14.564,8-14.852,8.346a.624.624,0,0,0,0,.807c.289.341,7.151,8.346,14.852,8.346s14.564-8,14.852-8.346A.627.627,0,0,0,29.889,115.065ZM15,120.421a5,5,0,1,1,5-5A5,5,0,0,1,15,120.421Z" transform="translate(0 -106.671)" fill={fill}/>
          </g>
        </g>
      </g>
    </svg>

  </>
);
export default HidePasswordIcon;
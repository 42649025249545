import EarningIcon from './earning-icon';
import SettingIcon from './setting-icon';
import PurchaseIcon from './purchase-icon'
import MediaIcon from './media-icon'
import ConsentIcon from './consent-icon'
import SupportIcon from './support-icon'
import LegalIcon from './legal-icon'
import SignOutIcon from './sign-out-icon'
import SignInIcon from './sign-in-icon'
import UserIcon from './user-icon'
import CreatorIcon from './creator-icon'
import CreatorBlackIcon from './creator-black-icon'

const icons = {
  'earning-icon': EarningIcon,
  'setting-icon': SettingIcon,
  'purchase-icon': PurchaseIcon,
  'media-icon': MediaIcon,
  'consent-icon': ConsentIcon,
  'support-icon': SupportIcon,
  'legal-icon': LegalIcon,
  'sign-out-icon': SignOutIcon,
  'sign-in-icon': SignInIcon,
  'create-user-icon': UserIcon,
  'creator-icon': CreatorIcon,
  'creator-black-icon': CreatorBlackIcon
}

export default icons;
import React, { useEffect } from 'react'
import TopMenu from '../../../components/basic/top-menu'
import BottomMenu from '../../../components/basic/bottom-menu'
import MediaMsg from '../../../components/message/media-msg'
import { TextField } from '../../../components/basic/text-field'
import { SelectField } from '../../../components/basic/select-field'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { setSeparateState, upperFirstChar } from '../../../helpers/utils'
import { useFetchApiAction } from '../../../action/main-action'
import Mutations from '../../../params/mutations'
import { useLocation, useNavigate } from 'react-router-dom'
import Queries from '../../../params/queries'
import { useRecoilState } from 'recoil'
import { messageAutoAtom } from '../../../state/pages/message'

const schema = yup.object({
  media: yup.object().nullable().required(),
  price: yup.string().when("free", {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required(),
  }),
  amount: yup.number().required(' ').typeError('must be a number').transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  }),
  unit: yup.string().required(' '),
  text: yup.string().required(),
  free: yup.boolean(),
}).required();

const MsgAuto = ({ paramsUrl }) => {
  const [{ loading }, setMessageAuto] = useRecoilState(messageAutoAtom);
  const setLoading = setSeparateState(setMessageAuto, "loading")

  const navigate = useNavigate();
  const location = useLocation();
  const fetchApi = useFetchApiAction();
  const event = paramsUrl.tab === "follower" ? "follow" : "subscribe";
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const value = location.state;
    if (value) {
      setLoading(false);
      return;
    }
    setLoading(true);
    fetchApi(Queries.Messages.RuleEvent, { event: event }).then(res => {
      if (!res?.messenger_rules_event) {
        setLoading(false);
        return;
      }
      const data = res?.messenger_rules_event;
      const value = JSON.parse(data.messages[0].value);
      setValue("price", value.price);
      setValue("text", value.text);
      setValue("preview", value.preview);
      setValue("media", {
        info: JSON.stringify([{ duration: value.duration, width: value.width, height: value.height }]),
        id: value.media,
        thumbnail: { grid: value.thumbnail },
        type: value.duration > 0 ? "video" : "",
        source: {url: value.url}
      });
      setValue("amount", data.delay.amount);
      setValue("unit", data.delay.unit);
      setValue("free", value.free);
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.tab])

  useEffect(() => {
    const value = location.state;
    if (value) {
      setValue("price", parseFloat(value.price?.toString().replace("$", "")) || '');
      setValue("text", value.text);
      setValue("preview", value.preview);
      setValue("media", value.media);
      setValue("amount", value.amount);
      setValue("unit", value.unit);
      setValue("free", value.free);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const onSave = (data) => {
    let info = data.media?.info ? JSON.parse(data.media.info) : [];
    info = info.find(find => !!find.duration)

    if (data.media && data.media.type === 'video' && info.duration < 10 && data.free !== true) {
      return;
    }

    const value = {
      price: data.free === true ? 0 : parseFloat(data.price?.replace("$", "")),
      text: data.text,
      preview: data.free === true ? 10 : data.preview,
      free: data.free === true,
    };
    if (data.media) {
      value.media = data.media.id;
      value.width = info.width;
      value.height = info.height;
      value.thumbnail = data.media.thumbnail.grid;
      value.duration = info.duration
      value.url = data.free === true ? data.media.source.url : null
    }
    fetchApi(Mutations.Messages.UpdateRule, {
      rule: {
        event: event,
        messages: {
          type: 'teaser',
          value: JSON.stringify(value)
        },
        delay: {
          unit: data.unit || 'minutes',
          amount: parseInt(data.amount || 0)
        }
      }
    }).then(res => {
      navigate('/messages/auto', {replace: true});
    })
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={upperFirstChar(paramsUrl.tab)}
        action={() => navigate(-1)}
      />
      <form className={'jf-flex-basic-page'}>
        {!loading && <>
          <div className={'jf-text-field-label'} style={{ margin: '30px 20px 10px 20px' }}>Delay</div>
          <div className={'jf-flex-row'} style={{ margin: '0 20px' }}>
            <TextField
              type={'number'}
              register={register("amount")}
              error={errors.amount?.message}
              styleContain={{ marginRight: 20, flex: 1 }}
            />
            <SelectField
              register={register("unit")}
              setValue={(v) => setValue("unit", v)}
              value={getValues('unit')}
              error={errors.unit?.message}
              list={["minutes", 'hours']}
              styleContain={{ flex: 1 }}
            />
          </div>
          {(errors.amount?.message || errors.unit?.message) &&
          <div className={'jf-text-field-msg jf-error'} style={{ margin: '-10px 20px 0' }}>Delay is a required field</div>}
          <div style={{ flexGrow: 1 }}/>
          <div style={{ padding: 20 }}>
            <MediaMsg
              locationState={() => getValues()}
              register={register}
              setValue={setValue}
              onSave={handleSubmit(onSave)}
              getValues={getValues}
              errors={errors}
            />
          </div>
        </>}
      </form>
      <BottomMenu/>
    </>
  );
}

export default MsgAuto;
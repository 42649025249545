const Mutations = {
  AccountBlocked: {
    BlockAccount: `
      mutation BlockAccount ($accountId: String!) {
        blockAccount(accountId: $accountId) {
          id
        }
      }`
  },
  Media: {
    Update:  `
      mutation UpdateMedia($media: MediaInput!) {
        updateMedia(media: $media){id}
      }`,
    Delete:  `
      mutation DeleteMedia($media: String!) {
        deleteMedia(media: $media)
      }`,
  },
  MediaLike: {
    Update: `
      mutation UpdateMediaLike($media: String!){ 
        like(media: $media)
      }`
  },
  Profile: {
    Update: `
      mutation UpProfile($account: AccountInput!) {
        upProfile(account: $account){id}
      }`,
    GenerateRtmp: `
      mutation {
        generateStudioRtmp
      }`,
    VerifyEmail: `
      mutation VerifyEmail($hash: String!) {
        verify_email(hash: $hash)
      }
    `,
    Delete: `
      mutation DeleteUser($password: String!) {
        delete_user(password: $password)
      }
    `
  },
  Relation: {
    Following: `
      mutation UpdateFollowing($id: String!){
        upFollowing(id:$id)
      }`,
  },
  Report: {
    MakeReport: `
      mutation MakeReport ($report_user: String!, $report_media: String!, $reason: String, $details: String) {
        makeReport(report_user: $report_user, report_media: $report_media, reason: $reason, details: $details) {
          id
        }
      }`,
  },
  ShortLink: `
    mutation GenerateShortLink($media: String!) {
      generateShortLink(media: $media) {
        id add_date
      }
    }`,
  Tip: {
    Add: `
      mutation AddTip($user: String!, $amount: Int!, $message:String){
        addTip(user: $user, amount: $amount, message: $message) {
          user {id}
        }
      }`,
  },
  NewLiveChatMessage:  `
      mutation NewLiveChatMessage($user: String!, $text: String!){
        liveChatMessage(user: $user, text: $text) {
          id
        }
      }`,
  Comments:  {
    Add: `
      mutation addComment ($media: String!, $message: String!, $thread: String) {
      addComment(media: $media, message: $message, thread: $thread) {
        id media {id} user {username} from {username avatar fullname}
        message thread {id message} add_date
        likes score like
      }
    }
    `,
    Like: `mutation LikeComment ($comment: String!) {
      likeComment(comment: $comment)
    }`
  },
  Messages: {
    Add: `mutation NewMessage($to:String!,$data:MessengerMessageDataInput!){
      newMessage(to:$to,data:$data){
        id
        thread {
          id
          visible1
          visible2
        }
        data{
          type
        }
      }
    }`,
    UpdateThreadUnread:`mutation UpdateThreadRead($thread:String!){
      updateThreadRead(thread:$thread){
        id  users{ id username fullname avatar }
      }
    }`,
    UpdateSupportThreadUnread:`mutation UpdateSupportThreadRead{
      updateSupportThreadRead{
        id  users{ id username fullname avatar }
      }
    }`,
    UpdateRule: `mutation UpdateMessengerRule($rule:MessengerRuleInput!){
      updateMessengerRule(rule: $rule){
        id
      }
    }`,
    SwitchRule: `mutation SwitchMessengerRule($event: String!, $enabled: Boolean!){
      switchMessengerRule(event: $event, enabled: $enabled)
    }`,
    AddTemplate: `mutation NewMessengerTemplate($data:MessengerTemplateInput!){
      newMessengerTemplate(template:$data){
        messages {type value}
      }
    }`,
    DelTemplate: `mutation DeleteMessengerTemplate($id: String!){
      deleteMessengerTemplate(id: $id)
    }`,
    GenerateThread: `mutation GenerateThread($username: String!){
      generateThread(username: $username) {id}
    }`,
    UnlockTeaser: `mutation UnlockTeaser($message_id:String!, $thread: String!) {
      unlock_teaser(message_id:$message_id, thread:$thread){
        id
        from {
           id fullname
           username avatar
        }
        data{ type value }
     }
    }`
  },
  SubscriptionDetail: {
    Save: `mutation CreateUserSubscriptionDetail($period: PeriodInput!,$trial: PeriodInput){
      createUserSubscriptionDetail( period: $period, trial: $trial) {id}
    }`,
    Update: `mutation UpdateSubscriptionDetailByUser($period: PeriodInput!,$trial: PeriodInput){
      updateSubscriptionDetailByUser(period: $period, trial: $trial) {id}
    }`,
    Delete: `mutation {
      deleteUserSubscriptionDetail
    }`,
  },
  PasswordReset: {
    PasswordResetRequest: `
      mutation PasswordResetRequest($email:String!){
        password_reset_request(email:$email){
          user
          add_date
          expiration_date
        }
      }
    `,
    PasswordReset: `
      mutation PasswordReset($id:String!, $password:String!){
        password_reset(id: $id, password:$password){
          ok,
          errors
        }
      }
    `
  },
  CreatorSignup: `
    mutation CreatorSignup($instagram: String!, $twitter: String!){
      creator_signup(instagram: $instagram, twitter: $twitter){
        id
        user
        fees {
          subscription
          sales
        }
        status
        add_date
      }
    }
  `,
  UpdateWallet: `
    mutation UpdateWallet($cards: [CardInput!]){
      updateWallet(cards: $cards){
        id
        user{id username}
        amount
        cards {
          name
          number
          cvv
          date {
            month
            year
          }
        }
        last_update
      }
    }
  `
};

export default Mutations;
import { atom } from 'recoil';

export const suggestionTagsAtom = atom({
  key: 'suggestionTags',
  default: []
});

export const locationAtom = atom({
  key: 'location',
  default: false,
});

export const loadingAtom = atom({
  key: 'loading',
  default: false,
});

export const loadingAppAtom = atom({
  key: 'loadingApp',
  default: false,
});

export const camStreamAtom = atom({
  key: 'camStream',
  default: null,
});

export const swiperMobileIndexAtom = atom({
  key: 'swiperMobileIndex',
  default: -1,
});

export const swiperMobileBottomShowAtom = atom({
  key: 'swiperMobileBottomShow',
  default: true,
})

export const resetStateActionAtom = atom({
  key: 'resetStateAction',
  default: [],
})
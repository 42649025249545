import React from "react";

const ConsentIcon = ({
  style = {},
  fill = "#7c79fa",
  fillOpacity = "0.3",
  fillNone = "none",
  width = "60",
  height = "60",
  className = "",
  viewBox = "0 0 60 60",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <g
        id="Group_2146"
        dataName="Group 2146"
        transform="translate(-1683 -127)"
      >
        <rect
          id="Rectangle_1218"
          dataName="Rectangle 1218"
          width="60"
          height="60"
          transform="translate(1683 127)"
          fill="none"
        />
        <path
          id="diamond-icon"
          d="M43.89,2.294A2.275,2.275,0,0,0,41.786,1H10.207a2.276,2.276,0,0,0-2.11,1.3L1.241,15.885q-.024.048-.046.1a2.273,2.273,0,0,0,.174,2.175q.028.042.057.083L24.1,49.988A2.275,2.275,0,0,0,27.886,50L50.57,18.245,50.6,18.2a2.272,2.272,0,0,0,.149-2.3l0,0-.006-.012ZM26,6.875l-6.314,7.761H32.308Zm10.284,5.439L38.64,5.546H30.776ZM13.349,5.546h7.866l-5.507,6.77Zm26.932,9.091,1.938-5.559,2.807,5.559Zm-6.4,4.545H18.1l7.891,22.641Zm-.528,15.327L38.7,19.182H44.3ZM6.969,14.637,9.775,9.078l1.937,5.559Zm.722,4.545H13.3l5.341,15.327Z"
          transform="translate(1687.003 131)"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default ConsentIcon;

import React from 'react';
const CloseiconBlack = ({
  style = {},
  fill = '#130c18',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '14',
  className = '',
  viewBox = '0 0 14 14'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="close" transform="translate(0)">
        <rect id="Rectangle_1027" data-name="Rectangle 1027" width="2.828" height="16.97" rx="1" transform="translate(0 2) rotate(-45)" fill={fill} />
        <rect id="Rectangle_1028" data-name="Rectangle 1028" width="2.828" height="16.97" rx="1" transform="translate(12 0) rotate(45)" fill={fill} />
      </g>
    </svg>

  </>
);
export default CloseiconBlack;
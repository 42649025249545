import React from 'react';
import { useNavigate } from "react-router-dom";
import SVGIcon from '../svg/index';

const ItemMenu = React.memo (({ text, icon, iconLeft, path, styleText = {},className = '', list, children,...other }) => {
  const navigate = useNavigate();
  if (list) {
    return (
      <div className={'jf-item-menu-multi ' + className} {...other}>
        {list.map((item, i) => <div key={'f-item-menu-multi-' + i}
                                    onClick={item.onClick}
                                    className={'jf-row ' + (i !== 0 ? 'jf-mt-15px' : '')}>
          {item.iconLeft && <div className={'jf-item-menu-multi-icon jf-mr-20px ' + item.iconLeft}>
            <SVGIcon name={item.iconLeft}/>
          </div>}
          <div className='jf-item-menu-multi-text'>
            {item.text}
          </div>
          {item.icon && <div className={'jf-item-menu-multi-icon ' + item.icon}>
            <SVGIcon name={item.icon}/>
          </div>}
        </div>)}
      </div>
    );
  }

  return (
    <div onClick={() => {
      if (path) navigate(path)
    }} className={'jf-item-menu ' + className} {...other}>
      {iconLeft && <div className={'jf-item-menu-icon ' + iconLeft}>
        <SVGIcon name={iconLeft}/>
      </div>}
      <div className={'jf-item-menu-text'} style={styleText}>
        {text}
      </div>
      {icon && <div className={'jf-item-menu-icon ' + icon}>
        <SVGIcon name={icon}/>
      </div>}
      {children}
    </div>
  );
});

export default ItemMenu;
import React, {useEffect, useRef}  from 'react'
import SVGIcon from '../svg'
import variables from '../../scss/variables.module.scss'
import Avatar from '../basic/avatar'
import { LiveChatMessages } from '../../helpers/live-grahpql'
import { useFetchApiAction } from '../../action/main-action'
import Mutations from '../../params/mutations'
import { authProfileAtom } from '../../state/auth'
import {useRecoilValue, useSetRecoilState,useRecoilState} from 'recoil'
import { activePopupState } from '../../state/components/popup'
import Queries from '../../params/queries'
import { liveBoxChatAtom } from '../../state/components/live-box'
import { setSeparateState } from '../../helpers/utils'

const LiveBoxChat = ({otherIcon, ...props}) => {
  const [{text, messages}, setLiveBoxChat] = useRecoilState(liveBoxChatAtom);
  const fetchAction = useFetchApiAction();
  const authProfile = useRecoilValue(authProfileAtom)
  const setTipsPopup = useSetRecoilState(activePopupState('tips'));
  const chatRef = useRef();
  const setMessages = setSeparateState(setLiveBoxChat, "messages");
  const setText = setSeparateState(setLiveBoxChat, "text");

  useEffect(() => {
    if (props.user.live) {
      fetchAction(Queries.LiveChat, { user: props.user.id }).then(res => {
        res?.livechat && setMessages(res.livechat);
        LiveChatMessages.run(props.user.id, (result) => {
          setMessages(old => ([...old, { ...result.data.livechat }]))
        })
      });
    } else {
      if (LiveChatMessages.subscription) {
        LiveChatMessages.subscription();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages])

  const send = () => {
    if (text !== '') {
      const t = text.substr(0,512);
      setText('');
      fetchAction(Mutations.NewLiveChatMessage, { text: t, user: props.user.id }).then();
    }
  }
  const showTips = () => {
    if (props.user) {
      setTipsPopup({ active: true, profile: props.user })
    }
  }

  return <div className={'jf-live-chat '}>
    <div className='jf-live-chat-body chat' ref={chatRef}>
      <div style={{ flexGrow: 1 }}/>
      {messages && messages.map((item, i) =>
        <div className={'jf-live-chat-item chat__message'} key={'jf-live-chat-item-' + i}>
          <Avatar image={item.user.avatar} text={item.user.fullname} width={40} height={40}/>
          <div>
            <div style={{opacity: 0.6}}>@{item.user.username}</div>
            <div style={{opacity: 0.8, wordBreak: 'break-word'}}>{item.text}</div>
          </div>
        </div>
      )}
    </div>
    <div className={'jf-live-chat-footer'}>
      {authProfile ? <>
          <input className={'jf-live-chat-input'}
                 onKeyPress={event => {
                   if (event.key === 'Enter') {
                     send()
                   }
                 }}
                 placeholder={'Add Comment'}
                 value={text} onChange={(e) => setText(e.target.value)}/>
        <button className={'jf-live-chat-input-icon-btn jf-ml-10px jf-mr-10px'} onClick={send}>
          <SVGIcon name={'send-fill-icon'} fill={variables['colors-jf-white']} width={22} height={24}/>
        </button>
          {otherIcon ? otherIcon() : <button className={'jf-live-chat-input-icon-btn'} onClick={showTips}>
          <SVGIcon name={'money-icon'} fill={variables['colors-jf-white']} width={20} height={27}/>
        </button>}
      </> : <div className={'jf-live-chat-input'} style={{ textAlign: 'center' }}>You need to login write messages</div>
      }
    </div>
  </div>;
}

export default LiveBoxChat;

export const minWidthScreen = '(min-width: 900px)';

export const countFormat = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num || 0;
}

export const msgLastTimeFormat = (timestamp) => {
  const current = new Date();
  const date = new Date(timestamp);
  const milliseconds = current.getTime() - date.getTime();
  if (milliseconds <= 86400000) {
    if (milliseconds < 3600000) {
      const min = (milliseconds / 60000 + 0.5).toFixed(0);
      return min + ' ' + (min > 1 ? 'minutes' : 'minute') + ' ago';
    }
    const hours = (milliseconds / 3600000 + 0.5).toFixed(0);
    return hours + ' ' + (hours > 1 ? 'hours' : 'hour') + ' ago';
  }

  const time = date.toLocaleTimeString('en-US',
    { hour: 'numeric', minute: 'numeric', hour12: true });

  const yesterday = new Date(current);
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.toDateString() === date.toDateString()) {
    return 'Yesterday at ' + time
  }

  return date.toDateString('en-US');
}

export const DCookie = {
  getItem(k) {
    const find = document.cookie
      .split('; ')
      .find((row) => row.startsWith(k));
    return find && find.split('=')[1];
  },
  setItem(k, v) {
    const now = new Date();
    const time = now.getTime();
    //1 day
    const expireTime = time + 24 * 60 * 60 * 1000;
    now.setTime(expireTime);
    document.cookie = k + '=' + v + ';expires=' + now.toGMTString() + ';path=/'
  }
};

export const checkMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const formatDuration = (seconds) => {
  if (!seconds) {
    return null;
  }
  return new Date(seconds * 1000).toLocaleTimeString().substr(3, 5);
}

export const upperFirstChar = (text) => {
  return text.charAt(0).toLocaleUpperCase() + text.substr(1);
}

export const getTab = (tab, toSingle) => {
  return !toSingle ? (tab !== 'private' && tab !== "shop" ? tab.replace('s', '') + 's' : tab) :
    (tab !== 'private' && tab !== "shop" ? tab.replace('s', '') : tab)
}

export const getVideoType = (url) => {
  return "video/" + url.split('.').pop()
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function toBytes (str) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const unit = str.replace(/\d+/g, '');
  const num = str.replace(/\D/g, '');
  const k = 1024;
  const i = sizes.indexOf(unit.toLocaleUpperCase());

  return num * Math.pow(k, i)
}

export function getStorageUrl(url, type) {
  return url
}

export function uniqueList (data, key = "id") {
  let checked = [];
  let result = [];
  for (let i in data){
    let item = data[i]
    if (checked.indexOf(item[key]) < 0) {
      result.push(item);
      checked.push(item[key]);
    }
  }
  return result;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function setSeparateState (parentFn, k) {
  return (v) => {
    parentFn(old => {
      if (typeof v === 'function') {
        return {...old, [k]: v(old[k])}
      }
      return {...old, [k]: v}
    })
  }
}

export function setArrayState(parentFn, k) {
  return (v) => {
    console.log('v', v)
    console.log('k', k)
    parentFn(old => {
      if (typeof v === 'function') {
        return {...old, [k]: v(old[k])}
      }
      return {...old, [k]: v}
    })
  }
}

export function isiOS() {
  let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
import TopMenu from "../../../components/basic/top-menu";
import BottomMenu from "../../../components/basic/bottom-menu";
import {useNavigate} from "react-router-dom";


export const RefundPolicy = ({history}) => {
    const navigate = useNavigate();

    return <>
        <TopMenu
            icon={'greater-icon'}
            title={'About'}
            action={() => navigate("/about")}
        />
        <div className={'jf-basic-page'} style={{padding: 0}}>
            <div style={{margin: '20px'}}>
                <h1>Refund Policy</h1>
                <div>
                    <p>
                        We offer a 30 days refund from the moment of the last payment is confirmed.
                    </p>
                    <p>
                        The refund will be done only for the last Subscription payment
                    </p>
                    <p>
                        If the Client is interested in a refund he should contact us and specifically ask for a refund.
                    </p>
                    <p>
                        Once the refund was processed your account Authorization will be limited to the status before the payment confirmation.
                    </p>
                </div>
            </div>
        </div>
        <BottomMenu/>
    </>
        ;
}
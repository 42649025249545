import TopMenu from '../../../components/basic/top-menu'
import BottomMenu from '../../../components/basic/bottom-menu'
import React, { useEffect} from 'react'
import SVGIcon from '../../../components/svg'
import { TextField } from '../../../components/basic/text-field'
import { DatePickerField } from '../../../components/basic/date-picker-field'
import Button from '../../../components/basic/button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputTags from '../../../components/basic/input-tags'
import { useMediaAction } from '../../../action/media-action'
import { getTab, setSeparateState } from '../../../helpers/utils'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import { suggestionTagsAtom } from '../../../state'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import Queries from '../../../params/queries'
import VideoPlayer from '../../../components/swiper/video-player'
import SelectTab from '../../../components/basic/select-tab'
import { accountEditMediaAtom } from '../../../state/pages/account'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserAction } from '../../../action/user-action'

const schema = yup.object({
  description: yup.string().required().min(3).max(256).label('Description'),
  tags: yup.array().min(1).label('Tags').of(yup.string().min(2).max(20)
    .matches(/^[A-Za-z0-9\-\s~]*$/, ' ')),
  available_date: yup.date().label('Release'),
}).required();

const EditMedia = () => {
  const setAlertPopup = useSetRecoilState(activePopupState('alert'));
  const mediaAction = useMediaAction(false)
  const userAction = useUserAction(false)

  const [{availableDate, tabRelease, tab,media, edit }, setEditMedia] = useRecoilState(accountEditMediaAtom);
  const setTab = setSeparateState(setEditMedia, "tab");
  const setTabRelease = setSeparateState(setEditMedia, "tabRelease");
  const setMedia = setSeparateState(setEditMedia, "media");
  const setEdit = setSeparateState(setEditMedia, "edit");
  const setAvailableDate = setSeparateState(setEditMedia, "availableDate");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetEditMedia = useResetRecoilState(accountEditMediaAtom);

  const setSuggestions = useSetRecoilState(suggestionTagsAtom)
  const fetchAction = useFetchApiAction();

  const navigate = useNavigate()
  const match = {params: useParams()};

  const { register, trigger, handleSubmit, formState: { errors }, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    resetStateAction.add(resetEditMedia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isCancelled = false;
    const type = match.params.tab === "private" ? "private" : match.params.typeMedia
    const params = {
      limit: { limit: 1 },
      filter: { ids: [match.params.media]}
    }
    params.filter.visibility = "free"
    if (match.params.tab !== "private") {
      params.filter.visibility = match.params.tab
    }
    params.filter.type = getTab(type, true)
    userAction.accountMedia(params).then(res => {
      if (isCancelled) return;
      if (res) {
        ['description', 'tags', 'available_date', 'private'].forEach(key => {
          setValue(key, res[0][key])
        })
        const add_date = new Date(res[0].add_date);
        const available_date = new Date(res[0].available_date)
        setAvailableDate(available_date)
        setMedia(res[0]);
        setTab(match.params.tab)

        if (add_date.getDate() !== available_date.getDate() ||
          add_date.getMonth() !== available_date.getMonth() ||
          add_date.getFullYear() !== available_date.getFullYear()
        ) {
          setTabRelease("Scheduled")
        } else {
          setTabRelease("Now")
        }
      } else {
        navigate(-1)
      }
    })
    return () => {
      isCancelled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.media])

  useEffect(() => {
    const variables = { limit: { limit: 100 } }
    fetchAction(Queries.Tags, variables).then(res => {
      setSuggestions(res.tags.map(tag => ({ name: tag.tags })))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    const params = {
      id: media.id,
      description: data.description,
      visibility: 'free',
      available_date: availableDate,
      tags: data.tags || [],
      private: tab === "private",
    }

    if (tab === "subscription") {
      params.visibility = "subscription";
    }
    if (tabRelease !== "Scheduled" || params.private) {
      params.available_date = media.add_date
    }
    mediaAction.updateMedia(params).then(res => {
      if (!res.errors) {
        navigate(-1)
      }
    })
  }

  return (!media ? null :
      <>
        <TopMenu
          icon={'greater-icon'}
          title={"Edit"}
          action={() => navigate(-1)}
          rightContent={<div style={{ textAlign: 'end' }} className={'jf-pointer'}
                             onClick={() => navigate('/studio/upload')}>
            <SVGIcon name={'add-icon2'}/>
          </div>}
        />
        <div className={'jf-basic-page no-padding jf-studio-upload'}>
          <div className="jf-content-box jf-mb-20px">
            <div className="jf-content-previewBox">
              {media.type === 'photo' ? <img src={media.source.url} alt=""/> :
                <VideoPlayer sources={[media.source]} options={{ controls: true, fluid: true, preload: true }}
                             type={'normal'}/>
              }
            </div>
            <div className="jf-content-title"/>
            <Button className={'save-btn'} onClick={() => setEdit(old => !old)}>Edit <span className="jf-editicon">
              <SVGIcon name={!edit ? "bottom-arrow-icon" : "top-arrow-icon"} width={20} height={10.99}/>
            </span>
            </Button>
            <div style={{flexGrow: 1}}/>
          </div>
          <form
            style={{ display: edit ? 'flex' : 'none' }}
            className={"jf-editform"}
          >
            <TextField
              label={'Description'}
              error={errors.description?.message}
              register={register("description")}
            />
            <div className={'jf-text-field has-label ' + (errors.tags ? 'error' : '')} style={{ width: '100%' }}>
              <div className="jf-text-field-label">Tags</div>
              <InputTags
                setValue={(v) => {
                  setValue('tags', v)
                }} defaultValue={media.tags.map(tag => ({ name: tag }))}
                onBlur={() => trigger("tags")}>
                {errors.tags?.message && <div className={'jf-text-field-msg error'}>{errors.tags?.message}</div>}
              </InputTags>
            </div>
            <div className="jf-inputbox">
              <div className="jf-inputbox-title">Viewable by</div>
              <SelectTab
                style={{height: 60, width: '100%'}}
                liClassName={'jf-font-r'}
                tabs={[
                  { id: "free", name: "Everyone" },
                  { id: "subscription", name: "Subscribers" },
                  { id: "private", name: "Private" },
                ]}
                tab={{id: tab}}
                setTab={(tab) => {
                  setTab(tab.id)
                }}
              />
              <div className={"jf-Subscribers"  + (tab === 'private' ? " jf-hide" : "")}>
                <div className="jf-inputbox-title">Release</div>
                <SelectTab
                  style={{height: 60, width: '100%'}}
                  liClassName={'jf-font-r'}
                  tabs={[
                    { id: "Now", name: "Now" },
                    { id: "Scheduled", name: "Scheduled" },
                  ]}
                  tab={{id: tabRelease}}
                  setTab={(tab) => {
                    setTabRelease(tab.id)
                  }}
                />
                {
                  tabRelease === 'Scheduled' && (
                    <div className="jf-scheduled-content">
                      <div className="jf-inputbox">
                        <div className="jf-inputbox-title">Scheduled release</div>
                        <DatePickerField
                          value={availableDate}
                          width={'calc(100% - 40px)'}
                          setValue={(v) => {
                            setValue('available_date', v)
                            setAvailableDate(v)
                          }}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className={'jf-m-auto'}>
              <Button className={'save-btn'} typeHtml={'button'} onClick={handleSubmit(onSubmit)}>Save</Button>
              <Button className={'outline-btn'} typeHtml={'button'}
                      onClick={() => setAlertPopup({
                        active: true,
                        message: "Are you sure you want to delete?",
                        onOk: (hide) => {
                          mediaAction.deleteMedia(media.id).then(res => {
                            if (res && !res.errors) {
                              navigate(-1)
                            }
                            hide()
                          })
                        }
                      })}>Delete</Button>
            </div>
          </form>
        </div>
        <BottomMenu/>
      </>
  );
}

export default EditMedia;
import React, {useEffect} from 'react'
import BottomMenu from '../../../components/basic/bottom-menu'
import MediaTabs from '../../../components/complex/media-tabs'
import TopMenu from '../../../components/basic/top-menu'
import { useMediaAction } from '../../../action/media-action'
import { getTab, setSeparateState, uniqueList } from '../../../helpers/utils'
import InfiniteScroll from '../../../components/complex/infinite-scroll'
import Constants from '../../../params/constants'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { dashboardTagAtom } from '../../../state/pages/dashboard'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import { useNavigate, useParams } from 'react-router-dom'

const DashboardTag = (props) => {
  const [{media, page, loading, loadingMore}, setDashboardTag] = useRecoilState(dashboardTagAtom);
  const resetDashboardTag = useResetRecoilState(dashboardTagAtom);
  const setMedia = setSeparateState(setDashboardTag, "media");
  const setPage = setSeparateState(setDashboardTag, "page");
  const setLoading = setSeparateState(setDashboardTag, "loading");
  const setLoadingMore = setSeparateState(setDashboardTag, "loadingMore");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const routeParams = useParams()

  let activeTab = getTab(props.paramsUrl.tab || 'video', true);
  const mediaAction = useMediaAction(false);
  const limit = Constants.MediaListLimit;

  const navigate = useNavigate()

  useEffect(() => {
    resetStateAction.add(resetDashboardTag)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isCanceled = false;
    setLoading(true);
    setLoadingMore(true);
    const params = { limit: { order: false, limit: limit }, filter: {}, withLike: false }
    if (routeParams.tag) {
      params.filter.tag = routeParams.tag;
    }
    mediaAction.getMedias(activeTab, params).then(res => {
      if (!res || isCanceled) return;
      setMedia((old) => {
        return {...old, [getTab(activeTab)]: res}
      });
      setLoading(false);
      setLoadingMore(res.length < limit);
      setPage(1);
    })

    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tab, routeParams.tag]);

  useEffect(() => {
    if (loadingMore || page < 2) return;
    let isCanceled = false;
    setLoadingMore(true);
    const params = {
      limit: { order: false, limit: limit, skip: media[getTab(activeTab)].length },
      filter: {},
      withLike: false
    }
    if (routeParams.tag) {
      params.filter.tag = routeParams.tag;
    }
    mediaAction.getMedias(activeTab, params).then(res => {
      if (!res || isCanceled) return;
      setMedia((old) => {
        return {...old, [getTab(activeTab)]: uniqueList(old[getTab(activeTab)].concat(res))}
      });
      setLoading(false);
      setLoadingMore(res.length < limit);
      setPage(1);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return <>
    <TopMenu
      icon={'greater-icon'}
      action={() => navigate(-1)}
      title={"#" + routeParams.tag}
    />
    <div className={'jf-dashboard-tag'}>
      <InfiniteScroll setPage={setPage}>
        {(loadDiv) => <MediaTabs
          classContain={'jf-pb-20px'}
          loading={loading}
          navigate={navigate}
          href={(tab) => navigate('/' + tab + 's/' + routeParams.tag, {replace: true})}
          media={media}
          activeTab={activeTab}
          loadDiv={loadDiv}
        />}
      </InfiniteScroll>
    </div>
    <BottomMenu/>
  </>;
}

export default DashboardTag;
import React from 'react'

const EarningIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '20',
  height = '22.001',
  className = '',
  viewBox = '0 0 20 22.001'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Earnings"
          d="M13.11,5.4A4.443,4.443,0,1,1,8.667,1,4.422,4.422,0,0,1,13.11,5.4Zm-2.221,0A2.221,2.221,0,1,0,8.668,7.6a2.211,2.211,0,0,0,2.221-2.2Zm5.276,9.262,1.388-1.375V9.8a1.111,1.111,0,0,0-2.222,0v3.85a2.732,2.732,0,0,1,.833,1.012Zm4.765-2.668a1.116,1.116,0,0,1,.789.325h-.044a1.092,1.092,0,0,1,0,1.562L15.5,20a2.8,2.8,0,0,1-1.966.8H9a1.116,1.116,0,0,0-.778.319L6.647,22.681a1.118,1.118,0,0,1-1.566,0L2.326,19.953a1.093,1.093,0,0,1,0-1.551l3.143-3.2a3.709,3.709,0,0,1,2.877-1h5.209a1.739,1.739,0,0,1,1.777,1.485A1.642,1.642,0,0,1,13.921,17.5h-4.7a.55.55,0,1,0,0,1.1h4.443a2.765,2.765,0,0,0,2.777-2.651l3.7-3.63a1.116,1.116,0,0,1,.789-.325Z"
          transform="translate(-2.003 -1)" fillRule="evenodd"/>
  </svg>

}

export default EarningIcon;
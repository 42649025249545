import React from 'react';
const CheckmarkRoundIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '30',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="icon" d="M1,16A15,15,0,1,1,16,31,15,15,0,0,1,1,16Zm13.095,5.025,8.43-8.43a.75.75,0,0,0,0-1.035l-.8-.8a.75.75,0,0,0-1.05,0l-7.11,7.11L11.32,15.64a.72.72,0,0,0-1.05,0l-.8.795a.75.75,0,0,0,0,1.065l3.57,3.525a.72.72,0,0,0,1.05,0Z" transform="translate(-1 -1)" fill="#7c79fa" fillRule="evenodd" />
    </svg>

  </>
);
export default CheckmarkRoundIcon;
import { atom, atomFamily, selectorFamily } from 'recoil'
import { checkMobile } from '../../helpers/utils'

export const studioLiveShowAtom = atom({
  key: 'pageStudioLiveShow',
  default: false
});

export const studioRecordAtom = atom({
  key: 'studioRecord',
  default: {
    process: 0,
    camMixer: null,
    resetCameraView: false,
    duration: 15,
    device: checkMobile() ? "back" : "front",
    facingMode: "environment",
    completed: 0.0,
    countTimeId: null,
  }
});


export const studioRecordSoundAtom = atom({
  key: 'studioRecordSound',
  default: {
    soundSelect: null,
    playId: -1,
    soundList: [],
    query: '',
  }
});

export const studioMyFilesAtom = atom({
  key: 'studioMyFiles',
  default: []
});

export const studioUploadPreviewAtom = atom({
  key: 'studioUploadPreview',
  default: {
    result: [],
    error: false,
  }
})

export const studioRecordPreviewFormAtom = atom({
  key: 'studioRecordPreviewForm',
  default: {
    tab: "everyone",
    media: null,
    fileTemp: null,
    edit: false,
    tabRelease: "Now",
    availableDate: new Date(),

  }
})

export const studioUploadProgressAtom = atom({
  key: 'studioUploadProgress',
  default: {
    completed: 0,
    done: false
  }
})

export const studioUploadPreviewFormAtom = atomFamily({
  key: 'studioUploadPreviewForm',
  default: { },
})
export const studioUploadPreviewFormState = selectorFamily({
  key: 'studioUploadPreviewFormState',
  get: (id= null) => ({get}) => {
    const findItem = get(studioUploadPreviewFormAtom(id));

    if (!findItem?.id) return {
      id: id,
      tab: "everyone",
      media: null,
      fileTemp: null,
      edit: false,
      tabRelease: "Now",
      availableDate: new Date()
    }
    return findItem;
  },
  set: (id) => ({set}, info) => {
    if (id && info) {
      info.id = id;
    }
    set(studioUploadPreviewFormAtom(id), info);
  }
});
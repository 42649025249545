import React from 'react';
const SoundIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '20',
  viewBox = '0 0 20 17.762'
}) => (
  <>
    <svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width={width} height="17.746" viewBox={viewBox}>
      <path id="Path_46" data-name="Path 46" d="M19.9,12.695V0L6.371,2.132V12.743a5.2,5.2,0,0,0-2.251-.335C1.844,12.527,0,13.844,0,15.329s1.844,2.587,4.12,2.4S8.24,16.287,8.24,14.8V3.689l9.82-1.557v8.982a5.677,5.677,0,0,0-2.18-.287c-2.4.12-4.12,1.413-4.12,2.9s1.844,2.611,4.12,2.4S20,14.683,20,13.2A2.4,2.4,0,0,0,19.9,12.695Z" fill={fill} />
    </svg>

  </>
);
export default SoundIcon;
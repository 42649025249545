import { Virtuoso } from 'react-virtuoso'
import Avatar from '../../../../components/basic/avatar'
import variables from '../../../../scss/variables.module.scss'
import Button from '../../../../components/basic/button'
import React from 'react'
import { useRecoilState } from 'recoil'
import { accountPurchasesAtom } from '../../../../state/pages/account'
import { setSeparateState } from '../../../../helpers/utils'

const Subscriptions = ({loadMore, list}) => {
  const [{activeCol}, setPurchases] = useRecoilState(accountPurchasesAtom);

  const setActiveCol = setSeparateState(setPurchases, "activeCol");
  return <>
    <div className={'jf-purchases-transaction jf-mt-20px'} style={{border: 'none'}}>
      <div/>
      <div className={'jf-font-b'}>Username</div>
      <div className={'jf-font-b'}>Price</div>
      <div className={'jf-font-b jf-pointer'}>
        <span onClick={() => setActiveCol('date')}
              className={activeCol === "date" ? "jf-active" : ""}>Date</span><span> | </span>
        <span onClick={() => setActiveCol('status')}
              className={activeCol === "status" ? "jf-active" : ""}>Status</span></div>
    </div>
    <div style={{ flexGrow: 1 }}>
      <Virtuoso
        data={list}
        endReached={loadMore}
        itemContent={(index, item) => {
          const add_date = new Date(item.add_date);
          return <div className={'jf-purchases-transaction'}>
            <Avatar image={item.from.avatar} width={40} height={40} text={item.from.fullname}/>
            <div>@{item.from.username}</div>
            <div>${item.value}</div>
            <div>{
              activeCol === "date" ?
                add_date.toLocaleString('en', { month: 'short', day: 'numeric', year: '2-digit' }) :
                (false ?
                    <span style={{ color: variables['colors-jf-like'], fontSize: 12, width: 60 }}>Canceled</span> :
                    <Button className={'small jf-cancel-btn'}>Cancel</Button>
                )
            }
            </div>
          </div>;
        }}
      />
    </div>
    </>
}

export default Subscriptions;
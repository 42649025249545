import React, {useEffect}  from 'react'
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import { useUserAction } from '../../../../action/user-action'
import { authIdAtom } from '../../../../state/auth'
import {useRecoilValue, useRecoilState} from 'recoil'
import Avatar from '../../../../components/basic/avatar'
import variables from '../../../../scss/variables.module.scss'
import { accountBlockedListAtom } from '../../../../state/pages/account'
import { useNavigate } from 'react-router-dom'

const Blocked = (props) => {
  const navigate =useNavigate()
  const [list, setList] = useRecoilState(accountBlockedListAtom);
  const userAction = useUserAction(false);
  const authId = useRecoilValue(authIdAtom)

  useEffect(() => {
    userAction.getBlockedAccounts({user: authId}).then(res => {
      setList(res);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Blocked accounts'}
        action={() => navigate(-1)}
      />
      <div className={'jf-basic-page no-padding'}>
        {list.map(item =>
          <div className={'jf-item-menu jf-pt-10px jf-pb-10px'} style={{cursor: 'auto'}}
               key={'jf-blocked-account-' + item.id}>
          <Avatar image={item.blocked.avatar} text={item.blocked.fullname} width={60} height={60}/>
          <div className={'jf-item-menu-text jf-ml-15px'}>
            <span className={'jf-font-20-27 jf-font-m'}>{item.blocked.fullname}</span>
          </div>
          <button className={'jf-no-style-btn jf-font-20-27 jf-font-m'}
                  style={{color: variables['colors-jf-btn']}}>Unblock</button>
        </div>)}
      </div>
      <BottomMenu/>
    </>
  );
}

export default Blocked;

import ItemMenu from '../../../../components/basic/item-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import TopMenu from '../../../../components/basic/top-menu'
import { useNavigate } from 'react-router-dom'

const Settings = () => {
  const navigate = useNavigate()
  const list = [
    { path: "/account/settings/basic", title: "Username and email" },
    { path: "/account/settings/password", title: "Password" },
    { path: "/account/settings/email", title: "Email notifications" },
    { path: "/account/settings/gender", title: "Gender preferences" },
    { path: "/account/settings/blocked", title: "Blocked accounts" },
    { path: "/account/settings/delete", title: "Delete account" },
  ];
  return <>
    <TopMenu
      icon={'greater-icon'}
      title={'Settings'}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page'} style={{padding: 0}}>
    {list.map((item, i) =>
      <ItemMenu key={"jf-account-setting-" + i}
                text={item.title}
                icon={'greater-icon'}
                styleText={{fontSize: 20, lineHeight: '27px'}}
                path={item.path}
      />)}
    </div>
    <BottomMenu/>
  </>
}

export default Settings;
import React from 'react'
import TopMenu from '../../../../components/basic/top-menu'
import { TextField } from '../../../../components/basic/text-field'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUserAction } from '../../../../action/user-action'
import BottomMenu from '../../../../components/basic/bottom-menu'
import Button from '../../../../components/basic/button'
import Constants from '../../../../params/constants'
import { useNavigate } from 'react-router-dom'
import { fetchSignOut } from '../../../../helpers/fetch-graphql'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { authIdAtom, authProfileAtom } from '../../../../state/auth'

const schema = yup.object({
  password_current: yup.string().required().label('Current password'),
}).required();

const Delete = (props) => {
  const navigate = useNavigate();
  const setAuthProfile = useSetRecoilState(authProfileAtom);
  const setAuthId = useSetRecoilState(authIdAtom);
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });
  const userAction = useUserAction();

  const SignOut = () => {
    fetchSignOut().then(res => {
      setAuthId(null);
      setAuthProfile(null);
    })
  }

  const onSubmit = (data) => {
    data.password_flag = true;
    userAction.deleteUser(data).then(res => {
      if (res.errors && res.errors[0]) {
        setError('server', { message: res.errors[0].message })
      } else {
        SignOut()
      }
    });
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Delete account'}
        action={() => navigate(-1)}
      />
      <div className={'jf-basic-page no-padding jf-password-form'}>
        <article className={'jf-delete-page-note'}>
          Deleting your account will
            <span className={'black'}> permanently remove </span>
            all of your information and media from our database.
            If you encounter any issues please contact
            <span className={'black'}> {Constants.Contact} </span>
          <div className={'warning'}>It can’t be restored!</div>
          <button className={'jf-no-style-btn jf-delete-page-download'}>Download your personal data</button>
        </article>
        <form
          className={'jf-delete-page-form'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={'Current Password'}
            error={errors.password_current?.message}
            type={'password'}
            register={register("password_current")}
          />
          {errors.server?.message && (
            <div className='jf-error'>{errors.server?.message}</div>
          )}
          <Button className={'jf-account-save-btn'}
                  style={{width: 218}} type={'submit'}>Delete account</Button>
        </form>
      </div>
      <BottomMenu/>
    </>
  );
}

export default Delete;

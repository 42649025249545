import React, {useState} from 'react';
import { Arrow, useLayer } from 'react-laag'

const PopoverMenu = ({children, placement, menu, ...other}) => {
  const [isOpen, propsHover] = useState(false);

  const close = () => propsHover(old => !old);
  const { renderLayer, triggerProps, layerProps, arrowProps } =
    useLayer({
      isOpen,
      placement: placement || "top-start",
      arrowOffset: 4,
      triggerOffset: 10,
      onOutsideClick: close, // close the menu when the user clicks outside
      onDisappear: close, // close the menu when the menu gets scrolled out of sight
    });

  return (
    <>
      <div {...triggerProps} onClick={close} {...other}>
        {children}
      </div>
      {isOpen &&
      renderLayer(
        <div
          {...layerProps}
          style={{ ...layerProps.style }}
          className={'popover-menu'}
        >
          {menu && menu(close)}
          <Arrow {...arrowProps} size={5} roundness={0} />
        </div>
      )}
    </>
  );
}

export default PopoverMenu;
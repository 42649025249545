import React, { Component } from 'react';
import SVGIcon from '../svg/index'

class ThumbnailUploader extends Component {
  constructor (props) {
    super(props);
    this.fileRef = React.createRef();
  }
  onChangeFile = (event) => {
    const file = event.target.files.length > 0 ? event.target.files[0] : null;
    const {setImage, autoLoad = true} = this.props;
    if (!file) {
      setImage(null)
    } else if (autoLoad) {
      let reader = new FileReader();
      reader.onload = (upload) => {
        setImage({data: upload.target.result, file})
      };
      reader.readAsDataURL(file);
    } else {
      setImage({file})
    }
  }

  render () {
    const { type = 'square', image, style = {}, children } = this.props;
    style.height = style.height || 100;
    style.width = style.width || 100;

    return (
      <div className={
        'jf-thumbnail-up ' +
        (image ? 'has-image ' : '') +
        'type-' + type
      } style={style}>
        {image && <img src={image.data || image.url} alt=""/>}
        {children ? children({ onClick: () => this.fileRef.current.click()}) : <button className={'jf-thumbnail-up-btn'} type="button"
                onClick={() => this.fileRef.current.click()}>
          <SVGIcon name={'photo-camera-icon'}/>
        </button>}
        <input hidden type={'file'} onChange={this.onChangeFile} ref={this.fileRef}/>
      </div>
    );
  }
}

export default ThumbnailUploader;
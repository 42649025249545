import React from 'react';
const UserCommentIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '40',
  height = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg version="1.1" id="comments" xmlns="http://www.w3.org/2000/svg"
      width={width} height={height} viewBox={viewBox} >

      <path fill={fillNone} d="M0,0v40h40V0H0z M12,16.1c1.5,0,2.6,1.2,2.6,2.6c0,0.7-0.3,1.4-0.8,1.8c-0.5,0.5-1.1,0.8-1.8,0.8
	c-1.5,0-2.6-1.2-2.6-2.6C9.4,17.2,10.5,16.1,12,16.1z M19.5,21.3c-1.5,0-2.6-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7
	c1.5,0,2.7,1.2,2.7,2.6C22.2,20.1,21,21.3,19.5,21.3L19.5,21.3z M29.8,18.7c0,1.5-1.2,2.6-2.6,2.6h0c-1.5,0-2.6-1.2-2.6-2.6
	s1.2-2.6,2.6-2.6S29.8,17.2,29.8,18.7C29.8,18.7,29.8,18.7,29.8,18.7z"/>
      <path id="Path_43" fill={fill} d="M19.5,5C11,5,4,10.9,4,18.2c0,7.3,7,13.2,15.5,13.2v2.4c0,0.6,0.5,1.1,1.1,1.1
	c0.2,0,0.4-0.1,0.6-0.2c2.8-1.7,8.2-5.3,10.1-8c1.2-1.1,2.1-2.5,2.7-3.9c0.6-1.5,1-3.1,1-4.7C35.1,10.9,28.2,5,19.5,5z M13.8,20.5
	c-0.5,0.5-1.1,0.8-1.8,0.8c-1.5,0-2.6-1.2-2.6-2.6c0-1.5,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6C14.6,19.4,14.3,20.1,13.8,20.5z
	 M19.6,21.3L19.6,21.3c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.6C22.2,20.1,21,21.3,19.6,21.3z
	 M29.8,18.7c0,1.5-1.2,2.6-2.6,2.6h0c-1.5,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6S29.8,17.2,29.8,18.7C29.8,18.7,29.8,18.7,29.8,18.7z"
      />
    </svg>
  </>
);
export default UserCommentIcon;
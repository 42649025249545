import React from 'react';
import {getStorageUrl} from "../../helpers/utils";
import {useNavigate} from 'react-router-dom';
import { authProfileAtom } from '../../state/auth'
import {useRecoilValue} from 'recoil';

const Avatar  = ({ onClick, online, image, text, background = 'gray', width = 50,
  height = 50, username = null, live, labelLive = true}) => {
  const navigate = useNavigate();
  const authProfile = useRecoilValue(authProfileAtom);
  const onAvatarClick = () => {
    if (username !== null) {
      if (live) {
        navigate("/" + username + "/live")
      } else if (authProfile?.username === username) {
        navigate("/profile")
      } else {
        navigate("/" + username)
      }
    }
    onClick && onClick()
  }
  return (
    <div className={'jf-avatar ' + (live ? 'jf-live ' : ' ') + (username ? ' jf-pointer' : '')}
         onClick={onAvatarClick}
         style={{ width: width, minWidth: width, height: height, minHeight: height }}>
      {online && <div className='jf-avatar-online-icon'/>}
      <div className='jf-avatar-picture' style={{ backgroundColor: background }}>
        {image ? <img src={image !== '' && getStorageUrl(image, "profile")} alt=''/> :
          <span style={{ fontSize: width * 0.4 }}>{text && text.charAt(0).toLocaleUpperCase()}</span>}
      </div>
      {live && labelLive && <div className={'jf-live-label'}>LIVE</div>}
    </div>
  )
}

export default Avatar;
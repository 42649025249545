import React, { useEffect } from 'react'
import TopMenu from '../../../components/basic/top-menu'
import ItemMenu from '../../../components/basic/item-menu'
import variables from '../../../scss/variables.module.scss'
import ToggleSwitch from '../../../components/basic/toggle-switch'
import BottomMenu from '../../../components/basic/bottom-menu'
import { useRecoilValue, useRecoilState } from 'recoil'
import { authIdAtom } from '../../../state/auth'
import { useFetchApiAction } from '../../../action/main-action'
import Queries from '../../../params/queries'
import * as yup from 'yup'
import { SelectField } from '../../../components/basic/select-field'
import { TextField } from '../../../components/basic/text-field'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Mutations from '../../../params/mutations'
import Constants from '../../../params/constants'
import Button from '../../../components/basic/button'
import { accountSubscriptionAtom } from '../../../state/pages/account'
import { setSeparateState } from '../../../helpers/utils'
import { useNavigate } from 'react-router-dom'

const schema = yup.object({
  price: yup.string().required(),
  duration: yup.number().required().typeError('Tips Amount must be a number').transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  }),
  unit: yup.string().required(),
  period: yup.string().required().label('Subscriptions'),
  preview: yup.number()
}).required();

const Subscription = (props) => {

  const navigate = useNavigate()
  const [{checked, trialChecked, loading, saving, },setSubscription] = useRecoilState(accountSubscriptionAtom)
  const setChecked = setSeparateState(setSubscription, "checked")
  const setTrialChecked = setSeparateState(setSubscription, "trialChecked")
  const setLoading = setSeparateState(setSubscription, "loading")
  const setSaving = setSeparateState(setSubscription, "saving")
  const authId = useRecoilValue(authIdAtom);
  const fetchApi = useFetchApiAction();

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (!authId) return;
    setLoading(true)
    fetchApi(Queries.SubscriptionDetail, { user: authId }).then(res => {
      if (!res?.subscription_detail) {
        setChecked(false);
        setValue('duration', 1)
        setValue('price', 3.99)
        setValue('period', 9.99)
        setValue('unit', 'month')
        setLoading(false)
        setTrialChecked(true);
        return;
      }
      setChecked(true);
      if (res?.subscription_detail.trial) {
        ['price', 'unit', 'duration'].forEach(key => {
          setValue(key, res?.subscription_detail.trial[key])
        })
        setTrialChecked(true);
      }else {
        setTrialChecked(false);
      }
      setValue('period', res?.subscription_detail.period['price'])
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authId])

  const onChecked = (e) => {
    if (e.target.checked) {
      setLoading(false)
      handleSubmit((data) => {
        fetchApi(Mutations.SubscriptionDetail.Save, {
          trial: {
            price: parseFloat(data.price),
            duration: parseFloat(data.duration),
            unit: data.unit,
          },
          period: {
            price: parseFloat(data.period),
            duration: 1,
            unit: 'month',
          }
        }).then(res => {
          setChecked(true);
          setTrialChecked(true);
        })
      }, () => {
        setChecked(false);
      })()
    } else {
      fetchApi(Mutations.SubscriptionDetail.Delete, {}).then(res => {
        setChecked(false);
      })
    }
  }

  const save = () => {
    setSaving(true);
    handleSubmit((data) => {
      fetchApi(Mutations.SubscriptionDetail.Update, {
        trial: trialChecked ? {
          price: parseFloat(data.price),
          duration: parseFloat(data.duration),
          unit: data.unit,
        } : null,
        period: {
          price: parseFloat(data.period),
          duration: 1,
          unit: 'month',
        }
      }).then(res => {
        setSaving(false);
      })
    })()
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Subscription'}
        action={() => navigate(-1)}
      />
      <div className={'jf-flex-basic-page'}>
        {!loading && <>
          <ItemMenu
            text={'Enable'}
            style={{ cursor: 'auto', border: 'none' }}
            styleText={{ font: "18px/24px " + variables['fonts-jf-fontMedium'] }}
          >
            <ToggleSwitch keyId={'toggle-email'} checked={checked} onChange={onChecked}/>
          </ItemMenu>
          {checked && <>
            <div className={'jf-flex-row jf-pl-20px'} style={{ paddingRight: 23 }}>
              <div className={'jf-text-field-label'} style={{ flexGrow: 1 }}>Trial</div>
              <ToggleSwitch keyId={'toggle-trial'} checked={trialChecked}
                            onChange={(e) => setTrialChecked(e.target.checked)}/></div>
            {trialChecked && <>
              <TextField
              styleContain={{ padding: '0 20px' }}
              type={'money'}
              register={register("price")}
              error={errors.price?.message}
              defaultValue={getValues('price') || ''}
              iconLeft={<div className={'jf-money-text'}>$</div>}
            />
              <div className={'jf-flex-row'} style={{ margin: 20 }}>
                <TextField
                  type={'number'}
                  register={register("duration")}
                  error={errors.duration?.message}
                  styleContain={{ marginRight: 20, flex: 1 }}
                />
                <SelectField
                  register={register("unit")}
                  setValue={(v) => setValue("unit", v)}
                  value={getValues('unit')}
                  error={errors.unit?.message}
                  list={Constants.PeriodUnit}
                  styleContain={{ flex: 1 }}
                />
              </div>
            </>}
            <TextField
              styleContain={{ padding: '0 20px' }}
              type={'money'}
              label={'Subscriptions'}
              register={register("period")}
              error={errors.period?.message}
              defaultValue={getValues('period') || ''}
              iconLeft={<div className={'jf-money-text'}>$</div>}
              iconRight={<div style={{ color: variables['colors-jf-primary'], marginRight: 15 }}>/mo</div>}
            /></>}
        </>}
        <div style={{ flexGrow: 1 }}/>
        {checked && <Button onClick={save} className={'medium'} style={{ margin: '20px auto' }}>
          {saving ? 'Saving...' : 'Save'}</Button>}
      </div>
      <BottomMenu/>
    </>
  );
}

export default Subscription;
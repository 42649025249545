import { atom, atomFamily, selectorFamily } from 'recoil'

export const messageMediaMsgAtom = atom({
  key: 'messageMediaMsg',
  default: {
    media: null,
    free: false,
    duration: 0,
    parentVal: null,
  }
});

export const messageTeaserAtom = atomFamily({
  key: 'messageTeaser',
  default: { },
})
export const messageTeaserState = selectorFamily({
  key: 'messageTeaserState',
  get: (id= null) => ({get}) => {
    const findItem = get(messageTeaserAtom(id));

    if (!findItem.id) return {
      id: id,
      lock: false,
      error: null,
      unlocking: false
    }
    return findItem;
  },
  set: (id) => ({set}, info) => {
    if (id) {
      info.id = id;
    }
    set(messageTeaserAtom(info.id), info);
  }
});
import React from 'react';
const Bookmark = ({
  style = {},
  fill = '#130c18',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '10',
  className = '',
  viewBox = '0 0 10 14'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="14" viewBox={viewBox}>
      <g id="Path_5569" data-name="Path 5569" transform="translate(-141 -178.209)" fill="rgba(255,255,255,0)" strokeLinecap="round" strokeLinejoin="round">
        <path d="M 141.5 191.2198333740234 L 141.5 178.7087707519531 L 150.5 178.7087707519531 L 150.5 191.1827850341797 L 146.4600830078125 188.0223236083984 L 146.1611938476562 187.7885131835938 L 145.8555908203125 188.0134735107422 L 141.5 191.2198333740234 Z" stroke="none" />
        <path d="M 142 190.2308959960938 L 145.5591735839844 187.6108245849609 C 145.9205322265625 187.3448028564453 146.414794921875 187.3520812988281 146.7681732177734 187.6285247802734 L 150 190.1568145751953 L 150 179.2087707519531 L 142 179.2087707519531 L 142 190.2308959960938 M 151 192.2087707519531 L 146.1520080566406 188.4161376953125 L 141 192.2087707519531 L 141 178.2087707519531 L 151 178.2087707519531 L 151 192.2087707519531 Z" stroke="none" fill={fill} />
      </g>
    </svg>
  </>
);
export default Bookmark;
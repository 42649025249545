import React, { Component } from 'react';
import Header from '../../../components/header.js';
import { Link } from 'react-router-dom';
class Login extends Component {
  render() {
    return (
      <>
        <div className="jf-sign-content">
          <Header />
          <div className="jf-signin-body">
            <h1 className="jf-title">Sign In</h1>
            <ul className="jf-btn-ul">
              <li className="jf-btn-ul-li">
                <Link to="./otp" className="jf-btn">Send Code</Link>
              </li>
              <li className="jf-btn-ul-li">
                or
              </li>
              <li className="jf-btn-ul-li">
                <Link to="/" className="jf-btn2">Sign in with Twitter</Link>
              </li><li className="jf-btn-ul-li">
                <Link to="/" className="jf-btn2">Sign in with Gmail</Link>
              </li><li className="jf-btn-ul-li">
                <Link to="/" className="jf-btn2">Sign in with Email</Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
import React from 'react';
const Searchgreyicon = ({
  style = {},
  fill = '#8b8c90',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="17.604" viewBox={viewBox}>
      <g id="search" transform="translate(4 2)">
        <path id="Path_40" data-name="Path 40" d="M0,0H24V24H0Z" transform="translate(-4 -2)" fill={fillNone} />
        <path id="Path_16" data-name="Path 16" d="M29.613,28.439l-3.691-3.691a8.407,8.407,0,1,0-1.178,1.178l3.691,3.687a.831.831,0,1,0,1.178-1.174Zm-10.2-2.285a6.742,6.742,0,1,1,6.744-6.74A6.748,6.748,0,0,1,19.414,26.154Z" transform="translate(-12.429 -10.429)" fill={fill} />
      </g>
    </svg>

  </>
);
export default Searchgreyicon;
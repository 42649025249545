const WarningIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '35',
  viewBox = '0 0 35 35'
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_1228" data-name="Rectangle 1228" width="35" height="35" fill="none"/>
      <path id="Path_6306" data-name="Path 6306" d="M15.048,4.735,2.883,26.632A1.458,1.458,0,0,0,4.158,28.8H29.7a1.458,1.458,0,0,0,1.244-2.219L17.567,4.683a1.458,1.458,0,0,0-2.519.052Z" transform="translate(1.237 1.826)" fill="#595d73" fill-rule="evenodd" opacity="0.3"/>
      <rect id="Rectangle_1229" data-name="Rectangle 1229" width="2.917" height="10.208" rx="1" transform="translate(16.042 13.125)" fill="#595d73"/>
      <rect id="Rectangle_1230" data-name="Rectangle 1230" width="2.917" height="2.917" rx="1" transform="translate(16.042 24.792)" fill="#595d73"/>
  </svg>
}

export default WarningIcon;
import React, { useEffect, useState } from "react";
import Header from "../../../components/header.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TextField } from "../../../components/basic/text-field";
import {
  useSetRecoilState,
  useRecoilState,
  useResetRecoilState,
  useRecoilValue,
} from "recoil";
import { authIdAtom, authProfileAtom } from "../../../state/auth";
import { fetchSignIn } from "../../../helpers/fetch-graphql";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DCookie, setSeparateState } from "../../../helpers/utils";
import {
  useFetchApiAction,
  useResetStateWhenChangeUrlAction,
} from "../../../action/main-action";
import Queries from "../../../params/queries";
import Button from "../../../components/basic/button";
import { authSigninAtom } from "../../../state/pages/auth";
import {
  cookiePolicyAtom,
  activeBottomPopupState,
} from "../../../state/components/popup";

const schema = yup
  .object({
    email: yup.string().required().email().label("Email address"),
    password: yup.string().required().label("Password"),
  })
  .required();

function Signin() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const fetchAction = useFetchApiAction();
  const setAuthId = useSetRecoilState(authIdAtom);
  const setAuthProfile = useSetRecoilState(authProfileAtom);
  const cookiePolicy = useRecoilValue(cookiePolicyAtom);
  const setCookiePopup = useSetRecoilState(
    activeBottomPopupState("cookie_policy")
  );
  const [{ msg }, setAuthSignin] = useRecoilState(authSigninAtom);
  const setMsg = setSeparateState(setAuthSignin, "msg");
  const resetAuthSignin = useResetRecoilState(authSigninAtom);
  const resetStateAction = useResetStateWhenChangeUrlAction();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    resetStateAction.add(resetAuthSignin);
    fetchAction(Queries.Account.LoggedIn, {}).then((res) => {
      if (res.logged_in?.account) {
        setAuthId(DCookie.getItem("auth_id"));
        setAuthProfile(res.logged_in.account);
        navigate("/");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (!cookiePolicy) {
      return setIsSubmit(true);
    }

    data.type = "email";
    fetchSignIn(data).then((res) => {
      if (res.status === 200) {
        if (!res.data.error) {
          setAuthId(DCookie.getItem("auth_id"));
          if (location.state) {
            navigate(location.state.prevPath);
          } else {
            navigate(-1);
          }
        } else {
          setMsg(res.data.account);
        }
      }
    });
  };

  return (
    <>
      <div className="jf-sign-content">
        <Header />
        <div className="jf-signin-body">
          <h1 className="jf-title">Sign In</h1>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <div className="jf-input-boxs">
              <TextField
                placeholder={"Email Address"}
                error={errors.email?.message}
                register={register("email")}
              />
              <TextField
                placeholder={"Password"}
                error={errors.password?.message}
                type={"password"}
                register={register("password")}
              />
            </div>
            {isSubmit && !cookiePolicy && (
              <div className="jf-text-field-msg error">
                We use cookies for login. Please accept our{" "}
                <a onClick={() => setCookiePopup({ active: true })}>
                  cookies policy
                </a>{" "}
                before using our service
              </div>
            )}
            {msg && <div className={"jf-text-field-msg error"}>{msg}</div>}
            <div className="jf-forpass">
              Forgot password?{" "}
              <Link to="/forgot-pass" className="jf-gethelp">
                Get help sign in.
              </Link>
            </div>
            <ul className="jf-btn-ul">
              <li className="jf-btn-ul-li">
                <button className="jf-btn jf-basic-btn" type={"submit"}>
                  Sign In
                </button>
              </li>
              <li className="jf-btn-ul-li jf-mb-15px jf-mt-20px jf-font-r jf-font-20-22">
                Or
              </li>
              <li className="jf-btn-ul-li">
                <Button
                  type={"link"}
                  className={"white-btn"}
                  typeHtml={"button"}
                  to={"/signup"}
                >
                  Create Account
                </Button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
}

export default Signin;

import React, {useEffect, useCallback, useRef} from 'react'
import BottomMenu from '../../../../components/basic/bottom-menu'
import {useNavigate} from 'react-router-dom'
import TopMenu from '../../../../components/basic/top-menu'
import {useFetchApiAction, useResetStateWhenChangeUrlAction} from '../../../../action/main-action'
import Queries from '../../../../params/queries'
import {Virtuoso} from 'react-virtuoso'
import Avatar from '../../../../components/basic/avatar'
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {authIdAtom, authProfileAtom} from '../../../../state/auth'
import SVGIcon from '../../../../components/svg'
import variables from '../../../../scss/variables.module.scss'
import Mutations from '../../../../params/mutations'
import {LiveMessagesThread} from '../../../../helpers/live-grahpql'
import {activePopupState} from '../../../../state/components/popup'
import Teaser from '../../../../components/message/teaser'
import {Puff} from 'react-loader-spinner'
import PopoverMenu from '../../../../components/complex/popover-menu'
import {useDropzone} from 'react-dropzone'
import {setSeparateState, toBytes} from '../../../../helpers/utils'
import Constants from '../../../../params/constants'
import {fetchUploads} from '../../../../helpers/fetch-graphql'
import VideoPlayer from '../../../../components/swiper/video-player'
import {messageInboxAtom} from '../../../../state/pages/message'

let closeMenu = null;

const MessagesInbox = ({paramsUrl, isSupport}) => {
    const [{
        uploading,
        firstItemIndex,
        toBottom,
        limited,
        loading,
        list,
        toUser,
        text,
        threadId
    }, setMessageInbox] = useRecoilState(messageInboxAtom);

    const navigate = useNavigate();
    const setList = setSeparateState(setMessageInbox, "list")
    const setLoading = setSeparateState(setMessageInbox, "loading")
    const setLimited = setSeparateState(setMessageInbox, "limited")
    const setText = setSeparateState(setMessageInbox, "text")
    const setToBottom = setSeparateState(setMessageInbox, "toBottom")
    const setToUser = setSeparateState(setMessageInbox, "toUser")
    const setFirstItemIndex = setSeparateState(setMessageInbox, "firstItemIndex")
    const setUploading = setSeparateState(setMessageInbox, "uploading")
    const setThreadId = setSeparateState(setMessageInbox, "threadId")
    const fetchApi = useFetchApiAction();
    const authId = useRecoilValue(authIdAtom);
    const authProfile = useRecoilValue(authProfileAtom);
    const limitAmount = 30;
    const setTipsPopup = useSetRecoilState(activePopupState('tips'));
    const chatRef = useRef();

    const resetStateAction = useResetStateWhenChangeUrlAction()
    const resetMessageInbox = useResetRecoilState(messageInboxAtom)
    useEffect(() => {
        resetStateAction.add(resetMessageInbox)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDrop = acceptedFiles => {
        setUploading(true);
        setToBottom(old => old + 1);
        closeMenu();
        fetchUploads(acceptedFiles[0]).then(res => {
            if (res.data.url) {
                send({
                    url: res.data.url,
                    type: acceptedFiles[0].type.split("/")[0],
                    mimetype: acceptedFiles[0].type,
                    text: "file"
                })
            }
        })
    }

    const {getRootProps, getInputProps} = useDropzone({
        accept: [
            ...Constants.photoExtensions.map(e => ("image/" + e)),
            ...Constants.videoExtensions.map(e => ("video/" + e))
        ].join(","),
        maxFiles: 1,
        maxSize: toBytes('20mb'),
        onDrop,
    })

    useEffect(() => {
        if (!threadId) return

        fetchApi(Queries.Messages.MsgList, {
            thread: threadId,
            limit: {limit: limitAmount, skip: 0}
        }).then(res => {
            setList(res.messenger_messages.reverse());
            setLoading(false)
            setLimited(res.messenger_messages.length < limitAmount);
        })

        LiveMessagesThread.run(threadId, (result) => {
            setList(old => ([...old, {...result.data.messenger_message.message}]))
            setTimeout(() => {
                setToBottom(old => old + 1);
            })
        })

        return () => {
            LiveMessagesThread.end()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollToIndex(list.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toBottom, chatRef]);

    useEffect(() => {
        const UpdateThreadUnread = () => {
            fetchApi(Mutations.Messages.UpdateThreadUnread, {
                thread: paramsUrl.thread
            }).then(res => {
                setToUser(res.updateThreadRead?.users.find(u => u.id !== authId));
            });
        };
        const UpdateSupportThreadUnread = () => {
            fetchApi(Mutations.Messages.UpdateSupportThreadUnread, {}).then(res => {
                setThreadId(res.updateSupportThreadRead.id)
                setToUser(res.updateSupportThreadRead?.users.find(u => u.id !== authId));
            });
        };

        if (isSupport) {
            UpdateSupportThreadUnread();
        } else {
            UpdateThreadUnread();
            setThreadId(paramsUrl.thread)
        }

        return () => {
            setThreadId(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsUrl.thread, isSupport]);

    const loadMore = useCallback((e) => {
        if (!e || loading || limited) return;
        setLoading(true);
        const nextFirstItemIndex = firstItemIndex - limited;
        fetchApi(Queries.Messages.MsgList, {
            thread: threadId,
            limit: {limit: limitAmount, skip: list.length}
        }).then(res => {
            setFirstItemIndex(nextFirstItemIndex);
            setList(old => [...res.messenger_messages.reverse(), ...old]);
            setLoading(false)
            chatRef.current.scrollToIndex(res.messenger_messages.length);
            setLimited(res.messenger_messages.length < limitAmount);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId, list, loading, fetchApi, limited])

    const send = useCallback((file_info) => {
        console.log("is support: ", isSupport);

        // if isSupport -> we send to user.id: 7dc16a28912c0212bba9b90dc2725ed7 ( admin user)

        if (file_info) {
            fetchApi(Mutations.Messages.Add, {
                "to": isSupport ? '7dc16a28912c0212bba9b90dc2725ed7' : toUser?.id,
                "data": {
                    type: "upload",
                    value: JSON.stringify(file_info)
                }
            }).then(() => setUploading(false));
        } else if (text !== '') {
            const message = text.substr(0, 512);
            setText('');
            fetchApi(Mutations.Messages.Add, {
                "to":  isSupport ? '7dc16a28912c0212bba9b90dc2725ed7' : toUser?.id,
                "data": {
                    type: "text",
                    value: message
                }
            }).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId, fetchApi, text, setText, toUser])

    return <>
        <TopMenu
            icon={'greater-icon'}
            title={toUser?.fullname}
            action={() => navigate(-1)}
        />
        <div className={'jf-basic-page jf-messages-inbox-page'}>
            {list.length > 0 && <Virtuoso
                components={{
                    Header: () => (
                        <div style={{textAlign: "center", padding: "1rem"}}>
                            {!limited ? <Puff
                                wrapperStyle={{justifyContent: 'center'}}
                                color={variables['colors-jf-secondary']}
                                height={30}
                                width={30}
                            /> : null}
                        </div>
                    ),
                    Footer: () => uploading && <div className={'jf-messages-item jf-messages-right'}>
                        <div className={'jf-messages-value uploading'}>
                            <div className={'load-container'}>
                                <Puff
                                    wrapperStyle={{justifyContent: 'center'}}
                                    color={variables['colors-jf-secondary']}
                                    height={30}
                                    width={30}
                                />
                            </div>
                        </div>
                    </div>
                }}
                data={list}
                ref={chatRef}
                firstItemIndex={firstItemIndex}
                atTopStateChange={loadMore}
                initialTopMostItemIndex={limitAmount - 1}
                itemContent={(index, msg) => {
                    const value = msg.data.type === "text" ? msg.data.value : JSON.parse(msg.data.value);
                    const comp = <div className={'jf-messages-value ' + msg.data.type}>
                        {msg.data.type === "text" && value}
                        {msg.data.type === "teaser" && <Teaser item={msg} value={value} setPlayed={() => {
                        }} player={true} thread={threadId ? threadId : paramsUrl.thread}/>}
                        {msg.data.type === "upload" && <div>{value.type === "image" ? <img alt="" src={value.url}/> :
                            <VideoPlayer sources={[{url: value.url, type: value.mimetype}]}
                                         options={{controls: true}}
                                         type={'normal'}/>}</div>}
                    </div>;

                    let result;
                    if (msg.from.id === authId) {
                        result = <div className={'jf-messages-item jf-messages-right'}>
                            {comp}
                        </div>
                    } else {
                        result = <div className={'jf-messages-item jf-messages-left'}>
                            <Avatar image={msg.from.avatar} text={msg.from.fullname} width={40} height={40}/>
                            {comp}
                        </div>
                    }
                    return <div style={{display: 'flex', flexDirection: 'column'}}>
                        {result}
                        <div style={{minHeight: 20}}/>
                    </div>
                }}
            />}
        </div>
        <div className='jf-popup-comment-footer jf-messages-inbox-footer jf-write-box'>
            <PopoverMenu menu={(close) => {
                closeMenu = close
                return <ul>
                    {
                        authProfile?.role.creator && <li onClick={() => navigate("/library", {
                            state: {
                                url: "/messages/inbox/media",
                                user: toUser?.id
                            }
                        })}>Private media</li>
                    }
                    <li {...getRootProps()}><input {...getInputProps()}/>Upload media</li>
                </ul>
            }}>
                <button className={'jf-no-style-btn'}>
                    <SVGIcon name={'photo-fill-icon'} fill={variables['colors-jf-primary']} width={20}
                             height={17.5}/>
                </button>
            </PopoverMenu>
            <input
                className='jf-write-box-input jf-ml-15px'
                placeholder={'Type a message'}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        send()
                    }
                }}
                value={text} onChange={(e) => setText(e.target.value)}
            />
            {text && <button className={'jf-no-style-btn jf-ml-15px'} onClick={() => send()}>
                <SVGIcon name={'send-fill-icon'} fill={variables['colors-jf-primary']} width={22} height={24}/>
            </button>}
            {!isSupport && (
                <button className={'jf-no-style-btn jf-ml-15px'} onClick={() => setTipsPopup({
                    active: true, profile: toUser
                })}>
                    <SVGIcon name={'money-icon'} fill={variables['colors-jf-primary']} width={14.58} height={26.26}/>
                </button>
            )}
        </div>
        <BottomMenu/>
    </>
}

export default MessagesInbox;
import React from 'react';
const CheckIcon = ({
  style = {},
  fill = '#fff',
  width = '20',
  height = '15',
  className = '',
  viewBox = '0 0 20 15'
}) => (
  <>
    <svg id="next" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <path id="poly" d="M20,5,9,16,4,11,2,13l7,7L22,7Z" transform="translate(-2 -5)" fill={fill}/>
      </svg>
  </>
);
export default CheckIcon;
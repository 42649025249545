import { atom } from 'recoil'

export const dashboardTagAtom = atom({
  key: 'dashboardTag',
  default: {
    media: {
      videos: [],
      photos: []
    },
    page: 0,
    loading: true,
    loadingMore: false
  }
});
import TopMenu from '../../../../components/basic/top-menu'
import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import BottomMenu from '../../../../components/basic/bottom-menu'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import Queries from '../../../../params/queries'
import Button from '../../../../components/basic/button'
import SelectTab from '../../../../components/basic/select-tab'
import { Virtuoso } from 'react-virtuoso'
import Avatar from '../../../../components/basic/avatar'
import { setSeparateState, upperFirstChar } from '../../../../helpers/utils'
import Constants from '../../../../params/constants'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { authProfileAtom } from '../../../../state/auth'
import { studioRecordSoundAtom } from '../../../../state/pages/studio'
import { accountEarningsAtom } from '../../../../state/pages/account'

const Earnings = ({paramsUrl}) => {
  const navigate = useNavigate();

  const [{unpaid, lastPayment, list, loading, limited}, setEarnings] = useRecoilState(accountEarningsAtom)
  const resetEarnings = useResetRecoilState(studioRecordSoundAtom)
  const setUnpaid = setSeparateState(setEarnings, "unpaid")
  const setLastPayment = setSeparateState(setEarnings, "lastPayment")
  const setList = setSeparateState(setEarnings, "list")
  const setLoading = setSeparateState(setEarnings, "loading")
  const setLimited = setSeparateState(setEarnings, "limited")
  const resetStateAction = useResetStateWhenChangeUrlAction()

  const fetchApi = useFetchApiAction();
  const limitAmount = Constants.ListLimit;
  const authProfile = useRecoilValue(authProfileAtom);


  useEffect(() => {
    resetStateAction.add(resetEarnings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() =>{
    if (!authProfile?.role.creator) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authProfile]);

  useEffect(() => {
    setList([])
    fetchApi(Queries.Earnings["Full" + upperFirstChar(paramsUrl.tab)],
      { limit: { limit: limitAmount } }).then(res => {
      if (!res) return;
      setList(res['earnings_' + paramsUrl.tab]);
      setUnpaid(res.earnings_unpaid);
      setLastPayment(res.last_payment);
      setLoading(false)
      setLimited(res['earnings_' + paramsUrl.tab].length < limitAmount);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.tab])

  const arrUnpaid = unpaid ? unpaid.toString().split('.') : ['0']

  const loadMore = useCallback(() => {
    if (loading || limited) return;
    setLoading(true);
    fetchApi(Queries.Earnings[upperFirstChar(paramsUrl.tab)], {
      limit: { limit: limitAmount, skip: list.length }
    }).then(res => {
      if (!res) return;
      const newList = res['earnings_' +paramsUrl.tab]
      setList(old => [...old, ...newList]);
      setLoading(false)
      setLimited(newList.length < limitAmount);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, fetchApi, limited, paramsUrl.tab])

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"Earnings"}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page jf-earnings-page'}>
      <div className={'jf-earnings-paid'}>
        <div>Ready to be paid</div>
        <div><span>${arrUnpaid[0]}</span>.{
          arrUnpaid.length > 1 ? arrUnpaid[1].substr(0, 2) : '00'
        }</div>
        <div>Last Payment: {lastPayment ?
          "$" + lastPayment.value + " - " + (new Date(lastPayment.date))
            .toLocaleString('en', { month: 'short', day: 'numeric', year: '2-digit' }):
          "No previous payments"}</div>
      </div>
      <div className={'jf-earnings-period'}>
        <div>Pending 7 day waiting period</div>
        <div>$0</div>
      </div>
      <div className={'jf-pl-20px jf-pr-20px'}>
        <Button onClick={()=>{
          navigate('/account/earnings/payment');
        }}>Add payout method</Button>
        <SelectTab
          style={{minHeight: 50, marginTop: 20}}
          tabs={[{
            id: 'transactions',
            name: 'Transactions',
          }, {
            id: 'payouts',
            name: 'Payouts',
          }]}
          tab={{id: paramsUrl.tab}}
          setTab={(tab) =>  navigate("/account/earnings/" + tab.id)}
        />
      </div>
      <div style={{ flexGrow: 1, marginTop: 20 }}>
        <Virtuoso
          data={list}
          endReached={loadMore}
          itemContent={(index, item) => {
            if (paramsUrl.tab === "transactions") {
              if (!item.from) return "";
              const add_date = new Date(item.add_date)
              return <div className={'jf-earnings-transaction'}>
                <Avatar image={item.from.avatar} width={40} height={40} text={item.from.fullname}/>
                <div>@{item.from.username}</div>
                <div>{upperFirstChar(item.type)}</div>
                <div>${item.value}</div>
                <div>{add_date.toLocaleString('en', { month: 'short', day: 'numeric', year: '2-digit' })}</div>
              </div>;
            }
            if (item.from) return "";
            const date = new Date(item.date)
            return <div className={'jf-earnings-payout'}>
              <div>{date.toLocaleString('en', { month: 'short', day: 'numeric', year: '2-digit' })}</div>
              <div>${item.value}</div>
              <div>{upperFirstChar(item.status || '')}</div>
            </div>;
          }}
        />
      </div>
    </div>
    <BottomMenu/>
    </>;
}

export default Earnings;
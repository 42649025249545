import { atom } from 'recoil'

export const libraryAtom = atom({
  key: 'library',
  default: {
    loading: false,
    loadingMore: 0,
    activeTab: "video",
    media: null,
    data: {
      videos: [],
      photos: [],
    },
  }
});
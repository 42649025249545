import React from 'react';
const PlayBordericon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '11.4',
  className = '',
  viewBox = '0 0 11.4 13.42'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="13.42" viewBox={viewBox}>
      <path id="Rectangle" d="M0,.45A.256.256,0,0,1,.388.228L9.876,5.97a.259.259,0,0,1,0,.443L.388,12.154A.256.256,0,0,1,0,11.933Z" transform="translate(0.7 0.519)" fill={fillNone} stroke={fill} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4" />
    </svg>



  </>
);
export default PlayBordericon;
import { atom } from 'recoil'

export const authSigninAtom = atom({
  key: 'authSignin',
  default: {
    msg: null,
  }
});

export const authResetPasswordAtom = atom({
  key: 'authResetPassword',
  default: {
    isLoading: false,
    isSuccess: false,
    errorMsg: "",
  }
});

export const authForgotPasswordAtom = atom({
  key: 'authForgotPassword',
  default: {
    isLoading: false,
    isSentRequest: false,
    errorMsg: "",
  }
});

export const authForgotPasswordVerifyAtom = atom({
  key: 'authForgotPasswordVerify',
  default: {
    isLoading: false,
    isInvalidToken: true,
  }
});
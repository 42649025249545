import React, { Component } from 'react';
import SVGIcon from "../../components/svg/index";

class SongItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
      play: false,
    }
    this.audio = props.song ? new Audio(props.song) : null;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.play !== state.play) {
      return {
        play: props.play,
      }
    }
    return null
  }

  componentDidUpdate (nextProps, nextState, nextContext) {
    if (this.audio) {
      try {
        if (this.state.play) {
          this.audio.currentTime = 0;
          this.audio.play()
        } else {
          this.audio.pause();
        }
      } catch (e) {
        return false;
      }
    }
  }

  componentWillUnmount() {
    if (this.audio) {
      this.audio.pause();
      this.audio.remove();
    }
  }

  render () {
    const {name} = this.props;
    return (
    <div className="jf-soundDetail">
      <div className="jf-playIcon jf-pointer" onClick={this.props.togglePlay}>
        {this.state.play ? <SVGIcon name="sound-stop-icon"/> : <SVGIcon name="sound-play-icon"/>}
      </div>
      <div className="jf-sound-title">{name}</div>
    </div>
    );
  }
}

export default SongItem;
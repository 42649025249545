import React from 'react';
const ShopIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24.982',
  height = '23.184',
  className = '',
  viewBox = '0 0 24.982 23.184'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <g id="shop-icon" transform="translate(0.703 0.559)">
        <path id="Path_52" data-name="Path 52" d="M23,3.5,20.333,4.833,19,20.167" transform="translate(1 -3.5)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_53" data-name="Path 53" d="M15.159,5.5l-.986,11.333" transform="translate(-0.609 -2.833)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_54" data-name="Path 54" d="M10.159,5.5,9.174,16.833" transform="translate(-2.275 -2.833)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_55" data-name="Path 55" d="M9,18h8.667" transform="translate(-2.333 1.333)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_56" data-name="Path 56" d="M7.667,21.333A2.667,2.667,0,1,0,5,18.667,2.667,2.667,0,0,0,7.667,21.333Z" transform="translate(-3.667 0.667)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_57" data-name="Path 57" d="M18.667,21.333A2.667,2.667,0,1,0,16,18.667,2.667,2.667,0,0,0,18.667,21.333Z" transform="translate(0 0.667)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_58" data-name="Path 58" d="M24.667,5.5H4L5.333,16.833H24" transform="translate(-4 -2.833)" fill="none" stroke={fill} strokeWidth="1.25"/>
        <path id="Path_59" data-name="Path 59" d="M4.5,9.5h20" transform="translate(-3.833 -1.5)" fill="none" stroke={fill} strokeWidth="1.25"/>
      </g>
    </svg>
  </>
);
export default ShopIcon;

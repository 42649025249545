import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import React, {useRef, useEffect, useCallback} from 'react'
import SVGIcon from '../../../../components/svg'
import CamAudioMixRecorder from '../../../../helpers/camera'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import { activePopupState } from '../../../../state/components/popup'
import ProgressBar from '@ramonak/react-progress-bar/dist'
import variables from '../../../../scss/variables.module.scss'
import {useRecoilState} from 'recoil'
import { camStreamAtom } from '../../../../state'
import { setSeparateState } from '../../../../helpers/utils'
import { studioRecordAtom } from '../../../../state/pages/studio'
import { useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import { useLocation, useNavigate } from 'react-router-dom'

let camMixer = null;

const StudioRecord = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const setCameraPopup = useSetRecoilState(activePopupState('camera_options'));
  const [camStream, setCamStream] = useRecoilState(camStreamAtom);
  const [{process, resetCameraView, duration, device, facingMode, countTimeId, completed }, setRecord] = useRecoilState(studioRecordAtom);

  const resetRecord = useResetRecoilState(studioRecordAtom);
  const setProcess = setSeparateState(setRecord, "process");
  const setResetCameraView = setSeparateState(setRecord, "resetCameraView");
  const setDuration = setSeparateState(setRecord, "duration");
  const setDevice = setSeparateState(setRecord, "device");
  const setFacingMode = setSeparateState(setRecord, "facingMode");
  const setCompleted = setSeparateState(setRecord, "completed");
  const setCountTimeId = setSeparateState(setRecord, "countTimeId");
  const resetStateAction = useResetStateWhenChangeUrlAction();

  const refVideo = useRef();
  const refAudio = useRef();
  const audio = location.state?.audio;

  useEffect(() => {
    resetStateAction.add(resetRecord)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const func = async () => {
      try {
        if (camStream) {
          const tracks = camStream.getTracks();
          tracks.forEach(track => track.stop());
          if (refVideo.current) {
            refVideo.current.srcObject = null;
          }
        }

        camMixer = new CamAudioMixRecorder(
          refVideo.current,
          refAudio.current,
          audio?.id,
          duration,
          {
            stop: () => {
              setProcess(2);
            },
          }
        )
        const stream = await camMixer.getUserMedia(facingMode);
        if (stream) {
          refVideo.current.srcObject = stream;
          setCamStream(stream);
        }
        setProcess(0);
      } catch (e) {
        console.log(e);
      }
    }
    if (!resetCameraView && refVideo.current && refAudio.current) {
      func()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refVideo, refAudio, facingMode, resetCameraView, audio]);

  useEffect(() => {
    if (process !== 1 && countTimeId) {
      clearInterval(countTimeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[process, countTimeId])

  const changeDevice = (v) => {
    refVideo.current = null;

    setResetCameraView(true);
    setDevice(v);
    setFacingMode(v === 'front' ? "user": 'environment')
    setTimeout(() => {
      setResetCameraView(false);
    }, 100)
  }

  const record = useCallback(() => {
    camMixer.duration = duration;
    camMixer.timer.remainingTime = duration * 1000
    setCompleted(0.1);
    setCountTimeId(setInterval(() => {
      setCompleted(old => old+0.1)
    },100))
    camMixer.startRecording();
    setProcess(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, camMixer])

  const pause = () => {
    camMixer.pauseRecording();
    setProcess(2);
  }

  const play = useCallback(() => {
    if (camMixer.timer.remainingTime === 0) {
      return;
    }
    camMixer.startRecording();
    setCompleted(parseFloat(duration) - camMixer.timer.remainingTime/1000 + 1.0);
    setCountTimeId(setInterval(() => {
      setCompleted(old => old+0.1)
    },100))
    setProcess(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, camMixer])

  const save = () => {
    const record = camMixer.saveRecord()

    camMixer.mediaRecorder = null;
    navigate('/studio/record/preview', {state: {record}})
  }

  const cancel = () => {
    camMixer.mediaRecorder = null;
    changeDevice(device)
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={"Record"}
        action={() => navigate(-1)}
      />
      <div className="jf-basic-page no-padding jf-videorecord-content">
        {process > 0 && <div className={'jf-recording-timeline'}>
          <ProgressBar
            bgColor={variables['colors-jf-primary']}
            isLabelVisible={false}
            height={'5px'}
            completed={completed}
            maxCompleted={duration}
            transitionDuration={'100ms'}
          />
        </div>}

        {process === 0 && <div className={'jf-sound'}><button onClick={() => {
          navigate('/sounds', {state: {audio}})
        }} className="jf-no-style-btn jf-pointer"><span className="jf-icons">
          <SVGIcon name={'sound-icon'}/>
        </span><span className={'jf-text'}>{audio ? audio.name: "Add music..."}</span></button></div>}

        {!resetCameraView && <video width="100%" height="100%" ref={refVideo} autoPlay playsInline muted/>}

        {!resetCameraView && <audio controls ref={refAudio} hidden>
          {audio && <source src={audio.url} type={audio.mime_type}/>}
        </audio>}
        {refVideo.current?.srcObject && <>
          {process === 0 && <ul className="jf-shoottimers">
            {[15, 30, 60].map(d => <li key={'jf-record-durantion-' + d} className="jf-shoottimers-li">
              <button className={"jf-shoottimers-li-a jf-no-style-btn " + (d === duration ? ' jf-active ' : '')}
                      onClick={() => setDuration(d)
                      }>{d}s
              </button>
            </li>)}
          </ul>}
          {process === 0 && <div className="jf-videofooter">
            <div className="jf-flex">
              <button className="jf-videobtn jf-no-style-btn" onClick={() => {
                setCameraPopup({ active: true, setDevice: changeDevice, device })
              }}><SVGIcon name="video-camera-icon"/></button>
            </div>
            <div className="jf-flex">
              <button onClick={record} className="jf-videoicon  jf-no-style-btn"/>
            </div>
            <div className="jf-flex"/>
          </div>}
          {process === 1 && <div className="jf-videofooter jf-videofooter-recording">
            <div className="jf-flex"/>
            <div className="jf-flex">
              <button onClick={pause} className="jf-videoicon jf-no-style-btn"/>
            </div>
            <div className="jf-flex"/>
          </div>}
          {process === 2 && <div className="jf-videofooter">
            <div className="jf-flex"/>
            <div className="jf-flex">
              <button onClick={play} className="jf-videoicon jf-no-style-btn"/>
            </div>
            <div className="jf-flex">
              <div className="jf-icons">
                <button onClick={cancel} className="jf-backicon jf-no-style-btn">
                  <SVGIcon name="back-close-icon"/>
                </button>
                <button onClick={save} className="jf-backicon jf-no-style-btn">
                  <SVGIcon name="check-mark-round-icon"/>
                </button>
              </div>
            </div>
          </div>}
        </>}
      </div>
      <BottomMenu/>
    </>
  );
}

export default StudioRecord;
import React, {Component} from 'react';
import SVGIcon from '../../svg/index';
import ListTab from './list-tab'
import variables from '../../../scss/variables.module.scss'
import {Container} from "react-grid-system"

class MediaTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab || null,
            loading: props.loading || false,
            media: props.media || null,
            profile: props.profile || null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.loading !== state.loading ||
            state.media !== props.media ||
            state.profile !== props.profile ||
            state.activeTab !== props.activeTab
        ) {
            return {
                loading: props.loading,
                media: props.media,
                profile: props.profile,
                activeTab: props.activeTab
            }
        }
        return null
    }

    onChangeTab = (item) => {
        const {href} = this.props;
        if (href) {
            if (typeof href === 'string') {
                this.props.navigate(href + item.name, {replace: true});
            } else {
                href(item.name)
            }
        }
        this.setState({activeTab: item.name});
    }

    getTabs = () => {
        const {
            videos, photos, shop
        } = this.props.media;
        const tabs = [
            {
                name: 'video', icon: 'video-icon',
                component: <ListTab
                    type={'video'} medias={videos}
                    {...this.props}
                    profile={this.state.profile}
                />
            },
            {
                name: 'photo', icon: 'photo-icon',
                component: <ListTab type={'photo'}
                                    profile={this.state.profile}
                                    {...this.props}
                                    medias={photos}/>
            },
        ];
        if (shop !== undefined) {
            tabs.push({
                name: 'shop', icon: 'shop-icon',
                component: <ListTab medias={shop}
                                    {...this.props} type={'shop'}/>
            })
        }
        return tabs;
    }

    renderContent = () => {
        const tabs = this.getTabs();
        const activeTabInfo = tabs.find(item => item.name === this.state.activeTab);

        const {classContain = '', style = {}} = this.props;
        return (
            <div className={'jf-media-tabs'} style={style}>
                <ul className={'jf-media-tabs-header'}>
                    {tabs.map((item, i) =>
                        <li key={'jf-media-tabs-header-' + i}
                            onClick={() => this.onChangeTab(item)}
                            className={'jf-pointer ' + (this.state.activeTab === item.name ? 'active' : '')}>
                            <SVGIcon name={item.icon} fill={variables['colors-jf-gray']}/>
                        </li>
                    )}
                </ul>
                <div className={classContain}>
                    {!this.state.loading &&
                        <Container fluid className={'jf-media-tabs-list'}>
                            {activeTabInfo.component}
                        </Container>
                    }
                    {this.props.loadDiv}
                </div>
            </div>
        );
    }

    render() {
        return this.renderContent();
    }
}

export default MediaTabs;
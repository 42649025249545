import React from 'react';
const EmojigrayIcon = ({
  style = {},
  fill = '#afafb3',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="emojigray" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,21.6A9.6,9.6,0,1,1,21.6,12,9.6,9.6,0,0,1,12,21.6Zm0-6a3.589,3.589,0,0,1-3.379-2.4h-2.5a6,6,0,0,0,11.758,0h-2.5A3.589,3.589,0,0,1,12,15.6ZM8.4,8.4H6v2.4H8.4Zm7.2,2.4H18V8.4H15.6Z" fill="#afafb3"/>
    </svg>
  </>
);
export default EmojigrayIcon;
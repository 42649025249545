import React from 'react'

const MediaIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '18',
  height = '22',
  className = '',
  viewBox = '0 0 18 22'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Media"
          d="M18.75,23A2.225,2.225,0,0,0,21,20.8V3.2A2.225,2.225,0,0,0,18.75,1H5.25A2.225,2.225,0,0,0,3,3.2V20.8A2.225,2.225,0,0,0,5.25,23ZM5.25,20.8V3.2h13.5V20.8ZM9.75,9.14v5.72a.823.823,0,0,0,.449.727.86.86,0,0,0,.868-.045l4.376-2.86a.8.8,0,0,0,0-1.364l-4.376-2.86a.86.86,0,0,0-.868-.045.823.823,0,0,0-.449.727Z"
          transform="translate(-3 -1)" fillRule="evenodd"/>
  </svg>
}
export default MediaIcon;

import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import Header from "../../../components/header.js";
import { TextField } from "../../../components/basic/text-field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import Mutations from "../../../params/mutations";
import variables from "../../../scss/variables.module.scss";
import { useRecoilState, useResetRecoilState } from 'recoil'
import { authResetPasswordAtom } from '../../../state/pages/auth'
import { setSeparateState } from '../../../helpers/utils'

const schema = yup
  .object({
    password: yup
      .string()
      .required()
      .label("Password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
        "Password must contain at least 6 characters and includes upper lower number letters"
      ),
  })
  .required();

function ForgotPassword() {
  const [{isLoading, isSuccess, errorMsg, }, setResetPassword] = useRecoilState(authResetPasswordAtom);
  const setIsLoading = setSeparateState(setResetPassword, "isLoading");
  const setIsSuccess = setSeparateState(setResetPassword, "isSuccess");
  const setErrorMsg = setSeparateState(setResetPassword, "errorMsg");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetResetPassword = useResetRecoilState(authResetPasswordAtom);

  const navigate = useNavigate();
  const { id } = useParams();
  const fetchAction = useFetchApiAction();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    resetStateAction.add(resetResetPassword)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = ({ password }) => {
    setIsSuccess(false);
    setIsLoading(true);
    setErrorMsg('');

    fetchAction(Mutations.PasswordReset.PasswordReset, {
      id,
      password,
    }).then((res) => {
      if (res.password_reset) {
        if (res.password_reset.ok) {
          setIsSuccess(true);
        } else if (
          res.password_reset.errors &&
          res.password_reset.errors.length
        ) {
          setErrorMsg(res.password_reset.errors.join("\n"));
        }
      }

      setIsLoading(false);
    });
  };

  return (
    <>
      <div className="jf-forgot-pass-content">
        <Header onBack={() => navigate("/signin")} />
        <div className="jf-forgot-pass-body">
          <h1 className="jf-title">Reset password</h1>
          {isSuccess ? (
            <div className="jf-text-field-msg error">
              Password Reset Successfully, please go back to{" "}
              <Link to="/signin">Sign In</Link>
            </div>
          ) : (
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <div className="jf-input-boxs">
                <TextField
                  placeholder={"Password"}
                  error={errors.password?.message}
                  type={"password"}
                  register={register("password")}
                />
              </div>
              {errorMsg && (
                <div className="jf-text-field-msg error">{errorMsg}</div>
              )}
              {isLoading && (
                <div className="jf-forgot-pass-loading">
                  <Puff
                    wrapperStyle={{justifyContent: 'center'}}
                    color={variables["colors-jf-secondary"]}
                    height={50}
                    width={50}
                  />
                </div>
              )}
              <ul className="jf-btn-ul jf-mt-20px">
                <li className="jf-btn-ul-li">
                  <button
                    disabled={isLoading}
                    className="jf-btn jf-basic-btn"
                    type={"submit"}
                  >
                    Reset Password
                  </button>
                </li>
              </ul>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

import TopMenu from "../../../components/basic/top-menu";
import BottomMenu from "../../../components/basic/bottom-menu";
import React from "react";
import SVGIcon from "../../../components/svg";
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue } from "recoil";
import { authProfileAtom } from "../../../state/auth";

const Studio = () => {
  const authProfile = useRecoilValue(authProfileAtom);
  const isCreator = !!authProfile?.role?.creator;
  const navigate = useNavigate()

  return (
    <>
      <TopMenu
        icon={"greater-icon"}
        title={isCreator ? "Upload" : "Studio"}
        action={() => navigate(-1)}
      />
      <div className={"jf-basic-page no-padding"}>
        <div className="jf-addcontent-btns">
          {isCreator ? (
            <React.Fragment>
              <Link to={"/studio/record"} className="jf-addbtn">
                <div className="jf-icon">
                  <SVGIcon name="video-record-icon" />
                </div>
                <div className="jf-btntitle">Video record</div>
              </Link>
              <Link to={"/studio/live"} className="jf-addbtn jf-livestreaming">
                <div className="jf-icon">
                  <SVGIcon name="live-streaming-icon" />
                </div>
                <div className="jf-btntitle">Live streaming</div>
              </Link>
              <Link to={"/studio/upload"} className="jf-addbtn jf-pointer">
                <div className="jf-icon">
                  <SVGIcon name="upload-icon" />
                </div>
                <p className="jf-btntitle">
                  Click here to upload multiple files
                </p>
              </Link>
            </React.Fragment>
          ) : (
            <Link to={"/creator-request"} className="jf-addbtn jf-pointer">
              <div className="jf-icon">
                <SVGIcon name="creator-icon" />
              </div>
              <p className="jf-btntitle">Become a creator</p>
            </Link>
          )}
        </div>
      </div>
      <BottomMenu />
    </>
  );
};

export default Studio;

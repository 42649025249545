import { atom } from 'recoil';

export const mediaDashboardAtom = atom({
  key: 'mediaDashboard',
  default: [],
});

export const mediaBoxAtom = atom({
  key: 'mediaBox',
  default: [],
});

export const updatingAtom = atom({
  key: 'updating',
  default: false,
});
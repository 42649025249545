import React, {useEffect} from 'react'
import Main from './main/index';
import {useRecoilValue, useRecoilState} from 'recoil'
import {useMediaQuery} from 'react-responsive';
import MediaBox from '../components/media-box'
import {minWidthScreen} from '../helpers/utils'
import {authIdAtom, authProfileAtom} from '../state/auth'
import {loadingAtom, locationAtom, swiperMobileBottomShowAtom, swiperMobileIndexAtom} from '../state'
import Queries from '../params/queries'
import {useFetchApiAction} from '../action/main-action'
import LiveBox from '../components/live-box/index'
import {Swiper, SwiperSlide} from 'swiper/react';
import BottomMenu from '../components/basic/bottom-menu'

let swiper = null;
export default function PageMain() {
    const [authId, setAuthId] = useRecoilState(authIdAtom);
    const [authProfile, setAuthProfile] = useRecoilState(authProfileAtom);
    const [swiperMobileIndex, setSwiperMobileIndex] = useRecoilState(swiperMobileIndexAtom);
    const fetchAction = useFetchApiAction();
    const isBigScreen = useMediaQuery({query: minWidthScreen})
    const locationState = useRecoilValue(locationAtom);
    const swiperMobileBottomShow = useRecoilValue(swiperMobileBottomShowAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const index = locationState.slideIndex === 0 ? 0 : 1;

    useEffect(() => {
        if (authId && !authProfile) {
            fetchAction(Queries.Account.LoggedIn, {}).then(res => {
                if (res.logged_in?.account) {
                    setAuthProfile(res.logged_in.account)
                } else {
                    setAuthProfile(null)
                    setAuthId(null)
                }
            });
        }
        if (!authId && authProfile) {
            setAuthProfile(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authId]);

    useEffect(() => {
        setLoading(true);
        if (locationState && swiper && !swiper.destroyed) {
            swiper.slideTo(index);
            setSwiperMobileIndex(index)
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationState])

    // useEffect(() => {
    //     const videoCoverEl = document.querySelector(".jf-dashbord .swiper-slide-active .jf-modal_img-cover");
    //     const videoEl = document.querySelector(".jf-dashbord .swiper-slide-active video");
    //
    //     if (swiperMobileIndex === 1) {
    //         if (videoEl && !videoEl.paused) {
    //             videoEl.currentTime = 0;
    //             videoCoverEl.click();
    //         }
    //     } else {
    //         if (videoEl && videoEl.paused) {
    //             videoCoverEl.click();
    //         }
    //     }
    // }, [swiperMobileIndex])

    return (
        <>
            {isBigScreen && locationState && <div className={"jf-media-desktop"}>
                {["user.live", "studio.live"].indexOf(locationState.pagename) === -1 ?
                    <MediaBox from={'media-desktop'}/> :
                    <LiveBox/>}
            </div>}
            {isBigScreen && <Main/>}

            {!isBigScreen && <Swiper
                direction={'horizontal'}
                navigation={true}
                onSwiper={(e) => swiper = e}
                className={'jf-container'}
                onSlideChangeTransitionEnd={(e) => {
                    setSwiperMobileIndex(e.activeIndex)
                }}
            >
                <SwiperSlide>
                    {["user.live", "studio.live"].indexOf(locationState.pagename) === -1 ?
                        <MediaBox from={'media-desktop'}/> : <LiveBox/>}
                    {swiperMobileBottomShow && <BottomMenu type={''} hide={true}/>}
                </SwiperSlide>
                <SwiperSlide>
                    {!loading && <Main/>}
                </SwiperSlide>

            </Swiper>}
        </>
    );
}
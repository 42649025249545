import React, {useEffect, useCallback} from 'react'
import MediaTabs from '../../../components/complex/media-tabs'
import { useMediaAction } from '../../../action/media-action'
import SearchTextField from '../../../components/basic/search-text-field'
import { getTab, setSeparateState, uniqueList } from '../../../helpers/utils'
import Constants from '../../../params/constants'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { searchMediaAtom } from '../../../state/pages/search'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import { useNavigate, useParams } from 'react-router-dom'

const Media = (props) => {
  const [{media, query, loading, loadingMore}, setSearchMedia] = useRecoilState(searchMediaAtom);
  const resetSearchMedia = useResetRecoilState(searchMediaAtom);
  const setMedia = setSeparateState(setSearchMedia, "media");
  const setQuery = setSeparateState(setSearchMedia, "query");
  const setLoading = setSeparateState(setSearchMedia, "loading");
  const setLoadingMore = setSeparateState(setSearchMedia, "loadingMore");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const limit = Constants.MediaListLimit;
  const navigate= useNavigate();
  const match = {params: useParams()};
  const mediaAction = useMediaAction(false);

  useEffect(() => {
    resetStateAction.add(resetSearchMedia)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    let isCanceled = false;
    setLoadingMore(true);
    setLoading(true);
    const params = { limit: { order: false, limit: limit }, filter: { query }, withLike: false }
    mediaAction.getMedias(match.params.subTab || 'video', params).then(res => {
      if (isCanceled) return;
      setMedia((old) => {
        return {...old, [getTab(match.params.subTab || 'video')]: res}
      });
      setLoading(false);
      setLoadingMore(res.length === 0 ? 2 : 0);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.subTab, query]);

  const onLoadMore = useCallback(() => {
    if (loadingMore > 0) return;
    let isCanceled = false;
    setLoadingMore(1);
    const key = getTab(match.params.subTab || 'video');
    const params = { limit: { order: false, skip: media[key].length }, filter: { query }, withLike: false }
    mediaAction.getMedias(match.params.subTab || 'video', params).then(res => {
      if (isCanceled) return;
      setMedia((old) => {
        return {...old, [key]: uniqueList(old[key].concat(res))}
      });
      setLoadingMore(res.length === 0 ? 2 : 0);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMore, query])

  return <>
    <SearchTextField onChange={setQuery}/>
    <MediaTabs
      href={'/s/media/'}
      navigate={navigate}
      classContain={'jf-videos'}
      activeTab={match.params.subTab || 'video'}
      media={media}
      loading={loading}
      onLoadMore={onLoadMore}
      loadMore={loadingMore < 2}
    />
  </>
}

export default Media;
import React from "react";
import { useLayer, Arrow, useHover } from "react-laag";

export default function Tooltip({children, text}) {
  const [isOpen, propsHover] = useHover();

  const { renderLayer, triggerProps, layerProps, arrowProps } =
    useLayer({
      isOpen,
      placement: "left-center",
      arrowOffset: 4,
      triggerOffset: 10
    });

  return (
    <>
      <div {...triggerProps} {...propsHover}>
        {children}
      </div>
      {isOpen &&
      renderLayer(
        <div
          {...layerProps}
          style={{ ...layerProps.style }}
          className={'tooltip'}
        >
          {text}
          <Arrow {...arrowProps} size={5} roundness={0} />
        </div>
      )}
    </>
  );
}
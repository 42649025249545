import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSetRecoilState} from "recoil";
import {usernameAtom} from "../../state/users";
// import canAutoPlay from 'can-autoplay';

export default function VideoPlayer3(props) {

    const {item, index, active, readyToPlay, ios, mobile, element, iosVideoRef} = props;

    const provider = useRef(null);
    const container = useRef();

    const [videoType, setVideoType] = useState("");

    // const Hls = useRef(window.Hls);

    const setUsername = useSetRecoilState(usernameAtom);

    // const [play, setPlay] = useState(false);

    // console.log("player element: ", item.source);
    // const [ready, setReady] = useState('');

    const [visible, setVisible] = useState(false);

    const playVideoToggle = useCallback((play) => {
        const videoElem = provider.current;
        if (videoElem) {
            if (play) {
                playVideo(videoElem)
                    .then(() => {

                    })
                    .catch(err => {
                    });

            } else {
                videoElem.pause();
                // videoElem.currentTime = 0;
            }
        }
    }, []);

    // useEffect(() => {
    //     if (ios) {
    //         if (index === active && visible) {
    //             console.log("ios play", element);
    //         } else {
    //             console.log("ios pause", element);
    //         }
    //     }
    // }, [ios, active, index, visible]);

    useEffect(() => {
        if (index === active && visible) {
            playVideoToggle(true);
            setUsername(item.user.username);
        } else {
            playVideoToggle(false);
        }
    }, [active, index, visible, item.user.username, playVideoToggle, setUsername])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            console.log("video intersection ration: ", entries[0].intersectionRatio);
            if (entries[0].isIntersecting) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }, {
            root: null,
            threshold: 0.8
        });

        // if (ios === true) {
        //     const videoElem = iosVideoRef.current;
        //     const videoSrc = item.source.url;
        //     console.log(videoElem, item.source.type, videoSrc);
        //     observer.observe(videoElem);
        //     if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
        //         videoElem.src = videoSrc;
        //         videoElem.muted = true;
        //         videoElem.play();
        //     }
        //     return () => {
        //         observer.disconnect();
        //     };
        // }

        let hlsItem;
        const Hls = window.Hls;
        if (provider && provider.current) {
            const videoElem = provider.current;
            const videoSrc = item.source.url;

            videoElem.addEventListener("loadstart", handleEvent);
            videoElem.addEventListener("progress", handleEvent);
            videoElem.addEventListener("canplay", handleEvent);
            videoElem.addEventListener("canplaythrough", handleEvent);

            observer.observe(videoElem); // Asynchronous call

            setVideoType(item.source.type);
            if (item.source.type === "hls") {
                if (Hls.isSupported()) {
                    hlsItem = new Hls();
                    hlsItem.loadSource(videoSrc);
                    hlsItem.attachMedia(videoElem);
                } else if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
                    videoElem.src = videoSrc;
                    videoElem.muted = true;
                }
            }
        }
        return () => {
            observer.disconnect();
            if (hlsItem) {
                hlsItem.destroy();
                hlsItem = undefined;
            }
        }
    }, [item.source.type, item.source.url]);


    async function onClick() {
        const videoElem = provider.current;
        videoElem.muted = false;
        if (videoElem.paused) {
            await playVideo(videoElem);
        } else {
            videoElem.pause();
        }
    }

    /**
     * Auto play is disabled on mobile browsers unless muted=true
     * Require user interaction (click to play)
     */
    async function playVideo(videoElem) {
        try {
            // const canPlay = await canAutoPlay.video();
            // if (canPlay.result === false) {
            //     videoElem.muted = true;
            // }
            await videoElem.play();
        } catch (err) {
            console.log(err)
        }
        // if (promise !== undefined) {
        //     promise.then(_ => {
        //         // Autoplay started!
        //     }).catch(error => {
        //         // Autoplay not allowed!
        //         // Mute video and try to play again
        //         // if (videoElem) {
        //         //     videoElem.muted = true;
        //         //     videoElem.play();
        //         // }
        //     });
        // }
    }

    // function onContextMenu() {
    //     return true;
    // }

    function onCanPlay() {
        // item.canPlay = true;
        if (readyToPlay) {
            readyToPlay(item);
        }
        // setReady(provider.current.readyState);
    }

    const handleEvent = (ev) => {
        // setReady(provider.current.readyState + ' ' + ev.type);
    };

    const videoElement = <video
        // poster={item.thumbnail.poster ?? ''}
        controls={false}
        onClick={onClick} ref={provider}
        preload="auto" playsInline={true}
        onCanPlay={onCanPlay}
        loop={true}>
        {videoType === "video/mp4" && <source src={item.source.url} type="video/mp4"/>}
    </video>;

    const iosElement = (index === active) ? element : videoElement;


    return (
        <div className="video-container" ref={container}>
            {/*{ios === true ? iosElement : videoElement}*/}
            {/*{videoElement}*/}
            <video
                // style={{
                //     height: 'fit-content',
                //     width: 'fit-content',
                //     flexGrow: 1,
                //     display: 'inline-block',
                //     flexBasis: 0,
                //     margin: 0,
                //     padding: 0
                // }}
                poster={ios == true ? item.thumbnail.poster ?? '' : ''}
                controls={false}
                // onContextMenu={onContextMenu}
                onClick={onClick} ref={provider}
                preload="auto" playsInline={true}
                onCanPlay={onCanPlay}
                loop={true}>
                {videoType === "video/mp4" && <source src={item.source.url} type="video/mp4"/>}
            </video>
            {/*<video controls={false} style={{height: '300px', flexGrow: 0}} poster={item.thumbnail.original}></video>*/}
            {/*<img style={{height: "300px"}} src={item.thumbnail.original}/>*/}
        </div>
    );
}
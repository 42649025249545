import React from 'react';
import Avatar from '../../basic/avatar'
import { useRecoilValue } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import SVGIcon from '../../svg'
import Button from '../../basic/button'
import variables from '../../../scss/variables.module.scss'

const Subscription = () => {
  const info = useRecoilValue(activePopupState('subscription'));
  const profile = info.profile || null;
  const list = [
    'Full access to my content',
    'Direct message with me',
    'Cancel your subscription at any time',
    'Discreetly billed as SXWEB (Cyprus)',
  ];

  return <>
    <Avatar
      image={profile && profile.avatar}
      text={profile && profile.fullname}
      width={110}
      height={110}
    />
    <div className={'jf-popup-subscription-title'}>
      Subscribe to<br/>
      {profile && profile.fullname}
    </div>
    <div className={'jf-popup-subscription-content'}>
      {list.map((text, i) => <div className={'jf-row jf-mb-12px'}
                                  key={'jf-popup-subscription-content-' + i}>
        <SVGIcon name={'check-icon'} fill={variables['colors-jf-online']}/>
        <div>{text}</div>
      </div>)}
    </div>
    <div className={'jf-mb-20px'}>
      <Button className={'medium jf-m-auto'}>Subscribe</Button>
    </div>
  </>;
}

export default Subscription;
import React from 'react';
const HomeIcon = ({
  style = {},
  fill = "#7c79fa",
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '29',
  height = '26.364',
  className = '',
  viewBox = '0 0 29 26.364'
}) => (
  <>
    <svg id="home" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <path id="home" d="M26.328,27.7V15.042h1.524L17.3,5.5,6.746,15.042H8.27V27.7h3.873v-9.5H22.454v9.5h3.873m.428,2H22.027a1.584,1.584,0,0,1-1.576-1.583V20.211h-6.3v7.915a1.584,1.584,0,0,1-1.576,1.583H7.842a1.584,1.584,0,0,1-1.576-1.583V17.045H3.587a.789.789,0,0,1-.52-1.377L16.243,3.749a1.583,1.583,0,0,1,2.112,0L31.531,15.668a.786.786,0,0,1-.52,1.377H28.331v11.08A1.584,1.584,0,0,1,26.755,29.709Z" transform="translate(-2.802 -3.345)" fill={fill}/>
    </svg>


  </>
);
export default HomeIcon;
import React from 'react';
const DollerIcon2 = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '55',
  className = '',
  viewBox = '0 0 55 55'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="dollar" transform="translate(-1199 -699)">
        <rect id="Rectangle_1059" data-name="Rectangle 1059" width={width} height={width} transform="translate(1199 699)" fill={fillNone} opacity="0" />
        <path id="Path_47" data-name="Path 47" d="M194.75,139.373v-9.061c2.636.647,4.583,2.343,4.583,4.292a2.292,2.292,0,0,0,4.583,0c0-4.428-3.942-8.13-9.167-8.981v-2.477a2.292,2.292,0,0,0-4.583,0v2.477c-5.225.851-9.167,4.553-9.167,8.981s3.942,8.13,9.167,8.981v9.061c-2.636-.647-4.583-2.343-4.583-4.292a2.292,2.292,0,1,0-4.583,0c0,4.428,3.942,8.13,9.167,8.981v2.5a2.292,2.292,0,0,0,4.583,0v-2.5c5.225-.851,9.167-4.553,9.167-8.981S199.975,140.224,194.75,139.373Zm-9.167-4.769c0-1.949,1.948-3.645,4.583-4.292V138.9C187.531,138.249,185.583,136.553,185.583,134.6Zm9.167,18.042v-8.584c2.636.647,4.583,2.343,4.583,4.292S197.386,152,194.75,152.646Z" transform="translate(1034.5 584.563)" fill={fill} />
      </g>
    </svg>
  </>
);
export default DollerIcon2;
import React, { useEffect} from 'react';
import ToggleSwitch from '../../../../components/basic/toggle-switch'
import { useLocation, useNavigate } from 'react-router-dom'
import Queries from '../../../../params/queries'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import Mutations from '../../../../params/mutations'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { messageAutoTabAtom } from '../../../../state/pages/message'
import { setSeparateState } from '../../../../helpers/utils'

const AutoTab = () => {
  const [{loading, list }, setMessageAutoTab] = useRecoilState(messageAutoTabAtom);
  const setList = setSeparateState(setMessageAutoTab, "list")
  const setLoading = setSeparateState(setMessageAutoTab, "loading")

  const location = useLocation();
  const navigate = useNavigate();
  const fetchApi = useFetchApiAction();

  const follower = list?.find(item => item.event === "follow")
  const subscribe = list?.find(item => item.event === "subscribe")

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageAutoTab = useResetRecoilState(messageAutoTabAtom)

  useEffect(() => {
    resetStateAction.add(resetMessageAutoTab)
    const value = location.state;
    if (value) {
      setLoading(false);
      return;
    }
    setLoading(true);
    fetchApi(Queries.Messages.Rules, {limit: {limit: 4}}).then(res => {

      setList(res?.messenger_rules || [])
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const switchRule = (event) => {
    const index = list?.findIndex(item => item.event === event)
    if (index > -1) {
      fetchApi(Mutations.Messages.SwitchRule, {event: event, enabled: !list[index].enabled}).then(res => {
        list[index].enabled = !list[index].enabled;
        setList([...list]);
      })
    } else {
      navigate('/messages/auto/' + (event === "follow" ? "follower" : "subscriber"));
    }
  }

  return !loading ? <ul className="jf-automatedMessage">
    <li className="jf-automatedMessage-list">
      <div className="jf-automatedMessage-list-text jf-pointer"
           onClick={() => navigate('/messages/auto/follower')}>
        <div className="jf-MessageTitle">New Follower
        </div>
        <div className="jf-MessageSubtitle">Send an automated message when someone follows you</div>
      </div>
      <ToggleSwitch keyId={'toggle-new-follower'} checked={follower?.enabled} onChange={() => switchRule("follow")}/>
    </li>
    <li className="jf-automatedMessage-list">
      <div className="jf-automatedMessage-list-text jf-pointer"
           onClick={() => navigate('/messages/auto/subscriber')}>
        <div className="jf-MessageTitle">New subscriber
        </div>
        <div className="jf-MessageSubtitle">Send an automated message when someone subscribes to you</div>
      </div>
      <ToggleSwitch keyId={'toggle-new-subscriber'} checked={subscribe?.enabled} onChange={() => switchRule("subscribe")}/>
    </li>
  </ul> : null
}

export default AutoTab;
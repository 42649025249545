import React from 'react';
const ShareIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '55',
  className = '',
  viewBox = '0 0 55 55'
}) => (
  <>
    <svg id="share" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="Path_41" data-name="Path 41" d="M0,0H55V55H0Z" fill={fillNone} />
      <path id="Path_45" data-name="Path 45" d="M43.461,16.168,25.984.411a1.587,1.587,0,0,0-2.645,1.168V8.962S.265,6.868,0,33.226a.815.815,0,0,0,1.433.573c2.711-3.085,9.543-8.815,21.906-7.14V32.1a1.587,1.587,0,0,0,2.6,1.212l17.5-14.744a1.587,1.587,0,0,0,.022-2.4Z" transform="translate(5.5 10.077)" fill={fill} />
    </svg>
  </>
);
export default ShareIcon;
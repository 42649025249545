import React, {useEffect, useRef} from 'react'
import {useRecoilValue, useRecoilState} from 'recoil'
import {profileAtom} from '../../state/users'
import VideoPlayer from '../swiper/video-player'
import LiveBoxChat from './chat'
import Avatar from '../basic/avatar'
import {useMediaQuery} from 'react-responsive'
import {countFormat, minWidthScreen, setSeparateState} from '../../helpers/utils'
import {useNavigate} from 'react-router-dom';
import Logo from '../basic/logo';
import {liveBoxUserAtom} from '../../state/components/live-box';
import {useResetStateWhenChangeUrlAction} from '../../action/main-action';
import OvenPlayer from 'ovenplayer';

const LiveBoxUser = () => {
    const profile = useRecoilValue(profileAtom);
    const isBigScreen = useMediaQuery({query: minWidthScreen})
    const navigate = useNavigate();
    const [{end}, setLiveBoxUser] = useRecoilState(liveBoxUserAtom);
    const setEnd = setSeparateState(setLiveBoxUser, "end");
    const resetStateAction = useResetStateWhenChangeUrlAction();

    const player = useRef(null);

    useEffect(() => {
        resetStateAction.add(() => setEnd(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (profile && profile.status === 'stop') {
            setEnd(true)
        }
        if (profile?.live) {
            setEnd(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const playerId = document.getElementById("player-oven");
        if (playerId) {
            try {
                const playerOptions = {
                    "autoStart": true,
                    "autoFallback": true,
                    "mute": false,
                    "controls": false,
                    // "hlsConfig": {
                    //     "liveSyncDuration": 1.5,
                    //     "liveMaxLatencyDuration": 4,
                    //     "maxLiveSyncPlaybackRate": 1.5
                    // },
                    // "webrtcConfig": {
                    //     "timeoutMaxRetry": 5,
                    //     "connectionTimeout": 10000,
                    //     "playoutDelayHint": 3
                    // },
                    "sources": [{
                        label: 'LL-HLS',
                        "type": "ll-hls",
                        "file": "https://live.junefox.com/live/" + profile.username + "/llhls.m3u8"
                    }]
                };
                player.current = OvenPlayer.create("player-oven", playerOptions);
            } catch (e) {
                console.error(e);
            }
        }

    }, [profile])

    const onCloseBtn = () => {
        navigate('/' + profile.username);
    }
    const close = () => {
        setEnd(false)
        if (!isBigScreen) {
            onCloseBtn()
        }
    }

    return (
        <>
            {isBigScreen ? <div className="jf-header2">
                <Logo/>
                <div className="jf-fullscreen  responsive-display" style={{width: 30, marginTop: 5}}>
                </div>
            </div> : profile && <div className={'jf-row jf-user-live-info'} style={{marginLeft: 10}}>
                <div onClick={onCloseBtn}>
                    <Avatar
                        image={profile && profile.avatar}
                        text={profile && profile.fullname}
                        width={70}
                        height={70}
                    />
                </div>
                <div className={'jf-ml-20px jf-mt-auto jf-mb-auto'} style={{flexGrow: 1}}>
                    <div onClick={onCloseBtn}
                         className={'jf-font-20-22 jf-font-b '}>@{profile && profile.username}</div>
                    <div className={'jf-font-16-22 jf-font-r jf-mt-3px'}>
                        34K Views
                    </div>
                </div>
            </div>}
            {profile?.live ? <>
                <div className="jf-dashbord" style={{position: 'relative', height: '100%'}}>
                    <div id="player-oven"></div>
                    {/*<VideoPlayer*/}
                    {/*    play={true}*/}
                    {/*    options={{autoplay: true}}*/}
                    {/*    sources={[{*/}
                    {/*        type: 'hls',*/}
                    {/*        url: 'https://live.junefox.com/live/' + profile.username + '/llhls.m3u8'*/}
                    {/*    }]}*/}
                    {/*/>*/}
                </div>
            </> : <div className="jf-dashbord empty" style={{height: '100%'}}/>}
            {profile?.live && <LiveBoxChat user={profile}/>}
            {(end || !profile?.live) && profile && <>
                <div className={'jf-live-box-end'} onClick={close}/>
                <div className={'jf-live-box-end-content'} onClick={close}>
                    <label
                        className={'title'}>{end ? 'Live has ended' : profile?.fullname + ' is no longer streaming'}</label>
                    <Avatar
                        image={profile && profile.avatar}
                        text={profile && profile.fullname}
                        width={150}
                        height={150}
                    />
                    <label className={'username'}>@{profile && profile.username}</label>
                    {end && <div className={'jf-row'}>
                        <div><label>{countFormat(344)}</label><br/>Likes</div>
                        <div><label>{countFormat(454)}</label><br/>Comments</div>
                    </div>}
                </div>
            </>}
        </>
    );
}

export default LiveBoxUser
import React, {useEffect} from 'react'
import {getTab} from '../helpers/utils'
import SwiperLoop from './swiper/swiper-loop'
import SwiperMediaDashboard from './swiper/swiper-media-dashboard'
import Logo from './basic/logo'
import {locationAtom} from '../state'
import {useRecoilValue} from 'recoil'
import {useNavigate} from 'react-router-dom'
import {useMediaAction} from "../action/media-action";

const MediaBox = ({onChangeTabOther}) => {
    const locationState = useRecoilValue(locationAtom);
    const params = locationState.params;
    const navigate = useNavigate();
    let tab = getTab(params?.tab || "video");
    console.log("tab:", tab, params?.tab);
    if (['videos', 'photos', 'lives'].indexOf(tab) < 0) {
        tab = "videos";
    }
    const mediaAction = useMediaAction(false);

    const onChangeTab = (tab) => {
        if (onChangeTabOther) {
            onChangeTabOther(tab)
        } else {
            if (["account.profile.medias", "account.profile"].indexOf(locationState?.pagename) > -1) {
                navigate('/profile/' + tab);
            } else if (params?.username) {
                navigate('/' + params?.username + '/' + tab);
            } else if (["dashboard.medias.tags"].indexOf(locationState?.pagename) > -1) {
                navigate('/' + tab + '/' + params?.tag);
            } else {
                navigate('/' + tab);
            }
        }
    }

    // useEffect(() => {
    //     if (typeof params !== 'object') {
    //         return;
    //     }
    //     const {media_id, username, tab} = params;
    //     if (username && !tab) {
    //         navigate('/' + username + '/video');
    //         return;
    //     }
    //     if (username && tab && !media_id) {
    //         const params = {filter: {username, rand: false}, limit: {limit: 1}, withLike: true};
    //         params.limit.order = false;
    //         mediaAction.getMedias(tab, params).then(res => {
    //             if (res.length > 0) {
    //                 navigate('/' + username + '/' + tab + '/' + res[0].id);
    //                 return;
    //             }
    //         })
    //
    //     }
    // }, [params]);

    return (
        <>
            <div className="jf-header2">
                <Logo/>
                <ul className="jf-tabsmenu">
                    <li className={`jf-tabsmenu-li ${tab === 'videos' ? 'jf-active' : ''}`}
                        onClick={() => onChangeTab('videos')}>Videos
                    </li>
                    <li className={`jf-tabsmenu-li ${tab === 'lives' ? 'jf-active' : ''}`}
                        onClick={() => onChangeTab('lives')}>Live
                    </li>
                </ul>
                <div className="jf-fullscreen  responsive-display"
                     style={{width: 30, marginTop: 5, textAlign: 'end', flexGrow: 1}}>
                </div>
                <div className="jf-fullscreen desktop-display" style={{width: 166}}>
                </div>
            </div>
            {params?.media_id ?
                <SwiperLoop tab={tab} match={{params}}/> :
                <SwiperMediaDashboard tab={tab}/>
            }
        </>
    );
}

export default MediaBox;

import React, {useEffect} from 'react'
import TopMenu from '../../../components/basic/top-menu'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUserAction } from '../../../action/user-action'
import BottomMenu from '../../../components/basic/bottom-menu'
import Constants from '../../../params/constants'
import { authProfileAtom } from '../../../state/auth'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { setSeparateState, upperFirstChar } from '../../../helpers/utils'
import variables from  '../../../scss/variables.module.scss'
import Button from '../../../components/basic/button'
import { editProfileCompleteAtom } from '../../../state/pages/edit-profile'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import { useNavigate } from 'react-router-dom'

const schema = yup.object({
  gender: yup.string(),
  interests: yup.array()
}).required();

const Complete = (props) => {
  const [{dataForm }, setComplete] = useRecoilState(editProfileCompleteAtom);
  const setDataForm = setSeparateState(setComplete, "dataForm")

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetComplete = useResetRecoilState(editProfileCompleteAtom)

  const navigate = useNavigate();
  const [authProfile,setAuthProfile] = useRecoilState(authProfileAtom);
  const { handleSubmit, formState: {errors}, setError, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });
  const userAction = useUserAction();

  useEffect(() => {
    resetStateAction.add(resetComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onSubmit = (data) => {
    userAction.upProfile(data).then(res => {
      if (res.errors) {
        for (let k in res.errors) {
          setError(k, { type: 'server', message: res.errors[k] });
        }
      } else {
        setAuthProfile({...authProfile, ...data})
        navigate('/account' + (props.title ? '/settings' : ''));
      }
    });
  }
  useEffect(() => {
    if (authProfile) {
      const data = {};
      ['gender', "interests"].forEach(key => {
        setValue(key, authProfile[key])
        data[key] = authProfile[key];
      })
      setDataForm(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProfile])

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        action={() => navigate('/account' + (props.title ? '/settings' : ''))}
        title={props.title || 'Complete Your Profile'}
      />
      <div className={'jf-edit-profile-form'}>
        <form
          style={{ width: '100%', marginTop: 2 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <span className={'jf-choices-label'}>I'm a</span>
          <ul className="jf-choices" style={{ backgroundColor: variables['colors-jf-light2']}}>
            {
              Constants.Gender.map(
                (c, i) => <li key={'jf-gender-' + c}
                  className={`jf-choices-li ${c === dataForm['gender'] ? 'jf-active' : ''} ` + dataForm['gender']}
                              onClick={() => {
                                setDataForm({ ...dataForm, gender: dataForm['gender'] === c ? '': c })
                                setValue('gender', c)
                              }}>{upperFirstChar(c)}</li>
              )
            }
          </ul>
          {errors.gender?.message && <div className={'jf-text-field-msg error'}>{errors.gender?.message}</div>}
          <span className={'jf-choices-label'}>Interested in</span>
          <ul className="jf-choices" style={{gap: 10}}>
            {
              Constants.Interests.map(
                (c, i) => <li key={'jf-interests-' + c}
                              className={`jf-choices-li ${dataForm['interests'] &&
                              dataForm['interests'].indexOf(c) > -1 ? 'jf-active' : ''}`}
                              onClick={() => {
                                let newData = dataForm['interests'].concat([]) || [];
                                const index = newData.indexOf(c);
                                if (index > -1) newData.splice(index, 1)
                                else newData.push(c)
                                setDataForm({ ...dataForm, interests: newData });
                                setValue('interests', newData)
                              }}>{upperFirstChar(c)}</li>
              )
            }
          </ul>
          {errors.interests?.message && <div className={'jf-text-field-msg error'}>{errors.interests?.message}</div>}
          <span className={'pass-note'} style={{marginTop: 5}}>Multiple selections allowed</span>
          <Button className={'jf-account-save-btn'} type={'submit'}>Finish</Button>
        </form>
      </div>
      <BottomMenu/>
    </>
  );
}

export default Complete;

import React from 'react';
const CopyIcon = ({
  style = {},
  fill = '#130c18',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '14',
  height = '18',
  className = '',
  viewBox = '0 0 14 18'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <g id="copy" transform="translate(-48.65)">
        <g id="Group_1151" data-name="Group 1151" transform="translate(48.65)">
          <path id="Path_5631" data-name="Path 5631" d="M58.16,85.4H50.032a1.4,1.4,0,0,0-1.382,1.423V98.829a1.4,1.4,0,0,0,1.382,1.423H58.16a1.4,1.4,0,0,0,1.382-1.423V86.823A1.408,1.408,0,0,0,58.16,85.4Zm.412,13.425a.422.422,0,0,1-.415.428H50.029a.422.422,0,0,1-.415-.428v-12a.422.422,0,0,1,.415-.428h8.128a.422.422,0,0,1,.415.428Z" transform="translate(-48.65 -82.252)" fill={fill}/>
          <path id="Path_5632" data-name="Path 5632" d="M144.96,0h-8.128a1.4,1.4,0,0,0-1.382,1.423.484.484,0,1,0,.967,0A.422.422,0,0,1,136.832,1h8.128a.422.422,0,0,1,.415.428V13.429a.422.422,0,0,1-.415.428.5.5,0,0,0,0,1,1.4,1.4,0,0,0,1.382-1.423V1.423A1.4,1.4,0,0,0,144.96,0Z" transform="translate(-132.342)" fill={fill}/>
        </g>
      </g>
    </svg>
  </>
);
export default CopyIcon;
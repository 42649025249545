import React, {useEffect, useState} from 'react'
import {useMediaAction} from '../../action/media-action'
import {Swiper, SwiperSlide} from 'swiper/react';
import MediaDetail from './detail'
import {mediaBoxAtom, updatingAtom} from '../../state/media'
import {useRecoilState} from 'recoil'
import {getTab, setSeparateState} from '../../helpers/utils'
import {profileAtom} from '../../state/users'
import {Mousewheel, Virtual} from 'swiper';
import {useSubscriptionLiveUserAction} from '../../action/main-action'
import {swiperMediaLoopAtom} from '../../state/components/swipper'
import VideoPlayer3 from "./video-player3";
import VideoElement from "./video-element";

let swiper = null;
const SwiperLoop = ({match, tab}) => {
    // const navigate = useNavigate();
    const mediaAction = useMediaAction(false);
    const [media, setMedia] = useRecoilState(mediaBoxAtom);
    const [profile, setProfile] = useRecoilState(profileAtom);
    const [updating, setUpdating] = useRecoilState(updatingAtom);

    const [{
        loading,
        mediaType,
        // activeIndex,
        // moveIndex,
        lastItem,
        // restart
    }, setSwiperMediaLoop] = useRecoilState(swiperMediaLoopAtom);
    const setLoading = setSeparateState(setSwiperMediaLoop, "loading")
    const setMediaType = setSeparateState(setSwiperMediaLoop, "mediaType")
    // const setActiveIndex = setSeparateState(setSwiperMediaLoop, "activeIndex")
    // const setMoveIndex = setSeparateState(setSwiperMediaLoop, "moveIndex")
    const setLastItem = setSeparateState(setSwiperMediaLoop, "lastItem")
    // const setRestart = setSeparateState(setSwiperMediaLoop, "restart")
    // const resetStateAction = useResetStateWhenChangeUrlAction()

    const [activeIndex, setActiveIndex] = useState(0);

    const subscriptionLiveUserAction = useSubscriptionLiveUserAction(true);

    const maxLimit = 200;

    // const [activeIndex, setActiveIndex] = useState(0);

    // useEffect(() => {
    //     resetStateAction.add(() => {
    //         setMoveIndex(-1)
    //     })
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(() => {
        let isCancelled = false;
        const params = {filter: {}, limit: {limit: maxLimit}};
        params.filter.reference = match.params.media_id;
        params.filter.username = match.params.username;
        params.filter.visibility = "all";
        params.username = match.params.username;
        params.limit.order = false;
        mediaAction.getReference(tab, params).then(res => {
            if (!res || isCancelled) return;
            setUpdating(false)
            setProfile(res[0].user);

            subscriptionLiveUserAction(res[0].user.id).then()

            if (res.length === 2) {
                mediaAction.processFirstLast(tab, res, params, lastItem, setLastItem).then((newRes) => {
                    setLoading(false);
                    setMedia(newRes);
                    setMediaType(getTab(tab))
                })
            } else {
                setLoading(false);
                setMedia(res);
                if (res.length === 1) setActiveIndex(0);
                setMediaType(getTab(tab))
            }
        })
        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.username, match.params.media_id, tab]);

    useEffect(() => {
        if (!updating) {
            if (swiper && !swiper.destroyed) {
                const index = media.findIndex(item => item.id === match.params.media_id);
                swiper.slideTo(index, 0);
                setActiveIndex(index);
            }
        } else {
            setUpdating(false)
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [media])

    // useEffect(() => {
    //     if (moveIndex > -1 && media[moveIndex].id !== match.params.media_id) {
    //         navigate(['', match.params.username, getTab(tab, true), media[moveIndex].id].join('/'));
    //         setActiveIndex(-1);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [moveIndex])

    useEffect(() => {
        const old = media.find(item => item.user?.id.trim() === profile?.id.trim());
        if (!profile || !old || profile.live === old.user.live) return;
        setUpdating(true);
        setMedia([...media.map(item => {
            if (item.user.id.trim() === profile.id.trim()) {
                return {...item, user: {...item.user, live: profile.live}};
            }
            return item
        })])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    // const togglePlay = (index) => {
    //     if (index === activeIndex) {
    //         setActiveIndex(-1);
    //     } else {
    //         setActiveIndex(index);
    //     }
    // }
    // const loadMore = (swiper) => {
    //     if (!swiper.isEnd) return;
    //
    //     // console.log('load more: ', swiper.slides.length);
    //     const lastMedia = media[swiper.slides.length - 1];
    //     if (!lastMedia) {
    //         return;
    //     }
    //     console.log('load more: ', lastMedia.id)
    //
    //     const params = {filter: {}, limit: {limit: maxLimit}};
    //     params.filter.reference = match.params.media_id;
    //     params.filter.username = match.params.username;
    //     params.filter.visibility = "all";
    //     params.username = match.params.username;
    //     params.limit.order = false;
    //     mediaAction.getReference(tab, params).then(res => {
    //         console.log(res);
    //         // setMedia(old => [...old, ...res]);
    //     });
    // };

    const videoElement2 = <VideoElement></VideoElement>;
    const readyToPlay = (item) => {

    }

    // const item = media.find(item => item.id === match.params.media_id);
    return <>
        {!loading && getTab(tab) === mediaType ? (media && media.length > 0 ?
            <Swiper
                modules={[Virtual, Mousewheel]}
                virtual={{enabled: true, cache: true, addSlidesAfter: 3, addSlidesBefore: 3}}
                slidesPerView={1}
                navigation={true}
                initialSlide={media.findIndex(item => item.id === match.params.media_id)}
                direction={'vertical'}
                scrollbar={{draggable: true}}
                className="jf-dashbord"
                onSwiper={(e) => swiper = e}
                mousewheel={true}
                onActiveIndexChange={(swiper) => {
                    // setMoveIndex(e.realIndex);
                    // setRestart(true);
                    setActiveIndex(swiper.activeIndex);
                }}
                // onToEdge={loadMore}
            >
                {media.map((item, index) => {
                        const info = JSON.parse(item.info).find(find => !!find.width);
                        const className = info.width > info.height ? 'landscape' : 'portrait';
                        return item.type === 'video' ?
                            <SwiperSlide
                                virtualIndex={index}
                                className={className}
                                key={tab + '-swiper-loop-video-' + item.id + (index === 2 && media[0].id === item.id ? '-' + index : '')}>
                                {/*<VideoPlayer*/}
                                {/*  options={{ loop: true }}*/}
                                {/*  sources={[item.source]}*/}
                                {/*  restart={restart}*/}
                                {/*  setRestart={setRestart}*/}
                                {/*  togglePlay={() => togglePlay(index)}*/}
                                {/*  play={activeIndex !== -1 && index !== 2 && match.params.media_id === item.id}*/}
                                {/*  afterInit={(play) => {*/}
                                {/*  }}/>*/}
                                {/*<VideoPlayer3 key={item.id} item={item} index={index} active={activeIndex}*/}
                                {/*              play={activeIndex !== -1 && index !== 2 && match.params.media_id === item.id}></VideoPlayer3>*/}
                                <VideoPlayer3 key={item.id} item={item} index={index} active={activeIndex}
                                              readyToPlay={readyToPlay}
                                              element={videoElement2}></VideoPlayer3>
                            </SwiperSlide> :
                            <SwiperSlide
                                virtualIndex={index}
                                key={tab + '-swiper-loop-photo-' + item.id + (index === 2 && media[0].id === item.id ? '-' + index : '')}
                                className={className}>
                                <img className={'jf-modal_img'} src={item.source.url} alt={''}/>
                            </SwiperSlide>
                    }
                )}
            </Swiper> :
            <div className="jf-dashbord empty"/>) : <div className="jf-dashbord empty"/>
        }
        {swiper && media[swiper.realIndex] &&
            <MediaDetail swiper={swiper} medias={media}
                         item={media[swiper.realIndex]} type={media[swiper.realIndex]?.type}/>}
    </>;
}

export default SwiperLoop;
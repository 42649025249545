import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import SVGIcon from "../../../../components/svg";
import Queries from '../../../../params/queries'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import SearchTextField from '../../../../components/basic/search-text-field'
import SongItem from '../../../../components/complex/song-item'
import Button from '../../../../components/basic/button'
import { studioRecordSoundAtom } from '../../../../state/pages/studio'
import {useRecoilState, useResetRecoilState} from 'recoil';
import { setSeparateState } from '../../../../helpers/utils'

const Sound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [{soundSelect, playId, soundList, query}, setSound] = useRecoilState(studioRecordSoundAtom)
  const resetSound = useResetRecoilState(studioRecordSoundAtom)
  const setSoundSelect = setSeparateState(setSound, "soundSelect")
  const setPlayId = setSeparateState(setSound, "playId")
  const setSoundList = setSeparateState(setSound, "soundList")
  const setQuery = setSeparateState(setSound, "query")
  const fetchAction = useFetchApiAction();
  const resetStateAction = useResetStateWhenChangeUrlAction()

  useEffect(() => {
    setSoundSelect(location.state?.audio?.id);
    resetStateAction.add(resetSound)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isCanceled = false;
    const variables = {limit: {limit: 5},filter: {query: query}}
    fetchAction(Queries.Sounds, variables).then(res => {
      if (!res || isCanceled) return;
      setSoundList(res.sounds || []);
    });
    return () => isCanceled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const onSoundSelect = (item) => {
    setSoundSelect(item?.id || null)
    navigate('/studio/record', {state: {audio: item}})
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={"Sound"}
        action={() => navigate(-1)}
      />
      <div className="jf-basic-page no-padding jf-sound-content">
        <SearchTextField onChange={setQuery} placeholder={'Sound Search'}/>
        <ul className="jf-soundlist">
          {soundList && soundList.map((item, index) =>
            <li key={"jf-sound-item-" + item.id} className={`jf-soundlist-li ${soundSelect === item.id ? "selected" : ""}`}>
              <div className="jf-soundlist-li-a">
                <SongItem song={item.url} name={item.name}
                          play={playId === item.id}
                          togglePlay={() => setPlayId(old => old === item.id ? -1 : item.id)}/>
                <div className="jf-soundSelect">
                  <div className="jf-selecticon jf-pointer" onClick={() => onSoundSelect(item)}>
                    <SVGIcon name="check-mark-round-icon"/>
                  </div>
                </div>
              </div>
            </li>
          )}
        </ul>
        <Button className={'white-btn'} onClick={() => onSoundSelect(null) }>Remove Sound</Button>
      </div>
      <BottomMenu/>
      </>

  );
}
export default Sound;
import { atom } from 'recoil'

export const userProfileAtom = atom({
  key: 'userProfile',
  default: {
    loading: false,
    loadingMore: false,
    activeTab: "video",
    page: 0,
    media: {
      videos: [],
      shop: [],
      photos: [],
    },
  }
});
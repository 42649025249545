import React, { useEffect } from 'react'
import TopMenu from '../../../components/basic/top-menu'
import ThumbnailUploader from '../../../components/complex/thumbnail-uploader'
import { TextField } from '../../../components/basic/text-field'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TextClipboard from '../../../components/basic/text-clipboard'
import { useUserAction } from '../../../action/user-action'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { authProfileAtom } from '../../../state/auth'
import { fetchUploadAvatar } from '../../../helpers/fetch-graphql'
import ItemMenu from '../../../components/basic/item-menu'
import TextFieldValidation from '../../../components/basic/text-field-validation'
import Constants from '../../../params/constants'
import { getStorageUrl, setSeparateState } from '../../../helpers/utils'
import BottomMenu from '../../../components/basic/bottom-menu'
import { editProfileAtom } from '../../../state/pages/edit-profile'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import { useNavigate } from 'react-router-dom'

const schema = yup.object({
  fullname: yup.string().required().label('Name'),
  username: yup.string().required().label('Username').min(4).max(32)
    .matches(/^[A-Za-z0-9_.\-~]*$/, 'Username must only contain letters, numbers and symbols(._-~)'),
  email: yup.string().required().email().label('Email address'),
  phone: yup.string().label('Phone number'),
}).required();

const EditProfile = (props) => {
  const [{avatar}, setProfilePage] = useRecoilState(editProfileAtom);
  const setAvatar = setSeparateState(setProfilePage, "avatar")
  const resetProfilePage = useResetRecoilState(editProfileAtom);
  const resetStateAction = useResetStateWhenChangeUrlAction()

  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });
  const authProfile = useRecoilValue(authProfileAtom);
  const userAction = useUserAction();

  useEffect(() => {
    resetStateAction.run(resetProfilePage)
    userAction.getAuthProfile().then(({profile}) => {
      if (profile) {
        ['fullname', 'username', 'email', 'phone'].forEach(key => {
          setValue(key, profile[key])
        })
        if (profile.avatar !== '') {
          setAvatar({ url: getStorageUrl(profile.avatar, "profile") })
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    userAction.upProfile(data).then(res => {
      if (res.errors) {
        for (let k in res.errors) {
          setError(k, { type: 'server', message: res.errors[k] });
        }
      } else {
        navigate(-1);
      }
    });
  }

  const changeAvatar = (data) => {
    fetchUploadAvatar(data.file).then(res => {
      if (res.data.filename) {
        setAvatar({ url: getStorageUrl(res.data.filename, "profile") , data: data.data})
      }
    })
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Edit Profile'}
        action={() => navigate(-1)}
      />
      <div className={'jf-edit-profile-form'}>
        <form
          style={{ width: '100%' }}
        >
        <ThumbnailUploader
          image={avatar}
          setImage={changeAvatar}
          type={'round'}
          autoLoad={true}
        />
        <div className={'jf-edit-profile-form-note'}>Change photo</div>
          <TextField
            label={'Name'}
            error={errors.fullname?.message}
            register={register("fullname")}
          />
          <TextFieldValidation
            label={'Username'}
            error={errors.username?.message}
            name={'username'}
            setError={setError}
            register={(onCheck)=> register("username", {onBlur: onCheck })}
          />
          <TextClipboard text={authProfile && (Constants.Site.URL + '/' + authProfile.username)}/>
          <TextField
            label={'Email Address'}
            error={errors.email?.message}
            register={register("email")}
          />
          <TextField
            label={'Phone Number'}
            error={errors.phone?.message}
            register={register("phone")}
          />
        </form>
        <ItemMenu
          text={'Change Password'}
          icon={'greater-icon'}
          path={'/profile/edit/password'}
          className={'none-border'}
        />
      </div>
      <button className="jf-btn jf-basic-btn jf-save-btn" style={{height: 70}}
              onClick={handleSubmit(onSubmit)}>Save</button>
      <BottomMenu/>
    </>
  );
}

export default EditProfile;

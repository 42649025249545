import SVGIcon from "../../svg/index";
import React, {useEffect, useState} from "react";
import Button from "../../basic/button";
import {isiOS} from "../../../helpers/utils";
import CloseIcon from "../../svg/general-icon/close-icon";

const InstallAppPopup = ({hide}) => {

    const [install, setInstall] = useState(false);
    const [ios, setiOS] = useState(false);

    useEffect(() => {
        setiOS(isiOS());
        localStorage.setItem("installAppPopup", new Date().getTime() + '');
    }, [])

    const onAccept = async () => {
        setInstall(true);
        hide();
        let deferredPrompt = window['deferredPrompt'];
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const {outcome} = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                deferredPrompt = null;
            }
        }
    }

    const onDecline = () => {
        setInstall(false)
        hide();
    }

    const notiOSElement = <div className="jf-bottom-popup-cookie-policy">
        <SVGIcon name="junefox-icon2"/>
        <div className="jf-bottom-popup-cookie-policy-container">
            <div className="jf-bottom-popup-header">Install app</div>
            <div className="jf-bottom-popup-body">
                For the best experience please install JuneFox on your phone.
            </div>
        </div>
        <div className="jf-bottom-popup-cookie-actions">
            <Button className={'medium cookie-decline'} onClick={onDecline}>
                Decline
            </Button>
            <Button className={'medium'} onClick={onAccept}>
                Accept
            </Button>
        </div>
    </div>;

    const iosElement = <div className="jf-bottom-popup-ios-install-app">
        <div className="jf-bottom-popup-cookie-policy-container">
            <div className="jf-bottom-popup-header-close-btn">
                <div onClick={hide}><CloseIcon width={18} fill="#8a88fb"/></div>
            </div>
            <SVGIcon name="junefox-icon2"/>
            <div style={{"height": "10px"}}></div>
            <div className="jf-bottom-popup-header">Install JuneFox</div>
            <div className="jf-bottom-popup-body">
                <div>It's easy, just tap <SVGIcon name="share-icon2"/> and then 'Add to home'</div>
            </div>
        </div>
    </div>

    return ios ? iosElement : notiOSElement;
};

export default InstallAppPopup;

import React from 'react';
const Likeicon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  fillBorder = 'fff',
  width = '55',
  className = '',
  viewBox = '0 0 55 55'
}) => (
  <>
    <svg id="favorite-24px" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="Path_41" data-name="Path 41" d="M0,0H55V55H0Z" fill={fillNone} />
      <path id="Path_44" data-name="Path 44" d="M44.893,25.929A30.079,30.079,0,0,0,48,20.117C51.725,10.559,44.531,0,34.845,0A13.844,13.844,0,0,0,24.564,4.657a13.656,13.656,0,0,0-15.6-3.564A15.257,15.257,0,0,0,0,15.168c0,12.077,14.368,21.272,21.605,28.65a4.063,4.063,0,0,0,5.726,0C32.781,38.3,40.146,32.875,44.893,25.929Z" transform="translate(3 5.003)" fill={fill} />
    </svg>
  </>
);
export default Likeicon;
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import React, {useEffect, useRef} from 'react'
import { formatBytes, setSeparateState } from '../../../../helpers/utils'
import ProgressBar from "@ramonak/react-progress-bar";
import variables from '../../../../scss/variables.module.scss'
import Button from '../../../../components/basic/button'
import { fetchUploadMedia } from '../../../../helpers/fetch-graphql'
import { studioUploadProgressAtom } from '../../../../state/pages/studio'
import {useRecoilState, useResetRecoilState} from 'recoil';
import { useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import { useLocation, useNavigate } from 'react-router-dom'

const StudioUploadProgress = ({}) => {
  const navigate =useNavigate()
  const location =useLocation()
  const cancelFileUpload = useRef(null);
  const [{completed,done}, setProcess] = useRecoilState(studioUploadProgressAtom);
  const resetProcess = useResetRecoilState(studioUploadProgressAtom);
  const setCompleted = setSeparateState(setProcess,"completed");
  const setDone = setSeparateState(setProcess,"done");
  const uploadAction = fetchUploadMedia(setCompleted, cancelFileUpload)
  const resetStateAction = useResetStateWhenChangeUrlAction()

  useEffect(() => {
    resetStateAction.add(resetProcess)

    if (!location.state?.data || location.state.updated) {
      navigate("/studio/upload")
    } else {
      uploadAction(location.state.data).then(res => {
        if (res && !res.data.error) {
          setDone(true)
        }
        navigate(location.pathname, {state: {...location.state, updated: true}, replace: true})
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const files = location.state?.data?.map(item => (
    <li key={'jf-progress-file-' + item.file.name} className={'jf-studio-upload-file'}>
      <div className={'jf-file-size'}>{formatBytes(item.file.size)}</div>{item.file.name}
    </li>
  ))
  const total = location.state?.data?.reduce((partial_sum, a) => partial_sum + a.file.size, 0)

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={"Progress"}
        action={done ? () =>  navigate('/studio/upload') : () =>navigate(-1)}
      />
      <div className={'jf-basic-page jf-studio-upload-page'}>
        <aside className={'jf-info'}>
          <ul>{files}</ul>
          {files?.length > 0 && <>
            <div style={{float: 'right'}}>{formatBytes(total)}</div>
            <div>{files.length} file{files.length > 1 ? 's' : ''}</div>
          </>}
        </aside>
        <ProgressBar
          bgColor={variables['colors-jf-primary']}
          isLabelVisible={false}
          height={'10px'}
          completed={completed}
        />
        <div className={'jf-btn-group'}>
          {!done ? <Button className={'save-btn medium'} onClick={cancelUpload}>Cancel</Button>:
            <Button type={'link'} className={'save-btn medium'} to={'/account/media'}>Done</Button>}
          {done && <><div>Or</div>
          <Button type={'link'} className={'outline-btn'}
                  to={'/studio/upload'}>Upload more files</Button></>}
        </div>
      </div>
      <BottomMenu/>
    </>
  );
}

export default StudioUploadProgress;
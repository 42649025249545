import React from 'react'
import PopoverMenu from '../complex/popover-menu'
import DatePicker from 'sassy-datepicker'

export const DatePickerField = ({ value, setValue, width, error, placeholder, onChange, right,...props }) => {
  const gfg_Run = (today) => {
    if (!today) return ""
    return today.getMonth() + 1 + '/'
      + today.getDate() + '/'
      + today.getFullYear();
  }
  return <PopoverMenu style={{ width: width || 'auto' }}
                      menu={close => <DatePicker onChange={(v) => {
                        setValue(v)
                        close()
                      }} selected={value}/>}>
    <input type="text" className="jf-input" placeholder={placeholder}
           value={gfg_Run(value)} readOnly={true} {...props}/>
    {value && <span style={{ position: 'absolute', zIndex: 1, top: '55%', right: 35, cursor: 'pointer' }}
                    onClick={() => {
                      setValue(undefined)
                    }}>x</span>}
  </PopoverMenu>
}
import React from 'react';
const SoundPlayIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '30',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg id="play" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_1224" data-name="Rectangle 1224" width={width} height="30" fill={fillNone} />
      <circle id="Ellipse_139" data-name="Ellipse 139" cx="12" cy="12" r="12" transform="translate(3 3)" fill="#e6e6e6" />
      <path id="Path_6303" data-name="Path 6303" d="M11.158,15.873l3.992-3.482a.5.5,0,0,0,.048-.705.486.486,0,0,0-.047-.047l-3.993-3.5a.5.5,0,0,0-.83.376V15.5a.5.5,0,0,0,.829.377Z" transform="translate(3.261 3.001)" fill={fill} fillRule="evenodd" />
    </svg>
  </>
);
export default SoundPlayIcon;
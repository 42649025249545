import React from 'react';
const UserIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '28',
  className = '',
  viewBox = '0 0 28 28'
}) => (
  <>
    <svg id="user" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="user-2" data-name="user" transform="translate(-50 -306)">
        <rect id="Rectangle_1033" data-name="Rectangle 1033" width="28" height="28" transform="translate(50 306)" fill="rgba(255,255,255,0)" />
        <g id="Group_10" data-name="Group 10" transform="translate(55.6 310.667)">
          <g id="Ellipse_9" data-name="Ellipse 9" transform="translate(3.266)" fill="rgba(255,255,255,0)" stroke="#fff" strokeWidth="2">
            <circle cx="5.133" cy="5.133" r="5.133" stroke="none" />
            <circle cx="5.133" cy="5.133" r="4.133" fill="none" />
          </g>
          <path id="Path_28" data-name="Path 28" d="M2953,330.9s7.613-13.86,16.8,0" transform="translate(-2953.001 -311.398)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
        </g>
      </g>
    </svg>


  </>
);
export default UserIcon;
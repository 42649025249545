import React, { useCallback, useEffect } from 'react'
import MediaTabs from '../../../components/complex/media-tabs'
import { useMediaAction } from '../../../action/media-action'
import SearchTextField from '../../../components/basic/search-text-field'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getTab, setSeparateState, uniqueList } from '../../../helpers/utils'
import { searchFavoriteAtom } from '../../../state/pages/search'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { useResetStateWhenChangeUrlAction } from '../../../action/main-action'

const Favorites = (props) => {
  const [{mediaLiked, mediaPurchase, query, loading, loadingMore}, setSearchFavorite] = useRecoilState(searchFavoriteAtom);
  const resetSearchFavorite = useResetRecoilState(searchFavoriteAtom);
  const setMediaLiked = setSeparateState(setSearchFavorite, "mediaLiked");
  const setQuery = setSeparateState(setSearchFavorite, "query");
  const setLoading = setSeparateState(setSearchFavorite, "loading");
  const setLoadingMore = setSeparateState(setSearchFavorite, "loadingMore");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const match = {params: useParams()};
  const navigate  = useNavigate()

  const mediaAction = useMediaAction(false);

  useEffect(() => {
    resetStateAction.add(resetSearchFavorite)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isCanceled = false;
    setLoading(true);
    if (match.params.tag === "liked") {
      const params = { limit: { order: false }, filter: { query } }
      mediaAction.getFavorites(match.params.subTab || 'video', params).then(res => {
        if (isCanceled) return;
        setMediaLiked((old) => {
          return { ...old, [getTab(match.params.subTab || 'video')]: res }
        });
        setLoading(false);
        setLoadingMore(res.length === 0 ? 2 : 0);
      })
      return () => {
        isCanceled = true;
      }
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.subTab, query, match.params.tag]);

  const onLoadMore = useCallback(() => {
    if (loadingMore > 0 || match.params.tag !== "liked") return;
    let isCanceled = false;
    setLoadingMore(1);
    const key =getTab(match.params.subTab || 'video');
    const params = {
      limit: { order: false, skip: mediaLiked[key].length },
      filter: { query }
    }
    mediaAction.getFavorites(match.params.subTab || 'video', params).then(res => {
      if (isCanceled) return;
      setMediaLiked((old) => {
        return {...old, [key]: uniqueList(old[key].concat(res))}
      });
      setLoadingMore(res.length === 0 ? 2 : 0);
    })
    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMore, query])

  return <>
    <SearchTextField onChange={setQuery}/>
    <div className="jf-filters-list">
      <Link to={'/s/favorites/' + (match.params.subTab || "video") + '/purchased'}
            className={"jf-filtersBox " +
            (match.params.tag === "purchased" ? "jf-active" :"")}>Purchased</Link>
      <Link to={'/s/favorites/' + (match.params.subTab || "video") + '/liked'}
            className={"jf-filtersBox " +
            (match.params.tag === "liked" ? "jf-active" :"")}>Liked</Link>
    </div>
    <MediaTabs
      onLoadMore={onLoadMore}
      href={(tab) => navigate('/s/favorites/' + tab + "/" + match.params.tag)}
      navigate={navigate}
      classContain={'jf-videos'}
      activeTab={match.params.subTab || 'video'}
      media={match.params.tag === "liked" ? mediaLiked : mediaPurchase}
      loading={loading}
      loadMore={loadingMore < 2}
    />
  </>
}

export default Favorites;
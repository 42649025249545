import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import React, {useEffect} from 'react'
import { TextField } from '../../../../components/basic/text-field'
import { DatePickerField } from '../../../../components/basic/date-picker-field'
import Button from '../../../../components/basic/button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputTags from '../../../../components/basic/input-tags'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { suggestionTagsAtom } from '../../../../state'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import Queries from '../../../../params/queries'
import SelectTab from '../../../../components/basic/select-tab'
import { studioRecordPreviewFormAtom } from '../../../../state/pages/studio'
import { setSeparateState } from '../../../../helpers/utils'
import { useLocation, useNavigate } from 'react-router-dom'

const schema = yup.object({
  description: yup.string().required().min(3).max(256).label('Description'),
  tags: yup.array().min(1).label('Tags').of(yup.string().min(2).max(20)
    .matches(/^[A-Za-z0-9\-\s~]*$/, ' ')),
  available_date: yup.date().label('Release'),
}).required();

const StudioRecordPreview = () => {
  const [{availableDate, tabRelease, tab }, setPreview] = useRecoilState(studioRecordPreviewFormAtom);
  const setTab = setSeparateState(setPreview, "tab");
  const setTabRelease = setSeparateState(setPreview, "tabRelease");
  const setAvailableDate = setSeparateState(setPreview, "availableDate");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetPreview = useResetRecoilState(studioRecordPreviewFormAtom);

  const setSuggestions = useSetRecoilState(suggestionTagsAtom)
  const fetchAction = useFetchApiAction();
  const navigate = useNavigate()
  const location = useLocation()

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  useEffect(() => {
    resetStateAction.add(resetPreview)
    if (!location.state?.record) {
      navigate(-1)
    } else {
      const variables = { limit: { limit: 100 } }
      setValue('tags', [])
      fetchAction(Queries.Tags, variables).then(res => {
        setSuggestions(res.tags.map(tag => ({ name: tag.tags })))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    const params = {
      delay: location.state?.record.delay || 0,
      sound: location.state?.record.sound || "",
      record: location.state?.record.record,
      date: location.state?.record.date,
      description: data.description,
      visibility: 'free',
      available_date: data.available_date,
      tags: data.tags || [],
      private: tab === "private",
    }

    if (tab === "Subscribers") {
      params.visibility = "subscription";
    }
    if (tabRelease !== "Scheduled" || params.private) {
      params.available_date = new Date()
    }
    navigate("/studio/record/progress", {state: {data: params}})
  }

  return (
      <>
        <TopMenu
          icon={'greater-icon'}
          title={"Preview"}
          action={() => navigate(-1)}
        />
        <div className={'jf-basic-page no-padding jf-studio-upload'}>
          <form
            className={"jf-editform jf-mt-20px"}
          >
            <TextField
              label={'Description'}
              error={errors.description?.message}
              register={register("description")}
            />
            <div className={'jf-text-field has-label ' + (errors.tags ? 'error' : '')} style={{ width: '100%' }}>
              <div className="jf-text-field-label">Tags</div>
              <InputTags
                setValue={(v) => {
                  setValue('tags', v)
                }}
                onBlur={() => {}}>
                {errors.tags?.message && <div className={'jf-text-field-msg error'}>{errors.tags?.message}</div>}
              </InputTags>
            </div>
            <div className="jf-inputbox">
              <div className="jf-inputbox-title">Viewable by</div>
              <SelectTab
                style={{height: 60, width: '100%'}}
                liClassName={'jf-font-r'}
                tabs={[
                  { id: "everyone", name: "Everyone" },
                  { id: "Subscribers", name: "Subscribers" },
                  { id: "private", name: "Private" },
                ]}
                tab={{id: tab}}
                setTab={(tab) => {
                  setTab(tab.id)
                }}
              />
              <div className={"jf-Subscribers" + (tab === 'private' ? " jf-hide" : "")}>
                <div className="jf-inputbox-title">Release</div>
                <SelectTab
                  style={{height: 60, width: '100%'}}
                  liClassName={'jf-font-r'}
                  tabs={[
                    { id: "Now", name: "Now" },
                    { id: "Scheduled", name: "Scheduled" },
                  ]}
                  tab={{id: tabRelease}}
                  setTab={(tab) => {
                    setTabRelease(tab.id)
                  }}
                />
                {
                  tabRelease === 'Scheduled' && (
                    <div className="jf-scheduled-content">
                      <div className="jf-inputbox">
                        <div className="jf-inputbox-title">Scheduled release</div>
                        <DatePickerField
                          value={availableDate}
                          width={'calc(100% - 40px)'}
                          setValue={(v) => {
                            setValue('available_date', v)
                            setAvailableDate(v)
                          }}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className={'jf-m-auto'}>
              <Button className={'save-btn'} typeHtml={'button'} onClick={handleSubmit(onSubmit)}>Upload</Button>
              <Button className={'outline-btn'} typeHtml={'button'}
                      onClick={() => navigate(-1)}>Back</Button>
            </div>
          </form>
        </div>
        <BottomMenu/>
      </>
  );
}

export default StudioRecordPreview;
import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import SVGIcon from '../svg/index';
import {authProfileAtom} from '../../state/auth'
import {useRecoilValue} from 'recoil';
import Avatar from './avatar'
import variables from '../../scss/variables.module.scss'

const BottomMenu = React.memo(({activeTo, activeIndex, type = '2', hide = false}) => {
    const auth = useRecoilValue(authProfileAtom);

    const [hideMenu, setHideMenu] = useState(hide ? true : false);

    const list = [
        {to: '/', icon: 'home-icon' + type},
        {to: '/s', icon: 'search-icon' + type},
        {to: '/studio', icon: 'add-icon' + type},
        {to: '/messages/inbox', icon: 'comment-icon' + type},
        {to: '/account', icon: 'user-icon' + type},
    ];
    if (activeTo !== undefined && activeTo !== null) {
        list[activeIndex].to = activeTo;
    }

    function toggleMenu() {
        setHideMenu(v => !v);
    }

    const menuElement =
        <>
            {hide && <li className={"jf-bottom-menu-ul-li"}
                         key={'bottom-menu-0'} onClick={toggleMenu}>
                <SVGIcon name={'close-icon'} className="logo" width={'30'}
                         fill={'#ffffff'}/>
            </li>}
            {
                list
                    .filter((item, i) => {
                        if (i == 0 && hide) {
                            return false;
                        }
                        return true;
                    })
                    .map((item, i) => <li className={"jf-bottom-menu-ul-li"}
                                          key={'bottom-menu-' + i}>
                            <NavLink exact={"true"} to={'user-icon' + type && !auth ? '/menu' : item.to}
                                     className={({isActive}) => "jf-back jf-bottom-menu-ul-li-a" + (isActive ? "jf-active" : "")}>
                                {item.icon === 'user-icon' + type && auth ?
                                    <Avatar width={35} height={35} image={auth.avatar} text={auth.fullname}/> :
                                    <SVGIcon name={item.icon} className="logo"
                                             fill={type === '2' ? variables['colors-jf-primary'] : variables['jf-white']}/>}
                            </NavLink>
                        </li>
                    )
            }
        </>;

    const burgerMenuElement = <li className={"jf-bottom-menu-ul-li"}
                                  key={'bottom-menu-0'} onClick={toggleMenu}>
        <SVGIcon name={'burger-menu-icon'} className="logo" width={'40'}
                 fill={type === '2' ? variables['colors-jf-primary'] : variables['jf-white']}/>
    </li>;

    return (<div className={"jf-bottom-menu type" + type + (hideMenu ? ' hide' : '')}>
        <ul className="jf-bottom-menu-ul">
            {hideMenu ? burgerMenuElement : menuElement}
        </ul>
    </div>);
});

export default BottomMenu;
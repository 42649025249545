class Timer {
  constructor(callback, delay, timeListen) {
    this.callback = callback;
    this.remainingTime = delay;
    this.startTime = 0;
    this.timerId = 0;
    this.timeListen = timeListen;

  }

  pause() {
    clearTimeout(this.timerId);
    this.remainingTime -= Date.now() - this.startTime;
    this.timeListen.pause && this.timeListen.pause()

  }

  resume() {
    this.startTime = Date.now();
    clearTimeout(this.timerId);
    this.timerId = setTimeout(this.callback, this.remainingTime)
    this.timeListen.resume && this.timeListen.resume()
  }

  start() {
    this.timerId = setTimeout(this.callback, this.remainingTime);
    this.startTime = Date.now();
    this.timeListen.start && this.timeListen.start()
  }
}

class CamAudioMixRecorder {


  constructor(video, audio, audioId, duration, timeListen) {

    this.video = video;
    this.audio = audio;

    this.audioId = audioId;
    this.duration = duration;

    this.stream = null;

    this.delay = 0;

    this.mediaRecorder = null;

    this.audioStart = 0;
    this.recordStart = 0;

    this.blobsRecorded = [];

    this.timer = new Timer(() => {
      console.log("time finished");
      this.stopRecording();
    }, this.duration * 1000, timeListen);

    audioId && this.audio.addEventListener('play', (ev) => {
      this.audioStart = Date.now();
      this.startMediaRecording();
    });
  }

  async checkMediaAccess() {
    const devices = await this.getDevices();
    const access = {
      video: [],
      audio: []
    };
    for (const device of devices) {
      if (device.kind === 'audioinput' && device.label) {
        access.audio.push(device);
      }
      if (device.kind === 'videoinput' && device.label) {
        access.video.push(device);
      }
    }
    return access;
  }

  async getDevices() {
    return await navigator.mediaDevices.enumerateDevices();
  }

  async getUserMedia(facingMode, audioDeviceId) {
    const constrains = {
      audio: audioDeviceId ? {deviceId: audioDeviceId} : true,
      video: facingMode ? {
        width: {min: 640, ideal: 1280},
        height: {min: 320, ideal: 720},
        facingMode: facingMode
      } : true
    };

    this.stream = await navigator.mediaDevices.getUserMedia(constrains);

    return this.stream;
  }

  async loadAudioSource(src, type) {
    if (this.audio && this.audioId) {
      const source = document.createElement("source");
      source.setAttribute("src", src);
      source.setAttribute("type", type);
      this.audio.append(source);

      await this.audioReady();
    }
  }

  async clearAudioSources() {
    if (this.audio) {
      while (this.audio.firstChild) {
        this.audio.removeChild(this.audio.firstChild);
      }
    }
  }

  async audioReady() {
    if (!this.audio) {
      return;
    }
    this.audio.load();

    return new Promise((resolve, reject) => {
      this.audio.addEventListener('loadeddata', (ev) => {
        resolve(ev);
      });
    });
  }

  startMediaRecording() {

    if (!this.mediaRecorder || this.mediaRecorder.state === 'inactive') {

      if (!this.stream) {
        throw new Error("Make sure you have granted permission to your camera");
      }

      this.timer.start();

      // set MIME type of recording as video/webm
      this.mediaRecorder = new MediaRecorder(this.stream);

      this.mediaRecorder.addEventListener('start', () => {
        this.recordStart = Date.now();
        this.delay += this.recordStart - this.audioStart;
      });

      this.mediaRecorder.addEventListener('resume', () => {
        this.recordStart = Date.now();
        this.delay += this.recordStart - this.audioStart;
      });


      // event : new recorded video blob available
      this.mediaRecorder.addEventListener('dataavailable', (e) => {
        this.blobsRecorded.push(e.data);
      });

      // event : recording stopped & all blobs sent
      this.mediaRecorder.addEventListener('stop', () => {
        this.timer.timeListen.stop && this.timer.timeListen.stop();
      });

      // start recording with each recorded blob having 1 second video
      this.mediaRecorder.start(1000);
    } else {
      this.timer.resume();
      this.mediaRecorder.resume();
    }
  }

  startRecording(stream) {
    if (this.audio && this.audioId) {
      const promise = this.audio.play();
      if (promise !== undefined) {
        promise.then(_ => {
        }).catch(error => {
        });
      }
    } else {
      this.startMediaRecording();
    }
    this.video.volume = 0;
  }

  pauseRecording() {
    this.timer.pause();
    if (this.audio) {
      this.audio.pause();
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.pause();
    }
  }

  stopRecording() {
    this.timer.pause();
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  saveRecord() {
    const blob = new Blob(this.blobsRecorded, {type: this.mediaRecorder.mimeType});

    return {
      delay: this.delay || 0,
      sound: this.audioId || "",
      record: blob,
      date: new Date()
    }
  }


}

export default CamAudioMixRecorder;


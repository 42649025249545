import { Link } from 'react-router-dom'
import SVGIcon from "../svg";
import React from "react";

const Logo = ({className}) => {
  return <Link to={'/'} className={'jf-logo ' + className}>
    <SVGIcon name="junefox-icon2"/>
  </Link>
}

export default Logo;
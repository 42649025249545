import React, {useEffect, useRef, useState} from 'react'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {
    // activeMediaAtom,
    mediaDashboardAtom, updatingAtom,
} from '../../state/media'
import {Swiper, SwiperSlide} from 'swiper/react';
import MediaDetail from './detail'
import {profileAtom, usernameAtom} from '../../state/users'
import {Mousewheel, Virtual} from 'swiper';
// import {Mousewheel, Virtual} from 'swiper/modules';
import {getTab, isiOS, setSeparateState} from '../../helpers/utils'
import {useMediaAction} from '../../action/media-action'
import {useSubscriptionLiveUserAction} from '../../action/main-action'
import {locationAtom, swiperMobileIndexAtom} from '../../state'
import {authIdAtom} from '../../state/auth'
import {swiperMediaDashboardAtom} from '../../state/components/swipper'
import VideoPlayer3 from "./video-player3";
import VideoElement from "./video-element";
import {activeBottomPopupState} from "../../state/components/popup";
import isMobile from "is-mobile";
import {useUserAction} from "../../action/user-action";

// SwiperCore.use([Mousewheel, Virtual]);
let swiper = null
const SwiperMediaDashboard = ({tab}) => {
    const setUsername = useSetRecoilState(usernameAtom);
    const [updating, setUpdating] = useRecoilState(updatingAtom);
    const [medias, setMedias] = useRecoilState(mediaDashboardAtom);
    const profile = useRecoilValue(profileAtom);

    const [{
        loading, filter, // moveIndex,

    }, setSwiperMediaDashboard] = useRecoilState(swiperMediaDashboardAtom);
    const setLoading = setSeparateState(setSwiperMediaDashboard, "loading")
    const setFilter = setSeparateState(setSwiperMediaDashboard, "filter")
    // const setActiveIndex = setSeparateState(setSwiperMediaDashboard, "activeIndex");
    const [activeIndex, setActiveIndex] = useState(0);
    const setRestart = setSeparateState(setSwiperMediaDashboard, "restart")

    const mediaAction = useMediaAction(false);
    const userAction = useUserAction(false);
    const subscriptionLiveUserAction = useSubscriptionLiveUserAction(true);
    const locationState = useRecoilValue(locationAtom);
    const swiperMobileIndex = useRecoilValue(swiperMobileIndexAtom);
    const authId = useRecoilValue(authIdAtom);
    const limitAmount = 20;
    const [activePopup, setActivePopup] = useRecoilState(activeBottomPopupState());


    const iosVideoRef = useRef();

    async function onClick() {
        try {
            const videoElem = iosVideoRef.current;
            videoElem.muted = false;
            if (videoElem.paused) {
                await videoElem.play();
            } else {
                videoElem.pause();
            }
        } catch (e) {
            console.error(e);
        }
    }

    const videoElement = <video ref={iosVideoRef} playsInline={true} controls={false} preload="auto" loop={true}
                                onClick={onClick}></video>;

    // useEffect(() => {
    //     // console.log("init swiper");
    //     // videoElement2.props.cocos="1111";
    //     // console.log("props:", videoElement2.props);
    //     // videoElement.autoplay = true;
    //     // videoElement.controls = false;
    //     // videoElement.playsInline = true;
    //     // videoElement.preload = 'auto';
    //     // videoElement.loop = true;
    // }, []);

    useEffect(() => {
        const newFilter = {visibility: "all"}
        if (["account.profile.medias", "account.profile"].indexOf(locationState?.pagename) > -1) {
            newFilter.user = authId;
        }
        if (locationState.params?.username) {
            newFilter.username = locationState.params.username;
        }
        if (["dashboard.medias.tags"].indexOf(locationState?.pagename) > -1) {
            newFilter.tag = locationState.params.tag;
        }
        if (JSON.stringify(filter) !== JSON.stringify(newFilter)) {
            setFilter(newFilter)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationState, authId, filter]);

    useEffect(() => {
        console.log("load medias: ", tab);
        if (filter === null) return;
        setUsername(null);
        setMedias([]);
        setLoading(true);
        let isCancelled = false;
        const params = {filter: {...filter, rand: true}, limit: {limit: limitAmount}, withLike: true};
        params.limit.order = false;
        if (tab === 'lives') {
            userAction.lives({limit: {limit: limitAmount}}).then(res=>{
                console.log(res);
            });
        } else {
            mediaAction.getMedias(tab, params).then(res => {
                if (!res || isCancelled) return;
                setUpdating(true)
                setMedias(res);
                setLoading(false)
                setUsername(res[0]?.user.username);
            })
        }
        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, filter]);

    useEffect(() => {
        if (!updating) {
            setActiveIndex(0);
        } else {
            setUpdating(false)
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medias])

    useEffect(() => {
        if (!profile) return;
        setUpdating(true);
        setMedias([...medias.map(item => {
            if (item.user.id.trim() === profile.id.trim()) {
                return {...item, user: {...item.user, live: profile.live}};
            }
            return item
        })])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    // useEffect(() => {
    //     setActiveIndex(moveIndex);
    //     setRestart(true);
    //     if (medias[moveIndex]) {
    //         setUsername(medias[moveIndex].user.username);
    //         subscriptionLiveUserAction(medias[moveIndex].user.id).then()
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [moveIndex])

    useEffect(() => {
        // setActiveIndex(activeIndex);
        // setRestart(true);
        if (medias[activeIndex]) {
            // setUsername(medias[activeIndex].user.username);
            subscriptionLiveUserAction(medias[activeIndex].user.id).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    const loadMore = (e) => {
        if (!e.isEnd) return;
        const params = {
            filter: {...filter, rand: true}, limit: {limit: limitAmount, skip: e.slides.length}, withLike: true
        };
        params.limit.order = false;

        mediaAction.getMedias(tab, params).then(res => {
            if (!res) return;
            setUpdating(true);
            setMedias(old => {
                // uniqueList([...old, ...res];
                return [...old, ...res];
            });
            e.updateSlides();
            setLoading(false);

        })
    }

    useEffect(() => {
        setRestart(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swiperMobileIndex])

    const readyToPlay = (item) => {

    }

    const mobile = isMobile();
    const ios = isiOS();

    return <>
        {!loading ? (medias && medias.length > 0 ? <Swiper
            modules={[Virtual, Mousewheel]}
            virtual={{cache: true, enabled: true, addSlidesBefore: 3, addSlidesAfter: 3}}
            slidesPerView={1}
            direction={'vertical'}
            scrollbar={{draggable: true}}
            className="jf-dashbord"
            onSwiper={(e) => swiper = e}
            navigation={true}
            mousewheel={true}
            onActiveIndexChange={swiper => {
                console.log('onActiveIndexChange', swiper.activeIndex);
                setActiveIndex(swiper.activeIndex);
                // console.log("active index: ", swiper.activeIndex);
                // const slides = swiper.slides.filter(s => {
                //     return s.className.includes("swiper-slide-next");
                // });
                // if (slides.length > 0) {
                //     const nextSlide = slides[0];
                //     const videoElem = nextSlide.querySelector("video");
                //     console.log("state: ", videoElem.readyState);
                //     if (videoElem && videoElem.readyState === 4) {
                //         swiper.allowSlideNext = true;
                //         swiper.enable();
                //         return;
                //     }
                // }
                // swiper.allowSlideNext = false;
                // swiper.disable();
                // setTimeout(() => {
                //     console.log("we allow swiping in 1s", slides);
                //     swiper.enable();
                //     swiper.allowSlideNext = true;
                //
                // }, 5000);
            }}
            onSlideChange={(swiper) => {
                console.log('onSlideChange', swiper.activeIndex);

                if (!mobile) {
                    return;
                }


                if (ios && window.navigator.standalone === true) {
                    return;
                }

                if (!ios && !window['deferredPrompt']) {
                    return;
                }
                const date = localStorage.getItem("installAppPopup");
                // if the popup was visible in the last 24h (86400 seconds)
                if (date && new Date().getTime() - parseInt(date) < 60 * 1000) {
                    return;
                }

                if (swiper.activeIndex % 5 === 0) {
                    setActivePopup({id: 'install_app', active: true, ios})
                }
                // setActiveIndex(swiper.activeIndex);
            }}
            // onSlideChangeTransitionEnd={swiper => {
            //     // console.log('onSlideChangeTransitionEnd', swiper.activeIndex);
            //     // setMoveIndex(swiper.realIndex);
            //     // setActiveIndex(swiper.activeIndex);
            //     // console.log("transition end", swiper.activeIndex);
            // }}
            onToEdge={loadMore}
        >
            {medias.map((item, index) => {
                const info = JSON.parse(item.info).find(find => !!find.width);
                const className = info.width > info.height ? 'landscape' : 'portrait';
                return item.type === 'video' ?
                    <SwiperSlide key={'swiper-media-video-' + item.id} className={className} virtualIndex={index}>
                        {/*<VideoPlayer3 key={item.id} item={item} index={index} active={activeIndex}*/}
                        {/*              play={swiperMobileIndex !== 1 && activeIndex !== -1 && medias[activeIndex] && medias[activeIndex].id === item.id}></VideoPlayer3>*/}
                        <VideoPlayer3 key={item.id} item={item} index={index} active={activeIndex}
                                      readyToPlay={readyToPlay}
                                      element={videoElement} ios={ios} mobile={mobile}
                                      iosVideoRef={iosVideoRef}></VideoPlayer3>
                    </SwiperSlide> :
                    <SwiperSlide key={'swiper-media-photo-' + item.id} className={className} virtualIndex={index}>
                        <img className={'jf-modal_img'} src={item.source.url} alt={''}/>
                    </SwiperSlide>
            })}
        </Swiper> : <div className="jf-dashbord empty">
            <div
                className={'jf-dashbord-message'}>{filter.username || filter.user ? "user has no media" : ("no more " + getTab(tab))}</div>
        </div>) : <div className="jf-dashbord empty"/>}
        {swiper && medias[swiper.realIndex] &&
            <MediaDetail item={medias[swiper.realIndex]} type={medias[swiper.realIndex]?.type} medias={medias}
                         swiper={swiper}/>}
    </>;
}

export default SwiperMediaDashboard;
import React, { Component } from "react";
import SVGIcon from '../svg/index'
import variables from '../../scss/variables.module.scss'
export default class SearchTextField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: props.value || '',
      prevValue: props.value || '',
      isFocus: false
    }
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(prop,state) {
    if (prop.value !== undefined && prop.value !== state.prevValue) {
      return {
        value: prop.value || '',
        prevValue: prop.value || ''
      }
    }
    return null;
  }

  changeInput = (event) => {
    const self = this;
    const value = event ? event.target.value : '';

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      value,
      typingTimeout: setTimeout(function () {
        const { onChange } = self.props;
        if (onChange) {
          onChange(value);
        }
      }, value === '' ? 0 : 500)
    });
  }

  render() {
    const {placeholder, classContainer} = this.props;
    return (
      <div className={"jf-search-text " + classContainer}>
        {(!this.state.isFocus && this.state.value === "") &&
        <div className={'jf-placeholder'}
             onClick={() => {
               this.inputRef.current.focus();
             }}>
          <SVGIcon name={'search-icon'} fill={variables['colors-jf-gray']} width={16} height={16}/>
          <div>{placeholder || 'Search'}</div>
        </div>}
        <input
          type="text"
          ref={this.inputRef}
          className="jf-search-text-input"
          value={this.state.value}
          onChange={this.changeInput}
          onFocus={() => {
            this.setState({isFocus: true})
            if (this.props.onFocus) {
              this.props.onFocus()
            }
          }}
          onKeyDown={this.props.onKeyDown}
          onBlur={() => {
            this.setState({isFocus: false})
            if (this.props.onBlur) {
              this.props.onBlur()
            }
          }}
        />
        {this.state.value &&
        <div className='jf-search-text-right jf-pointer' onClick={() => {
          this.changeInput(null)
          if (this.props.onKeyDown) {
            this.props.onKeyDown(null)
          }
        }}>
          <SVGIcon name='close-icon' height={20} width={20}/>
        </div>
        }
      </div>
    );
  }
}
import React from 'react';
const BackArrow = ({
  style = {},
  fill = '#615dfa',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '16',
  className = '',
  viewBox = '0 0 16 16'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="back_1_" data-name="back (1)" transform="translate(-2 -2)">
        <g id="Group_6" data-name="Group 6" transform="translate(2 2)">
          <path id="Path_1" data-name="Path 1" d="M16,7H3.83L9.415,1.415,8,0,0,8l8,8,1.415-1.415L3.83,9H16Z" fill={fill} />
        </g>
      </g>
    </svg>

  </>
);
export default BackArrow;
import {useEffect} from 'react'
import { useMediaAction } from '../../action/media-action'
import { useMatch, useNavigate } from 'react-router-dom'

const ShortLink = (props) => {
  const navigate = useNavigate()
  const match = useMatch('/short/:id');  
  const mediaAction = useMediaAction();

  useEffect(() => {
    mediaAction.getShortLink(match.params.id).then(res => {
      if (res) {
        navigate([
          "",
          res.media.user.username,
          res.media.type, res.media.id
        ].join("/"))
      } else {
        navigate('/')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null;
}

export default ShortLink
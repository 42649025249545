import { atom } from 'recoil';

export const followersAtom = atom({
  key: 'followers',
  default: [],
});

export const followingAtom = atom({
  key: 'following',
  default: [],
});

import React from "react";

const SignInIcon = ({
  style = {},
  fill = "#fff",
  fillOpacity = "0.3",
  fillNone = "none",
  width = "22",
  height = "22.005",
  className = "",
  viewBox = "0 0 22 22.005",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        id="send"
        d="M14.837,2h6.722A2.444,2.444,0,0,1,24,4.444V21.561A2.444,2.444,0,0,1,21.559,24H14.837a.611.611,0,0,1-.611-.611V22.171a.611.611,0,0,1,.611-.611h6.722V4.444H14.837a.611.611,0,0,1-.611-.611V2.611A.611.611,0,0,1,14.837,2Zm4.278,10.768v.464a.917.917,0,0,1-.269.648l-6.282,6.27a.611.611,0,0,1-.868,0l-.868-.856a.611.611,0,0,1,0-.868l4.216-4.2H2.614A.611.611,0,0,1,2,13.612V12.39a.611.611,0,0,1,.611-.611h12.43l-4.216-4.2a.6.6,0,0,1,0-.856l.868-.868a.611.611,0,0,1,.868,0l6.282,6.27a.941.941,0,0,1,.269.648Z"
        transform="translate(-2.003 -2)"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SignInIcon;

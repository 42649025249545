import React from 'react';
const SendFillIcon = ({
  style = {},
  fill = '#fff',
  width = '24',
  height = '24.023',
  viewBox = '0 0 24.023 24.023'
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="send-2" data-name="send" d="M23.594,12.952l-22.419,11a.678.678,0,0,1-.788-.143L.23,23.647a.758.758,0,0,1-.167-.767l2.6-7.532a2.8,2.8,0,0,1,2.513-1.914l6.188-.287a.711.711,0,0,0,.68-.706v-.778a.711.711,0,0,0-.68-.706l-6.188-.286A2.8,2.8,0,0,1,2.664,8.757L.063,1.226A.76.76,0,0,1,.191.417L.349.254A.679.679,0,0,1,1.177.069l22.418,11a.725.725,0,0,1,.4.645v.532a.728.728,0,0,1-.4.706Z" transform="translate(0)" fill={fill}/>
  </svg>
  );
export default SendFillIcon

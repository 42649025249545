import TopMenu from "../../../components/basic/top-menu";
import BottomMenu from "../../../components/basic/bottom-menu";
import {useNavigate} from "react-router-dom";


export const TermsOfService = ({history}) => {
    const navigate = useNavigate();

    return <>
        <TopMenu
            icon={'greater-icon'}
            title={'About'}
            action={() => navigate("/about")}
        />
        <div className={'jf-basic-page'} style={{padding: 0}}>
            <div style={{margin: '20px'}}>
                <h1> Terms of service</h1>
                <div>
                    <p>
                        PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A BINDING CONTRACT.
                    </p>
                    <p>
                        When using the Service, you will be subject to JuneFox.com’s
                        <a href="/about/privacy-policy">Privacy Policy</a>, JuneFox.com's
                        <a href="/about/cookie-policy">Cookie Policy</a> and additional guidelines or rules that are
                        posted on the Service or made available to you, or applicable to specific services and features
                        that are disclosed to you in connection with such services.
                    </p>
                    <p>
                        The Terms apply to all Users. You agree that by registering, downloading, accessing
                        or using the Service, you are entering into a legally binding agreement between you
                        and JuneFox.com regarding your use of the Service. You acknowledge that you have read,
                        understood, and agreed to be bound by these Terms. If you do not agree to these Terms,
                        do not access or otherwise use any of the Service.
                    </p>

                    <h2> Definitions</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>
                                Term
                            </th>
                            <th>
                                Definition
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                The "Terms"
                            </td>
                            <td>
                                These Terms of Service as outlined in this document.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "JuneFox.com", "we", or "us"
                            </td>
                            <td>
                                Internet Fox SRL,trading under the name JuneFox.com.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                The "Service"
                            </td>
                            <td>
                                The JuneFox.com website, located at JuneFox.com, and any other websites, applications,
                                or
                                services provided, owned or operated by us that link to these Terms.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Customer(s)"
                            </td>
                            <td>
                                Any and all Users who purchase, unlock or otherwise access Content made available
                                through the Service by Creators.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "User(s)"
                            </td>
                            <td>
                                Any and all visitors, users, and others who access or use the Service.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Creator(s)"
                            </td>
                            <td>
                                Any and all Users who sell or otherwise provide Content through the Service to
                                Customers.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Content"
                            </td>
                            <td>
                                Videos, images, messages, text, sound, applications, code or other data or materials
                                that Users may create,
                                post, transmit, perform, store, or make available on or through the Service.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Credits"
                            </td>
                            <td>
                                A virtual currency used to purchase, unlock or otherwise access Content.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h2>
                        About the Service
                    </h2> <p>
                    JuneFox.com provides a digital service platform that allows Creators to monetize
                    their Content with their Customers. By accessing the Service you acknowledge that
                    you understand the Service is for entertainment purposes only.
                </p> <p>
                    We reserve the right, at any time and from time to time, temporarily or permanently,
                    in whole or in part, to: modify or discontinue the Service, with or without notice;
                    charge fees in connection with the use of the Service; modify and/or waive any fees
                    charged in connection with the Service; and/or offer opportunities to some or all
                    Users of the Service. You agree that we shall not be liable to you or to any third
                    party for any modification, suspension or discontinuance of the Service, in whole or
                    in part, or any content, feature or product offered through the Service.
                    Your continued use of the Service after such changes constitutes your acceptance of the changes.
                </p> <h2>
                    Jurisdictional Issues
                </h2> <p>
                    The Service is controlled and operated by JuneFox.com from its country of incorporation and is not
                    intended to subject JuneFox.com to the laws or jurisdiction of any other state, province,
                    country or territory. Although JuneFox.com attempts to ensure that the Service complies
                    with certain laws of other jurisdictions in an effort to adhere to industry best
                    practices (for example, the United States’ Digital Millennium Copyright Act and 18 U.S.C.
                    §2257),
                    such attempts shall not be deemed to subject JuneFox.com to the laws of those jurisdictions.
                    JuneFox.com does not represent or warrant that the Service or any part thereof is appropriate
                    or available for use in any particular jurisdiction. In choosing to access the Service,
                    you do so on your own initiative and at your own risk, and you are responsible for
                    complying with all local laws, rules and regulations. We may limit the Service's availability,
                    in whole or in part, to any person, geographic area or jurisdiction we choose, at any
                    time and in our sole discretion. Not all products or services described on the Service
                    are available in all jurisdictions.
                </p> <h2>
                    Minors
                </h2> <p>
                    You must be at least 18 years old or the age of legal majority in your jurisdiction
                    of residence, whichever is older, to have our permission to use our Service.
                </p> <p>
                    We have zero-tolerance for minors using the Service. Moreover, we have zero tolerance for any
                    Content referencing a minor for any reason,
                    including pretending to be a minor as an adult or Content that features simulations of minors.
                    If you believe that a minor is using the Service, we request and encourage Users to report them.
                    We reserve the right to and do report each and every suspected minor to any and all law
                    enforcement agencies we feel appropriate.
                </p> <p>
                    BY VISITING, DOWNLOADING, OR OTHERWISE USING THE SERVICE, YOU REPRESENT THAT: (A)
                    YOU ARE AT LEAST 18 YEARS OF AGE OR THE AGE OF MAJORITY IN YOUR JURISDICTION OF
                    RESIDENCE (IF GREATER THAN 18 YEARS OF AGE); AND (B) THE JURISDICTION YOU ARE
                    ACCESSING THE SERVICE FROM DOES NOT PROHIBIT THE RECEIPT, DOWNLOADING, VIEWING
                    OR DISTRIBUTION OF SEXUALLY EXPLICT OR ADULT CONTENT.
                </p> <p>
                    Parental control protections (such as computer hardware, software or filtering services) are
                    commercially available that may assist you in limiting access to material that is harmful to
                    minors.
                    Guidance on using such protections is available on the U.S. Federal Trade Commission’s
                    website <a rel="noreferrer" href="https://www.consumer.ftc.gov/articles/0029-parental-controls"
                               target="_blank">here</a>.
                    Please note that JuneFox.com does not endorse any of the products or services listed at such site.
                </p> <h2>
                    Privacy Policy
                </h2> <p>
                    Your privacy is important to Us. Please see our
                    <a href="/about/privacy-policy">
                        Privacy Policy
                    </a> for information relating to how we collect, use, and disclose your personal
                    information.
                </p> <h2>
                    User Registration
                </h2> <p>
                    You may be required to register an account to access certain features of the Service.
                    You agree to keep your password confidential and will be responsible for all usage,
                    activity and purchases conducted on the Service through your account. We reserve the right to
                    remove,
                    reclaim, or change a username you select if we determine, in our sole discretion,
                    that such username is inappropriate, obscene, or otherwise objectionable.
                </p> <p>
                    Unless expressly permitted in writing by JuneFox.com, you may not sell, rent, lease,
                    share or provide access to your account to anyone else. JuneFox.com reserves all
                    available legal rights and remedies to prevent unauthorized use of the Service,
                    including, but not limited to, technological barriers, IP mapping, and, in
                    serious cases, directly contacting your Internet Service Provider (ISP) regarding
                    such unauthorized use.
                </p> <p>
                    To the extent you voluntarily create a User profile to participate in certain select
                    services offered by us, your profile (and its contents) may be searchable by other
                    Users registered through the Service. Likewise, your profile (and its contents) may
                    be searchable by publicly available search engines.
                </p> <h2>
                    Use of Devices and Services
                </h2> <p>
                    Access to the Service may require the use of your personal computer or mobile device,
                    as well as communications with or use of space on such devices.
                    You are responsible for any Internet connection or mobile fees and charges
                    that you incur when accessing the Service.
                </p> <h2>
                    Modifications
                </h2> <p>
                    We reserve the right to change these Terms at any time. If we make changes,
                    we will notify you by revising the date at the bottom of the Terms and, in some cases,
                    we will provide you with additional notice (such as adding a statement to our
                    homepage or sending you an email notification). In order to comply with Regulation (EU)
                    2019/1150
                    (the "Platform to Business Regulation" or "P2BR") we will provide no less than 15 days'
                    notice of any changes to these Terms on a 'durable medium' (as defined in the P2BR),
                    other than those changes set out in Article 3 (4) of the P2BR.
                    Changes to these Terms are effective at the time they are published.
                    Your continued use of the Service after the effective
                    date of the revised Terms constitutes your acceptance of the Terms.
                </p> <h2>
                    License to Use the Service
                </h2> <p>
                    Subject to these Terms, JuneFox.com grants you a personal, worldwide, royalty-free, non-assignable,
                    nonexclusive,
                    revocable, and non-sublicensable license to access and use the Service.
                    This license is for the sole purpose of letting you use and enjoy the Service’s
                    benefits as intended by JuneFox.com and permitted by these Terms.
                </p> <h2>
                    Content
                </h2> <p>
                    Subject to what is permitted under applicable law (including but not limited to copyright law),
                    you are free to choose the
                    type of Content you produce and post or publish. You shall be solely responsible
                    for your own Content and the consequences of posting or publishing such Content.
                    In connection with Content, you affirm, represent, and/or warrant that: (i) you
                    own or have the necessary licenses, rights, consents, and permissions to use and
                    authorize us to use all patent, trademark, trade secret, copyright, right to
                    privacy, right to publicity, or other proprietary commercial or personal rights
                    in and to any and all your Content to enable inclusion and use of Content
                    in the manner contemplated by the Service and these Terms, and (ii) you have
                    the written consent, release, and/or permission of each and every identifiable
                    persons in your Content to use the name or likeness of each individual for use
                    in your Content in the manner contemplated by the Service and these Terms.
                </p> <p>
                    We are not responsible for any Content that violates a community's norms or mores.
                    We expect and demand that you comply with all federal, state, provincial and local
                    laws when using the Service and when submitting or posting Content to the Service.
                    If you are unsure whether or not Content will violate a law, you are urged to contact
                    a legal professional prior to posting the Content. We cannot enforce every jurisdiction's laws
                    for all Content that is posted to the Service. As such, we are not responsible for
                    the Content of the Service.
                </p> <p>
                    You must evaluate, and bear all risks associated with, the use of any Content,
                    including any reliance on the accuracy, completeness, or usefulness, or lawfulness
                    of such Content. In this regard, you acknowledge that you may not rely on any Content
                    created by us or Content transmitted to the Service. You are responsible for all your
                    Content that is uploaded, posted, emailed, transmitted or otherwise made available
                    via the Service.
                </p> <p>
                    If we have a reason to suspect that your Content violates any third-party right,
                    including without limitation any copyright, trademark, or property right,
                    we can require you to provide us with written evidence of your ownership of,
                    or right to use, the material in question. If we require such written evidence,
                    you agree to provide it to us within five working days of the date of our request.
                    Your failure to provide us with such required written evidence within that timeframe
                    may lead to the immediate termination of your account, us demanding compensation from
                    you for any accrued costs and damages related to such Content and the immediate
                    suspension of any and all pending payments from us to you.
                </p> <p>
                    Please note that you may not permit any other person to use your account and
                    that you must immediately inform us of any apparent breach of security,
                    such as loss or theft of your password.
                </p> <p>
                    While we will make efforts we believe are reasonable to safeguard Content,
                    and to make Content available in case of loss or deletion, we have no
                    responsibility or liability for the deletion or failure to store or the security
                    of any Content. We reserve the right to mark or treat as "inactive" and
                    archive Content, as determined by us. We reserve the right to change our
                    practices and storage rules at any time in our sole discretion with or
                    without notice to you.
                </p> <p>
                    You understand and agree that we may monitor, censor or remove any and all Content,
                    in whole or in part, that in our sole judgment violates these Terms or may harm the
                    reputation of the Service.
                    However, we have no obligation to display or review Content, and cannot ensure
                    prompt removal of objectionable Content after it has been posted. Accordingly,
                    we assume no liability for any action or inaction regarding transmissions,
                    communications or Content provided by any User or third party. We have no liability
                    or responsibility to anyone for performance or nonperformance of the
                    activities described in this section.
                </p> <h2>
                    License to the Content
                </h2> <p>
                    For purposes of clarity, you retain any ownership rights that you may have in the Content,
                    subject to the terms and conditions of this Agreement. For all Content that you make available
                    through or in connection with the Service, you hereby grant to us a world-wide, royalty-free,
                    fully paid-up, non-exclusive, perpetual, irrevocable, transferable and fully-sublicensable
                    (through multiple tiers) license, without additional consideration to you or any third party,
                    to reproduce, distribute, perform and display (publicly or otherwise), adapt, modify and
                    otherwise use and exploit such Content, in any format or media now known or hereafter developed,
                    and for any purpose in connection with the Service (including promotional purposes).
                </p> <h2>
                    User Interactions
                </h2> <p>
                    JuneFox.com is not responsible for the conduct of any User on the Service.
                    You agree to use caution in all interactions with other Users.
                    You agree that you will not provide your financial information (for example,
                    your credit card or bank account information), or wire or otherwise send money,
                    to other Users.
                </p> <p>
                    YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.
                    YOU UNDERSTAND THAT JuneFox.com DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS
                    USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS. JuneFox.com MAKES
                    NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS.
                </p> <h2>
                    Prohibited Conduct
                </h2> <p>
                    You agree that you will only use the Service for the lawful purposes
                    expressly permitted and contemplated by these Terms. The Service may not be used in connection
                    with any commercial endeavors
                    except those that that we expressly approve in writing.
                </p> <p>
                    You agree that you will view the Service and its Content unaltered and unmodified.
                    You acknowledge and understand that you are prohibited from modifying the Service
                    or eliminating any of the Content of the Service, including ads. By using the
                    Service you expressly agree to accept advertising served on and through the
                    Service and to refrain from using adblocking software or to disable adblocking
                    software before visiting the Service.
                </p> <p>
                    You agree that you will not use or attempt to use any method, device, software
                    or routine to harm others or interfere with the functioning of the Service
                    or use and/or monitor any information in or related to the Service for any
                    unauthorized purpose. Your use of the Service is conditioned on your compliance
                    with the rules of conduct set forth in this section; your failure to comply with
                    such rules may result in termination of your access to the Service pursuant to the
                    “Termination” section below. BY USING THE SERVICE YOU AGREE NOT TO:
                </p>
                    <ul>
                        <li>
                            behave in a manner that negatively affects other Users' ability to use or enjoy the Service,
                            including, without limitation, by engaging in conduct that is abusive, harmful, harassing,
                            inflammatory, intimidating, invasive of another's privacy, stalking, threatening, violent or
                            encourages violence to people or animals, or racially, ethnically, or otherwise offensive or
                            objectionable;
                        </li>
                        <li>
                            engage in any form of harassment, prostitution, sex trafficking, solicitation, or other
                            illegal or offensive behavior toward Creators;
                        </li>
                        <li>
                            attempt to obtain or obtain personal contact information from any Creators on the Service;
                        </li>
                        <li>
                            attempt to co-ordinate an in-person meeting with any of the Creators on the Service;
                        </li>
                        <li>
                            post any Content depicting child pornography, rape, snuff, torture, death, violence,
                            bestiality,
                            incest, racial slurs or hate speech (either aurally or in writing);
                        </li>
                        <li>
                            post any Content that is illegal, unlawful, obscene, defamatory, libelous, slanderous,
                            harassing, hateful,
                            racially or ethnically offensive, or encourages conduct that would be considered a criminal
                            offense,
                            gives rise to civil liability, violates any law, or is otherwise inappropriate;
                        </li>
                        <li>
                            post any Content that depicts any person under 18 years of age (or older as required by any
                            jurisdiction in
                            which the age of majority is greater than 18);
                        </li>
                        <li>
                            post any simulated Content that would violate any of these Terms if such Content were not
                            simulated;
                        </li>
                        <li>
                            post any Content that contains misrepresentations or falsehoods that could damage the
                            reputation of the Service or of any third party;
                        </li>
                        <li>
                            violate any law or regulation (including, without limitation, any law related to torts,
                            contracts, copyrights, patents,
                            trademarks, trade secrets, defamation, obscenity, pornography, rights of publicity or other
                            rights)
                            or encourage, solicit or instruct a third-party to do so;
                        </li>
                        <li>
                            post any Content that contains or promotes contests, sweepstakes, lotteries, or otherwise
                            related to gambling;
                        </li>
                        <li>
                            post any Content that contains unsolicited advertising, promotional materials, spam, junk
                            mail,
                            pyramid schemes, chain letters, or any other form of unauthorized solicitation;
                        </li>
                        <li>
                            post any Content in which another person is impersonated or that misrepresents your
                            affiliation with or connection to a person;
                        </li>
                        <li>
                            post any Content containing materials subject to intellectual property rights (including
                            copyright) or other rights
                            of third parties that you do have the right to post to the Service;
                        </li>
                        <li>
                            transmit or use software or other code designed to interrupt, destroy, limit or otherwise
                            interfere with the
                            functionality of any computer software or hardware or telecommunications equipment,
                            including, without limitation,
                            by engaging in any denial of service attack, distributed denial-of-service attack or similar
                            conduct;
                        </li>
                        <li>
                            exceed your authorized access to any portion of the Service;
                        </li>
                        <li>
                            obtain or attempt to obtain access to any Content or information through any means not
                            intentionally provided through the Service;
                        </li>
                        <li>
                            exploit errors in design, undocumented features or bugs to gain access that would not
                            otherwise be available.
                        </li>
                        <li>
                            remove, modify, circumvent or bypass any digital rights management (“DRM”) technology,
                            encryption or security measures;
                        </li>
                        <li>
                            collect, store or process anyone’s personal data;
                        </li>
                        <li>
                            modify any part of the Service or its advertising, except as expressly permitted herein;
                        </li>
                        <li>
                            do anything that could overburden, disable, damage, or impair any aspect of the Service;
                        </li>
                        <li>
                            use any robot, spider, site search/retrieval application or other manual or automatic
                            device to retrieve, index, "scrape," "data mine" or in any way gather Service content
                            or reproduce or circumvent the navigational structure or presentation of the Service
                            without our express prior written consent. Notwithstanding the foregoing, we grants
                            the operators of public search engines permission to use spiders to copy materials
                            from the Service for the sole purpose of and solely to the extent necessary for creating
                            publicly available searchable indices of the materials, but not caches or archives of
                            such materials. JuneFox.com reserves the right to revoke these exceptions either generally
                            or in
                            specific cases;
                        </li>
                        <li>
                            create a database by systematically downloading and storing Service content;
                        </li>
                        <li>
                            use any manual process to monitor or reproduce any material on the Service;
                        </li>
                        <li>
                            use any information obtained from the Service to interfere with any advertising on the
                            Service,
                            including, without limitation, its display in your web browser;
                        </li>
                        <li>
                            transmit any viruses, Trojan horses, worms, logic bombs, time bombs, cancelbots, corrupted
                            files or any other similar software or code that is malicious or harmful or that that may
                            damage another party’s property or the Service;
                        </li>
                        <li>
                            attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the
                            Service,
                            the server on which the Service is stored, or any server, computer or database connected to
                            the Service;
                        </li>
                        <li>
                            remove any copyright or other proprietary notices from our Service or any of the materials
                            contained therein;
                        </li>
                        <li>
                            reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial purposes,
                            any portion of, use of, or access to the Service;
                        </li>
                        <li>
                            modify, adapt, translate, reverse engineer, decompile or disassemble any portion of the
                            Service;
                        </li>
                        <li>
                            frame or mirror any part of the Service without our express prior written consent.
                        </li>
                    </ul>
                    <h2>
                        Customer Support
                    </h2> <p>
                    When communicating with our customer support, you agree to be respectful and kind.
                    If we feel that your behaviour towards any of our customer support staff or
                    other employees is at any time threatening or offensive, we reserve the right
                    to immediately terminate your account.
                </p> <h2>
                    Advertising
                </h2> <p>
                    In consideration for JuneFox.com allowing you to use the Service, you agree that we,
                    our affiliates, and our third-party partners may place advertising on the Service.
                </p> <h2>
                    Credits and Purchases
                </h2> <p>
                    You may purchase access to Content on the Service using your credit card or Credits.
                    You may purchase Credits solely for use in the Service. Other than a limited, personal,
                    revocable, non-transferable, non-sublicenseable license to use the Credits as part of the
                    Service,
                    you have no right or title in or to any such Credits appearing or originating in the Service,
                    or any other attributes associated with use of the Service or stored within the Service.
                    We have the absolute right to manage, regulate, control, modify and/or discontinue the
                    availability
                    or use of Credits on the Service in our sole discretion, and we shall have no liability to you
                    or anyone for the exercise of such rights. Transfers of Credits are strictly prohibited except
                    where explicitly authorized within the Service. Except as expressly provided in these Terms,
                    you may not sell any Credits for “real-world” money or otherwise exchange such items for value.
                    Any attempt to exchange Credits for actual currency, other than by following the process
                    established by JuneFox.com, is in violation of these Terms and may result in a lifetime ban from
                    the Service and possible legal action. Unless otherwise provided by law, Credits are
                    non-refundable,
                    and you are not entitled to a refund for any unused Credits. Once you purchase access to Content
                    or
                    redeem a Credit for access to Content, you may not return, exchange or receive a refund for that
                    Content.
                    Notwithstanding the foregoing, we reserve the right to issue refunds for purchases or Credits at
                    our
                    sole discretion. If we issue a refund for a purchase or Credit, we are under no obligation to
                    issue
                    the same or similar refund in the future.
                </p> <p>
                    Credit purchases can be made by credit cards, and when available, by debit cards,
                    and will be processed through a third-party payment processor.
                    Purchases are payable in advance. We may offer different packages
                    you can choose from. We reserve the right to change Credit prices
                    at any time, at our sole discretion and without liability to you.
                    Any Credits will not be available in your account in the event that
                    your account is deleted by you or otherwise terminated in accordance
                    with these Terms, or if we discontinue providing the Service.
                    You agree to pay all fees and applicable taxes incurred by you or
                    anyone using a JuneFox.com account registered to you. JuneFox.com may revise
                    the pricing for the goods and services offered through the Service at any time.
                </p> <p>
                    Names that appear on the statement associated with your payment method (e.g., credit card, bank
                    account, etc.)
                    for any applicable charges may include Nektun Limited, SXWEB, or other billing entities
                    depending on your location.
                    Nektun Limited or the applicable billing entity may include other information on your statement
                    as required by its
                    agreements with banks, other third parties or by applicable laws or regulations. If you use a
                    bank account to make
                    a purchase through the Service, a debit will be charged to that account.
                </p> <p>
                    You agree that you will not use an IP proxy or other similar methods to disguise the place where
                    you are located,
                    whether to circumvent geographical restrictions on Content and gain access to the Service when
                    it would not otherwise be available to you,
                    or to make Purchases at a price (including the application of sales taxes, excise taxes, Value
                    Added Taxes or VAT) not applicable to your location,
                    or for any other purpose. If you do this, we reserve the right to terminate your access to the
                    Service.
                </p> <p>
                    If you do not log into your account for more than twelve (12) months, then we reserve the
                    right at our sole discretion to terminate your right to use the Service. In such a case,
                    you hereby expressly accept, agree and acknowledge that you will forfeit any unused Credits,
                    which you cannot later recover.
                </p> <h2>
                    Card payment processing
                </h2> <p>
                    Nektun Limited is responsible for card payment processing.
                </p> <h2>
                    Payment Processors
                </h2> <p>
                    All financial transactions made in connection with the Service will be processed by a
                    third-party payment processor.
                    In no event will JuneFox.com be responsible for the actions or inactions of any third-party payment
                    processor.
                    If you believe there has been a billing error, you can seek support at sxweb.com or by sending
                    us an email
                    at the billing support email address provided under the “Contact” section below.
                </p> <h2>
                    Becoming a Creator
                </h2> <p>
                    If you want to become a Creator and sell access to Content through the Service,
                    you must first provide us true and correct personal information.
                    If the information you provide is not true and correct, you face
                    the immediate termination of your account and you may be subject to
                    legal sanctions. Certain changes to your personal information such as
                    country of residence or date of birth can only be made by our staff.
                    Therefore, if your information appears incorrect or needs to be changed
                    you may need to contact our staff to have this done.
                </p> <p>
                    We may, in our sole discretion, revoke your right to sell access to Content through the Service
                    for any reason.
                </p> <p>
                    You may be asked to provide a valid email address for verification purposes,
                    and you agree that we may send you emails, both of a transactional nature
                    and also for commercial and promotional purposes. You will have the ability to
                    opt out of commercial and promotional emails, as described more fully in the
                    <a href="/about/privacy-policy">
                        Privacy Policy.
                    </a></p> <p>
                    You will choose your own display name which must be unique to you,
                    not offensive to others and not in violation of another's copyright or trademark.
                    You will also choose your password, which you can change later.
                </p> <p>
                    You will be requested to verify your identity and age through a third party identity
                    verification partner.
                </p> <p>
                    In order to do so, you need to submit the following documents to us:
                </p>
                    <ul>
                        <li>
                            A Photo ID, meaning a government issued identification containing your date of birth,
                            expiration date, your photo and your full legal name. This could be, for example, your
                            driver's license (in countries where a national ID is not mandatory), international
                            passport,
                            citizenship card, state ID, national passport or your national ID card.
                        </li>
                        <li>
                            A photo of yourself while holding the above-mentioned Photo ID.
                        </li>
                    </ul>
                    <p>
                        You acknowledge that you are solely responsible for the activity that
                        occurs on your account, and you must immediately notify us if you believe
                        there has been the slightest breach of security or unauthorized use of your account.
                    </p> <p>
                    You will be liable for any losses incurred by us due to the unauthorized
                    use of your account. We are not liable for your losses caused by
                    any unauthorized use of your account and you specifically waive any
                    such claim and agree to defend and indemnify us against any such claims
                    made against us by third parties.
                </p> <h2>
                    Creators' Commission
                </h2> <p>
                    Payments will only be made to the same beneficiary name as provided during the KYC onboarding
                    process.
                    If you need to change this name (for example, you have changed your name,
                    the name assigned to your ID documentation is different to the beneficiary
                    name of your bank in your country, or you would like to be paid to a legal entity)
                    please contact <a external=""
                                      href="mailto:support@JuneFox.com">support@JuneFox.com</a>. You will need to
                    provide
                    proof of the reason for
                    the change being required, we may need additional information from you as part
                    of the due diligence process, and we reserve the absolute right to reject your request.
                    If you request payment to a legal entity, the information provided during the KYC
                    verification must be an UBO (Ultimate Beneficial Owner) of the entity, in accordance with EU
                    2015/849,
                    as amended, regardless of the entity being subject to EU legislation. Payments will
                    not be made to third parties under any circumstances, including, but not limited to,
                    spouses, family members, escrow services, business partners, court appointed guardians,
                    or receivers of any kind.
                </p> <p>
                    We will deduct and withhold from any amounts payable to you under these Terms
                    such amounts as we are directed to deduct and withhold or are required to
                    deduct and withhold with respect to such payment under the provision
                    of any applicable laws (including sales taxes, excise taxes, Value Added Taxes or VAT) and remit
                    such deduction and withholding amount to the appropriate governmental entity.
                </p> <p>
                    We will provide you with a statement of transactions on your account, including details
                    of your earnings and payouts, that you can access on the Service. You can request a
                    payout on your net earnings at any time, provided they exceed the minimum payout of $200.
                    A wire payment fee may be charged to you for this, depending on the payout method(s)
                    available in the country in which you are based. Payout requests below this amount
                    are not permitted except in connection with a request to terminate your relationship
                    with us as a Creator. All earnings are subject to a hold period of 7 days, to account
                    for potential disputes by purchaser(s). Earnings in the hold period will appear in your
                    account as soon as the applicable transaction has been completed, but will not be
                    available to be paid out until the end of the hold period. Although we will initiate
                    payment to you promptly following your request in compliance with this paragraph,
                    we cannot guarantee that you will receive the corresponding funds by any particular date.
                    If you provide us with incorrect payment details, any fees for chargebacks resulting
                    from such incorrect payment details will be offset against any amounts otherwise payable to you.
                </p> <p>
                    In the event you violate any part of these Terms, you violate any third-party right,
                    including without limitation any copyright, intellectual property, or privacy right, or where
                    a third party claims that all or any part of your Content caused it damage,
                    we may, at our sole discretion, withhold indefinitely payments to you.
                </p> <p>
                    We remind you that it is your duty and obligation to fulfill all tax
                    obligations with regards to your activities for us, for example registration,
                    declaration and payment obligations related to income taxes, Sales Taxes and VAT.
                    If we assist you with these obligations, it does not release you from your
                    obligations. It is your duty to inform us in a timely manner of changes to
                    your personal data such as an address change or a new email.
                </p> <p>
                    If you have a Creator account with a positive balance with no transactions for a
                    consecutive period of 12 months, we will attempt to contact you with a view to
                    asking you to withdraw the balance from your account. If we are not able to contact you,
                    we will charge you a dormancy fee of 8.33% of such balance per month from the first
                    anniversary of the date of last account activity until the earlier of (1) the date
                    of the next account activity or (2) the date your balance reaches zero, i.e., after
                    another 12 months of inactivity. If we are charging your account the dormancy fee,
                    but you want to reactivate it, please contact customer support on
                    <a external="" href="mailto:support@JuneFox.com">support@JuneFox.com</a>.
                </p> <h2>
                    Third-Party Services and Websites
                </h2> <p>
                    The Service may contain advertisements and promotions offered by third parties
                    and links to other web sites or resources. JuneFox.com is not responsible for
                    the availability (or lack of availability) of such external websites or
                    resources. If you choose to interact with the third parties made available
                    through our Service, such party’s terms will govern their relationship with you.
                    JuneFox.com is not responsible or liable for such third parties’ terms or actions.
                </p> <h2>
                    Ideas and Feedback
                </h2> <p>
                    Unless we expressly agree otherwise in writing, if you provide us with any ideas, proposals,
                    suggestions or materials (“Input”), whether related to the Services or otherwise, you hereby
                    acknowledge and agree that (a) your provision of any Input is gratuitous, unsolicited and
                    without
                    restriction and does not place JuneFox.com under any fiduciary or other obligation; (b) any Input is
                    not confidential and JuneFox.com has no confidentiality obligations with respect to such Input; and
                    (c) to the extent permitted under applicable law, any Input will become our sole property.
                    Without limiting the foregoing, you hereby grant to us a world-wide, royalty-free, fully
                    paid-up,
                    exclusive, perpetual, irrevocable, transferable and fully sublicensable (through multiple tiers)
                    license, without additional consideration to you or any third party, to: (i)reproduce,
                    distribute,
                    perform and display (publicly or otherwise), adapt, modify and otherwise use and exploit such
                    Input,
                    in any format or media now known or hereafter developed, and you hereby represent and warrant
                    that
                    you have all necessary rights to grant the foregoing license. We may use Input for any purpose
                    whatsoever without compensation to you or any other person. You are and remain responsible and
                    liable for the content of any Input.
                </p> <h2>
                    Termination
                </h2> <p>
                    These Terms are effective until terminated. JuneFox.com, in its sole discretion, may suspend or
                    terminate
                    your access to or use of the Service, at any time and for any reason, including if JuneFox.com
                    believes
                    that you have violated or acted inconsistently with the letter or spirit of these Terms. Upon
                    any such termination,
                    your right to use the Service will immediately cease. You agree that any termination of your
                    access to or use of
                    the Service may be effected without prior notice, and that JuneFox.com may immediately deactivate or
                    delete your
                    password and user name, and all related information and files associated with it, and/or bar any
                    further
                    access to such information or files.
                </p> <p>
                    For Creator accounts, to comply with the P2BR, unless the reason for Termination is any of the
                    reasons
                    set out in Article 4 (4) of the P2BR, we will provide you with at least 30 days' notice of the
                    reason(s)
                    for termination on a 'durable medium' (as defined in the P2BR).
                </p> <p>
                    You agree that JuneFox.com shall not be liable to you or any third party
                    for any termination of your access to the Service or to any such information or files, and shall
                    not be
                    required to make such information or files available to you after any such termination. We
                    reserve the
                    right to make use of any personal, technological, legal, or other means available to enforce the
                    Terms,
                    at any time without liability and without the obligation to give you prior notice, including,
                    but not limited to,
                    preventing you from accessing the Services.
                </p> <p>
                    If your account is terminated by you or by JuneFox.com for any reason, these Terms
                    continue and remain enforceable between you and JuneFox.com, and you will not be entitled
                    to any refund for purchases made. Your information will be maintained and deleted
                    in accordance with our
                    <a href="/about/privacy-policy">
                        Privacy Policy
                    </a>.
                </p> <h2>
                    NO REPRESENTATIONS OR WARRANTIES
                </h2> <p>
                    THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT THEREIN,
                    AND ANY OTHER INFORMATION, PROPERTY AND RIGHTS GRANTED OR PROVIDED TO YOU BY
                    JuneFox.com ARE PROVIDED TO YOU ON AN “AS IS” BASIS. JuneFox.com AND ITS SUPPLIERS MAKE NO
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, EITHER
                    EXPRESS, IMPLIED OR STATUTORY AND ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT,
                    ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, JuneFox.com
                    DOES NOT MAKE ANY REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO ACCURACY,
                    SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT, ERROR-FREE OPERATION,
                    RESULTS TO BE OBTAINED FROM USE, OR NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE
                    MAY BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS OR
                    SCHEDULED OR UNSCHEDULED MAINTENANCE OR FOR OTHER REASONS. SOME JURISDICTIONS DO
                    NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY
                    TO YOU.
                </p> <h2>
                    LIMITATION OF LIABILITY
                </h2> <p>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL JuneFox.com,
                    ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
                    INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR
                    INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,
                    RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
                    SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD-PARTIES ON, THROUGH,
                    OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION
                    OF YOUR CONTENT, EVEN IF JuneFox.com HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    IN NO EVENT WILL JuneFox.com’S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE
                    SERVICE EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO JuneFox.com FOR THE SERVICE WHILE YOU
                    HAVE AN ACCOUNT.
                </p> <p>
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES,
                    SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY
                    TO YOU.
                </p> <p>
                    While we try to maintain the integrity and security of the Service and the servers
                    from which the Service is operated, we do not guarantee that the Service will be
                    or remain secure, complete or correct, or that access to the Service will be uninterrupted.
                    The Service may include inaccuracies, errors and materials that violate or conflict with these
                    Terms.
                    Additionally, third parties may make unauthorized alterations to the Service.
                    If you become aware of any unauthorized third party alteration to the Service,
                    contact us at <a external=""
                                     href="mailto:support@JuneFox.com">support@JuneFox.com</a> with a description of the
                    material(s) at issue
                    and the URL or location on the Service where such material(s) appear.
                </p> <h2>
                    Indemnification
                </h2> <p>
                    You agree, to the extent permitted under applicable law, to indemnify,
                    defend and hold harmless JuneFox.com, our affiliates, and their and our respective
                    officers, directors, agents, and employees from and against any and all complaints,
                    demands, claims, damages, losses, costs, liabilities and expenses, including
                    attorney’s fees, due to, arising out of, or relating in any way to your access
                    to or use of the Service, your Content, or your breach of these Terms.
                </p> <h2>
                    Copyright Infringement Claims.
                </h2> <p>
                    The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright
                    owners who believe that material appearing on the Internet infringes their rights under U.S.
                    copyright law. You can find our DMCA policy
                    <a href="/about/dmca">
                        here
                    </a>.
                </p> <h2>
                    Intellectual Property
                </h2> <p>
                    The information and materials made available through the Service are and shall remain the
                    property of JuneFox.com
                    and its licensors and suppliers, and are protected by copyright, trademark, patent, and/or other
                    proprietary
                    rights and laws. Subject to your compliance with these Terms, and solely for so long as you are
                    permitted
                    by JuneFox.com to access and use the Service, you may view one (1) copy of any content on the
                    Service to which
                    we provide you access hereunder on any single computer or mobile device solely for your
                    personal,
                    non-commercial use, provided that you keep intact all copyright and other proprietary notices.
                    Except as expressly authorized in advance by JuneFox.com in writing, you agree not to reproduce,
                    modify, rent, lease, loan, sell, distribute, or create derivative works based (whether in whole
                    or in part)
                    on, all or any part of the Service or any materials made available through the Service.
                    Trade names, trademarks and service marks of JuneFox.com include without limitation,
                    JuneFox.com and any associated logos. All trademarks and service marks on the Service not owned by
                    JuneFox.com
                    are the property of their respective owners. The trade names, trademarks and service marks owned
                    by JuneFox.com,
                    whether registered or unregistered, may not be used in connection with any product or service
                    that is not ours,
                    in any manner that is likely to cause confusion. Nothing contained on the Service should be
                    construed as granting,
                    by implication, estoppel or otherwise, any license or right to use any of JuneFox.com’s trade names,
                    trademarks or service marks without our express prior written consent. Reference on the Service
                    to any products,
                    services, processes or other information, by trade name, trademark, manufacturer, supplier,
                    or otherwise does not constitute or imply endorsement, sponsorship, or recommendation thereof by
                    us or any other affiliation.
                </p> <h2>
                    Information Security
                </h2> <p>
                    JuneFox.com uses reasonable and appropriate organizational, technical, administrative and physical
                    measures to protect
                    the integrity and security of your information. These measures may vary based on the sensitivity
                    of your information.
                    We limit access to your personal data to those employees, agents, contractors and other third
                    parties who have a business
                    need to access it and they will only process your personal data on our instructions and are
                    subject to a duty of confidentiality.
                </p> <h2>
                    Governing Law: Disputes
                </h2> <p>
                    These Terms shall be construed in accordance with and governed by the laws of Cyprus,
                    without reference to rules regarding conflicts of law that would cause the application
                    of the laws of any other jurisdiction to the rights and duties of the parties.
                    All disputes arising out of or in connection with these Terms will be settled
                    by binding arbitration conducted before a single arbitrator in Amsterdam,
                    The Netherlands in the English language, administered by the International
                    Centre for Dispute Resolution under its International Arbitration Rules.
                    Any award issued as a result of such arbitration shall be binding and enforceable
                    in courts of applicable jurisdiction. Notwithstanding the foregoing, you agree
                    that JuneFox.com may seek injunctive remedies (or an equivalent type of urgent legal relief)
                    in the courts of any jurisdiction.
                </p> <h2>
                    Contact
                </h2> <p>
                    Questions or concerns regarding these Terms can be addressed to <a
                    external=""
                    href="mailto:support@JuneFox.com">support@JuneFox.com</a>.
                    Please note that e-mail communications will not necessarily be secure; accordingly
                    you should not include credit card information or other sensitive information
                    in your e-mail correspondence with us. California residents may reach the Complaint
                    Assistance Unit of the Division of Consumer Services of the California Department
                    of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento,
                    CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
                </p>
                    <p> Billing queries should be directed to
                        <a external="" href="mailto:billing@junefox.com">billing@junefox.com</a>
                        <br/>
                        The details of our primary billing company, INTERNET FOX S.R.L., are: <br/> <br/>
                        INTERNET FOX S.R.L. <br/>
                        MUNICIPIUL BUCUREŞTI, SECTOR 6<br/>
                        ALE. BAIUT Nr. 1<br/>
                        Bl. D13<br/>
                        Sc. 1<br/>
                        Ap. 1<br/>
                        VAT: RO 39642510<br/>
                    </p> <h2>
                    Miscellaneous
                </h2> <p>
                    These Terms do not, and shall not be construed to, create any partnership, joint venture,
                    employer-employee, agency or franchisor-franchisee relationship between you and JuneFox.com.
                    If any provision of these Terms is found to be unlawful, void or for any reason unenforceable,
                    that provision will be deemed severable from these Terms and will not affect the validity and
                    enforceability of any remaining provision. You may not assign, transfer or sublicense any or
                    all of your rights or obligations under these Terms without our express prior written consent.
                    We may assign, transfer or sublicense any or all of our rights or obligations under these
                    Terms without restriction. No waiver by either party of any breach or default hereunder
                    will be deemed to be a waiver of any preceding or subsequent breach or default. Any heading,
                    caption or section title contained herein is inserted only as a matter of convenience, and in
                    no way defines or explains any section or provision hereof. This, together with all policies
                    referred to herein, is the entire agreement between you and JuneFox.com relating to the subject
                    matter herein and supersedes any and all prior or contemporaneous written or oral agreements
                    or understandings between you and JuneFox.com relating to such subject matter. Notices to you may
                    be made via posting to the Service, by e-mail, or by regular mail, in JuneFox.com’s discretion.
                    The Service may also provide notices of changes to these Terms or other matters by displaying
                    such notices or by providing links to such notices. Without limitation, you agree that a printed
                    version of these Terms and of any notice given in electronic form shall be admissible in
                    judicial
                    or administrative proceedings based upon or relating to these Terms to the same extent and
                    subject
                    to the same conditions as other business documents and records originally generated and
                    maintained in printed form.
                    JuneFox.com will not be responsible for failures to fulfill any obligations due to causes beyond its
                    control.
                </p> <h2>
                    Language
                </h2> <p>
                    These Terms were originally written in English. To the extent any translated version of these
                    Terms conflicts with the English version,
                    the English version controls.
                </p> <p><b>Last modified:</b> July 2021
                </p></div>
                <p>
                    © 2022 Internet Fox SRL unless otherwise noted. All rights reserved.
                </p>
            </div>

        </div>
        <BottomMenu/>
    </>
        ;

}



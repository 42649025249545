import React, { useEffect } from 'react'
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import MediaMsg from '../../../../components/message/media-msg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import Mutations from '../../../../params/mutations'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectTab from '../../../../components/basic/select-tab'
import SVGIcon from '../../../../components/svg'
import variables from '../../../../scss/variables.module.scss'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { messageMassAtom } from '../../../../state/pages/message'
import { setSeparateState } from '../../../../helpers/utils'

const schema = yup.object({
  media: yup.object().nullable().required(),
  price: yup.string().when("free", {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required(),
  }),
  text: yup.string().required(),
  free: yup.boolean(),
}).required();

const MassNew = () => {
  const [{tab, custom, users, loading }, setMessageMass] = useRecoilState(messageMassAtom);
  const setTab = setSeparateState(setMessageMass, "tab")
  const setLoading = setSeparateState(setMessageMass, "loading")
  const setCustom = setSeparateState(setMessageMass, "custom")
  const setUsers = setSeparateState(setMessageMass, "users")

  const navigate = useNavigate();
  const location = useLocation();
  const fetchApi = useFetchApiAction();
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageMass = useResetRecoilState(messageMassAtom)

  useEffect(() => {
    resetStateAction.add(resetMessageMass)
    setTab(location.state?.tab || "everyone")
    setCustom(location.state?.custom === true)
    setUsers(location.state?.users || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoading(true);
    const value = location?.message;
    if (value) {
      setValue("price", parseFloat(value.price?.toString().replace("$", "")) || '');
      setValue("text", value.text);
      setValue("preview", value?.preview || 0);
      setValue("media", value.media);
      setValue("free", value.free);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const onSave = (data) => {
    let info = data.media?.info ? JSON.parse(data.media.info) : [];
    info = info.find(find => !!find.duration)

    if (data.media && data.media.type === 'video' && info.duration < 10 && data.free !== true) {
      return;
    }

    const value = {
      price: data.free === true ? 0 : parseFloat(data.price?.replace("$", "")),
      text: data.text,
      preview: data.free === true ? 10 : data.preview,
      free: data.free === true,
    };
    if (data.media) {
      value.media = data.media.id;
      value.thumbnail = data.media.thumbnail.grid;
      value.duration = info?.duration
      value.url = data.free === true ? data.media.source.url : null
    }
    let list = [];
    list[0] = {
      type: 'teaser',
      value: JSON.stringify(value)
    }
    fetchApi(Mutations.Messages.AddTemplate, {
      data: {
        send_to: custom ? "custom" : tab,
        messages: list,
        custom: !custom ? [] : users
      }
    }).then(res => {
      navigate('/messages/mass')
    })
  }

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={'Messages'}
      action={() => navigate(-1)}
    />
    <div className={'jf-flex-basic-page jf-mass-message'}>
      <SelectTab
        style={{ height: 45 }}
        liClassName={'jf-font-r'}
        tabs={[
          { id: "everyone", name: "Everyone" },
          { id: "follower", name: "Followers" },
          { id: "subscriber", name: "Subscribers" },
        ]}
        tab={{ id: custom === true ? null : tab }}
        setTab={(tab) => {
          setTab(tab.id)
          setCustom(false)
          setUsers([])
        }}
      />
      <div className={'jf-flex-row jf-ml-20px jf-pointer'} onClick={() => {
        navigate("/messages/mass/users", {state: {
          tab, custom: custom, users: users,
          message: getValues(),
          url: "/messages/mass/new"
        }})
      }}>
        <button className={'jf-check-circle-btn'}>
          <SVGIcon name={'check-circle-icon'}
                   fillCircle={custom ?
                     variables['colors-jf-primary'] : '#fff'}/>
        </button>
        <div className={'jf-ml-10px'}>Custom</div>
      </div>
      <div style={{ flexGrow: 1 }}/>
      <form>
        {!loading && <>
          <div style={{ flexGrow: 1 }}/>
          <div style={{ padding: 20 }}>
            <MediaMsg
              locationState={() => ({
                tab, custom: custom, users: users,
                message: getValues(),
              })}
              register={register}
              setValue={setValue}
              onSave={handleSubmit(onSave)}
              getValues={getValues}
              errors={errors}
              lableBtn={'Send'}
            />
          </div>
        </>}
      </form>
    </div>
    <BottomMenu/>
  </>;
}

export default MassNew;
import React from 'react';
const FullScreenIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '55',
  className = '',
  viewBox = '0 0 55 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="60" viewBox={viewBox}>
      <g id="scan" transform="translate(-1086 -433)">
        <rect id="Rectangle_1060" data-name="Rectangle 1060" width={width} height="60" transform="translate(1086 433)" fill={fillNone} opacity="0" />
        <g id="scanicon" transform="translate(1093.333 441)">
          <path id="Path_48" data-name="Path 48" d="M15,2.875A1.875,1.875,0,0,1,16.875,1h11.25A1.875,1.875,0,0,1,30,2.875v11.25a1.875,1.875,0,1,1-3.75,0V4.75H16.875A1.875,1.875,0,0,1,15,2.875Z" transform="translate(11.25 -1)" fill={fill} />
          <path id="Path_49" data-name="Path 49" d="M1,2.875A1.875,1.875,0,0,1,2.875,1h11.25a1.875,1.875,0,1,1,0,3.75H4.75v9.375a1.875,1.875,0,1,1-3.75,0Z" transform="translate(-1 -1)" fill={fill} />
          <path id="Path_50" data-name="Path 50" d="M2.875,15A1.875,1.875,0,0,1,4.75,16.875V26.25h9.375a1.875,1.875,0,1,1,0,3.75H2.875A1.875,1.875,0,0,1,1,28.125V16.875A1.875,1.875,0,0,1,2.875,15Z" transform="translate(-1 11.25)" fill={fill} />
          <path id="Path_51" data-name="Path 51" d="M28.125,15A1.875,1.875,0,0,1,30,16.875v11.25A1.875,1.875,0,0,1,28.125,30H16.875a1.875,1.875,0,1,1,0-3.75H26.25V16.875A1.875,1.875,0,0,1,28.125,15Z" transform="translate(11.25 11.25)" fill={fill} />
        </g>
      </g>
    </svg>


  </>
);
export default FullScreenIcon;
import React from 'react';
const ThreeDotsIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
        <g id="like" transform="translate(-354 -560)">
          <g id="favorite-24px" transform="translate(354 560)">
            <path id="Path_41" data-name="Path 41" d="M0,0H40V40H0Z" fill="none"/>
            <g id="icon" transform="translate(33 33) rotate(180)">
              <rect id="Rectangle_793" data-name="Rectangle 793" width="25" height="25" transform="translate(25) rotate(90)" fill="rgba(255,255,255,0)"/>
              <g id="Group_1037" data-name="Group 1037" transform="translate(1 10)">
                <circle id="Ellipse_6" data-name="Ellipse 6" cx="2.5" cy="2.5" r="2.5" fill={fill}/>
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" transform="translate(9)" fill={fill}/>
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(18)" fill={fill}/>
              </g>
            </g>
          </g>
        </g>
    </svg>
  </>
);
export default ThreeDotsIcon;
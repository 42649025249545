import SVGIcon from "../../svg/index";
import React from "react";
import Button from "../../basic/button";
import { useSetRecoilState } from "recoil";
import { cookiePolicyAtom } from '../../../state/components/popup'

const CookiePolicy = ({ hide }) => {
  const setCookiePolicyStateAction = useSetRecoilState(cookiePolicyAtom);
  const onAccept = () => {
    setCookiePolicyStateAction(true)
    hide()
  }

  const onDecline = () => {
    setCookiePolicyStateAction(false)
    hide()
  }

  return (
    <div className="jf-bottom-popup-cookie-policy">
      <SVGIcon name="cookie-icon" />
      <div className="jf-bottom-popup-cookie-policy-container">
        <div className="jf-bottom-popup-header">{"We used cookies"}</div>
        <div className="jf-bottom-popup-body">
          This website uses cookies to ensure you get the best experience on our
          website. Read our <a href="/about/cookie-policy">Cookie Policy</a>
        </div>
      </div>
      <div className="jf-bottom-popup-cookie-actions">
        <Button className={'medium cookie-decline'} onClick={onDecline}>
          Decline
        </Button>
        <Button className={'medium'} onClick={onAccept}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookiePolicy;

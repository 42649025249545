import {atomFamily, atom, selectorFamily} from 'recoil';

export const popupsAtom = atomFamily({
  key: 'popups',
  default: { },
})

export const popupIdsAtom = atom({
  key: 'popupIds',
  default: [],
})

export const activePopupState = selectorFamily({
  key: 'activePopup',
  get: (id= null) => ({get}) => {
    if (id) {
      return get(popupsAtom(id));
    }
    const list = get(popupIdsAtom);
    let findItem = null;
    list.find(id => {
      const info = get(popupsAtom(id));
      if (info.active) {
        findItem = info;
      }
      return info.active;
    });
    return findItem;
  },
  set: (id) => ({set}, info) => {
    if (id) {
      info.id = id;
    }
    set(popupsAtom(info.id), info);
    set(popupIdsAtom, prev => [...prev, info.id].filter(function (value, index, self) {
      return self.indexOf(value) === index;
    }));
  }
});

export const bottomPopupsAtom = atomFamily({
  key: 'bottomPopups',
  default: { },
})

export const bottomPopupIdsAtom = atom({
  key: 'bottomPopupIds',
  default: [],
})

export const activeBottomPopupState = selectorFamily({
  key: 'activeBottomPopup',
  get: (id= null) => ({get}) => {
    if (id) {
      return get(bottomPopupsAtom(id));
    }
    const list = get(bottomPopupIdsAtom);
    let findItem = null;
    list.find(id => {
      const info = get(bottomPopupsAtom(id));
      if (info.active) {
        findItem = info;
      }
      return info.active;
    });
    return findItem;
  },
  set: (id) => ({set}, info) => {
    if (id) {
      info.id = id;
    }
    set(bottomPopupsAtom(info.id), info);
    set(bottomPopupIdsAtom, prev => [...prev, info.id].filter(function (value, index, self) {
      return self.indexOf(value) === index;
    }));
  }
});

export const commentPopupAtom = atom({
  key: 'commentPopup',
  default: {
    loading: false,
    limited: false,
    comments: [],
    count: 0,
    text: '',
    mediaId: ''
  }
});

export const sharePopupAtom = atom({
  key: 'sharePopup',
  default: {
    loading: true,
    url: '',
  }
});

const localStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(savedValue === 'true');
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const cookiePolicyAtom = atom({
  key: 'cookiePolicy',
  default: false,
  effects_UNSTABLE: [
    localStorageEffect('cookie_policy'),
  ]
});
import React from 'react'

const SettingIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '20.783',
  height = '22',
  className = '',
  viewBox = '0 0 20.783 22'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="settings" d="M21.052,14.281a2.2,2.2,0,0,1-.8-1.808v-.948a2.2,2.2,0,0,1,.8-1.808l.885-.727a1.1,1.1,0,0,0,.255-1.4l-1.284-2.2a1.107,1.107,0,0,0-.951-.551.932.932,0,0,0-.387.077l-1.107.4a2.1,2.1,0,0,1-.775.143A2.219,2.219,0,0,1,16.494,5.1a6.069,6.069,0,0,0-.819-.474,2.205,2.205,0,0,1-1.173-1.6l-.188-1.1A1.105,1.105,0,0,0,13.207,1H10.662a1.105,1.105,0,0,0-1.107.926l-.188,1.1a2.2,2.2,0,0,1-1.129,1.6A6.069,6.069,0,0,0,7.42,5.1a2.219,2.219,0,0,1-1.2.353,2.1,2.1,0,0,1-.775-.143l-1.106-.4a.933.933,0,0,0-.387-.077,1.107,1.107,0,0,0-.952.551l-1.261,2.2a1.1,1.1,0,0,0,.266,1.4l.885.727a2.2,2.2,0,0,1,.8,1.808v.948a2.2,2.2,0,0,1-.8,1.808l-.885.727a1.1,1.1,0,0,0-.266,1.4l1.284,2.2a1.108,1.108,0,0,0,.952.551.932.932,0,0,0,.387-.077l1.106-.4a2.1,2.1,0,0,1,.775-.143,2.218,2.218,0,0,1,1.2.353,6.077,6.077,0,0,0,.819.474,2.2,2.2,0,0,1,1.173,1.6l.188,1.1A1.105,1.105,0,0,0,10.729,23h2.545a1.105,1.105,0,0,0,1.106-.926l.188-1.1a2.2,2.2,0,0,1,1.173-1.6,6.078,6.078,0,0,0,.819-.474,2.218,2.218,0,0,1,1.2-.353,2.1,2.1,0,0,1,.775.143l1.106.4a.931.931,0,0,0,.387.077,1.108,1.108,0,0,0,.952-.551l1.284-2.2a1.1,1.1,0,0,0-.332-1.4Zm-9.084,3.781a6.074,6.074,0,1,1,4.3-1.776A6.074,6.074,0,0,1,11.968,18.062ZM9.755,12a2.213,2.213,0,1,1,2.213,2.2A2.209,2.209,0,0,1,9.755,12Z" transform="translate(-1.6 -1)" fillRule="evenodd"/>
  </svg>
}

export default SettingIcon;
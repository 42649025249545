import React from 'react';
const InstagramStroy = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.08',
  fillNone = 'none',
  width = '60',
  className = '',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_942" data-name="Rectangle 942" width={width} height={width} rx="30" fill={fill} />
        </clipPath>
        <radialGradient id="radial-gradient" cx="0.372" cy="1.001" r="1.247" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#fd5" />
          <stop offset="0.328" stopColor="#ff543f" />
          <stop offset="0.348" stopColor="#fc5245" />
          <stop offset="0.504" stopColor="#e64771" />
          <stop offset="0.643" stopColor="#d53e91" />
          <stop offset="0.761" stopColor="#cc39a4" />
          <stop offset="0.841" stopColor="#c837ab" />
        </radialGradient>
        <radialGradient id="radial-gradient-2" cx="0.161" cy="-0.012" r="0.828" gradientTransform="translate(0 -0.004) scale(1 0.666)" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#4168c9" />
          <stop offset="0.999" stopColor="#4168c9" stopOpacity="0" />
        </radialGradient>
      </defs>
      <g id="instagram-story" transform="translate(-5.99 -5.99)">
        <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(5.99 5.99)" clipPath="url(#clip-path)">
          <path id="Path_3" data-name="Path 3" d="M52.677,65.958l-33.316.032A13.367,13.367,0,0,1,6.022,52.677L5.99,19.361A13.367,13.367,0,0,1,19.3,6.022L52.619,5.99A13.367,13.367,0,0,1,65.958,19.3l.032,33.316A13.363,13.363,0,0,1,52.677,65.958Z" transform="translate(-5.99 -5.99)" fill="url(#radial-gradient)" />
          <path id="Path_4" data-name="Path 4" d="M52.677,65.958l-33.316.032A13.367,13.367,0,0,1,6.022,52.677L5.99,19.361A13.367,13.367,0,0,1,19.3,6.022L52.619,5.99A13.367,13.367,0,0,1,65.958,19.3l.032,33.316A13.363,13.363,0,0,1,52.677,65.958Z" transform="translate(-5.99 -5.99)" fill="url(#radial-gradient-2)" />
        </g>
        <g id="noun_Instagram_Stories_682475" data-name="noun_Instagram Stories_682475" transform="translate(13.001 12.96)">
          <g id="Group_1080" data-name="Group 1080" transform="translate(4.99 5.03)">
            <g id="Group_1075" data-name="Group 1075" transform="translate(8.814 31.551)">
              <path id="Path_5547" data-name="Path 5547" d="M36.112,88.33a18.115,18.115,0,0,1-8.486-2.112A1.243,1.243,0,1,1,28.8,84.027,15.647,15.647,0,0,0,39.66,85.44a1.242,1.242,0,1,1,.566,2.42A18.2,18.2,0,0,1,36.112,88.33Z" transform="translate(-26.967 -83.88)" fill={fill} />
            </g>
            <g id="Group_1076" data-name="Group 1076" transform="translate(0.39 20.722)">
              <path id="Path_5548" data-name="Path 5548" d="M11.982,67.505a1.241,1.241,0,0,1-.856-.341A17.933,17.933,0,0,1,6,58.353a1.245,1.245,0,0,1,2.42-.585,15.454,15.454,0,0,0,4.42,7.593,1.242,1.242,0,0,1-.857,2.144Z" transform="translate(-5.963 -56.818)" fill={fill} />
            </g>
            <g id="Group_1077" data-name="Group 1077" transform="translate(0 9.693)">
              <path id="Path_5549" data-name="Path 5549" d="M6.236,37.135q-.061,0-.124-.006A1.243,1.243,0,0,1,5,35.77a17.84,17.84,0,0,1,1.589-5.8,1.245,1.245,0,0,1,2.258,1.049,15.355,15.355,0,0,0-1.368,4.993A1.244,1.244,0,0,1,6.236,37.135Z" transform="translate(-4.99 -29.254)" fill={fill} />
            </g>
            <g id="Group_1078" data-name="Group 1078" transform="translate(5.225 4.196)">
              <path id="Path_5550" data-name="Path 5550" d="M19.263,18.373a1.242,1.242,0,0,1-.961-2.03q.167-.2.339-.4a1.244,1.244,0,0,1,1.877,1.632c-.1.113-.2.228-.292.345A1.244,1.244,0,0,1,19.263,18.373Z" transform="translate(-18.019 -15.516)" fill={fill} />
            </g>
            <g id="Group_1079" data-name="Group 1079" transform="translate(16.713)">
              <path id="Path_5551" data-name="Path 5551" d="M56.526,38.661a1.242,1.242,0,0,1-.641-2.308A15.511,15.511,0,0,0,47.909,7.515a1.242,1.242,0,1,1,0-2.485,17.994,17.994,0,0,1,9.255,33.454A1.236,1.236,0,0,1,56.526,38.661Z" transform="translate(-46.664 -5.03)" fill={fill} />
            </g>
            <path id="Path_5552" data-name="Path 5552" d="M45.727,36.4H38.594V29.285H36.1V36.4H28.97v2.484H36.1V46h2.49V38.887h7.133Z" transform="translate(-19.353 -19.58)" fill={fill} />
          </g>
        </g>
      </g>
    </svg>

  </>
);
export default InstagramStroy;
import React from 'react';
const PhotoIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  height = '19.643',
  className = '',
  viewBox = '0 0 24 19.643'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <path id="image-icon" d="M3.857,4.156A1.73,1.73,0,0,0,2.143,5.889v10.4L5.8,13.513a.567.567,0,0,1,.7.011l5.254,4.248,6.5-8.765a.568.568,0,0,1,.9-.023l4.7,5.706v-8.8a1.73,1.73,0,0,0-1.714-1.733ZM2.143,19.747V17.725L6.132,14.7,11.5,19.042a.567.567,0,0,0,.814-.1l6.424-8.657,5.12,6.209v3.257a1.73,1.73,0,0,1-1.714,1.733H3.86a1.73,1.73,0,0,1-1.714-1.733ZM1,5.88V19.747a2.879,2.879,0,0,0,2.857,2.888H22.143A2.879,2.879,0,0,0,25,19.747V5.88a2.879,2.879,0,0,0-2.857-2.888H3.857A2.879,2.879,0,0,0,1,5.88Zm6.286.578A1.733,1.733,0,1,0,9,8.19,1.723,1.723,0,0,0,7.286,6.457ZM6.715,8.19a.572.572,0,1,1,.571.578A.575.575,0,0,1,6.715,8.19Z" transform="translate(-1 -2.992)" fill={fill} fillRule="evenodd"/>
    </svg>
  </>
);
export default PhotoIcon;

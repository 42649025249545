import React from 'react';
const PlusIcon = ({
  style = {},
  fill = '#130c18',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '20',
  className = '',
  viewBox = '0 0 20 20'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="plus" transform="translate(-200 -854)">
        <rect id="Rectangle_1049" data-name="Rectangle 1049" width="20" height="1.667" transform="translate(200 863.167)" fill={fill} />
        <rect id="Rectangle_1050" data-name="Rectangle 1050" width="1.667" height="20" transform="translate(209.167 854)" fill={fill} />
      </g>
    </svg>


  </>
);
export default PlusIcon;
import SVGIcon from '../svg'
import { formatDuration, setSeparateState } from '../../helpers/utils'
import Button from '../basic/button'
import React from 'react'
import {Link} from 'react-router-dom'
import VideoPlayer from '../swiper/video-player'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { messageTeaserState } from '../../state/components/message'
import { useFetchApiAction } from '../../action/main-action'
import Mutations from '../../params/mutations'
import { messageInboxAtom } from '../../state/pages/message'
import { authProfileAtom } from '../../state/auth'
import { Puff } from 'react-loader-spinner'
import variables from '../../scss/variables.module.scss'

const Teaser = ({item, value, thread, remove, player = true, setPlayed}) => {
  const [{ lock, unlocking, error }, setTeaser] = useRecoilState(messageTeaserState(item.id));
  const setLock = setSeparateState(setTeaser, "lock");
  const setError = setSeparateState(setTeaser, "error");
  const setUnlocking = setSeparateState(setTeaser, "unlocking");
  const fetchApi = useFetchApiAction();
  const setMessageInbox = useSetRecoilState(messageInboxAtom);
  const authProfile = useRecoilValue(authProfileAtom);
  const setList = setSeparateState(setMessageInbox, "list")

  const unlock = () => {
    if (!player || value.url || item.from.id === authProfile.id) return
    setUnlocking(true);
    setError('');
    fetchApi(Mutations.Messages.UnlockTeaser, { message_id: item.id, thread }).then((res) => {
      if (!res.unlock_teaser) {
        setError('wallet');
        setUnlocking(false);
        return;
      }
      setList(old => old.map(m => {
        if (m.id === item.id) {
          return res.unlock_teaser;
        }
        return m;
      }))
      setLock(false);
      setUnlocking(false);
    })
  }
  return <>
    {player && value.teaser && <div className={!lock && !value.url ? "" : "jf-hide"}><VideoPlayer
      sources={[{ url: value.teaser.url, type: "hls" }]}
      options={{ controls: true }}
      id={item.id}
      timeupdate={(player) => {
        if (value.url) {
          return;
        }
        if (player.currentTime() >= value.preview) {
          setLock(true)
          if (setPlayed) {
            setPlayed();
          }
        }
      }}
      type={'normal'}/></div>}
    {player && value.url && <VideoPlayer sources={[{ url: value.url, type: "hls" }]}
                                         options={{ controls: true }}
                                         id={item.id}
                                         type={'normal'}/>}
    {remove && <button className={'jf-no-style-btn jf-close'} onClick={() => remove(item)} type={'button'}>
      <SVGIcon name={'close-circle-icon'}/>
    </button>}

    {(!value.duration || lock || !value.teaser || !player) && <img alt={""} src={value.thumbnail}/>}
    {lock && <div className={'jf-img-cover'}>
      <SVGIcon name={'clock-icon'}/>
    </div>}
    <div className={'jf-messages-price'}>
      {value.duration && <div className={'jf-flex-row jf-mb-12px'}>
        <SVGIcon name={'video-camera-icon'} width={20} height={20}/>
        <div className={'jf-ml-5px'}>{formatDuration(value.duration || 0)}</div>
      </div>}
      <Button onClick={unlock}>{
        unlocking ? <Puff
          color={variables['colors-jf-secondary']}
          height={20}
          width={20}/> : value.price > 0 ?
          value.url ? 'UNLOCKED' : ('UNLOCK FOR $' + value.price) : 'FREE'
      }</Button>
    </div>
    {error === "wallet" && <>
      <div className="jf-error">Not enough credits in your wallet.</div>
      <Link to={'/'}>Add more credits</Link></>}
    <div>{value.text}</div>
  </>;
}

export default Teaser;
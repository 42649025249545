import { createClient } from 'graphql-ws';
import { DCookie } from '../helpers/utils'

const Constants = {
  graphqlWSClient: null,
  graphqlWSClientUrl: null,
  Site: {
    Name: "JuneFox",
    URL: "https://junefox.com"
  },
  Gender: ["man", "woman","other"],
  Interests: ["men", "women", "other"],
  ReportReason: ["Stolen content", "Not working", "Spam", "Inappropriate content", "Other"],
  WalletFunds: [10, 20, 50, 100],
  PeriodUnit: ["day","month"],
  Contact: "support@junefox.com",
  ShortUrl: "https://sl.junefox.com",
  LiveServer: "rtmp://live.junefox.com/live/",
  WebRTCServer: "wss://live.junefox.com:3334/live/",
  ListLimit: 10,
  MediaListLimit: parseInt(process.env.REACT_APP_MEDIA_LIMIT_DEFAULT),
  videoExtensions: ['mp4', 'avi', 'mkv', 'webm', 'mov'],
  photoExtensions: ['png', 'jpg', 'webp'],

  init: (id) => {
    const url = `${process.env.REACT_APP_WS_API_URL}/api` + (id ? "?auth=" + DCookie.getItem("access_token") : "");
    if (Constants.graphqlWSClientUrl === url && Constants.graphqlWSClient) {
      return;
    }
    if (Constants.graphqlWSClient) {
      Constants.graphqlWSClient.dispose();
    }

    Constants.graphqlWSClientUrl = url;
    Constants.graphqlWSClient = createClient({
      url: url,
      keepAlive: 60000 // 1 minute
    })
  },
};

export default Constants;
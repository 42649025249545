import React from 'react';
const ShareIcon2 = ({
                        width = '30',
                        height = '30',
                        viewBox = '0 0 30 30'
                    }) => (
    <>
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0462 9.89863H19.4875C18.97 9.89863 18.55 10.318 18.55 10.8361C18.55 11.3543 18.97 11.7736 19.4875 11.7736H22.0469C22.5637 11.7736 22.9844 12.1943 22.9844 12.7111V24.9711C22.9844 25.488 22.5637 25.9086 22.0469 25.9086H7.95374C7.43686 25.9086 7.01624 25.488 7.01624 24.9711V12.7111C7.01624 12.1943 7.43686 11.7736 7.95374 11.7736H10.5131C11.0312 11.7736 11.4506 11.3543 11.4506 10.8361C11.4506 10.318 11.0312 9.89863 10.5131 9.89863H7.95374C6.40311 9.89863 5.14124 11.1605 5.14124 12.7111V24.9711C5.14124 26.5218 6.40311 27.7836 7.95374 27.7836H22.0469C23.5975 27.7836 24.8594 26.5218 24.8594 24.9711V12.7111C24.8587 11.1599 23.5975 9.89863 22.0462 9.89863ZM11.5044 8.00613L14.0937 5.41676V17.7593C14.0937 18.2768 14.5131 18.6968 15.0312 18.6968C15.5494 18.6968 15.9687 18.2768 15.9687 17.7593V5.41676L18.5581 8.00613C18.7412 8.18926 18.9812 8.28051 19.2212 8.28051C19.4612 8.28051 19.7012 8.18926 19.8844 8.00613C20.2506 7.64051 20.2506 7.04613 19.8844 6.68051L15.695 2.49051C15.3281 2.12426 14.735 2.12426 14.3687 2.49051L10.1787 6.68051C9.81249 7.04613 9.81249 7.64051 10.1787 8.00613C10.545 8.37238 11.1381 8.37238 11.5044 8.00613Z" fill="#615DFA"/>
        </svg>
    </>
);
export default ShareIcon2;

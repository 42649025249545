import TopMenu from '../../../../components/basic/top-menu'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BottomMenu from '../../../../components/basic/bottom-menu'
import Button from '../../../../components/basic/button'
import SelectTab from '../../../../components/basic/select-tab'
import Avatar from '../../../../components/basic/avatar'
import variables from '../../../../scss/variables.module.scss'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { activePopupState } from '../../../../state/components/popup'
import SVGIcon from '../../../../components/svg'
import Transactions from './transactions'
import Subscriptions from './subscriptions'
import { accountPurchasesAtom } from '../../../../state/pages/account'
import { setSeparateState } from '../../../../helpers/utils'
import { useFetchApiAction } from '../../../../action/main-action'
import Queries from '../../../../params/queries'
import Constants from '../../../../params/constants'
import Mutations from '../../../../params/mutations'

const Purchases = ({paramsUrl}) => {
  const navigate = useNavigate();
  const [{list, cards, wallet}, setPurchases] = useRecoilState(accountPurchasesAtom);
  const setList = setSeparateState(setPurchases, "list");
  const setCards = setSeparateState(setPurchases, "cards");
  const setWallet = setSeparateState(setPurchases, "wallet");
  const fetchApi = useFetchApiAction();
  const setAddCardPopup = useSetRecoilState(activePopupState('add_card'));

  useEffect(() =>{
    fetchApi(Queries.Wallet).then(res => {
      setWallet(res.wallet);
      setCards(res.wallet.cards || [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() =>{
    setList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUrl.tab])

  const loadMore = () => {

  }

  const removeCard = (index) => {
    const data = [...cards]
    data.splice(index, 1);

    fetchApi(Mutations.UpdateWallet, { cards: data }).then((res) => {
      if (res && res.updateWallet) {
        setCards(res.updateWallet.cards || [])
        setWallet(res.updateWallet)
      } else if (res.errors && res.errors[0]) {
        // setMsg(res.errors[0].message);
      }
    });
  }

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"Purchases"}
      action={() => navigate( -1)}
    />
    <div className={'jf-basic-page jf-purchases-page'}>
      <div className={'jf-purchases-wallet'}>
        <div>${wallet.amount}</div>
        <div>Wallet credits</div>
      </div>
      <div className={'jf-funds-note'}>ADD FUNDS TO YOUR WALLET</div>
      <div className={'jf-flex-row jf-funds'}>
        {Constants.WalletFunds.map(txt => <Button key={"jf-funds-" + txt} className={'white-btn'}>${txt}</Button>)}</div>
      <div className={'jf-pl-20px jf-pr-20px'}>
        {cards.map((card, index) => <div key={index} className={'jf-flex-row jf-gap-10px jf-mb-20px'}>
          <Avatar image={card.name}/>
          <div className={'jf-flex-col'} style={{flexGrow: 1}}>
            <div>{card.name}</div>
            <div>**** **** **** {card.number.substr(-4)}</div>
          </div>
          <button className={'jf-no-style-btn'} onClick={() => removeCard(index)}>
            <SVGIcon name={'close-circle-icon'}
                     fillCircle={variables['colors-jf-primary']}/>
          </button>
        </div>)}
      <Button onClick={()=>{
        setAddCardPopup({active:true, afterAdd: (data) => setCards(old => [...old, data])})
      }}>Add card</Button>
      <SelectTab
        style={{minHeight: 50, marginTop: 20}}
        tabs={[{
          id: 'transactions',
          name: 'Transactions',
        }, {
          id: 'subscriptions',
          name: 'Subscriptions',
        }]}
        tab={{id: paramsUrl.tab}}
        setTab={(tab) =>  navigate("/account/purchases/" + tab.id)}
      />
        {paramsUrl.tab === "transactions" && <Transactions list={list} loadMore={loadMore}/>}
        {paramsUrl.tab === "subscriptions" && <Subscriptions list={list} loadMore={loadMore}/>}
      </div>
    </div>
    <BottomMenu/>
  </>;
}

export default Purchases;
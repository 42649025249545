import React from 'react';
const Imageicon = ({
  style = {},
  fill = '#ffd06a',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="image" transform="translate(0 -30)">
        <path id="Path_5587" data-name="Path 5587" d="M0,30H20V70H0Z" transform="translate(0 0)" fill="#c4f3ff" />
        <path id="Path_5588" data-name="Path 5588" d="M256,30h20V70H256Z" transform="translate(-236 0)" fill="#8ae7ff" />
        <path id="Path_5589" data-name="Path 5589" d="M200.688,90,196,115.4l20-2.655V90Z" transform="translate(-180.688 -54.69)" fill="#3a6fd8" />
        <path id="Path_5590" data-name="Path 5590" d="M60,90v14.69L75.313,115.4V90Z" transform="translate(-55.313 -54.69)" fill="#3b88f5" />
        <path id="Path_5591" data-name="Path 5591" d="M200.688,312.164H216v-6.637c-7.656,0-11.484-2.013-15.312-4.027L196,306.832Z" transform="translate(-180.688 -247.473)" fill="#2db94d" />
        <path id="Path_5592" data-name="Path 5592" d="M60,256v14.69H75.313V260.027C71.484,258.013,67.656,256,60,256Z" transform="translate(-55.313 -206)" fill="#61ce5a" />
        <circle id="Ellipse_90" data-name="Ellipse 90" cx="4" cy="4" r="4" transform="translate(23.93 38.619)" fill={fill} />
      </g>
    </svg>

  </>
);
export default Imageicon;
import React from 'react';
const BottomArrowIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '40',
  className = '',
  strokeWidth=2,
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="bottom_arrow" data-name="bottom arrow" transform="translate(66 -1318) rotate(90)">
        <rect id="Rectangle_1058" data-name="Rectangle 1058" width={width} height={width} transform="translate(1318 26)" fill={fillNone} />
        <path id="Expand_More" data-name="Expand More" d="M732.464,57.942,720.05,70.24l-12.418-12.3a1.524,1.524,0,0,0-2.141,0,1.493,1.493,0,0,0,0,2.124l13.489,13.361h0a1.52,1.52,0,0,0,2.138,0l13.489-13.361a1.5,1.5,0,0,0,0-2.124A1.528,1.528,0,0,0,732.464,57.942Z" transform="translate(1272.5 766.047) rotate(-90)" fill={fill} stroke={fill} strokeWidth={strokeWidth} />
      </g>
    </svg>

  </>
);
export default BottomArrowIcon;
import React, {useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DCookie } from '../../../helpers/utils'
import { useSetRecoilState } from 'recoil'
import { authIdAtom, authProfileAtom } from '../../../state/auth'
import Queries from '../../../params/queries'
import { useFetchApiAction } from '../../../action/main-action'

function WelcomeScreen() {
  const navigate = useNavigate();
  const fetchAction = useFetchApiAction();
  const setAuthId = useSetRecoilState(authIdAtom);
  const setAuthProfile = useSetRecoilState(authProfileAtom);

  useEffect(() => {
    fetchAction(Queries.Account.LoggedIn, {}).then(res => {
      if (res.logged_in?.account) {
        setAuthId(DCookie.getItem('auth_id'))
        setAuthProfile(res.logged_in.account)
        navigate('/');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'jf-container'}>
      <header className="jf-splashHeader">
        <div className="jf-logo">june<span className="jf-logo-span">fox</span></div>
      </header>
      <div className="jf-home-bg">
        <div style={{flexGrow: 1 }}/>
        <div className="jf-content">
          <div className="jf-social">
            <div className="jf-social-wrap">
              <h1 className="jf-social-title">Real People<br/><span className="jf-social-color">Real Videos</span></h1>
              <p className="jf-social-desc">Explore the world by watching and creating live streaming</p>
            </div>
            <div className="js-actions">
              <Link to="./signin" className="jf-btn jf-btn-primary">Sign In</Link>
              <Link to="./signup" className="jf-btn jf-btn-secondary">Sign Up</Link>
            </div>
          </div>
          {/* <Link to="/login" className="jf-btn jf-btn-login">Sign In</Link> */}
          <p className="jf-copyright">&copy; 2021. All rights reserved.</p>
        </div>
      </div>
    </div>


  );
}

export default WelcomeScreen

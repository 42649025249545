import React from 'react'

const EmojiIcon = ({
  style = {},
  fill = '#afafb3',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1073" data-name="Group 1073" transform="translate(-364 -849)">
        <g id="Ellipse_71" data-name="Ellipse 71" transform="translate(364 849)" fill="none" stroke="#fff"
           strokeWidth="2">
          <circle cx="12" cy="12" r="12" stroke="none"/>
          <circle cx="12" cy="12" r="11" fill="none"/>
        </g>
        <g id="Group_1072" data-name="Group 1072">
          <ellipse id="Ellipse_72" data-name="Ellipse 72" cx="1.5" cy="2" rx="1.5" ry="2" transform="translate(371 858)"
                   fill={fill}/>
          <ellipse id="Ellipse_73" data-name="Ellipse 73" cx="1.5" cy="2" rx="1.5" ry="2" transform="translate(378 858)"
                   fill={fill}/>
        </g>
        <path id="Path_5543" data-name="Path 5543"
              d="M3,0C4.657,0,6,.575,6,1.284S4.657,3,3,3,0,1.993,0,1.284,1.343,0,3,0Z" transform="translate(373 864)"
              fill={fill}
        />
      </g>
    </svg>
  </>
)
export default EmojiIcon
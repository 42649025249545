import React, { useEffect, useCallback, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { activeBottomPopupState, commentPopupAtom } from '../../../state/components/popup'
import SVGIcon from '../../svg'
import { Virtuoso } from 'react-virtuoso'
import { useFetchApiAction } from '../../../action/main-action'
import Queries from '../../../params/queries'
import Avatar from '../../basic/avatar'
import { msgLastTimeFormat, setSeparateState, uniqueList } from '../../../helpers/utils'
import FavoriteButton from '../../basic/favorite-button'
import Mutations from '../../../params/mutations'
import { authProfileAtom } from '../../../state/auth'
import variables from '../../../scss/variables.module.scss'
import {useNavigate} from 'react-router-dom';
import Constants from '../../../params/constants'
import { mediaBoxAtom, mediaDashboardAtom, updatingAtom } from '../../../state/media'

const Comment = ({hide}) => {
  const info = useRecoilValue(activeBottomPopupState('comment'));
  const [{text,count,comments,loading, limited}, setCommentPopup] = useRecoilState(commentPopupAtom)
  const fetchApi = useFetchApiAction();
  const authProfile = useRecoilValue(authProfileAtom);
  const navigate = useNavigate();
  const chatRef = useRef();
  const limitAmount = Constants.ListLimit;

  const setLoading = setSeparateState(setCommentPopup, "loading");
  const setLimited = setSeparateState(setCommentPopup, "limited");
  const setComments = setSeparateState(setCommentPopup, "comments");
  const setCount = setSeparateState(setCommentPopup, "count");
  const setText = setSeparateState(setCommentPopup, "text");
  const setUpdating = useSetRecoilState(updatingAtom)
  const [media, setMedia] = useRecoilState(mediaDashboardAtom);
  const [mediaBox, setMediaBox] = useRecoilState(mediaBoxAtom);

  useEffect(() => {
    setLoading(true)
    if (!info.media) {
      return;
    }
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  const loadMore = useCallback(() => {
    if (loading || limited) return;
    setLoading(true);
    const limit = {limit: limitAmount, order: false, skip: comments.length};
    const filter = {media: info.media};
    fetchApi(Queries.Comments, {limit, filter, media: info.media}).then((res) => {
      if (!res || !res.mediaComments) return;
      setComments(old => uniqueList([...old, ...res.mediaComments]))
      setCount(res.media_comments_count)
      setLoading(false)
      setLimited(res.mediaComments.length < limitAmount);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments,loading, info.media,fetchApi, limited])

  const send = useCallback(() => {
    if (text !== '') {
      const message = text.substr(0,512);
      setText('');
      fetchApi(Mutations.Comments.Add, {
        "media": info.media,
        message
      }).then(res => {
        if (!res || !res.addComment) return;
        setComments(old => [...old, res.addComment])
        chatRef.current.scrollToIndex(comments.length);
        setCount(old => old+1);
        const newList = media ? media.map((old) => {
          if(old.id === info.media) {
            return {...old, comments: old.comments + 1}
          }
          return old;
        }) : []
        const newListBox = mediaBox ? mediaBox.map((old) => {
          if(old.id === info.media) {
            return {...old, comments: old.comments + 1}
          }
          return old;
        }) : []
        setUpdating(true);
        setMedia([...newList]);
        setMediaBox([...newListBox]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRef, info.media,fetchApi, text, setText, comments])

  const clickLikeBtn = (item) => {
    if (loading || !authProfile) return;
    setLoading(true);
    fetchApi(Mutations.Comments.Like, {
      "comment": item.id,
    }).then(res => {
      if (!res || res.likeComment === undefined) return;
      setComments(old =>{
        return  old.map(oldItem => oldItem.id === item.id ? {
          ...oldItem,
          likes: oldItem.likes + (res.likeComment ? 1 : -1),
          like: res.likeComment,
        } : oldItem);
      })
      setLoading(false);
    });
  }

  return <>
    <div className='jf-popup-comment-header'>
      <div className={'title'}>{count} Comment{count> 1?'s' :''}</div>
      <button className={'jf-no-style-btn jf-popup-close-btn'}
              onClick={hide}><SVGIcon name={'close-icon'} width={20}/></button>
    </div>
    <div className={'jf-popup-comment-list'}>
      <Virtuoso
        data={comments} ref={chatRef}
        endReached={loadMore}
        itemContent={(index, comment) => {
          return <div className={'jf-comment-item'}>
            <Avatar image={comment.from.avatar} width={40} height={40} text={comment.from.fullname}
                    username={comment.from.username} onClick={hide}/>
            <div className={'jf-comment-item-body'}>
              <div className={'jf-pointer'}
                onClick={() => {
                  navigate('/'+ comment.from.username)
                  hide();
                }}>@{comment.from.username}</div>
              <div>{comment.message}</div>
              <div>{msgLastTimeFormat(comment.add_date)}</div>

            </div>
            <FavoriteButton
              onClickCallback={() => clickLikeBtn(comment)}
              count={comment.likes}
              enabled={comment.like}
              showRequire={!authProfile}
            />
          </div>
        }}
      />
    </div>
    <div className='jf-popup-comment-footer jf-write-box'>
      {authProfile ? <input
        className='jf-write-box-input'
        placeholder={'Say something nice...'}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            send()
          }
        }}
        value={text} onChange={(e) => setText(e.target.value)}
      />: <div className={'jf-write-box-input alert'}>You need to login write comment</div>}
      {text && <button className={'jf-no-style-btn'} onClick={send}>
        <SVGIcon name={'send-fill-icon'} fill={variables['colors-jf-white']} width={22} height={24}/>
      </button>}
    </div>
  </>
}

export default Comment;
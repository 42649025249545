import React from 'react';
const ThreeDotsMenuicon2 = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="dots" transform="translate(-354 -560)">
        <path id="Path_41" data-name="Path 41" d="M0,0H40V40H0Z" transform="translate(354 560)" fill={fillNone} />
        <g id="Group_1037" data-name="Group 1037" transform="translate(386 583) rotate(180)">
          <circle id="Ellipse_6" data-name="Ellipse 6" cx="2.5" cy="2.5" r="2.5" fill={fill} />
          <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" transform="translate(9)" fill={fill} />
          <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(18)" fill={fill} />
        </g>
      </g>
    </svg>

  </>
);
export default ThreeDotsMenuicon2;
import React, {useEffect, useCallback} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SearchTextField from '../../../../components/basic/search-text-field'
import Avatar from '../../../../components/basic/avatar'
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import SelectTab from '../../../../components/basic/select-tab'
import { Virtuoso } from 'react-virtuoso'
import Queries from '../../../../params/queries'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import Constants from '../../../../params/constants'
import SVGIcon from '../../../../components/svg'
import variables from '../../../../scss/variables.module.scss'
import { onlyUnique, setSeparateState, uniqueList } from '../../../../helpers/utils'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { messageMassUserAtom } from '../../../../state/pages/message'

const MassUser =  ({match}) => {
  const [{tab, skipTabQuery, custom, loadingMore, query, selectedUsers, users }, setMessageMassUser] = useRecoilState(messageMassUserAtom);
  const setUsers = setSeparateState(setMessageMassUser, "users")
  const setSelectedUsers = setSeparateState(setMessageMassUser, "selectedUsers")
  const setQuery = setSeparateState(setMessageMassUser, "query")
  const setLoadingMore = setSeparateState(setMessageMassUser, "loadingMore")
  const setCustom = setSeparateState(setMessageMassUser, "custom")
  const setSkipTabQuery = setSeparateState(setMessageMassUser, "skipTabQuery")
  const setTab = setSeparateState(setMessageMassUser, "tab")

  const navigate = useNavigate();
  const location = useLocation();
  const fetchAction = useFetchApiAction();
  const limit = Constants.ListLimit;

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageMassUser = useResetRecoilState(messageMassUserAtom)
  useEffect(() => {
    resetStateAction.add(resetMessageMassUser);

    setSelectedUsers(location.state?.users || []);
    setTab(location.state?.tab || "everyone");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (skipTabQuery === 1) {
      setSkipTabQuery(0);
      return ;
    }

    let customNew = custom;
    if(skipTabQuery !== -1) {
      setSelectedUsers([]);
      setCustom(true);
      customNew = true
    }

    let isCanceled = false;
    loadData(query, tab, isCanceled, (list) => {
      if (customNew && (skipTabQuery !== -1 || (skipTabQuery === -1 && location.state?.users.length === 0))) {
        setSelectedUsers(list.map(item => item.id));
      }
    });
    return () => isCanceled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if(skipTabQuery === -1) {
      setSkipTabQuery(0);
      return ;
    }
    let isCanceled = false;
    setSkipTabQuery(1);
    loadData(query, tab, isCanceled, (list) => {
      setSkipTabQuery(0);
    });
    return () => isCanceled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const loadData = (query, tab, isCanceled, cb) => {
    setUsers([]);
    const variables = {limit: {limit},filter: {query: query}, type: tab}
    fetchAction(Queries.Account.SearchRelation, variables).then(res => {
      if (isCanceled) return;
      const list = res.relative_users || [];
      setUsers(list);
      setLoadingMore(list.length === limit ? 1 : 2);
      cb && cb(list);
    });
  }

  const loadMore = useCallback(() => {
    if (loadingMore !== 1) return;
    const variables = {limit: {limit,skip: users.length},filter: {query: query}, type: tab}
    fetchAction(Queries.Account.SearchRelation, variables).then(res => {
      let list = res.relative_users || [];
      setUsers(uniqueList(users.concat(list)));
      setLoadingMore(list.length === limit ? 1 : 2);
      if (custom) {
        setSelectedUsers(old => (uniqueList([...old.concat(list.map(item => item.id))])));
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, loadingMore, fetchAction, query]);

  const onCheck= useCallback((user) => {
    const newList = [...selectedUsers].filter(onlyUnique);
    const indexEx = newList.indexOf(user.id);
    if (indexEx === -1) {
      newList.push(user.id);
    } else {
      newList.splice(indexEx, 1);
    }
    setSelectedUsers([...newList]);

    if (newList.length > 0) {
      setCustom(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, users]);

  const goNext = useCallback(() => {
    const state = {
      ...location.state,
      custom: custom,
      users: selectedUsers,
      tab: tab
    };

    navigate(location.state?.url, {state, replace: true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom, selectedUsers, tab]);

  return <>
    <TopMenu
    title={'New mass message'}
    icon={'greater-icon'}
    action={() => navigate(-1)}
    rightContent={<div style={{ textAlign: 'end' }}>
      <button className={'jf-no-style-btn jf-font-20-22 jf-color-gray jf-font-m'}
              style={{ marginRight: 10 }}
              onClick={goNext}>Next
      </button>
    </div>}
    />
    <div style={{flexGrow: 1}} className={'jf-flex-col jf-mass-message'}>
      <SelectTab
        style={{height: 45}}
        liClassName={'jf-font-r'}
        tabs={[
          { id: "everyone", name: "Everyone" },
          { id: "follower", name: "Followers" },
          { id: "subscriber", name: "Subscribers" },
        ]}
        tab={{id: tab}}
        setTab={(tab) => {
          setTab(tab.id)
        }}
      />

      <div className={'jf-flex-row jf-ml-20px jf-pointer'} onClick={() => {
        if (custom) {
          setSelectedUsers([]);
        } else {
          setSelectedUsers(users.map(item => item.id));
        }
        setCustom(!custom);
      }}>
        <button className={'jf-check-circle-btn'}>
          <SVGIcon name={'check-circle-icon'}
                   fillCircle={custom ? variables['colors-jf-primary'] : "#fff"}/>
        </button>
        <div className={'jf-ml-10px'}>Custom Select</div>
      </div>

      <SearchTextField onChange={setQuery} placeholder={'Search'}/>
      <div className={'jf-mb-15px'}/>
      <Virtuoso
        data={users}
        endReached={loadMore}
        itemContent={(index, user) => {
          return <div className={'jf-user-item'}>
            <Avatar image={user.avatar} width={60} height={60} text={user.fullname}/>
            <div className={'jf-user-item-body'}>
              <div>{user.fullname}</div>
              <div>@{user.username}</div>
            </div>
            <button className={'jf-check-circle-btn'} onClick={() => onCheck(user)}>
              <SVGIcon name={'check-circle-icon'}
                       fillCircle={selectedUsers?.indexOf(user.id) > -1 ? variables['colors-jf-primary'] : '#fff'}/>
            </button>
          </div>
        }}
      />
    </div>
    <BottomMenu/>
  </>
}

export default MassUser;
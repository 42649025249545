import React from 'react';
const LiveStreamingIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '60',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1995" data-name="Group 1995" transform="translate(-1667 -390)">
        <rect id="Rectangle_1217" data-name="Rectangle 1217" width={width} height={width} transform="translate(1667 390)" fill={fillNone} />
        <path id="broadcast" d="M9.532,4.864a21.875,21.875,0,0,0,0,30.937,1.562,1.562,0,1,1-2.21,2.21,25,25,0,0,1,0-35.356,1.562,1.562,0,1,1,2.21,2.21Zm6.631,6.631a12.5,12.5,0,0,0,0,17.675,1.564,1.564,0,1,1-2.212,2.212,15.625,15.625,0,0,1,0-22.1,1.564,1.564,0,0,1,2.212,2.212ZM33.838,9.282a1.562,1.562,0,0,1,2.212,0,15.625,15.625,0,0,1,0,22.1,1.564,1.564,0,1,1-2.212-2.212,12.5,12.5,0,0,0,0-17.675,1.562,1.562,0,0,1,0-2.212Zm6.631-6.625a1.562,1.562,0,0,1,2.209,0,25,25,0,0,1,0,35.353,1.562,1.562,0,1,1-2.21-2.21,21.875,21.875,0,0,0,0-30.937,1.562,1.562,0,0,1,0-2.209ZM31.25,20.332A6.25,6.25,0,1,1,25,14.082,6.25,6.25,0,0,1,31.25,20.332Z" transform="translate(1672.5 399.304)" fill={fill} stroke={fill} strokeWidth="1" />
      </g>
    </svg>

  </>
);
export default LiveStreamingIcon;
import React from 'react';
const Coin = ({
  style = {},
  fill = '#fb3',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '20',
  className = '',
  viewBox = '0 0 20 20'
}) => (
  <>
    <svg id="money" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <circle id="Ellipse_147" data-name="Ellipse 147" cx="10" cy="10" r="10" fill="#ffe14d" />
      <path id="Path_6229" data-name="Path 6229" d="M266,10A10.011,10.011,0,0,0,256,0V20A10.011,10.011,0,0,0,266,10Z" transform="translate(-246)" fill="#fb3" />
      <g id="Group_1618" data-name="Group 1618" transform="translate(2.383 2.383)">
        <g id="Group_1617" data-name="Group 1617">
          <path id="Path_6230" data-name="Path 6230" d="M68.617,76.234a7.617,7.617,0,1,1,7.617-7.617A7.626,7.626,0,0,1,68.617,76.234Z" transform="translate(-61 -61)" fill={fill} />
        </g>
      </g>
      <path id="Path_6231" data-name="Path 6231" d="M263.617,68.617A7.626,7.626,0,0,0,256,61V76.234A7.626,7.626,0,0,0,263.617,68.617Z" transform="translate(-246 -58.617)" fill="#e68a2e" />
      <g id="Cent_2_" transform="translate(7.07 4.721)">
        <path id="Path_6232" data-name="Path 6232" d="M184.516,125.589v-2.317c.674.165,1.172.6,1.172,1.1a.586.586,0,0,0,1.172,0,2.549,2.549,0,0,0-2.344-2.3v-.633a.586.586,0,1,0-1.172,0v.633a2.3,2.3,0,1,0,0,4.593v2.317c-.674-.165-1.172-.6-1.172-1.1a.586.586,0,1,0-1.172,0,2.549,2.549,0,0,0,2.344,2.3v.639a.586.586,0,0,0,1.172,0v-.639a2.3,2.3,0,1,0,0-4.593Zm-2.344-1.219c0-.5.5-.932,1.172-1.1v2.195C182.67,125.3,182.172,124.868,182.172,124.37Zm2.344,4.613v-2.195c.674.165,1.172.6,1.172,1.1S185.19,128.817,184.516,128.983Z" transform="translate(-181 -120.854)" fill="#ffe14d" />
      </g>
      <path id="Path_6233" data-name="Path 6233" d="M256.586,130.821v-.639a2.3,2.3,0,1,0,0-4.593v-2.317c.674.165,1.172.6,1.172,1.1a.586.586,0,0,0,1.172,0,2.549,2.549,0,0,0-2.344-2.3v-.633a.586.586,0,0,0-.586-.586v10.553A.586.586,0,0,0,256.586,130.821Zm0-4.033c.674.165,1.172.6,1.172,1.1s-.5.932-1.172,1.1Z" transform="translate(-246 -116.133)" fill={fill} />
    </svg>

  </>
);
export default Coin;
import React from 'react'
import accountIcons from './account/index';
import generalIcons from './general-icon/index';
import footerIcons from './footer/index';
import userPanelIcon from './userpannel/index';
import modelIcon from './modelicon/index';

const getPath = (name, props, isOrg = false, iconsTest) => {
    const list = [accountIcons, generalIcons, footerIcons, userPanelIcon, modelIcon];
    const icons = {};
    for (let i in list) {
        for (let name in list[i]) {
            if (!icons.hasOwnProperty(name)) {
                icons[name] = list[i][name];
            } else {
                throw new Error(name + " icon name is exist.");
            }
            if (iconsTest) {
                iconsTest[name] = icons[name];
            }
        }
    }
    if (!icons[name]) {
        return null;
    }
    const ComponentIcon = icons[name];

    if (isOrg) {
        return <ComponentIcon/>;
    }
    return <ComponentIcon {...props}/>;
}
const SVGIcon = ({
                     name = "",
                     isOrg = false,
                     ...props
                 }) => (
    <>
        {getPath(name, {...props})}
    </>
);

export default SVGIcon;

export const SVGIconShowAll = () => {
    const icons = {};
    getPath(null, null, null, icons);
    return Object.keys(icons).map(name => {
        const Comp = icons[name];
        return <><Comp/>{name}</>
    })
}
import React, { useEffect, forwardRef, useImperativeHandle} from 'react'
import SVGIcon from '../../../../components/svg'
import  { DatePickerField } from '../../../../components/basic/date-picker-field'
import { TextField } from '../../../../components/basic/text-field'
import Button from '../../../../components/basic/button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputTags from '../../../../components/basic/input-tags'
import VideoPlayer from '../../../../components/swiper/video-player'
import SelectTab from '../../../../components/basic/select-tab'
import { useRecoilState } from 'recoil'
import { studioUploadPreviewFormState } from '../../../../state/pages/studio'
import { setSeparateState } from '../../../../helpers/utils'
import { useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import { useNavigate } from 'react-router-dom'

const schema = yup.object({
  description: yup.string().required().min(3).max(256).label('Description'),
  tags: yup.array().min(1).label('Tags').of(yup.string().min(2).max(20)
    .matches(/^[A-Za-z0-9\-\s~]*$/, ' ')),
  available_date: yup.date().label('Release'),
}).required();

const StudioUploadPreviewForm = forwardRef(({file}, ref) => {
  const [{ availableDate, tabRelease, tab, edit, fileTemp, media }, setPreview] = useRecoilState(studioUploadPreviewFormState(file?.name));
  const setMedia = setSeparateState(setPreview, "media");
  const setFileTemp = setSeparateState(setPreview, "fileTemp");
  const setEdit = setSeparateState(setPreview, "edit");
  const setTab = setSeparateState(setPreview, "tab");
  const setTabRelease = setSeparateState(setPreview, "tabRelease");
  const setAvailableDate = setSeparateState(setPreview, "availableDate");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const navigate= useNavigate()

  const { register, handleSubmit, trigger, formState: { errors }, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    resetStateAction.add(() => setPreview(null))
    setFileTemp(file);
    if (file.type.indexOf('image') > -1) {
      let reader = new FileReader();
      reader.onload = (upload) => {
        setMedia(upload.target.result)
      };
      reader.readAsDataURL(file);
    } else if (file.type.indexOf('video') > -1) {
      setMedia(file)
    }
    setValue('tags', [])
    setValue('available_date', availableDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(ref, () => ({
    onSubmit (onOk, onError) {
      handleSubmit((data) => {
        const params = {
          description: data.description,
          visibility: 'free',
          available_date: data.available_date,
          tags: data.tags || [],
          private: tab === "private",
          file,
        }

        if (tab === "Subscribers") {
          params.visibility = "subscription";
        }
        if (tabRelease !== "Scheduled" || params.private) {
          params.available_date = new Date()
        }
        onOk(params);
      }, () => {
        setEdit(true)
        onError()
      })()
    }
  }));

  return (
    <>
      <div className="jf-content-box jf-mb-20px">
        <div className="jf-content-previewBox">
          {media && (fileTemp.type.indexOf('image') > -1 ? <img src={media} alt=""/> :
              <VideoPlayer sources={[{ url: URL.createObjectURL(media), type: fileTemp.type }]}
                           options={{ controls: true, fluid: true, preload:true }} type={'normal'}/>
          )}
        </div>
        <div className="jf-content-title">{media && fileTemp.name}</div>
        <Button className={'save-btn'} onClick={() => setEdit(old => !old)}>Edit <span className="jf-editicon">
              <SVGIcon name={!edit ? "bottom-arrow-icon" : "top-arrow-icon"} width={20} height={10.99}/>
            </span>
        </Button>
        <div style={{flexGrow: 1}}/>
      </div>
      <form
        style={{ display: edit ? 'flex' : 'none' }}
        className={"jf-editform"}
      >
        <TextField
          label={'Description'}
          error={errors.description?.message}
          register={register("description")}
        />
        <div className={'jf-text-field has-label ' + (errors.tags ? 'error' : '')} style={{ width: '100%' }}>
          <div className="jf-text-field-label">Tags</div>
          <InputTags
            setValue={(v) => {
              setValue('tags', v)
            }}
            onBlur={() => trigger("tags")}>
            {errors.tags?.message && <div className={'jf-text-field-msg error'}>{errors.tags?.message}</div>}
          </InputTags>
        </div>
        <div className="jf-inputbox">
          <div className="jf-inputbox-title">Viewable by</div>
          <SelectTab
            style={{height: 60, width: '100%'}}
            liClassName={'jf-font-r'}
            tabs={[
              { id: "everyone", name: "Everyone" },
              { id: "Subscribers", name: "Subscribers" },
              { id: "private", name: "Private" },
            ]}
            tab={{id: tab}}
            setTab={(tab) => {
              setTab(tab.id)
            }}
          />
          <div className={"jf-Subscribers" + (tab === 'private' ? " jf-hide" : "")}>
            <div className="jf-inputbox-title">Release</div>
            <SelectTab
              style={{height: 60, width: '100%'}}
              liClassName={'jf-font-r'}
              tabs={[
                { id: "Now", name: "Now" },
                { id: "Scheduled", name: "Scheduled" },
              ]}
              tab={{id: tabRelease}}
              setTab={(tab) => {
                setTabRelease(tab.id)
              }}
            />
            {
              tabRelease === 'Scheduled' && (
                <div className="jf-scheduled-content">
                  <div className="jf-inputbox">
                    <div className="jf-inputbox-title">Scheduled release</div>
                    <DatePickerField
                      value={availableDate}
                      width={'calc(100% - 40px)'}
                      setValue={(v) => {
                        setValue('available_date', v)
                        setAvailableDate(v)
                      }}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </form>
    </>
  );
})

export default StudioUploadPreviewForm;
import React  from 'react'
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import ItemMenu from '../../../../components/basic/item-menu'
import variables from '../../../../scss/variables.module.scss'
import ToggleSwitch from '../../../../components/basic/toggle-switch'
import Button from '../../../../components/basic/button'
import { accountEmailNotificationAtom } from '../../../../state/pages/account'
import {useRecoilState} from 'recoil'
import { useNavigate } from 'react-router-dom'

const EmailNotification = (props) => {
  const [checked, setChecked] = useRecoilState(accountEmailNotificationAtom)

  const navigate =useNavigate()
  const onSubmit = () => {
    navigate(-1);
  }

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={'Email notifications'}
        action={() => navigate(-1)}
      />
      <div className={'jf-basic-page no-padding jf-email-page'}>
        <ItemMenu
          text={'Authenticator app'}
          style={{cursor: 'auto'}}
          styleText={{font: "18px/24px " + variables['fonts-jf-fontMedium']}}
        >
          <ToggleSwitch keyId={'toggle-email'} checked={checked} onChange={(e) => {
            setChecked(e.target.checked);
          }}/>
        </ItemMenu>
        <Button className={'jf-account-save-btn'} onClick={onSubmit}>Save</Button>
      </div>
      <BottomMenu/>
    </>
  );
}

export default EmailNotification;

import React, { Component } from 'react';
import GoBack from "../components/Goback";
import Logo from './basic/logo'

class Header extends Component {
  render() {
    return (
      <>
        <header className="jf-header">
          <GoBack onBack={this.props.onBack} />
          <Logo className={'small'}/>
        </header>
      </>
    );
  }
}

export default Header;
import React, { useEffect } from 'react'
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import MediaMsg from '../../../../components/message/media-msg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import Mutations from '../../../../params/mutations'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { messageNewMediaMsgAtom } from '../../../../state/pages/message'
import { setSeparateState } from '../../../../helpers/utils'

const schema = yup.object({
  media: yup.object().nullable().required(),
  price: yup.string().when("free", {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required(),
  }),
  text: yup.string().required(),
  free: yup.boolean(),
}).required();

const NewMediaMsg = (props) => {
  const [{ loading }, setMessageNewMediaMsg] = useRecoilState(messageNewMediaMsgAtom);
  const setLoading = setSeparateState(setMessageNewMediaMsg, "loading")
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageNewMediaMsg = useResetRecoilState(messageNewMediaMsgAtom)

  const navigate = useNavigate();
  const location = useLocation();
  const fetchApi = useFetchApiAction();
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    resetStateAction.add(resetMessageNewMediaMsg)
    if (!location.state?.user){
      navigate('/messages/inbox', {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setLoading(true);
    const value = location.state?.message;
    if (value) {
      setValue("price", parseFloat(value.price?.toString().replace("$", "")) || '');
      setValue("text", value.text);
      setValue("preview", value.preview);
      setValue("media", value.media);
      setValue("free", value.free);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const onSave = (data) => {
    let info = data.media?.info ? JSON.parse(data.media.info) : [];
    info = info.find(find => !!find.duration)

    if (data.media && data.media.type === 'video' && info.duration < 10 && data.free !== true) {
      return;
    }

    const value = {
      price: data.free === true ? 0 : parseFloat(data.price?.replace("$", "")),
      text: data.text,
      preview: data.free === true ? 10 : data.preview,
      free: data.free === true,
    };
    if (data.media) {
      value.media = data.media.id;
      value.thumbnail = data.media.thumbnail.grid;
      value.duration = info?.duration
      value.teaser = data.media?.teaser
      value.url = data.free === true ? data.media.source.url : null
    }

    fetchApi(Mutations.Messages.Add, {
      "to": location.state?.user,
      "data": {
        type: "teaser",
        value: JSON.stringify(value)
      }
    }).then(res => {
      navigate(-1)
    })
  }

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={'Messages'}
      action={() => navigate(-1)}
    />
    <div className={'jf-flex-basic-page jf-mass-message'}>
      <div style={{ flexGrow: 1 }}/>
      <form>
        {!loading && <>
          <div style={{ flexGrow: 1 }}/>
          <div style={{ padding: 20 }}>
            <MediaMsg
              locationState={() => ({
                user: location.state?.user,
                message: getValues(),
              })}
              register={register}
              setValue={setValue}
              onSave={handleSubmit(onSave)}
              getValues={getValues}
              errors={errors}
              lableBtn={'Send'}
            />
          </div>
        </>}
      </form>
    </div>
    <BottomMenu/>
  </>;
}

export default NewMediaMsg;
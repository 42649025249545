import React, {useEffect, useRef} from 'react';
import {Puff} from 'react-loader-spinner'
import variables from '../../scss/variables.module.scss'

const InfiniteScroll = ({children, setPage, loadingMore}) => {
    // add loader refrence
    const loader = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
        };
        // initialize IntersectionObserver
        // and attaching to Load More div
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }

        return () => {
            observer.disconnect();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // here we handle what happens when user scrolls to Load More div
    // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((page) => page + 1)
        }
    }


    return children(<div className="loading" ref={loader} style={{textAlign: "center", marginTop: -10}}>
        {loadingMore && <Puff
            wrapperStyle={{justifyContent: 'center'}}
            color={variables['colors-jf-secondary']}
            height={30}
            width={30}
        />}
    </div>)
}

export default InfiniteScroll;
import React from 'react';
import SVGIcon from './svg/SVGIcon';
import { useLocation, useNavigate } from 'react-router-dom'

const GoBack = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleBack = () => {
    if (props.onBack) {
      props.onBack()
    } else if (location.state &&
      location.state.prevPath.pathname === '/profile') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }
  return (
    <>
      <div className="jf-back-btn" onClick={handleBack}>
        <SVGIcon name="backarrow"></SVGIcon>
      </div>
    </>
  )
}
export default GoBack
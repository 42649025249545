import React, {useEffect, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import TopMenu from '../../../../components/basic/top-menu'
import BottomMenu from '../../../../components/basic/bottom-menu'
import { formatBytes, toBytes } from '../../../../helpers/utils'
import Button from '../../../../components/basic/button'
import SVGIcon from '../../../../components/svg'
import {useRecoilState} from 'recoil';
import { studioMyFilesAtom } from '../../../../state/pages/studio'
import { useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import { useNavigate } from 'react-router-dom'

function StudioUpload({ }) {
  const [myFiles, setMyFiles] = useRecoilState(studioMyFilesAtom)
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const navigate = useNavigate()

  useEffect(() => {
    resetStateAction.add(()=> setMyFiles([]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myFiles])

  const totalSizeLimit = toBytes(process.env.REACT_APP_MAX_TOTAL_SIZE)

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: process.env.REACT_APP_MAX_FILES,
    onDrop,
  })
  const removeFile = file => () => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  const files = myFiles.map(file => (
    <li key={file.path} className={'jf-studio-upload-file'}>
      <button onClick={removeFile(file)} className={'jf-no-style-btn'}>
        <SVGIcon name={'close-icon'} width={20} height={20}/>
      </button>
      <div className={'jf-file-size'}>{formatBytes(file.size)}</div>{file.name}
    </li>
  ))
  const total = myFiles.reduce((partial_sum, a) => partial_sum + a.size, 0)

  return (

    <>
      <TopMenu
        icon={'greater-icon'}
        title={"Upload"}
        action={() => navigate(-1)}
      />
      <div className={'jf-basic-page jf-studio-upload-page'}>
        <section className="container" >
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>Drag and drop files</p>
          </div>
          <aside>
            <ul>{files}</ul>
            {files.length > 0 && <>
              <div style={{float: 'right'}}>{formatBytes(total)}</div>
              <div>{files.length} file{files.length > 1 ? 's' : ''}</div>
            </>}
            {totalSizeLimit < total && <div className={'jf-warning'}>
              <SVGIcon name={'warning-icon'}/>
              <label>Max {process.env.REACT_APP_MAX_TOTAL_SIZE} allowed</label></div>}
          </aside>
        </section>
        <Button onClick={() => {
          if (myFiles.length > 0) {
            navigate("/studio/upload/preview", {state: { files: myFiles }, replace: true})
          }
        }} className={'save-btn'}>Proceed</Button>
      </div>
      <BottomMenu/>
    </>
  );
}

export default StudioUpload;

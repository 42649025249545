import TopMenu from '../../../components/basic/top-menu'
import BottomMenu from '../../../components/basic/bottom-menu'
import React, {useEffect, useCallback} from 'react'
import Avatar from '../../../components/basic/avatar'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { authProfileAtom } from '../../../state/auth'
import { TextField } from '../../../components/basic/text-field'
import InputTags from '../../../components/basic/input-tags'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Queries from '../../../params/queries'
import { useFetchApiAction, useSubscriptionLiveUserAction } from '../../../action/main-action'
import { locationAtom, suggestionTagsAtom, swiperMobileBottomShowAtom } from '../../../state'
import SelectTab from '../../../components/basic/select-tab'
import Constants from '../../../params/constants'
import Button from '../../../components/basic/button'
import SVGIcon from '../../../components/svg'
import { useUserAction } from '../../../action/user-action'
import { useMediaQuery } from 'react-responsive'
import { minWidthScreen } from '../../../helpers/utils'
import OvenLivekitTool from '../../../helpers/oven-live-kit'
import { studioLiveShowAtom } from '../../../state/pages/studio'
import { liveBoxAuthAtomFamily } from '../../../state/components/live-box'
import { useNavigate } from 'react-router-dom'

const StreamKey = () => {
  const [show, setShow] = useRecoilState(studioLiveShowAtom)
  const [profile, setProfile] = useRecoilState(authProfileAtom);
  const userAction = useUserAction();

  const generateRtmp = () => {
    userAction.generateRtmp().then(res => {
      setProfile({...profile, studio: {rtmp: res}})
    });
  }

  const copy = useCallback(() => {
    navigator.clipboard.writeText(profile.studio?.rtmp);
  }, [profile])

  return <div className="jf-text-field-input jf-stream-key">
    <span style={{flexGrow: 1}}>{show ? profile && profile.studio?.rtmp :  profile && "*".repeat(profile.studio?.rtmp.length)}</span>
    <button type={'button'}  className={'jf-no-style-btn'} onClick={copy}>
      <SVGIcon name={'copy-fill-icon'}/>
    </button>
    <button type={'button'} className={'jf-no-style-btn'} onClick={() => setShow(old => !old)}>
      <SVGIcon name={show ? "hide-password-icon" : "show-password-icon"} width={25}/>
    </button>
    <button type={'button'}  className={'jf-no-style-btn'} onClick={generateRtmp}>
      <SVGIcon name={'sync-icon'}/>
    </button>
  </div>
}

const schema = yup.object({
  description: yup.string().label('Description'),
  tags: yup.array().label('Tags').of(yup.string().min(2).max(20)
    .matches(/^[A-Za-z0-9\-\s~]*$/, ' ')),
}).required();

const StudioLive = ({paramsUrl}) => {
  const setSuggestions = useSetRecoilState(suggestionTagsAtom)
  const [authProfile, setAuthProfile] = useRecoilState(authProfileAtom);
  const fetchAction = useFetchApiAction();
  const isBigScreen = useMediaQuery({ query: minWidthScreen })
  const userAction = useUserAction();
  const subscriptionLiveUserAction = useSubscriptionLiveUserAction();
  const [startCamera, setStartCamera] = useRecoilState(liveBoxAuthAtomFamily("startCamera"));
  const setSwiperMobileBottomShow = useSetRecoilState(swiperMobileBottomShowAtom);

  const setLocation = useSetRecoilState(locationAtom);
  const navigate = useNavigate();
  const { register, handleSubmit, trigger, formState: { errors }, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const variables = { limit: { limit: 100 } }
    fetchAction(Queries.Tags, variables).then(res => {
      setSuggestions(res.tags.map(tag => ({ name: tag.tags })))
    });

    userAction.getAuthProfile().then(res => {
      subscriptionLiveUserAction(res.profile.id).then()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goLive = (data) => {
    if (startCamera && authProfile?.live) {
      OvenLivekitTool.remove();
      setStartCamera(false)
    } else if (!authProfile?.live) {
      setAuthProfile(old => ({ ...old, description: data.description, status: '' }))
      setStartCamera(true)
    }
  }

  useEffect(() => {
    if (startCamera && authProfile?.live) {
      setLocation(old => ({...old, slideIndex: 0, time: Date.now()}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProfile])

  useEffect(() => {
    setSwiperMobileBottomShow(false);
  })

  return (
    <>
      <TopMenu
        icon={'greater-icon'}
        title={"Live"}
        action={() => navigate(-1)}
      />
      <div className={'jf-basic-page jf-studio-live '}>
        <Avatar
          live={true}
          labelLive={authProfile && authProfile.live}
          image={authProfile && authProfile.avatar}
          text={authProfile && authProfile.fullname}
          width={120}
          height={120}
        />
        <div className={'jf-username'}>@{authProfile && authProfile.username}</div>
        <form
          className={"jf-editform"}
          onSubmit={handleSubmit(goLive)}
        >
          <TextField
            label={'Description'}
            error={errors.description?.message}
            register={register("description")}
          />
          <div className={'jf-text-field has-label ' + (errors.tags ? 'error' : '')} style={{ width: '100%' }}>
            <div className="jf-text-field-label">Tags</div>
            <InputTags
              noShowNote={true}
              setValue={(v) => {
                setValue('tags', v)
              }}
              onBlur={() => trigger("tags")}>
              {errors.tags?.message && <div className={'jf-text-field-msg error'}>{errors.tags?.message}</div>}
            </InputTags>
          </div>
          <SelectTab
            style={{ height: 50, width: '100%' }}
            tabs={[
              { id: "rtmp", name: "RTMP" },
              { id: "camera", name: "Camera" },
            ]}
            tab={{ id: paramsUrl.tab }}
            setTab={(tab) => navigate("/studio/live/" + tab.id, {replace: true})}
          />
          {paramsUrl.tab === 'rtmp' ?
            <>
              <div className={'jf-server-label'}>Server : <span>{Constants.LiveServer}</span></div>
              <div className={'jf-text-field has-label ' + (errors.tags ? 'error' : '')} style={{ width: '100%' }}>
                <div className="jf-text-field-label">Stream Key</div>
                <StreamKey/>
              </div>
              {!isBigScreen && authProfile?.live && authProfile?.livestream === "RTMP" &&
              <div className={'jf-m-auto jf-mb-20px'}>
                <Button className={'outline-btn'} typeHtml={'button'}>You are LIVE</Button>
                <Button className={'save-btn'} type={'link'}
                        to={'/' + authProfile.username + '/live'}>Preview</Button>
              </div>}
            </> :
            <>
              {
                !(authProfile && authProfile?.live && authProfile?.livestream === "RTMP") &&
                <div className={'jf-m-auto jf-mb-20px'}>
                  {startCamera && !authProfile?.live &&
                  <div className={'jf-basic-btn outline-btn medium'}>{'   please wait...'}</div>}
                  {
                    startCamera && authProfile?.live &&
                    <Button className={'save-btn medium'}>Stop Live</Button>
                  }
                  {!startCamera && authProfile?.live &&
                  <div className={'jf-basic-btn outline-btn medium'}>{'   please wait...'}</div>}
                  {!startCamera && !authProfile?.live && <Button className={'save-btn medium'}>Go Live</Button>}
                </div>
              }
            </>}
        </form>
      </div>
      <BottomMenu/>
    </>
  );
}

export default StudioLive;
import React, {useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SVGIcon  from '../svg'
import { TextField } from '../basic/text-field'
import { TextAreaField } from '../basic/text-area-field'
import Button from '../basic/button'
import variables from '../../scss/variables.module.scss'
import { useMediaAction } from '../../action/media-action'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { messageMediaMsgAtom } from '../../state/components/message'
import { setSeparateState } from '../../helpers/utils'
import { useResetStateWhenChangeUrlAction } from '../../action/main-action'

const MediaMsg = ({ register, setValue, onSave, getValues, errors, lableBtn, locationState }) => {
  const [{ media, free, duration, parentVal }, setMessageMediaMsg] = useRecoilState(messageMediaMsgAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const mediaAction = useMediaAction(false)
  const setMedia = setSeparateState(setMessageMediaMsg, "media")
  const setFree = setSeparateState(setMessageMediaMsg, "free")
  const setDuration = setSeparateState(setMessageMediaMsg, "duration")
  const setParentVal = setSeparateState(setMessageMediaMsg, "parentVal")

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageMediaMsg = useResetRecoilState(messageMediaMsgAtom)
  const min = Math.min(5, Math.floor(duration));
  const max = Math.min(Math.floor(duration), 20);
  const distance = max - min;
  const infoDuration = (media?.info ? JSON.parse(media.info) : []).find(find => !!find.duration)
  const info = (media?.info ? JSON.parse(media.info) : []).find(find => !!find.width)

  const sliderValueChanged = e => {
    if (e.target.value <= 20) {
      setParentVal(e.target.value);
      setValue('preview', e.target.value);
    }
  }

  useEffect(() => {
    resetStateAction.add(resetMessageMediaMsg)
    const m = location.state?.media || getValues('media') || null
    setMedia(m);
    setFree(getValues('free') === true);
    setDuration(0);
    setParentVal(getValues('preview') || 0);
    if (!m || !media?.id) return;
    mediaAction.getMedias("private", {
      withLike: false,
      limit: { limit: 1 },
      filter: { ids: [media.id] }
    }).then(res => {
      setMedia(res[0])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue('media', media);
    setDuration(0)
    if (media && media.type === 'video') {
      setDuration(Math.floor(infoDuration.duration));
      if (location.state?.media) {
        setParentVal(Math.min(Math.floor(infoDuration?.duration), 10));
        setValue('preview', Math.min(Math.floor(infoDuration?.duration), 10));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media])

  return (
    <div className={'jf-media-msg'}>
      <div className={'jf-flex-row jf-mb-12px jf-pointer'} onClick={() => {
        setValue('free', !free)
        setFree(old => !old);
      }}>
        <button className={'jf-check-circle-btn'} type={'button'}>
          <SVGIcon name={'check-circle-icon'}
                   fillCircle={free !== true ?
                     variables['colors-jf-primary'] : '#fff'}/>
        </button>
        <div className={'jf-ml-10px'}>Ask price for media</div>
      </div>
      {free === true ? null : <TextField
        defaultValue={getValues('price') || ''}
        type={'money'}
        prefix={'$'}
        register={register("price")}
        error={errors.price?.message}
        iconLeft={<div className={'jf-money-text'}>Price</div>}
      />}
      {media && media.type === 'video' && free !== true ? <div className="jf-timebar">
        <div className="jf-timebar-title">Preview for</div>
        <div className={'jf-flex-row'}>
          <div className="jf-max-range">{min}s</div>
          <input
            type="range"
            value={parentVal}
            min={min}
            className={"swiper-no-swiping"}
            style={{width: '100%', background: 'linear-gradient(to right, '+variables['colors-jf-primary']
                +' 0%, '+variables['colors-jf-primary']+' '+ ((parentVal - min)/distance*100) +'%, #fff ' + ((parentVal - min)/distance*100) + '%, white 100%)' }}
            max={max}
            id="myRange"
            onChange={sliderValueChanged}
          />
          <div className="jf-max-range">{max}s</div>
        </div>
        {duration < 10 && <div className={'jf-error jf-timebar-title'}>only videos longer than 10 seconds are allowed</div>}
        <div className="jf-select-range jf-mb-12px">{parentVal} s</div>
      </div> : ''}
      <div className={'jf-select-box ' + (!info || info?.width > info?.height ? 'landscape' : '')}>
        {!media ? <div className={'jf-select-box-content'}
             onClick={() => navigate('/library', {state: {...locationState(), media,url: location.pathname}, replace: true})}>
          <SVGIcon name={'cloud-plus-icon'}/>
          <div>Select media</div>
        </div> : <>
          <button className={'jf-no-style-btn jf-close'} onClick={() => setMedia(null)} type={'button'}>
            <SVGIcon name={'close-circle-icon'}/>
          </button>
        <img src={media.thumbnail.grid} alt={''}/>
        </>}
      </div>
      {errors.media?.message && <div className={'jf-text-field-msg jf-error'}>{errors.media?.message}</div>}

      <TextAreaField
        styleContain={{margin: '10px 0'}}
        placeholder={'Text Message'}
        error={errors.text?.message}
        register={register("text")}
      />
      <Button onClick={onSave} typeHtml={'button'}>{lableBtn || 'Save'}</Button>
    </div>
  );
}

export default MediaMsg;
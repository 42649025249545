import React from 'react';
const CommentIcon = ({
  style = {},
  fill = "#7c79fa",
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '29',
  className = '',
  viewBox = '0 0 29 29'
}) => (
  <>
    <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
        <g id="comment" opacity="0.8">
          <g id="Union_1" data-name="Union 1" transform="translate(0 0)" fill="none">
            <path d="M10.734,23.727H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H27a2,2,0,0,1,2,2V21.727a2,2,0,0,1-2,2H18.266L14.5,29Z" stroke="none"/>
            <path d="M 14.49989891052246 25.55868911743164 L 17.23682022094727 21.7267017364502 L 26.99980163574219 21.72690010070801 L 27 2.000000715255737 L 2.000000715255737 1.999800801277161 L 1.999800801277161 21.7267017364502 L 11.76297092437744 21.7267017364502 L 14.49989891052246 25.55868911743164 M 14.49990081787109 28.99980163574219 L 10.73369121551514 23.7267017364502 L 1.999800801277161 23.7267017364502 C 0.8955008387565613 23.7267017364502 8.178710686479462e-07 22.83120155334473 8.178710686479462e-07 21.72690010070801 L 8.178710686479462e-07 1.999800801277161 C 8.178710686479462e-07 0.8955008387565613 0.8955008387565613 8.178710686479462e-07 1.999800801277161 8.178710686479462e-07 L 27 8.178710686479462e-07 C 28.10430145263672 8.178710686479462e-07 28.99980163574219 0.8955008387565613 28.99980163574219 1.999800801277161 L 28.99980163574219 21.72690010070801 C 28.99980163574219 22.83120155334473 28.10430145263672 23.7267017364502 27 23.7267017364502 L 18.26609992980957 23.7267017364502 L 14.49990081787109 28.99980163574219 Z" stroke="none" fill={fill}/>
          </g>
          <rect id="Rectangle_1052" data-name="Rectangle 1052" width="10" height="2" rx="1" transform="translate(9.5 10.799)" fill={fill}/>
        </g>
    </svg>


  </>
);
export default CommentIcon;
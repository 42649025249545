import React from 'react';
const MessageRedIcon = ({
  style = {},
  fill = '#1483fb',
  fillOpacity = '0.08',
  fillNone = 'none',
  width = '60',
  className = '',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox} >

      <g id="message-red" fill="#E94153" transform="translate(-24 -603)">
        <path className="st0" d="M54,603c-16.6,0-30,13.4-30,30s13.4,30,30,30s30-13.4,30-30S70.6,603,54,603z M50.9,631.7
		c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.6c0,1.5-1.2,2.7-2.7,2.7l0,0C52.1,634.3,50.9,633.1,50.9,631.7z"/>
        <g id="favorite-24px" transform="translate(34 613)">
          <path className="st1" d="M0,0v40h40V0H0z M19.5,21.3c-1.5,0-2.6-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.6
			C22.2,20.1,21,21.3,19.5,21.3L19.5,21.3z" fill={fillNone} />
          <path id="Path_43" className="st2" fill="#fff" d="M19.5,5C11,5,4,10.9,4,18.3c0,7.3,7,13.2,15.5,13.2v2.4c0,0.6,0.5,1.1,1.1,1.1
			c0.2,0,0.4-0.1,0.6-0.2c2.8-1.7,8.2-5.3,10.1-8c1.2-1.1,2.1-2.5,2.7-3.9c0.6-1.5,1-3.1,1-4.7C35.1,10.9,28.2,5,19.5,5z M13.8,20.5
			c-0.5,0.5-1.1,0.8-1.8,0.8c-1.5,0-2.6-1.2-2.6-2.6c0-1.5,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6C14.6,19.4,14.3,20.1,13.8,20.5z
			 M19.6,21.3L19.6,21.3c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.6C22.2,20.1,21,21.3,19.6,21.3z M29.8,18.7
			c0,1.5-1.2,2.6-2.6,2.6l0,0c-1.5,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6S29.8,17.2,29.8,18.7L29.8,18.7z"/>
          <path className="st0" fill="#E94153" d="M22.2,18.6c0,1.5-1.2,2.7-2.7,2.7l0,0c-1.5,0-2.6-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7
			C21,16,22.2,17.2,22.2,18.6z"/>
        </g>
      </g>
    </svg>

  </>
);
export default MessageRedIcon;
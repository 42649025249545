import React from 'react'

const PurchaseIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '18',
  height = '22.004',
  className = '',
  viewBox = '0 0 18 22.004'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Purchases"
          d="M19.88,1H4.13A1.113,1.113,0,0,0,3,2.1V19.99a1.142,1.142,0,0,0,.326.781l2.126,2.068A.535.535,0,0,0,5.851,23h.326a.535.535,0,0,0,.394-.165L8.63,20.8a.569.569,0,0,1,.394-.165.58.58,0,0,1,.394.165L11.454,22.8a.535.535,0,0,0,.394.165h.326a.535.535,0,0,0,.394-.165L14.615,20.8a.569.569,0,0,1,.394-.165.579.579,0,0,1,.371.165L17.416,22.8a.535.535,0,0,0,.394.165h.326a.535.535,0,0,0,.394-.165L20.679,20.8a1.088,1.088,0,0,0,.326-.77V2.1A1.113,1.113,0,0,0,19.88,1ZM15.942,9.8a.556.556,0,0,0,.563-.55V8.15a.556.556,0,0,0-.563-.55H8.067a.556.556,0,0,0-.562.55v1.1a.556.556,0,0,0,.563.55Zm-3.375,4.4a.556.556,0,0,0,.563-.55v-1.1a.556.556,0,0,0-.562-.55h-4.5a.556.556,0,0,0-.562.55v1.1a.556.556,0,0,0,.563.55Zm5.445,6.072.743-.726V3.2H5.254v16.32l.765.748L7.4,18.911a1.138,1.138,0,0,1,.787-.319H9.822a1.138,1.138,0,0,1,.788.319L12,20.275l1.451-1.364a1.139,1.139,0,0,1,.8-.319h1.575a1.138,1.138,0,0,1,.787.319l.563.55Z"
          transform="translate(-3.005 -1)" fillRule="evenodd"/>
  </svg>

}

export default PurchaseIcon;
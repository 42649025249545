import TopMenu from "../../../components/basic/top-menu";
import BottomMenu from "../../../components/basic/bottom-menu";
import {useNavigate} from "react-router-dom";


export const PrivacyPolicy = ({history}) => {
    const navigate = useNavigate();

    return <>
        <TopMenu
            icon={'greater-icon'}
            title={'About'}
            action={() => navigate("/about")}
        />
        <div className={'jf-basic-page'} style={{padding: 0}}>
            <div style={{margin: '20px'}}><h1>
                Privacy Policy
            </h1>
                <div><p>
                    This privacy policy describes how we collect, use and protect your personal data.
                    By using the Service, you are accepting the practices described in this Policy, to the extent
                    permitted by law.
                </p> <h2>
                    Definitions
                </h2>
                    <table>
                        <thead>
                        <tr>
                            <th>
                                Term
                            </th>
                            <th>
                                Definition
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                The "Policy"
                            </td>
                            <td>
                                The privacy policy as outlined in this document.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "junefox.com", "we", or "us"
                            </td>
                            <td>
                                Internet Fox SRL, trading under the name junefox.com.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                The "Service"
                            </td>
                            <td>
                                The junefox.com website, located at junefox.com, and any other websites, applications, or
                                services provided, owned or operated by us that link to this Policy.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Users"
                            </td>
                            <td>
                                Any and all visitors, users, and others who access or use the Service.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Comply with a Legal Obligation"
                            </td>
                            <td>
                                Processing your personal data where it is necessary for compliance with a legal
                                obligation that we are subject to.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "EEA"
                            </td>
                            <td>
                                The European Economic Area, being the member states of the European Union together with
                                Norway, Iceland and Liechtenstein.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Legitimate Interests"
                            </td>
                            <td>
                                The interests of our business in conducting and managing our business to enable us to
                                provide you with the best and most secure
                                experience when using the Service and related functionality, or the interests of a third
                                party. We make sure we consider and balance
                                any potential impact on you (both positive and negative) and your rights before we
                                process your personal data for our or any third party’s
                                legitimate interests. We do not use your personal data for activities where our (or the
                                relevant third party’s) interests are overridden by the
                                impact on you (unless we have your consent or are otherwise required or permitted by
                                law). You can obtain further information about how we assess
                                our and any relevant third party’s legitimate interests against any potential impact on
                                you in respect of specific activities by contacting us.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                "Performance of a Contract"
                            </td>
                            <td>
                                Processing your data where it is necessary for the performance of a contract to which
                                you are a party or to take steps at your request before entering into such a contract.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h2>
                        Terms of Service
                    </h2> <p>
                        This Policy forms part of our
                        <a href="/about/terms-of-service">
                            Terms of Service
                        </a>,
                        which describes the terms under which we provide the Service.
                    </p> <h2>
                        Modifications
                    </h2> <p>
                        We reserve the right to change this Policy at any time.
                        If we make changes, we will notify you by revising the date at the bottom
                        of the Policy and, in some cases, we may provide you with additional notice
                        (such as adding a statement to our homepage or sending you an email notification).
                        Changes to this Policy are effective at the time they are published.<br/>
                        Your continued use of the Service after the effective date of the revised
                        Policy constitutes your acceptance of the policies.
                    </p> <h2>
                        Personal Information We Collect About You
                    </h2> <p>
                        We obtain information about you through the means discussed below when we provide the Service.
                        Please note that we need certain types of information so that we can provide the
                        Service to you. If you do not provide us with such information,
                        or if you ask us to delete it, you may no longer be able to access or use the Service or certain
                        functionality.
                    </p> <h3>
                        Information You Provide to Us
                    </h3> <p>
                        We collect information: (1) you directly give us, such as your image (in any content you
                        upload),
                        username and email address when you register for the Service (and for Creators, certain other
                        information,
                        including, without limitation, name, birthdate, and (where applicable) government-issued photo
                        identification and photograph);
                        (2) when you upload, purchase, view, or download certain content or products from the Service;
                        or (3) when you otherwise use the features and functionality of the Service.
                    </p> <h3>
                        Information We Collect Automatically
                    </h3> <p>
                        We use various technologies to collect information, and this may include cookies
                        and web beacons. Cookies are small data files stored on your hard drive
                        or in your device memory. Web beacons (also known as “tracking pixels”) are non-visible
                        electronic images.
                        For information about what cookies are, how they work, how junefox.com uses them,
                        and how to remove them, please see our
                        <a href="/about/cookie-policy">
                            Cookie Policy
                        </a>. <br/>
                        Examples of such information we automatically collect include the type of device you use,
                        access times, general location information of your Internet Service Provider
                        based on your IP Address, information about your usage of the Service and the page you
                        visited before navigating to our Service.<br/>
                        These technologies are used for analytic and product improvement purposes,
                        such as seeing which areas and features of the Service are popular and determining
                        whether an email has been opened and acted upon.
                    </p> <h3>
                        Information from Other Sources
                    </h3> <p>
                        We may obtain additional information such as demographic and statistical information
                        from third parties and sources other than the Service, such as advertisers or social
                        media networks (such as Twitter) for which you have approved our access.
                        When you access the Service through social media networks or when you connect the
                        Service to social media networks, you are authorizing junefox.com to collect, store,
                        and use such additional information and content in accordance with this
                        Policy.<br/>
                        If we combine or associate information from other sources with information that we
                        collect through the Service, we will treat the combined information in accordance
                        with this Policy.
                    </p> <h2>
                        How We Use Personal Information
                    </h2>
                    <ul>
                        <li>
                            We may use information about you for various purposes but only when the law allows us to.
                            Most commonly, we will use your personal data in the following circumstances:
                            (1) where we need to for Performance of a Contract; (2) where it is necessary for our
                            Legitimate Interests
                            (or those of a third party) and your interests and fundamental rights do not override those
                            interests;
                            (3) where we need to Comply with a Legal Obligation. Generally, we do not rely on consent as
                            a legal
                            basis for processing your personal data although we will get your consent before sending
                            third-party direct
                            marketing communications to you via email or text message.
                            You have the right to withdraw consent to marketing at any time by contacting us.
                        </li>
                        <li>
                            junefox.com uses such information to: (1) operate, maintain, enhance, provide, create, and
                            develop all of the features,
                            functionality, and services (new or existing) found on the Service; (2) provide security for
                            our websites, products,
                            software, or applications; (3) manage relationships with junefox.com account holders (e.g.,
                            partners, affiliates),
                            including making or receiving payment; (4) improve Users’ experience with junefox.com by
                            providing content recommendations
                            and by delivering content that we hope Users will find relevant and interesting, including
                            advertising and marketing messages;
                            (5) prevent fraud and abuse; and (6) understand the usage trends of our Users.
                        </li>
                        <li>
                            We use your email address to communicate with you, including to notify you
                            of major Service updates, for customer service purposes, or to contact you regarding
                            any content that you have posted to or downloaded from the Service.
                        </li>
                        <li>
                            junefox.com may periodically send promotional notifications to you related to the Service if you
                            have registered
                            with us or requested information from us and you have not opted-out of receiving that
                            marketing.
                            You may opt-out of receiving promotional communications from us in your account settings
                            once you have
                            logged in to the Service or follow the unsubscribe instructions at the bottom of any email
                            or text from us.
                            If you opt-out, we may still send you non-promotional communications, such as those about
                            your account.
                            We will get your opt-in consent before we share your personal data with any third party for
                            marketing purposes.
                        </li>
                        <li>
                            In certain cases, we have a legal obligation to collect and process your personal
                            information (such as our obligation to share data with tax authorities).
                        </li>
                        <li>
                            We may also ask for your consent to process your personal information for a specific
                            purpose that we communicate with you. When you consent to our processing your personal
                            information for a specified purpose, you may withdraw your consent at any time
                            and we will stop processing of your data for that purpose.
                        </li>
                        <li>
                            junefox.com will rely on legal grounds to process your personal information to the extent
                            permitted by applicable law, which may include, without limitation:
                            to honor contractual commitments, to take steps in anticipation of entering into a
                            contract, to fulfill legal obligations, your consent, and junefox.com's legitimate interests.
                        </li>
                    </ul>
                    <h3>
                        Change of Purpose
                    </h3>
                    <ul>
                        <li>
                            We will only use your personal data for the purposes for which we collected it, unless we
                            reasonably consider that
                            we need to use it for another reason and that reason is compatible with the original
                            purpose. If you would like an explanation
                            as to how the processing for the new purpose is compatible with the original purpose, please
                            contact us.
                        </li>
                        <li>
                            If we need to use your personal information for an unrelated purpose,
                            we will notify you and we will explain the legal basis which allows us to do so.
                        </li>
                        <li>
                            Please note that we may process your personal data without your knowledge or consent,
                            in compliance with the above rules, where this is to Comply with a Legal Obligation.
                        </li>
                    </ul>
                    <h2>
                        When We Disclose Personal Information
                    </h2> <p>
                        We share such information as described below:
                    </p>
                    <ul>
                        <li>
                            We disclose such information to service providers working on our behalf, such as,
                            to provide website hosting, maintenance, and security services; fulfill orders;
                            conduct data analysis and create reports; offer certain functionality; or assist
                            junefox.com in improving the Service and creating new services and features.
                            We require that these parties process such information in compliance with the law and this
                            Policy,
                            we authorize them to use the information only for the purposes for which it is provided
                            to them, and we require them to use reasonable confidentiality measures.
                        </li>
                        <li>
                            junefox.com may make information available to our affiliates (meaning entities controlled
                            by, controlling, or under common control with junefox.com) that are either subject to this
                            Policy or follow practices at least as protective as those described in this Policy.
                        </li>
                        <li>
                            junefox.com may disclose user information if we believe in good faith that such disclosure
                            is necessary to Comply with a Legal Obligation or other applicable laws around the world
                            (for example, in the country of your residence), or respond to a court order,
                            judicial or other government request, subpoena, or warrant in the manner legally required.
                        </li>
                        <li>
                            junefox.com also reserves the right to disclose information that we believe, in good faith,
                            is appropriate or necessary to (1) protect junefox.com from potential liability or fraudulent,
                            abusive, or unlawful uses; (2) investigate and defend ourselves against third-party claims,
                            or allegations; (3) protect the security or integrity of the Service; or (4) protect the
                            rights,
                            property, or safety of junefox.com, our Users, or others.
                        </li>
                        <li>
                            We may disclose your personal data to third parties to whom we may choose to sell,
                            transfer or merge parts or our business or our assets.
                        </li>
                    </ul>
                    <p>
                        You will receive notice if we intend to share your personal information with a third party in a
                        manner not
                        described in this Policy, and in such cases, you will have an opportunity to choose not to share
                        the information.
                    </p> <h2>
                        Users in the UK, EEA or Switzerland
                    </h2> <p>
                        If you are in the UK, EEA or Switzerland, you should know that junefox.com is the controller
                        of your personal information.
                    </p> <h3>
                        Data Subject Requests
                    </h3> <p>
                        In certain circumstances, as the data subject, you have the right to request access to personal
                        data we hold
                        about you and to ask that your personal data be corrected, erased or transferred. You may also
                        have the right to
                        object to, or request that we restrict, certain processing. If you would like to exercise any of
                        these rights,
                        you may contact us as indicated below.
                    </p> <p>
                        Your <b>right to request access</b> entitles you to request a copy of the
                        personal data we hold about you and to check that we are processing it lawfully.
                    </p> <p>
                        Your <b>right to request correction</b> of your personal data that we hold
                        enables you to have incomplete or inaccurate data corrected,
                        though we may need to verify the accuracy of the new data you provide to us.
                    </p> <p>
                        The <b>right to request erasure</b> of your personal data entitles you to ask
                        us to delete or remove personal data where there
                        is no good reason for us continuing to process it.
                    </p> <p>
                        You also have the right to ask us to delete or remove your personal data where you have
                        successfully exercised your
                        right to object to processing (see below), where we may have processed your information
                        unlawfully, or where we are
                        required to erase your personal data to comply with local law. Note, however, that we may not
                        always be able to comply
                        with your request for erasure for specific legal reasons which will be notified to you, if
                        applicable, at the time of your request.
                    </p> <p>
                        You may <b>object to the processing</b> of your personal data insofar as we
                        base the processing of your personal data on Legitimate Interests
                        and there is something about your particular situation which makes you want to object to
                        processing on this ground as you feel it impacts
                        on your fundamental rights and freedoms. If you wish to do so, we kindly ask for an explanation
                        of the reasons for the objection. If your
                        objection is justified, we will discontinue or adapt the data processing or explain why we have
                        compelling legitimate grounds to continue
                        processing your personal data notwithstanding your objection.
                    </p> <p>
                        You may, of course, object to the processing of your personal data for advertising and data
                        analysis purposes at any time.
                    </p> <p>
                        You may <b>request restriction</b> of processing of your personal data. This
                        entitles you to ask us to suspend
                        the processing of your personal data in the following scenarios:
                    </p>
                    <ul>
                        <li>
                            If you want us to establish the data’s accuracy;
                        </li>
                        <li>
                            Where our use of the data is unlawful but you do not want us to erase it;
                        </li>
                        <li>
                            Where you need us to hold the data even if we no longer require it as you need it to
                            establish, exercise or defend legal claims;
                        </li>
                        <li>
                            You have objected to our use of your data but we need to verify whether we have overriding
                            legitimate grounds to use it.
                        </li>
                    </ul>
                    <p>
                        You may <b>request the transfer</b> of your personal data to you or a third
                        party. In this case, we will provide to you,
                        or a third party you have chosen, your personal data in a structured, commonly used,
                        machine-readable format.
                        Note that this right only applies to automated information which you initially provided consent
                        for us to use or
                        where we used the information to perform a contract with you.
                    </p> <p>
                        As mentioned above, we do not generally rely on consent to process your personal data. If we do,
                        you may withdraw your
                        consent at any time (although this will not affect the lawfulness of any processing carried out
                        before you withdrew your consent).
                        If you withdraw your consent, we may not be able to provide certain services to you. We will
                        advise you if this is the case at
                        the time you withdraw your consent.
                    </p> <p>
                        You will not have to pay a fee to exercise any of the above rights. However,
                        we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
                        Alternatively, we could refuse to comply with your request in these circumstances.
                    </p> <p>
                        We may need to request specific information from you to help us confirm your identity in order
                        to respond
                        to any exercise of the above rights. This is a security measure to ensure that personal data is
                        not disclosed
                        to any person who has no right to receive it. We may also contact you to ask you for further
                        information
                        in relation to your request to speed up our response.
                    </p> <p>
                        We try to respond to all legitimate requests within one month. Occasionally it could take us
                        longer than
                        a month if your request is particularly complex or you have made a number of requests. In this
                        case, we will notify you and keep you updated.
                    </p> <h3>
                        International Data Transfers
                    </h3> <p>
                        We may collect your personal information from, transfer it to, and store and process it in other
                        countries outside of where you live.
                        If we provide any information about you to any such entities, we will take appropriate measures
                        to ensure such companies protect your
                        information in accordance with this Policy and applicable law. If you are in the UK or the EEA,
                        and we transfer your personal data
                        outside of the EEA, we ensure a similar degree of protection applies by implementing at least
                        one of the following safeguards:
                    </p>
                    <ul>
                        <li>
                            We will only transfer your personal data to countries that have been deemed to provide an
                            adequate
                            level of protection for personal data by the European Commission.
                        </li>
                        <li>
                            Where we use certain service providers, we may use specific contracts approved by the
                            European
                            Commission which give personal data the same protection as it has in Europe.
                        </li>
                    </ul>
                    <p>
                        Please contact us if you would like further information on the specific mechanism used when
                        transferring your data outside the EEA.
                    </p> <h3>
                        Complaints
                    </h3> <p>
                        If you’re based in the EU and in some other jurisdictions, you have the option to file a
                        complaint against the processing
                        of your personal data with the competent supervisory authority. We would however appreciate the
                        chance to deal with your
                        concerns before you approach the supervisory authority so please contact us in the first
                        instance.
                    </p> <h2>
                        Your Choices
                    </h2> <p>
                        You may decline to share certain information with junefox.com, in which case junefox.com
                        may not be able to provide you with some of the features and functionality
                        found on the Service. If you have created a junefox.com account, you may be able
                        to update your profile information and preferences or request to disable your account.
                        For requests to review, update, delete, or otherwise limit junefox.com's use of information
                        that you have provided directly to junefox.com, you may contact <a external=""
                                                                                       href="mailto:support@junefox.com">support@junefox.com</a>.
                        In your request, please include your email address and your username and specify
                        all relevant background. To protect your privacy and security, we may take steps
                        to verify your identity before granting you access or making corrections to your
                        information. You are responsible for maintaining the secrecy of your unique password
                        and account information at all times.
                    </p> <h2>
                        Account Deletion
                    </h2> <p>
                        If you wish to delete your account, you may instruct us to do so by contacting us
                        at <a external="" href="mailto:support@junefox.com">support@junefox.com</a>. If you
                        delete your account, junefox.com may retain certain information
                        about you for the activities described in this Policy, or as permitted or required by
                        applicable law.
                    </p> <h2>
                        Third-Party Services and Websites
                    </h2> <p>
                        If you connect to a third-party service (such as Twitter, or another third party)
                        through the Service or otherwise link your junefox.com account with a third-party service,
                        you are requesting and authorizing us to share or grant access to information on your
                        behalf (such as your username, the fact that your connection originated from the Service,
                        and other relevant usage and diagnostic information) with such third party. <br/>
                        The Service may include links to third party websites, plug-ins and applications.
                        Clicking on those links or enabling those connections may allow third parties to collect
                        or share data about you. We do not control these third-party websites and are not
                        responsible for their privacy statements. When you leave the Service, we encourage
                        you to read the privacy policy of every website you visit.
                    </p> <h2>
                        Retention of Your Information
                    </h2> <p>
                        junefox.com will only retain your personal data for as long as necessary to fulfill the relevant
                        purposes described in this Policy,
                        as may be required (or permitted) by law (such as for regulatory, tax, accounting and reporting
                        purposes), or as otherwise communicated to you.
                        We may retain your personal data for a longer period in the event of a complaint or if we
                        reasonably believe there is a prospect of litigation
                        in respect of our relationship with you.
                    </p> <p>
                        To determine the appropriate retention period for personal data, we consider the amount, nature
                        and sensitivity of the personal data,
                        the potential risk of harm from unauthorized use or disclosure of your personal data, the
                        purposes for which we process your personal data,
                        and whether we can achieve these purposes through other means, and the applicable legal,
                        regulatory, tax, accounting or other requirements.
                    </p> <p>
                        By law, for tax purposes, we have to keep basic information about our customers (including
                        contact, identity, financial and transaction data)
                        for six years after they cease being customers.
                    </p> <p>
                        In some circumstances you can ask us to delete your data (see the sections Account Deletion and
                        Data Subject Requests, above).
                        Once you have asked us to delete your account, we will delete all information that we are not
                        required or permitted to retain by law.
                    </p> <p>
                        In some circumstances we will anonymize your personal data (so that it can no longer be
                        associated with you) for research or statistical purposes,
                        in which case we may use this information indefinitely without further notice to you.
                    </p> <h2>
                        Merger or Sale
                    </h2> <p>
                        We reserve the right to transfer or assign the information that we have collected from Users in
                        connection with any contemplated change
                        of ownership transaction, including, without limitation, any merger, acquisition, sale of stock
                        or assets related to the Service, corporate reorganization,
                        or other change of control event, including in the course of diligence, provided that the
                        transferee or assignee shall be obligated to treat the
                        transferred or assigned information in accordance with this Policy.
                    </p> <h2>
                        Minors
                    </h2> <p>
                        junefox.com does not knowingly collect, use or disclose information from Users
                        who are not at least 18 years old or of the age of legal majority in their jurisdiction of
                        residence,
                        whichever is older. Our Service is intended for adults only and it is not directed
                        to minors. If you believe we have collected personal information from a minor, please
                        contact us at <a href="mailto:support@junefox.com">support@junefox.com</a>.
                    </p> <h2>
                        Do Not Track
                    </h2> <p>
                        The Service is not designed to respond to “do not track” signals sent by some browsers.
                    </p> <h2>
                        Contact
                    </h2> <p>
                        Please contact us with any questions or comments about this Policy by email to <a
                        external="" href="mailto:support@junefox.com">support@junefox.com</a>.
                    </p> <h2>
                        Language
                    </h2> <p>
                        This policy was originally written in English. To the extent any translated version of these
                        this policy conflicts with the English version, the English version controls.
                    </p> <p><b>Last modified:</b> April 2021
                    </p></div>
                <p>
                    © 2022 Internet Fox SRL unless otherwise noted. All rights reserved.
                </p></div>
        </div>
        <BottomMenu/>
    </>
        ;

}
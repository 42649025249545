import React from 'react';
const UploadIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '60',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_2000" data-name="Group 2000" transform="translate(-1668 -526)">
        <rect id="Rectangle_1219" data-name="Rectangle 1219" width={width} height={width} transform="translate(1668 526)" fill={fillNone} />
        <path id="round-top-arrow" d="M25,0A25,25,0,1,0,42.678,7.322,25,25,0,0,0,25,0Zm0,45a20,20,0,1,1,14.142-5.858A20,20,0,0,1,25,45Zm.475-35a1.925,1.925,0,0,1,1.325.55l7.825,7.825a1.25,1.25,0,0,1,0,1.775L32.85,21.925a1.225,1.225,0,0,1-1.75,0l-3.6-3.6V38.75A1.25,1.25,0,0,1,26.25,40h-2.5a1.25,1.25,0,0,1-1.25-1.25V18.325L18.9,21.95a1.25,1.25,0,0,1-1.775,0l-1.75-1.775a1.25,1.25,0,0,1,0-1.775L23.2,10.55A1.875,1.875,0,0,1,24.525,10Z" transform="translate(1673 531)" fill={fill} />
      </g>
    </svg>

  </>
);
export default UploadIcon;
import React from 'react';
const FacebookIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '60',
  className = '',
  viewBox = '0 0 60 60'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="facebook" transform="translate(-5 -5)">
        <path id="Path_1" data-name="Path 1" d="M35,5A30,30,0,1,0,65,35,30,30,0,0,0,35,5Z" fill="#039be5" />
        <path id="Path_2" data-name="Path 2" d="M31.771,37.316h7.444l1.169-7.542H31.77V25.651c0-3.133,1.026-5.912,3.965-5.912h4.722V13.157a40.217,40.217,0,0,0-5.9-.356c-6.924,0-10.983,3.647-10.983,11.955v5.018H16.457v7.542h7.117V58.046a28.9,28.9,0,0,0,4.3.355,28.89,28.89,0,0,0,3.88-.187Z" transform="translate(6.543 6.599)" fill={fill} />
      </g>
    </svg>

  </>
);
export default FacebookIcon;
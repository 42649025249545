import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { activePopupState } from '../../../state/components/popup'
import { useUserAction } from '../../../action/user-action'

const ReportBlock = ({hide}) => {
  const info = useRecoilValue(activePopupState('report_block'));
  const setReportPopup = useSetRecoilState(activePopupState('report'));
  const media = info.media || null;
  const user = media?.user || info.user || null;
  const useAction = useUserAction();

  const blockBtn = () => {
    const data = {accountId : user.id}
    useAction.blockAccount(data).then(res => {
      if (res.id) {
        hide();
      }
    });
  }

  return <div>
    {media && <div className={'jf-popup-report_block-report'} onClick={() => {
      hide();
      setReportPopup({active: true, media: media})
    }}>
      Report
    </div>}
    <div className={'jf-popup-report_block-block'} style={!media ? {borderRadius: "20px"} : {}} onClick={blockBtn}>
      Block
    </div>
  </div>;
}

export default ReportBlock;
import React from 'react';
const UserIcon = ({
  style = {},
  fill = "#7c79fa",
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '29',
  height = '16',
  className = '',
  viewBox = '0 0 29 16'
}) => (
  <>
    <svg id="user" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <g id="menu-icon" transform="translate(-2002 189.5)" opacity="0.8">
          <rect id="Rectangle_1053" data-name="Rectangle 1053" width="29" height="2" rx="1" transform="translate(2002 -189.5)" fill={fill}/>
          <rect id="Rectangle_1054" data-name="Rectangle 1054" width="18" height="2" rx="1" transform="translate(2013 -182.5)" fill={fill}/>
          <rect id="Rectangle_1055" data-name="Rectangle 1055" width="14" height="2" rx="1" transform="translate(2017 -175.5)" fill={fill}/>
        </g>
    </svg>


  </>
);
export default UserIcon;
import React from 'react';
const PluscircleIcon = ({
  style = {},
  fill = '#fff',
  fillCircle = '#23d2e2',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="add" transform="translate(-359 -531)">
        <circle id="Ellipse_23" data-name="Ellipse 23" cx="12" cy="12" r="12" transform="translate(359 531)" fill={fillCircle}/>
        <g id="Group_38" data-name="Group 38" transform="translate(366.2 538.2)">
          <g id="Group_36" data-name="Group 36">
            <g id="Rectangle_1049" data-name="Rectangle 1049" transform="translate(0 4)" fill={fill} stroke="#fff" strokeWidth="1">
              <rect width="9.6" height="1.6" stroke="none"/>
              <rect x="0.5" y="0.5" width="8.6" height="0.6" fill="none"/>
            </g>
            <g id="Rectangle_1050" data-name="Rectangle 1050" transform="translate(4)" fill={fill} stroke="#fff" strokeWidth="1">
              <rect width="1.6" height="9.6" stroke="none"/>
              <rect x="0.5" y="0.5" width="0.6" height="8.6" fill="none"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </>
);
export default PluscircleIcon;
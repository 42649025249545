import React from 'react';

const SelectTab = ({tabs, tab, setTab, className = '', liClassName = '', ...props}) => {
  return <ul className={"jf-select-tab " + className} {...props}>
    {tabs.map(item => {
      return <li className={`jf-select-tab-li ${item.id === tab.id ? 'jf-active ' : ' '}` + liClassName}
                 style={{width: (100/tabs.length).toFixed(2) + '%'}}
                 key={"jf-select-tab-" + item.id}
                 onClick={() => setTab(item)}>{item.name}</li>
    })}
  </ul>
}

export default SelectTab;
import React from 'react';
import Usernamepostdetail from '../usernamepostdetail'
import IconAvatar from '../complex/icon-avatar'
import LikeButton from './like-button'
import SVGIcon from '../svg/index'
import { countFormat, getTab, setSeparateState } from '../../helpers/utils'
import { useRelationAction } from '../../action/relation-action'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { activeBottomPopupState, activePopupState } from '../../state/components/popup'
import { authProfileAtom } from '../../state/auth'
import SwiperArrow from './swiper-arrow'
import { swiperMediaDetailAtom } from '../../state/components/swipper'

const MediaDetail = React.memo(({item, type, medias, swiper, isLive}) => {
  const relationAction = useRelationAction();
  const setSharePopup = useSetRecoilState(activeBottomPopupState('share'));
  const setTipPopup = useSetRecoilState(activePopupState('tips'));
  const setReportAndBlockPopup = useSetRecoilState(activePopupState('report_block'));
  const setCommentPopup = useSetRecoilState(activeBottomPopupState('comment'));
  const authProfile = useRecoilValue(authProfileAtom);
  const [{ loading }, setSwiperMediaDetail] = useRecoilState(swiperMediaDetailAtom);
  const setLoading = setSeparateState(setSwiperMediaDetail, "loading")

  const size = {width: 40, height: 40};

  const isOfAuth = authProfile && authProfile.username === item.user.username;

  const onCheck = (user) => {
    if (isOfAuth) {
      return;
    }
    if (loading) return
    setLoading(true);
    relationAction.upFollowing(user.id).then(() => setLoading(false));
  }

  function toggleFullScreen() {
    if (isLive) {
      return;
    }
    if (item.type === 'video') {
      const elem = document.querySelectorAll(".swiper-slide-active video")[0]
      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }
    }
  }

  return <>
    {!isLive && <Usernamepostdetail media={item} tab={getTab(type)}/>}
    <div className="jf-user-pannel">
      {medias && medias.length > 0 && <SwiperArrow swiper={swiper} medias={medias}/>}
      {item.type === 'video' && <div className="jf-user-pannel-shares jf-fullscreen desktop-display">
        <button className={'jf-no-style-btn'} onClick={toggleFullScreen}>
          <SVGIcon name="fullscreen-icon" width={45}/>
        </button>
      </div>}
      <IconAvatar
        username={item.user.username}
        live={item.user?.live}
        onCheck={() => onCheck(item.user)}
        checked={item.user.isFollowing}
        width={58} height={58}
        showCheck={item.user.id !== authProfile?.id}
        image={item.user.avatar} text={item.user.fullname}/>
      <LikeButton item={item} type={type}/>
      <div className="jf-user-pannel-comments jf-pointer"
           onClick={() => setCommentPopup({active: true, media: item.id})}>
        <SVGIcon name="user-comment-icon" className="jf-comment-icon" {...size}/>
        <span className="jf-comment-span">{countFormat(item.comments)}</span>
      </div>
      <button className={'jf-no-style-btn jf-user-pannel-shares'} onClick={() =>
        setSharePopup({ active: true,
          media: item.id
        })
      }>
        <SVGIcon name="share-icon" className="jf-shares-icon" {...size}/>
        <span className="jf-shares-span">Share</span>
      </button>
      <div className="jf-user-pannel-shares jf-pointer" onClick={() => {
        setTipPopup({active:true,profile: item.user})
      }}>
        <SVGIcon name="money-icon" fill={'#fff'} width={17} height={30}/>
      </div>
      <div className="jf-user-pannel-shares threedotsmenuicon2 jf-pointer"
           style={{visibility: item.user.id !== authProfile?.id ? 'visible': 'hidden'}}
        onClick={() => authProfile && authProfile.id !== item.user.id && setReportAndBlockPopup({active: true, media: item})}>
        <SVGIcon name="three-dots-icon" fill={'#fff'}/>
      </div>
    </div>
  </>;
})

export default MediaDetail;
import React  from 'react';
import Avatar from '../basic/avatar';
import SVGIcon from '../svg/SVGIcon';
import variables from '../../scss/variables.module.scss'

const IconAvatar = React.memo((props) => {
  const { showCheck = true, online, checked, onCheck, link, width = 60, height = 60, ...propsOther } = props;
  return (
    <div className='jf-icon-avatar' style={{width: width, height: height + 10}}>
      <Avatar online={false} {...propsOther} width={width} height={height}/>
      <button className={'jf-icon-avatar-check-btn'} onClick={onCheck} style={{visibility: showCheck?'visible':'hidden'}}>
        <SVGIcon name={checked ? 'checkcircleicon' : 'pluscircleicon'} width={20} fill={variables['colors-jf-white']}/>
      </button>
    </div>
  )
})

export default IconAvatar;
import React from "react";

const UserIcon = ({
  style = {},
  fill = "#fff",
  fillOpacity = "0.3",
  fillNone = "none",
  width = "22",
  height = "22",
  className = "",
  viewBox = "0 0 22 22",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        id="join"
        d="M12,1A11,11,0,0,0,3.6,19.1l0,0,.056.061A10.976,10.976,0,0,0,11.858,23H12a10.976,10.976,0,0,0,8.353-3.842l.053-.057v0A11,11,0,0,0,12,1Zm7.648,16.58a9.465,9.465,0,1,0-15.3,0,8.981,8.981,0,0,1,4.662-3.788,4.86,4.86,0,1,1,5.972,0,8.986,8.986,0,0,1,4.663,3.788Zm-7.9,3.885.22,0H12a9.42,9.42,0,0,0,6.576-2.67,7.422,7.422,0,0,0-13.152,0,9.422,9.422,0,0,0,6.325,2.666ZM12,6.635a3.326,3.326,0,1,0,3.326,3.326A3.326,3.326,0,0,0,12,6.635Z"
        transform="translate(-1 -1)"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default UserIcon;

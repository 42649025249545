import TopMenu from '../../../../components/basic/top-menu'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BottomMenu from '../../../../components/basic/bottom-menu'
import { useRecoilValue } from 'recoil'
import { authProfileAtom } from '../../../../state/auth'

const PurchasesPayment = ({match}) => {
  const navigate = useNavigate();
  const authProfile = useRecoilValue(authProfileAtom);

  useEffect(() =>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authProfile]);

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"Payment"}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page jf-purchases-page'}>
    </div>
    <BottomMenu/>
  </>;
}

export default PurchasesPayment;
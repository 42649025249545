import React from 'react';
const VideoIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
     <g id="Rectangle_1063" data-name="Rectangle 1063" fill="rgba(230,230,230,0)" stroke={fill} strokeWidth="1">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="none"/>
     </g>
     <path id="Rectangle" d="M0,.4A.2.2,0,0,1,.31.221L7.9,4.814a.207.207,0,0,1,0,.354L.31,9.761A.2.2,0,0,1,0,9.584Z" transform="translate(8.4 7.409)" fill="rgba(230,230,230,0)" stroke={fill} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    </svg>
  </>
);
export default VideoIcon;

import React, {useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom'
import Queries from '../../../../params/queries'
import { useFetchApiAction } from '../../../../action/main-action'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authIdAtom } from '../../../../state/auth'
import Constants from '../../../../params/constants'
import Avatar from '../../../../components/basic/avatar'
import { msgLastTimeFormat, setSeparateState, uniqueList } from '../../../../helpers/utils'
import { Virtuoso } from 'react-virtuoso'
import { messageInboxTabAtom } from '../../../../state/pages/message'
import { LiveMessengerThread } from '../../../../helpers/live-grahpql';

const InboxTab = () => {
  const [{limited, loading, list }, setMessageInboxTab] = useRecoilState(messageInboxTabAtom);

  const setList = setSeparateState(setMessageInboxTab, "list")
  const setLoading = setSeparateState(setMessageInboxTab, "loading")
  const setLimited = setSeparateState(setMessageInboxTab, "limited")

  const navigate = useNavigate();
  const fetchApi = useFetchApiAction();
  const limitAmount = Constants.ListLimit;
  const authId = useRecoilValue(authIdAtom);

  useEffect(() => {

    let isCancel = false;
    fetchApi(Queries.Messages.Threads, {
      limit: { limit: limitAmount }
    }).then(res => {
      if (isCancel) return;
      setList(res.messenger_threads);
      setLoading(false)
      setLimited(res.messenger_threads.length < limitAmount);
    })
    return () => {
      isCancel = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = useCallback(() => {
    if (loading || limited || list.length === 0) return;
    setLoading(true);
    fetchApi(Queries.Messages.Threads, {
      limit: { limit: limitAmount, skip: list.length }
    }).then(res => {
      if (!res) return;
      setList(old => uniqueList([...old, ...res.messenger_threads]));
      setLoading(false)
      setLimited(res.messenger_threads.length < limitAmount);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, fetchApi, limited])

  useEffect(() => {
    LiveMessengerThread.run(authId, (res) => {
      const thread = res?.data?.messenger_thread?.thread
      const action = res?.data?.messenger_thread?.action
      if (thread && action === 'new') {
        setList(old => uniqueList([...old, thread]));
      }
    })

    return () => {
      LiveMessengerThread.end()
    }
  }, [authId])

  return <Virtuoso
    data={list}
    endReached={loadMore}
    emptyComponent={() => <div className='jf-message-empty'><div className='js-message-empty-text'>No messages</div></div>}
    itemContent={(index, item) => {
      const i = item.users.findIndex(user => user.id === authId);
      const j = item.users.findIndex(user => user.id !== authId);
      const user = item.users[j];
      const unread = item['unread' + (i + 1)];
      const lastMessage = item.last_message ? item.last_message : undefined
      const value = lastMessage && lastMessage?.data?.type !== "text" ? JSON.parse(lastMessage.data.value) :
      lastMessage?.data?.value;

      return <div className={'jf-message-item jf-pointer'}
                  onClick={() => {
                    navigate('/messages/inbox/' + item.id)
                  }}>
        <Avatar image={user.avatar} width={60} height={60} text={user.fullname}/>
        <div className={'jf-message-item-body'}>
          <div>{user.fullname}</div>
          <div>{lastMessage?.data.type !== "text" ? value?.text : value}</div>
        </div>
        <div>
          <div>{lastMessage?.add_date ? msgLastTimeFormat(lastMessage?.add_date) : ''}</div>
          {unread > 0 && <div className={'jf-unread'}>{unread > 9 ? '9+' : unread}</div>}
        </div>
      </div>
    }}
  />
}

export default InboxTab;
import React from 'react'
import UserProfile from '../user-profile'
import { useRecoilValue } from 'recoil'
import { usernameAtom } from '../../../state/users'
import { useParams } from 'react-router-dom'

const Dashboard = () =>  {
  const username = useRecoilValue(usernameAtom);
  const params = useParams();

  return <UserProfile paramsUrl={{
      ...params,
      username: username,
  }} from={'dashboard'}/>;
}

export default Dashboard;
import React, { useEffect } from 'react';
import TopMenu from '../../../components/basic/top-menu'
import BottomMenu from '../../../components/basic/bottom-menu'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authProfileAtom } from '../../../state/auth'
import ItemMenu from '../../../components/basic/item-menu'

const Menu = (props) => {
  const navigate = useNavigate();
  const styleItem = { marginLeft: -20, marginRight: -20, width: 'auto' };

  const auth = useRecoilValue(authProfileAtom);
  
  useEffect(() => {
    if (auth) {
      navigate('/account')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return <>
    <TopMenu
      icon={'greater-icon'}
      title={"JuneFox"}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page'}>
      <ItemMenu list={[
        { iconLeft: 'create-user-icon', text: 'Join for free', onClick: () => navigate('/signup') },
      ]} style={styleItem}/>
      <ItemMenu list={[
        { iconLeft: 'sign-in-icon', text: 'Login', onClick: () => navigate('/signin') },
        { iconLeft: 'support-icon', text: 'Support' },
      ]} style={styleItem}/>
      <ItemMenu list={[
        { iconLeft: 'creator-black-icon', text: 'Become a creator', onClick: () => navigate('/creator-request') },
      ]} style={styleItem}/>
      <ItemMenu list={[
        { iconLeft: 'legal-icon', text: 'About', onClick: () => navigate('/about') },
      ]} style={styleItem}/>
    </div>
    <BottomMenu/>
  </>;
}

export default Menu;
import React from 'react'

const LegalIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '18',
  height = '22.004',
  className = '',
  viewBox = '0 0 18 22.004'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Legal"
          d="M5.25,1h13.5A2.225,2.225,0,0,1,21,3.2V15.04a3.263,3.263,0,0,1-.99,2.332l-4.77,4.664A3.414,3.414,0,0,1,12.855,23H5.25A2.225,2.225,0,0,1,3,20.8V3.2A2.225,2.225,0,0,1,5.25,1Zm13.5,13.2V3.2H5.25V20.8H12V15.3a1.113,1.113,0,0,1,1.125-1.1ZM8.06,7.6h7.875a.556.556,0,0,0,.563-.55V5.95a.556.556,0,0,0-.562-.55H8.06a.556.556,0,0,0-.562.55v1.1a.556.556,0,0,0,.563.55Zm4.5,4.4H8.06a.556.556,0,0,1-.562-.55v-1.1A.556.556,0,0,1,8.06,9.8h4.5a.556.556,0,0,1,.563.55v1.1A.556.556,0,0,1,12.56,12Z"
          transform="translate(-3 -1)" fillRule="evenodd"/>
  </svg>
}

export default LegalIcon;

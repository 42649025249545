import React from 'react';
const JunefoxLogoIcon = ({
  style = {},
  fill = '#7c79fa',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '168',
  height= '62',
  viewBox = '0 0 168 62'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <text id="junefox" transform="translate(88 44)" fill={fill} fontSize={height}
            fontFamily="JuneFox Vijaya Bold" fontWeight="700"><tspan x="-79.029" y="0">june</tspan><tspan y="0" fill="#23d2e2">fox</tspan></text>
    </svg>

  </>
);
export default JunefoxLogoIcon;
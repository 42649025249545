import React from 'react';
const NextIcon = ({
  style = {},
  fill = '#141822',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '6',
  height = '10.893',
  className = '',
  viewBox = '0 0 6 10.893'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <path id="Expand_More" data-name="Expand More" d="M715,57.662l-4.508,4.509-4.509-4.51a.55.55,0,0,0-.777.779l4.9,4.9h0a.548.548,0,0,0,.776,0l4.9-4.9a.55.55,0,1,0-.778-.778Z" transform="translate(-57.5 715.94) rotate(-90)" fill={fill}/>
    </svg>
  </>
);
export default NextIcon;
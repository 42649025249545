import React, {useState, useEffect} from 'react'
import SVGIcon from '../svg'
import variables from '../../scss/variables.module.scss'

export const SelectField = ({ list, type, width, label, styleContain, error, placeholder, value, setValue,...props }) => {
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value || "");
  const dropDown = () => {
    setShowItems(old => !old);
  };

  useEffect(() =>{
    setSelectedItem(value);
  }, [value])

  const selectItem = item => {
    setSelectedItem(item);
    setValue(item)
    setShowItems(false);
  };

  return (
    <div className={'jf-text-field ' + (error || error === ' ' ? 'has-error ' : '') + (label ? 'has-label ' : '')}
         style={{ ...styleContain, width: width || '100%' }}>
      {
        label && <div className='jf-text-field-label'>
          {label}
        </div>
      }
      <div className={'jf-text-field-input jf-pointer'}>
        <div className={'jf-text-field-select'} onClick={dropDown}>&nbsp;
          {(selectedItem === "" && <span className={'jf-text-field-placeholder'}>{placeholder}</span>)}{
            selectedItem
          }</div>
        <button type={'button'} className="jf-text-field-view-btn jf-pointer" onClick={dropDown}>
          <SVGIcon name={showItems ? "top-arrow-icon" : "bottom-arrow-icon"} width={30}
                   strokeWidth="0.5" fill={variables['colors-jf-gray']}/>
        </button>
        <ul
          style={{ display: showItems ? "block" : "none" }}
          className={"jf-text-field-select-items"}
        >
          {list.map((item, i) => (
            <li
              key={i}
              onClick={() => selectItem(item)}
              className={selectedItem === item ? "selected" : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      {error && <div className={'jf-text-field-msg'}>{error}</div>}
    </div>
  );
}

import SVGIcon from '../svg'
import React from 'react'

const SwiperArrow = React.memo(({swiper, medias}) => {
  return <div className={'jf-swiper-arrow desktop-display'}>
    <div className="jf-user-pannel-shares jf-top-arrow jf-pointer" onClick={() => {
      if (swiper && !swiper.destroyed && medias && medias.length > 0) {
        swiper.slidePrev();
      }
    }}>
      <SVGIcon name="top-arrow-icon" width={40}/>
    </div>
    <div className="jf-user-pannel-shares jf-bottomarrow jf-pointer" onClick={() => {
      if (swiper && !swiper.destroyed && medias && medias.length > 0) {
        swiper.slideNext();
      }
    }}>
      <SVGIcon name="bottom-arrow-icon" width={40}/>
    </div>
  </div>
})

export default SwiperArrow;
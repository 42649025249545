import { useFetchApiAction } from './main-action';
import { getTab, upperFirstChar } from '../helpers/utils'
import Mutations from '../params/mutations'
import Queries from '../params/queries'
import Constants from '../params/constants'

export function useMediaAction(autoSet, setMedia) {
  const fetchApi = useFetchApiAction();

  const self = {
    getMedias: async function (type, params) {
      params.limit.limit = params.limit.limit || Constants.MediaListLimit;
      let query;
      switch (getTab(type, true)) {
        case 'photo':
          query = Queries.Media.Photos;
          break;
        case 'private':
          query = Queries.Account.MediaPrivate;
          break;
        default:
          query = Queries.Media.Videos;
          break;
      }
      const res = await fetchApi(query, params);
      if (autoSet) {
        setMedia(res && res[getTab(type)]);
      }
      return res ? res[getTab(type)] : [];
    },
    getReference: async function (type, params) {
      let query;
      switch (getTab(type, true)) {
        case 'photo':
          query = Queries.References.Photos;
          break;
        case 'shop':
          query = Queries.References.Shop;
          break;
        default:
          query = Queries.References.Videos;
          break;
      }
      const res = await fetchApi(query, params);
      let media = res ? res[getTab(type, true) + '_reference'] : []
      media = media.map(item => {
        item.user = res.profile;
        return item;
      });
      if (autoSet) {
        setMedia(media);
      }
      return media;
    },
    like: async function (params) {
      const res = await fetchApi(Mutations.MediaLike.Update, params);
      return res ? res.like : null;
    },
    processFirstLast: async (type, result, params, lastItem, setLastItem) => {
      const index = result.findIndex(item => item.id === params.filter.reference)
      const newParams = {limit: {limit: 1}, filter: {username: params.username}, withLike: true };
      let list, item;
      if (index === 0) {
        newParams.limit.order = true;
        item = lastItem && lastItem.user.username === params.username ? lastItem :
          await self.getMedias(type, newParams).then((res) => {
            return res[0];
          });
        list = [item ? item : result[0], ...result];
        setLastItem(list[0]);
      } else {
        newParams.limit.order = false;
        item = await self.getMedias(type, newParams).then((res) => {
          return res[0];
        })
        list = [...result, item ? item : result[1]];
      }
      return list;
    },
    getFavorites: async function (type, params) {
      let query = getTab(type, true) === 'photo' ? Queries.Favorites.Photos : Queries.Favorites.Videos;
      params.limit.limit = params.limit.limit || Constants.MediaListLimit;
      const res = await fetchApi(query, params);
      if (autoSet) {
        setMedia(res && res['favorite' + upperFirstChar(getTab(type))]);
      }
      return res ? res['favorite' + upperFirstChar(getTab(type))] : [];
    },
    generateShortLink: async function (media) {
      const res = await fetchApi(Mutations.ShortLink, {media: media});
      return res && res.generateShortLink
    },
    getShortLink: async function (id) {
      const res = await fetchApi(Queries.ShortLink, {id});
      return res && res.shortLink
    },
    makeReport: async function (data) {
      const res = await fetchApi(Mutations.Report.MakeReport, data);
      return res && res.makeReport
    },
    updateMedia: async function (data) {
      return await fetchApi(Mutations.Media.Update, {media: data})
    },
    deleteMedia: async function (media) {
      return await fetchApi(Mutations.Media.Delete, {media})
    }
  }

  return self;
}
import React from 'react'

const SignOutIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '19.997',
  height = '20.004',
  className = '',
  viewBox = '0 0 19.997 20.004'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="SignOut"
          d="M4.222,19.78h6.111a.556.556,0,0,1,.556.556v1.111a.556.556,0,0,1-.556.556H4.222A2.222,2.222,0,0,1,2,19.78V4.22A2.222,2.222,0,0,1,4.222,2h6.111a.556.556,0,0,1,.556.556V3.665a.556.556,0,0,1-.556.556H4.222ZM16.042,5.5l5.711,5.7a.855.855,0,0,1,.244.589v.422a.834.834,0,0,1-.244.589l-5.711,5.7a.556.556,0,0,1-.789,0l-.789-.778a.556.556,0,0,1,0-.789L18.3,13.111H7a.556.556,0,0,1-.556-.556V11.445A.556.556,0,0,1,7,10.889H18.3L14.464,7.067a.545.545,0,0,1,0-.778l.789-.789a.556.556,0,0,1,.789,0Z"
          transform="translate(-2 -1.998)" fillRule="evenodd"/>
  </svg>
}

export default SignOutIcon;

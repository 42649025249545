import React from 'react';
const SelectSongicon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 16'
}) => (
  <>
    <svg id="video-camera" xmlns="http://www.w3.org/2000/svg" width={width} height="15.429" viewBox={viewBox}>
      <path id="Path_5559" data-name="Path 5559" d="M23.594,87.175a.857.857,0,0,0-.834-.038l-5.617,2.809V87.9a2.571,2.571,0,0,0-2.571-2.571h-12A2.571,2.571,0,0,0,0,87.9V98.19a2.571,2.571,0,0,0,2.571,2.571h12a2.571,2.571,0,0,0,2.571-2.571V96.148l5.617,2.813A.857.857,0,0,0,24,98.19V87.9A.857.857,0,0,0,23.594,87.175Zm-15.022,9.3A3.429,3.429,0,1,1,12,93.047,3.429,3.429,0,0,1,8.571,96.476Z" transform="translate(0 -85.333)" fill={fill} />
      <circle id="Ellipse_78" data-name="Ellipse 78" cx="4.8" cy="4.8" r="4.8" transform="translate(3.6 3)" fill={fill} />
    </svg>

  </>
);
export default SelectSongicon;
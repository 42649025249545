import React, { useEffect } from "react";
import { Puff } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../../components/header.js";
import { TextField } from "../../../components/basic/text-field";
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import Mutations from "../../../params/mutations";
import variables from "../../../scss/variables.module.scss";
import { useRecoilState, useResetRecoilState } from 'recoil'
import { authForgotPasswordAtom } from '../../../state/pages/auth'
import { setSeparateState } from '../../../helpers/utils'

const schema = yup
  .object({
    email: yup.string().required().email().label("Email address"),
  })
  .required();

function ForgotPassword() {
  const [{isLoading, isSentRequest, errorMsg, }, setForgotPassword] = useRecoilState(authForgotPasswordAtom);
  const setIsLoading = setSeparateState(setForgotPassword, "isLoading");
  const setIsSentRequest = setSeparateState(setForgotPassword, "isSentRequest");
  const setErrorMsg = setSeparateState(setForgotPassword, "errorMsg");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetForgotPassword = useResetRecoilState(authForgotPasswordAtom);

  const fetchAction = useFetchApiAction(true);

  useEffect(() => {
    resetStateAction.add(resetForgotPassword)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsSentRequest(false);
    setIsLoading(true);
    setErrorMsg("");

    fetchAction(Mutations.PasswordReset.PasswordResetRequest, data).then(
      (res) => {
        if (res && res.data && res.data.password_reset_request && res.data.password_reset_request.user) {
          setIsSentRequest(true);
        } else if (res.errors && res.errors[0]) {
          setErrorMsg(res.errors[0].message);
        }

        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <div className="jf-forgot-pass-content">
        <Header />
        <div className="jf-forgot-pass-body">
          <h1 className="jf-title">Forgot password</h1>
          {isSentRequest ? (
            <div className="jf-text-field-msg error">
              Check your email to password reset link
            </div>
          ) : (
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <div className="jf-input-boxs">
                <TextField
                  placeholder={"Email Address"}
                  error={errors.email?.message}
                  register={register("email")}
                />
              </div>
              {errorMsg && (
                <div className={"jf-text-field-msg error"}>{errorMsg}</div>
              )}
              <ul className="jf-btn-ul jf-mt-20px">
                <li className="jf-btn-ul-li">
                  <button
                    disabled={isLoading}
                    className="jf-btn jf-basic-btn"
                    type={"submit"}
                  >
                    {isLoading && (
                      <Puff
                        wrapperStyle={{justifyContent: 'center'}}
                        className="jf-forgot-pass-loading"
                        color={variables['colors-jf-white']}
                        height={20}
                        width={20}
                      />
                    )}
                    Send code
                  </button>
                </li>
              </ul>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";
import PageMain from "./pages";
import Constants from './params/constants'
import './scss/style.scss';
import 'swiper/css';
import Popups from './components/complex/popup'
import BottomPopups from './components/complex/bottom-popup'
import { useRecoilValue, useRecoilState } from 'recoil'
import { authIdAtom } from './state/auth'
import { loadingAppAtom } from './state'

export default function App() {
  const authId = useRecoilValue(authIdAtom);
  const [loading, setLoading] = useRecoilState(loadingAppAtom);

  useEffect(() => {
    setLoading(true);
    Constants.init(authId);
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authId])

  return (
    <Router>
      {!loading && <div><Routes>
        <Route path="*" element={<PageMain/>}/>
      </Routes></div>}
      <Popups/>
      <BottomPopups/>
    </Router>
  );
}
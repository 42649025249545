import React from 'react';
import Avatar from './basic/avatar';
import Button from './basic/button';
import { useRelationAction } from '../action/relation-action'

const FollowItem = React.memo(({ user }) => {
  const relationAction = useRelationAction();

  const toggleBtn = () => {
    relationAction.upFollowing(user.id)
  };

  return <div className={'jf-basic-list-item'}>
    <Avatar image={user.avatar} text={user.fullname}/>
    <div className={'jf-basic-list-item-detail'}>
      <div className="jf-basic-list-item-detail-header">{user.fullname}</div>
      <div className="jf-basic-list-item-detail-body">{user.username}</div>
    </div>
    <div className={'jf-basic-list-item-left'}>
      <Button
        className={'small ' + (user.isFollowing !== false ? '' : 'secondary')}
        onClick={toggleBtn}
      >{user.isFollowing !== false ? 'Following' : 'Follow'}
      </Button>
    </div>
  </div>
});

export default FollowItem;
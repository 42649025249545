import {useEffect, useRef, useState} from "react";

export default function VideoElement(props) {
    const videoRef = useRef();
    const [source, setSource] = useState(props.source);

    let hlsItem;
    const Hls = window.Hls;

    useEffect(() => {
        if (!(videoRef && videoRef.current && source)) {
            return;
        }
        const videoElem = videoRef.current;

        if (source.type === "hls") {
            if (Hls.isSupported()) {
                hlsItem = new Hls();
                hlsItem.loadSource(source.url);
                hlsItem.attachMedia(videoElem);
            } else if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
                videoElem.src = source.url;
                // videoElem.muted = true;
            }
        }
    }, [])
    return (
        <video style={{height: '300px', flexGrow: 0}} ref={videoRef} controls={false} preload="auto" playsInline={true}
               loop={true} >
            {/*{source && <source src={source.url} type="video/mp4"/>}*/}
        </video>);
}
import React from 'react';
const CloseIcon = ({
  style = {},
  fill = '#595d73',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = 30,
  className = '',
  viewBox = '0 0 30 30'
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
    <g id="Group_1082" data-name="Group 1082" transform="translate(-1426 -285)">
      <rect id="Rectangle_1094" data-name="Rectangle 1094" width={width} height={width} transform="translate(1426 285)" fill={fillNone} opacity={fillOpacity}/>
      <g id="Group_1081" data-name="Group 1081" transform="translate(1431 290)">
        <rect id="Rectangle_1095" data-name="Rectangle 1095" width="2.013" height="26.845" rx="1.007" transform="translate(18.982) rotate(45)" fill={fill}/>
        <rect id="Rectangle_1096" data-name="Rectangle 1096" width="2.013" height="26.845" rx="1.007" transform="translate(0.001 1.746) rotate(-45)" fill={fill}/>
      </g>
    </g>
  </svg>

);

export default CloseIcon;



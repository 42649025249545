import React from 'react'

const SupportIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '18',
  height = '22',
  className = '',
  viewBox = '0 0 18 22'
}) => {
  return <svg id="message" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="support"
          d="M21,13v4a2,2,0,0,1-2,2v1a3,3,0,0,1-3,3H13a1,1,0,0,1-1-1v-.5a.5.5,0,0,1,.5-.5H16a1,1,0,0,0,1-1V19a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1V8A5,5,0,0,0,7,8v3a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1H5a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2V8A7,7,0,0,1,19,8v3a2,2,0,0,1,2,2Z"
          transform="translate(-3 -1)"/>
  </svg>

}

export default SupportIcon;
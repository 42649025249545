import React, { Component } from 'react';
import SVGIcon from '../svg/index'
import CurrencyInput from "react-currency-input-field";

export class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type
    }
  }

  switchViewPass = () => {
    this.setState({type: this.state.type === 'text' ? 'password' : 'text'})
  }

  render() {
    const {type, width, label, styleContain, error, register, iconLeft, iconRight, ...props} = this.props;

    return (
        <div className={'jf-text-field ' + (error || error === ' ' ? 'has-error ' : '') + (label ? 'has-label ' : '')}
             style={{...styleContain}}>
          {
            label && <div className='jf-text-field-label'>
              {label}
            </div>
          }
          <div className={'jf-text-field-input'}>
            {iconLeft}
            {type === 'money' ?
                <CurrencyInput
                    decimalSeparator="." groupSeparator=","
                    {...register}
                    {...props}/> :
                <input
                    type={['text', 'number', 'email', 'password'].indexOf(type) > -1 ? this.state.type : 'text'}
                    {...register}
                    {...props}
                />}
            {iconRight}
            {
              this.props.type === 'password' &&
              <button type={'button'} className="jf-text-field-view-btn" onClick={this.switchViewPass}>
                <SVGIcon name={this.state.type === 'text' ? "hide-password-icon" : "show-password-icon"}/>
              </button>
            }
          </div>
          {error && <div className={'jf-text-field-msg'}>{error}</div>}
        </div>
    )
  }
}

import React from 'react';
const CommentIcon2 = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '55',
  className = '',
  viewBox = '0 0 55 55'
}) => (
  <>


    <svg id="favorite-24px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox={viewBox}>

      <path fill={fillNone} d="M0,0v55h55V0H0z M26.9,29.1c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.7-3.9,3.9-4c2.2,0,4,1.7,4,3.9
	C30.9,27.3,29.2,29.1,26.9,29.1L26.9,29.1z"/>
      <path id="Path_43" fill={fill} d="M26.9,5C14.3,5,4,13.8,4,24.6s10.3,19.6,22.9,19.6v3.5c0,0.9,0.7,1.7,1.6,1.7
	c0.3,0,0.6-0.1,0.9-0.2c4.1-2.6,12.1-7.8,14.9-11.8c1.7-1.7,3.1-3.6,4-5.8c0.9-2.2,1.4-4.5,1.5-6.9C49.9,13.8,39.7,5,26.9,5z
	 M15.8,29.1c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9C19.7,27.4,18,29.1,15.8,29.1z M27,29.1L27,29.1
	c-2.2,0-4-1.8-4-3.9c0-2.2,1.7-3.9,3.9-4c2.2,0,4,1.7,4,3.9C30.9,27.3,29.2,29.1,27,29.1z M40.9,28c-0.7,0.7-1.7,1.1-2.7,1.1
	c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9C42,26.3,41.6,27.3,40.9,28z"/>
    </svg>

  </>
);
export default CommentIcon2;
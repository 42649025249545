import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";

import Header from "../../../components/header.js";
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../action/main-action'
import Queries from "../../../params/queries.js";
import variables from "../../../scss/variables.module.scss";
import { useRecoilState, useResetRecoilState } from 'recoil'
import { authForgotPasswordVerifyAtom } from '../../../state/pages/auth'
import { setSeparateState } from '../../../helpers/utils'

function ForgotPasswordVerify() {
  const navigate = useNavigate();
  const fetchAction = useFetchApiAction();
  const [{isLoading, isInvalidToken }, setForgotPasswordVerify] = useRecoilState(authForgotPasswordVerifyAtom);
  const setIsLoading = setSeparateState(setForgotPasswordVerify, "isLoading");
  const setIsInvalidToken = setSeparateState(setForgotPasswordVerify, "isInvalidToken");
  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetForgotPasswordVerify = useResetRecoilState(authForgotPasswordVerifyAtom);

  useEffect(() => {
    resetStateAction.add(resetForgotPasswordVerify)

    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");

    if (!id) {
      return setIsInvalidToken(true);
    }

    setIsLoading(true);
    fetchAction(Queries.PasswordReset.PasswordResetRequest, { id }).then(
      (res) => {
        if (res.password_reset_request && res.password_reset_request.user) {
          navigate(`/forgot-pass/${id}`);
        } else {
          setIsInvalidToken(true);
        }
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="jf-forgot-pass-content">
        <Header />
        <div className="jf-forgot-pass-body">
          <h1 className="jf-title">Forgot password</h1>
          {isLoading && (
            <div className="jf-forgot-pass-loading">
              <Puff
                wrapperStyle={{justifyContent: 'center'}}
                color={variables["colors-jf-secondary"]}
                height={50}
                width={50}
              />
            </div>
          )}
          {!isLoading && isInvalidToken && (
            <div className="jf-text-field-msg error">
              Token is invalid or expired
            </div>
          )}
          <div className="jf-forgot-pass-loading"></div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordVerify;

import {useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil';
import { useFetchApiAction } from './main-action';
import { followersAtom, followingAtom } from '../state/relations'
import { profileAtom } from '../state/users'
import { mediaBoxAtom, mediaDashboardAtom, updatingAtom } from '../state/media'
import Mutations from '../params/mutations'
import Queries from '../params/queries'
import Constants from '../params/constants'

const limit = Constants.ListLimit;

export function useRelationAction (autoSet = true) {
  const profile  = useRecoilValue(profileAtom);
  const [following, setFollowing] = useRecoilState(followingAtom);
  const [followers, setFollowers] = useRecoilState(followersAtom);
  const [medias, setMedias] = useRecoilState(mediaDashboardAtom);
  const [mediasBox, setMediasBox] = useRecoilState(mediaBoxAtom);
  const setProfile  = useSetRecoilState(profileAtom);
  const fetchApi = useFetchApiAction();
  const setUpdating = useSetRecoilState(updatingAtom)

  return {
    getFollowers: async function () {
      const variables = {
        limit: {
          limit: limit
        }
      }
      const res = await fetchApi(Queries.Relation.Follower, variables);
      setFollowers(res ? res.followers : []);
    },
    getFollowing: async function () {
      const variables = {
        limit: {
          limit: limit
        }
      }
      const res = await fetchApi(Queries.Relation.Following, variables);
      setFollowing(res ? res.following : []);
    },
    upFollowing: async function (id) {
      const res = await fetchApi(Mutations.Relation.Following, { id: id });
      if (res === undefined) {
        return;
      }
      this.setUpFollowing(id, res.upFollowing);
    },
    setUpFollowing: (id, status) => {
      setUpdating(true);
      if (medias) {
        setMedias(medias.map((item) => {
          if (item.user.id === id) {
            return { ...item, user: {...item.user, isFollowing: status} };
          }
          return item;
        }));
      }
      if (mediasBox) {
        setMediasBox(mediasBox.map((item) => {
          if (item.user.id === id) {
            return { ...item, user: {...item.user, isFollowing: status} };
          }
          return item;
        }));
      }
      if (following) {
        setFollowing(following.map((item) => {
          if (item.user.id === id) {
            return { ...item, user: {...item.user, isFollowing: status} };
          }
          return item;
        }));
      }
      if (followers) {
        setFollowers(followers.map((item) => {
          if (item.user.id === id) {
            return { ...item, user: {...item.user, isFollowing: status} };
          }
          return item;
        }));
      }
      if (profile && profile.id === id) {
        setProfile({
          ...profile, isFollowing: status, followers: profile.followers + (status ? 1 : -1)
        });
      }
    }
  }
}
import Likeicon from './like-icon'
import UserCommentIcon from './user-comment-icon'
import ShareIcon from './share-icon'
import TopArrowIcon from './top-arrow-icon'
import BottomArrowIcon from './bottom-arrow-icon'
import FullScreenIcon from './fullscreen-icon'
import VideoIcon from './video-icon'
import PhotoIcon from './photo-icon'
import ShopIcon from './shop-icon'

const icons = {
  'like-icon': Likeicon,
  'user-comment-icon': UserCommentIcon,
  'share-icon': ShareIcon,
  'top-arrow-icon': TopArrowIcon,
  'bottom-arrow-icon': BottomArrowIcon,
  'fullscreen-icon': FullScreenIcon,
  'video-icon': VideoIcon,
  'photo-icon': PhotoIcon,
  'shop-icon': ShopIcon
}

export default icons;
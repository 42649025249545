import React, {useEffect} from 'react'
import { minWidthScreen } from '../../../helpers/utils'
import UserProfile from '../user-profile'
import {useMediaQuery} from 'react-responsive'
import { useUserAction } from '../../../action/user-action'
import {useSetRecoilState} from 'recoil'
import { profileAtom } from '../../../state/users'
import { useSubscriptionLiveUserAction } from '../../../action/main-action'
import { swiperMobileBottomShowAtom } from '../../../state'
import { useParams } from 'react-router-dom'

const UserLive = (props) =>  {
  const userAction = useUserAction();
  const isBigScreen = useMediaQuery({ query: minWidthScreen })
  const setProfile = useSetRecoilState(profileAtom)
  const subscriptionLiveUserAction = useSubscriptionLiveUserAction();
  const setSwiperMobileBottomShow = useSetRecoilState(swiperMobileBottomShowAtom);
  const params = useParams();

  useEffect(() =>{
    setProfile(null);
    if (!isBigScreen) {
      userAction.getProfile(params.username).then((res) =>{

        subscriptionLiveUserAction(res.profile?.id).then()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBigScreen]);

  useEffect(() => {
    setSwiperMobileBottomShow(false);
  })

  return <UserProfile {...props} from={'dashboard'}/>;
}

export default UserLive;
import React, {useEffect} from 'react';
import {Routes, Route, Navigate, useParams, useLocation} from 'react-router-dom'
import WelcomeScreen from '../auth/pages/welcomescreen';
import Login from '../auth/pages/login';
import Otp from '../auth/pages/otp';
import Signin from '../auth/pages/signin';
import Singup from '../auth/pages/signup';
import ForgotPass from '../auth/pages/forgotPassword';
import ForgotPasswordVerify from '../auth/pages/forgotPasswordVerify';
import ResetPassword from '../auth/pages/resetPassword';
import Dashboard from '../main/dashboard';
import Following from './following'
import Messages from './messages'
import UserProfile from './user-profile'
import AuthProfile from './account/auth-profile'
import EditProfile from './edit-profile'
import EditProfilePassword from './edit-profile/password'
import Followers from './followers'
import Account from './account'
import CreatorRequest from './account/creator-request';
import EmailVerify from './account/email-verify';
import {authIdAtom, authProfileAtom} from '../../state/auth'
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import Search from './search'
import DashboardTag from './dashboard/tag'
import DashboardMedia from './dashboard/media'
import {Titles} from '../../params/titles'
import {profileAtom} from '../../state/users'
import {getTab} from '../../helpers/utils'
import Constants from '../../params/constants'
import Complete from './edit-profile/complete'
import ShortLink from './short-link'
import Settings from './account/settings'
import About from './about';
import Support from './support';
import SettingBasic from './account/settings/basic'
import EmailNotification from './account/settings/email-notification'
import Blocked from './account/settings/blocked'
import Delete from './account/settings/delete'
import Studio from './studio'
import StudioUpload from './studio/upload/index'
import StudioRecord from './studio/record/index'
import StudioLive from './studio/live'
import MyMedia from './account/my-media'
import EditMedia from './account/edit-media'
import StudioUploadPreview from './studio/upload/preview'
import StudioUploadProgress from './studio/upload/progress'
import {camStreamAtom, locationAtom, swiperMobileBottomShowAtom} from '../../state'
import UserLive from './user-profile/live'
import Sound from './studio/record/sound'
import StudioRecordPreview from './studio/record/preview'
import StudioRecordProgress from './studio/record/progress'
import Earnings from './account/earnings'
import EarningsPayment from './account/earnings/payment'
import Purchases from './account/purchases'
import PurchasesPayment from './account/purchases/payment'
import OvenLivekitTool from '../../helpers/oven-live-kit'
import MessagesInbox from './messages/inbox/index'
import Subscription from './account/subscription'
import MsgAuto from './messages/auto'
import Library from './library'
import MassUser from './messages/mass/users'
import MassNew from './messages/mass'
import MessageToUser from './messages/to-user'
import NewMediaMsg from './messages/inbox/new-media-msg'
import {LiveAuthMedia} from '../../helpers/live-grahpql'
import Menu from '../auth/pages/menu';
import {liveBoxAuthAtomFamily} from '../../state/components/live-box'
import {useResetStateWhenChangeUrlAction} from '../../action/main-action'
import {activeBottomPopupState, cookiePolicyAtom} from '../../state/components/popup';
import {TermsOfService} from "./about/terms_of_service";
import {PrivacyPolicy} from "./about/privacy_policy";
import {CookiePolicy} from "./about/cookie_policy";
import {Dmca} from "./about/dmca";
import {RefundPolicy} from "./about/refund-policy";

const site_name = Constants.Site.Name
const Page = ({component, ...props}) => {
    const profile = useRecoilValue(profileAtom);
    const authProfile = useRecoilValue(authProfileAtom);
    const cookiePolicy = useRecoilValue(cookiePolicyAtom);
    const setCookiePopup = useSetRecoilState(activeBottomPopupState('cookie_policy'));
    const setLocationAtom = useSetRecoilState(locationAtom);
    const [camStream, setCamStream] = useRecoilState(camStreamAtom);
    const setStartCamera = useSetRecoilState(liveBoxAuthAtomFamily("startCamera"));
    const setSwiperMobileBottomShow = useSetRecoilState(swiperMobileBottomShowAtom);
    const resetStateAction = useResetStateWhenChangeUrlAction();
    const location = useLocation();
    const paramsUrl = {...props.paramsUrl, ...useParams()}

    useEffect(() => {
        if (!cookiePolicy) {
            setCookiePopup({active: true})
        }
    }, [setCookiePopup, cookiePolicy]);

    useEffect(() => {
        if (props.title) {
            document.title = props.title({
                paramsUrl: paramsUrl,
                name: profile && profile.fullname,
                auth_name: authProfile && authProfile.fullname
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title, profile, authProfile]);

    useEffect(() => {
        setLocationAtom({
            params: paramsUrl,
            pagename: props.pagename,
            slideIndex: props.slideIndex
        })
        if (['/studio/record'].indexOf(location.pathname) === -1) {
            if (camStream) {
                const tracks = camStream.getTracks();
                tracks.forEach(track => track.stop());
                setCamStream(null);
            }
        }
        if (props.pagename !== "studio.live") {
            OvenLivekitTool.remove();
            setStartCamera(false);
        }
        setSwiperMobileBottomShow(true);

        LiveAuthMedia.end()

        resetStateAction.run()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, props.pagename])

    return component({...props, paramsUrl});
};

const Main = () => {
    const authId = useRecoilValue(authIdAtom);
    const authProfile = useRecoilValue(authProfileAtom);
    const location = useLocation()
    const params = useParams()

    const routes = definedRoutes(authProfile);
    return <div className="jf-main">
        <Routes>
            {routes.map((item, i) => {
                const {
                    path, isAuth = false, component, titleFn,
                    pagename = '', slideIndex, exact = false, paramsUrl = {}, ...others
                } = item;
                return <Route
                    key={'route-id-' + i}
                    path={path}
                    exact={exact.toString()}
                    element={((typeof isAuth === 'boolean' && isAuth) || (typeof isAuth !== 'boolean' && isAuth({paramsUrl: {...paramsUrl, ...params}})))
                    && !authId ?
                        <Navigate to={'/signin'}
                                  state={{prevPath: location}}/> :
                        <Page title={titleFn || null} paramsUrl={paramsUrl}
                              pagename={pagename}
                              slideIndex={slideIndex}
                              component={component}/>}
                    {...others}
                />
            })}
        </Routes>
    </div>;
}

const definedRoutes = (authProfile) => {
    return [
        {path: '/welcomescreen', component: (props) => <WelcomeScreen {...props}/>},
        {path: '/Login', component: (props) => <Login {...props}/>},
        {path: '/otp', component: (props) => <Otp {...props}/>},
        {
            path: '/signin', component: (props) => <Signin  {...props}/>,
            titleFn: () => Titles.SignIn({site_name}),
        },
        {
            path: '/signup', component: (props) => <Singup {...props}/>,
            titleFn: () => Titles.SignUp({site_name}),
        },
        {
            path: '/menu', component: (props) => <Menu {...props}/>,
            titleFn: () => Titles.SignUp({site_name}),
        },
        {
            path: '/forgot-pass/:id', component: (props) => <ResetPassword {...props}/>, exact: true,
            titleFn: () => Titles.ForgotPassword({site_name}),
        },
        {
            path: '/forgot-pass', component: (props) => <ForgotPass {...props}/>, exact: true,
            titleFn: () => Titles.ForgotPassword({site_name}),
        },
        {
            path: '/verification', component: (props) => <ForgotPasswordVerify {...props}/>, exact: true,
            titleFn: () => Titles.ForgotPassword({site_name}),
        },
        {
            path: '/videos/:tag',
            paramsUrl: {tab: "videos"},
            component: (props) => <DashboardTag {...props}/>, exact: true,
            titleFn: () => Titles.Home.Media({media_type: "videos", site_name}),
            pagename: "dashboard.medias.tags", slideIndex: 0,
        },
        {
            path: '/photos/:tag',
            paramsUrl: {tab: "photos"},
            component: (props) => <DashboardTag {...props}/>, exact: true,
            titleFn: () => Titles.Home.Media({media_type: "photos", site_name}),
            pagename: "dashboard.medias.tags", slideIndex: 0,
        },
        {
            path: '/videos',
            paramsUrl: {tab: "videos"},
            component: (props) => <Dashboard {...props}/>, exact: true,
            titleFn: () => Titles.Home.Media({media_type: "videos", site_name}),
            pagename: "dashboard.medias", slideIndex: 0,
        },
        {
            path: '/photos',
            paramsUrl: {tab: "photos"},
            component: (props) => <Dashboard {...props}/>, exact: true,
            titleFn: () => Titles.Home.Media({media_type: "photos", site_name}),
            pagename: "dashboard.medias", slideIndex: 0,
        },
        {
            path: '/lives',
            paramsUrl: {tab: "lives"},
            component: (props) => <Dashboard {...props}/>, exact: true,
            titleFn: () => Titles.Home.Media({media_type: "lives", site_name}),
            pagename: "dashboard.medias", slideIndex: 0,
        },
        {
            path: '/', component: (props) => <Dashboard {...props}/>, exact: true,
            titleFn: () => Titles.Home.Index({site_name}),
            pagename: "dashboard", slideIndex: 0,
        },
        {path: '/following', component: (props) => <Following {...props}/>},
        {path: '/followers', component: (props) => <Followers {...props}/>},
        {
            paramsUrl: {tab: "inbox"},
            path: '/messages', component: (props) => <Navigate to={{
                pathname: '/messages/inbox',
            }} replace={true}/>, exact: true,
            isAuth: true,
        },
        {
            path: '/messages/inbox/media', component: (props) => <NewMediaMsg {...props}/>, exact: true,
            isAuth: true,
        },
        {
            path: '/messages/to', component: (props) => <Navigate to={{
                pathname: '/messages/inbox',
            }} replace={true}/>, exact: true,
            isAuth: true,
        },
        {
            path: '/messages/to/:username', component: (props) => <MessageToUser {...props}/>, exact: true,
            isAuth: true,
        },
        {
            paramsUrl: {tab: "follower"},
            path: '/messages/auto/follower', component: (props) => <MsgAuto {...props}/>,
            titleFn: (params) => Titles.Messages.Auto({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            paramsUrl: {tab: "subscriber"},
            path: '/messages/auto/subscriber', component: (props) => <MsgAuto {...props}/>,
            titleFn: (params) => Titles.Messages.Auto({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            path: '/messages/mass/new', component: (props) => <MassNew {...props}/>,
            titleFn: (params) => Titles.Messages.Mass({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            path: '/messages/mass/users', component: (props) => <MassUser {...props}/>,
            titleFn: (params) => Titles.Messages.Mass({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            paramsUrl: {tab: "inbox"},
            path: '/messages/inbox', component: (props) => <Messages {...props}/>,
            titleFn: () => Titles.Messages.Inbox({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            paramsUrl: {tab: "mass"},
            path: '/messages/mass', component: (props) => <Messages {...props}/>,
            titleFn: () => Titles.Messages.Mass({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            paramsUrl: {tab: "auto"},
            path: '/messages/auto', component: (props) => <Messages {...props}/>,
            titleFn: () => Titles.Messages.Auto({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages',
        },
        {
            path: '/messages/inbox/:thread', component: (props) => <MessagesInbox {...props}/>,
            titleFn: () => Titles.Messages.Inbox({site_name}),
            isAuth: true, exact: true,
            pagename: 'messages.inbox',
        },
        {
            path: '/s', component: (props) => <Search {...props}/>, exact: true,
            titleFn: () => Titles.Search.Index({site_name}),
            pagename: 'search',
        },
        {
            path: '/s/favorites', component: (props) => <Navigate to={{
                pathname: '/s/favorites/video/liked',
            }} replace={true}/>, exact: true,
            isAuth: true,
        },
        {
            path: '/s/:tab/:subTab/:tag',
            component: (props) => <Search {...props}/>,
            exact: true,
            isAuth: (props) => props.paramsUrl.tab === 'favorites',
            titleFn: (params) => Titles.Search.Media({
                type: params.paramsUrl.tab,
                media_type: getTab(params.paramsUrl.subTab),
                site_name
            }),
            pagename: 'search',
        },
        {
            path: '/s/:tab/:subTab',
            component: (props) => <Search {...props}/>,
            exact: true,
            isAuth: (props) => props.paramsUrl.tab === 'favorites',
            titleFn: (params) => Titles.Search.Media({
                tab: params.paramsUrl.tab,
                media_type: getTab(params.paramsUrl.subTab),
                site_name
            }),
            pagename: 'search',
        },
        {
            path: '/s/:tab', component: (props) => <Search {...props}/>, exact: true,
            isAuth: (props) => props.paramsUrl.tab === 'favorites',
            titleFn: (params) => Titles.Search.Tab({
                tab: params.paramsUrl.tab,
                site_name
            }),
            pagename: 'search',
        },
        {
            path: '/creator-request', component: (props) => <CreatorRequest {...props}/>,
            titleFn: () => Titles.Studio.Index({site_name}), exact: true, isAuth: true,
            pagename: 'studio',
        },
        {
            path: '/email_validation', component: (props) => <EmailVerify {...props}/>,
            titleFn: () => Titles.Account.Index({site_name}), exact: true, isAuth: true,
            pagename: 'account',
        },
        {
            path: '/studio', component: (props) => <Studio {...props}/>,
            titleFn: () => Titles.Studio.Index({site_name}), exact: true, isAuth: true,
            pagename: 'studio',
        },
        {
            path: '/studio/upload', component: (props) => <StudioUpload {...props}/>,
            titleFn: () => Titles.Studio.Upload({site_name}), exact: true, isAuth: true,
            pagename: 'studio.upload',
        },
        {
            path: '/studio/upload/preview', component: (props) => <StudioUploadPreview {...props}/>,
            titleFn: () => Titles.Studio.Upload({site_name}), exact: true, isAuth: true,
            pagename: 'studio.upload.preview',
        },
        {
            path: '/studio/upload/progress', component: (props) => <StudioUploadProgress {...props}/>,
            titleFn: () => Titles.Studio.Upload({site_name}), exact: true, isAuth: true,
            pagename: 'studio.upload.progress',
        },
        {
            path: '/studio/record', component: (props) => <StudioRecord {...props}/>,
            titleFn: () => Titles.Studio.Record({site_name}), exact: true, isAuth: true,
            pagename: 'studio.record',
        },
        {
            path: '/studio/record/preview', component: (props) => <StudioRecordPreview {...props}/>,
            titleFn: () => Titles.Studio.Record({site_name}), exact: true, isAuth: true,
            pagename: 'studio.record.preview',
        },
        {
            path: '/studio/record/progress', component: (props) => <StudioRecordProgress {...props}/>,
            titleFn: () => Titles.Studio.Record({site_name}), exact: true, isAuth: true,
            pagename: 'studio.record.progress',
        },
        {
            path: '/studio/live', component: (props) => <Navigate to={{
                pathname: '/studio/live/rtmp',
            }} replace={true}/>, exact: true,
            isAuth: true,
        },
        {
            paramsUrl: {tab: "camera"},
            path: '/studio/live/camera', component: (props) => <StudioLive {...props}/>,
            titleFn: () => Titles.Studio.Live({site_name}), exact: true, isAuth: true,
            pagename: 'studio.live',
        },
        {
            paramsUrl: {tab: "rtmp"},
            path: '/studio/live/rtmp', component: (props) => <StudioLive {...props}/>,
            titleFn: () => Titles.Studio.Live({site_name}), exact: true, isAuth: true,
            pagename: 'studio.live',
        },
        {
            path: '/account', component: (props) => <Account {...props}/>, exact: true, isAuth: true,
            titleFn: (params) => Titles.Account.Index({auth_name: params.auth_name, site_name}),
            pagename: 'account',
        },
        {
            path: '/account/media/:tab/:typeMedia/:media',
            component: (props) => <EditMedia {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.MyMedia({site_name}),
            isAuth: true,
            pagename: 'account.media.edit',
        },
        {
            path: '/account/media/:tab/:typeMedia',
            component: (props) => <MyMedia {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.MyMedia({site_name}),
            isAuth: true,
            pagename: 'account.media',
        },
        {
            paramsUrl: {tab: "free"},
            path: '/account/media/free', component: (props) => <Navigate to={{
                pathname: '/account/media/' + props.paramsUrl.tab + "/videos",
            }} replace={true}/>, exact: true,
            isAuth: true,
            pagename: 'account.media',
        },
        {
            paramsUrl: {tab: "subscription"},
            path: '/account/media/subscription', component: (props) => <Navigate to={{
                pathname: '/account/media/' + props.paramsUrl.tab + "/videos",
            }} replace={true}/>, exact: true,
            isAuth: true,
            pagename: 'account.media',
        },
        {
            paramsUrl: {tab: "private"},
            path: '/account/media/private', component: (props) => <MyMedia {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.MyMedia({site_name}),
            isAuth: true,
            pagename: 'account.media',
        },
        {
            path: '/account/media', component: (props) => <Navigate to={{
                pathname: '/account/media/free',
            }} replace={true}/>, exact: true,
            isAuth: true,
            pagename: 'account.media',
        },
        {
            path: '/account/earnings', component: (props) => <Navigate to={{
                pathname: '/account/earnings/transactions',
            }} replace={true}/>, exact: true,
            isAuth: true,
        },
        {
            paramsUrl: {tab: "payouts"},
            path: '/account/earnings/payouts',
            component: (props) => <Earnings {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Earnings({site_name}),
            isAuth: true,
            pagename: 'account.earnings',
        },
        {
            paramsUrl: {tab: "transactions"},
            path: '/account/earnings/transactions',
            component: (props) => <Earnings {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Earnings({site_name}),
            isAuth: true,
            pagename: 'account.earnings',
        },
        {
            path: '/account/earnings/payment',
            component: (props) => <EarningsPayment {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Earnings({site_name}),
            isAuth: true,
            pagename: 'account.earnings',
        },
        {
            path: '/account/purchases', component: (props) => <Navigate to={{
                pathname: '/account/purchases/transactions',
            }} replace={true}/>, exact: true,
            isAuth: true,
        },
        {
            paramsUrl: {tab: "transactions"},
            path: '/account/purchases/transactions',
            component: (props) => <Purchases {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Purchases({site_name}),
            isAuth: true,
            pagename: 'account.purchases',
        },
        {
            paramsUrl: {tab: "subscriptions"},
            path: '/account/purchases/subscriptions',
            component: (props) => <Purchases {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Purchases({site_name}),
            isAuth: true,
            pagename: 'account.purchases',
        },
        {
            path: '/account/purchases/payment',
            component: (props) => <PurchasesPayment {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Purchases({site_name}),
            isAuth: true,
            pagename: 'account.purchases',
        },
        {
            path: '/account/subscription', component: (props) => <Subscription {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Subscription({auth_name: params.auth_name, site_name}),
            isAuth: true,
            pagename: 'account.subscription',
        },
        {
            path: '/account/settings', component: (props) => <Settings {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Settings({auth_name: params.auth_name, site_name}),
            isAuth: true,
            pagename: 'account.settings',
        },
        {
            path: '/about', component: (props) => <About {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.About({auth_name: params.auth_name, site_name}),
            isAuth: false,
            pagename: 'account.settings',
        },
        {
            path: '/about/terms-of-service', component: (props) => <TermsOfService/>, exact: true,
            titleFn: (params) => Titles.Account.TermsOfService({auth_name: params.auth_name, site_name}),
            isAuth: false,
            pagename: 'account.settings',
        },
        {
            path: '/about/privacy-policy', component: (props) => <PrivacyPolicy/>, exact: true,
            titleFn: (params) => Titles.Account.PrivacyPolicy({auth_name: params.auth_name, site_name}),
            isAuth: false,
            pagename: 'account.settings',
        },
        {
            path: '/about/cookie-policy', component: (props) => <CookiePolicy/>, exact: true,
            titleFn: (params) => Titles.Account.CookiePolicy({auth_name: params.auth_name, site_name}),
            isAuth: false,
            pagename: 'account.settings',
        },
        {
            path: '/about/dmca', component: (props) => <Dmca/>, exact: true,
            titleFn: (params) => Titles.Account.Dmca({auth_name: params.auth_name, site_name}),
            isAuth: false,
            pagename: 'account.settings',
        },
        {
            path: '/about/refund-policy', component: (props) => <RefundPolicy/>, exact: true,
            titleFn: (params) => Titles.Account.RefundPolicy({auth_name: params.auth_name, site_name}),
            isAuth: false,
            pagename: 'account.settings',
        },
        {
            path: '/support', component: (props) => <Support {...props}/>, exact: true,
            titleFn: (params) => Titles.Account.Support({auth_name: params.auth_name, site_name}),
            isAuth: true,
            pagename: 'account.settings',
        },
        {
            path: '/support/contact', component: (props) => <MessagesInbox {...props} isSupport/>,
            titleFn: () => Titles.Account.Support({site_name}),
            isAuth: true, exact: true,
            pagename: 'account.settings',
        },
        {
            path: '/account/settings/password',
            component: (props) => <EditProfilePassword {...props}/>,
            exact: true,
            isAuth: true,
            titleFn: () => Titles.Account.Password(site_name),
            pagename: 'account.settings.password',
        },
        {
            path: '/account/settings/gender', component: (props) =>
                <Complete title={'Gender preferences'}{...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.Gender(site_name),
            pagename: 'account.settings.gender',
        },
        {
            path: '/account/settings/basic', component: (props) =>
                <SettingBasic {...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.SettingBasic(site_name),
            pagename: 'account.settings.basic',
        },
        {
            path: '/account/settings/email', component: (props) =>
                <EmailNotification {...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.Email(site_name),
            pagename: 'account.settings.email',
        },
        {
            path: '/account/settings/blocked', component: (props) =>
                <Blocked {...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.Blocked(site_name),
            pagename: 'account.settings.blocked',
        },
        {
            path: '/account/settings/delete', component: (props) =>
                <Delete {...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.Delete(site_name),
            pagename: 'account.settings.delete',
        },
        {
            path: '/sounds', component: (props) =>
                <Sound {...props}/>, exact: true,
            titleFn: () => Titles.Sound(site_name),
            pagename: 'sounds',
        },
        {
            path: '/profile', component: (props) => <AuthProfile {...props}/>, exact: true, isAuth: true,
            titleFn: (params) => Titles.Account.Profile({auth_name: params.auth_name, site_name}),
            pagename: 'account.profile',
        },
        {
            paramsUrl: {tab: "videos"},
            path: '/profile/videos',
            component: (props) => <AuthProfile {...props}/>, exact: true, isAuth: true,
            titleFn: (params) => Titles.Account.Profile({auth_name: params.auth_name, site_name}),
            pagename: 'account.profile.medias',
        },
        {
            paramsUrl: {tab: "photos"},
            path: '/profile/photos',
            component: (props) => <AuthProfile {...props}/>, exact: true, isAuth: true,
            titleFn: (params) => Titles.Account.Profile({auth_name: params.auth_name, site_name}),
            pagename: 'account.profile.medias',
        },
        {
            path: '/profile/edit/password',
            component: (props) => <EditProfilePassword {...props}/>,
            exact: true,
            isAuth: true,
            titleFn: () => Titles.Account.Password(site_name),
            pagename: 'account.profile.edit.password',
        },
        {
            path: '/profile/edit', component: (props) => <EditProfile {...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.Edit(site_name),
            pagename: 'account.profile.edit',
        },
        {
            path: '/complete', component: (props) => <Complete {...props}/>, exact: true, isAuth: true,
            titleFn: () => Titles.Account.Complete(site_name),
            pagename: 'complete',
        },
        {
            path: '/short/:id', component: (props) => <ShortLink {...props}/>, exact: true,
            pagename: 'short',
        },
        {
            path: '/library', component: (props) => <Library {...props}/>, exact: true,
            pagename: 'library',
        },
        {
            path: '/:username/live', component: (props) =>
                <UserLive {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Live({
                name: params.name,
                site_name
            }),
            pagename: 'user.live', slideIndex: 0,
        },
        {
            paramsUrl: {tab: "video"},
            path: '/:username/video/:media_id', component: (props) =>
                <DashboardMedia tab={"video"} {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Media({
                media_type: "video",
                name: params.name,
                site_name
            }),
            pagename: 'user.media', slideIndex: 0,
        },
        {
            paramsUrl: {tab: "photo"},
            path: '/:username/photo/:media_id', component: (props) =>
                <DashboardMedia tab={"photo"} {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Media({
                media_type: "photo",
                name: params.name,
                site_name
            }),
            pagename: 'user.media', slideIndex: 0,
        },
        {
            paramsUrl: {tab: "shop"},
            path: '/:username/shop/:media_id', component: (props) =>
                <DashboardMedia tab="shop" {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Media({
                media_type: "shop",
                name: params.name,
                site_name
            }),
            pagename: 'user.media', slideIndex: 0,
        },
        {
            paramsUrl: {tab: "video"},
            path: '/:username/video',
            component: (props) => props.paramsUrl.username === authProfile?.username ?
                <AuthProfile {...props}/> : <UserProfile {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Index({name: params.name, site_name}),
            pagename: 'user.medias',
        },
        {
            paramsUrl: {tab: "photo"},
            path: '/:username/photo',
            component: (props) => props.paramsUrl.username === authProfile?.username ?
                <AuthProfile {...props}/> : <UserProfile {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Index({name: params.name, site_name}),
            pagename: 'user.medias',
        },
        {
            path: '/:username',
            component: (props) => props.paramsUrl.username === authProfile?.username ?
                <AuthProfile {...props}/> : <UserProfile {...props}/>, exact: true,
            titleFn: (params) => Titles.Profile.Index({name: params.name, site_name}),
            pagename: 'user',
        },
    ]
}

export default Main;
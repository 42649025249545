import TopMenu from "../../../components/basic/top-menu";
import BottomMenu from "../../../components/basic/bottom-menu";
import {useNavigate} from "react-router-dom";


export const Dmca = ({history}) => {
    const navigate = useNavigate();

    return <>
        <TopMenu
            icon={'greater-icon'}
            title={'About'}
            action={() => navigate("/about")}
        />
        <div className={'jf-basic-page'} style={{padding: 0}}>

            <div style={{margin: '20px'}}>
                <h1> DMCA </h1>
                <div><h2>
                    How to Report a Copyright Infringement
                </h2> <p>
                    JuneFox.com (the “Site”) respects the intellectual property rights of others and expects its users
                    to do
                    the same.
                    JuneFox.com has implemented a policy to disable and/or terminate the accounts of users who
                    repeatedly
                    infringe or
                    are repeatedly charged with infringing the copyrights or other intellectual property rights of
                    others.
                    Copies of our Repeat Infringer Policy are available to subscribers upon written request.
                </p> <p>
                    Although our company is based outside the United States and not governed by the laws of the United
                    States, we respect the rights of copyright owners and thus have implemented certain policies in an
                    effort to voluntarily comply with laws such as the Digital Millennium Copyright Act.
                    Nonetheless, our Company reserves all rights and objections to the formal application of the laws of
                    the United States to its operations.
                </p> <p>
                    In accordance with the Digital Millennium Copyright Act of 1998, JuneFox.com will respond
                    expeditiously
                    to claims of copyright infringement committed by third parties using the Site that are reported to
                    JuneFox.com’s designated DMCA agent (“Designated Agent”), identified below.
                </p> <p>
                    If you are a copyright owner, or are authorized to act on behalf of one, please report alleged
                    copyright infringements taking place on or through the Site by completing a DMCA notice of alleged
                    infringement and delivering it to JuneFox.com’s Designated Agent.
                    Upon receipt of the notice as described below, JuneFox.com will take whatever action, in its sole
                    discretion, it deems appropriate, including removal of the challenged material from the Site.
                </p>
                    <h2>
                        Notice of Claimed Infringement
                    </h2>
                    <p>
                        If you believe that your work has been copied in a way that constitutes copyright infringement,
                        please provide our Designated Agent (identified below) with the following information:
                    </p>
                    <ol>
                        <li>
                            An electronic or physical signature of the person authorized to act on behalf of the owner
                            of the copyright or other intellectual property interest;
                        </li>
                        <li>
                            A description of the copyrighted work or other intellectual property that you claim has been
                            infringed;
                        </li>
                        <li>
                            A description of where the material that you claim is infringing is located on the Site
                            (preferably including specific URLs associated with the material);
                        </li>
                        <li>
                            Your address, telephone number, and email address;
                        </li>
                        <li>
                            A statement by you that you have a good faith belief that the disputed use is not authorized
                            by the copyright owner, its agent, or the law; and
                        </li>
                        <li>
                            A statement by you, made under penalty of perjury, that the above information in your
                            notification is accurate and that you are the copyright or intellectual property owner or
                            are authorized to act on the copyright or intellectual property owner’s behalf.
                        </li>
                    </ol>
                    <p>
                        You may send your Notice of Claimed Infringement (“Notice”) to our designated DMCA Agent:
                    </p>
                    <ul className="contact-address">
                        <li>Internet Fox SRL</li>
                        <li>MUNICIPIUL BUCUREŞTI, SECTOR 6</li>
                        <li>ALE. BAIUT Nr. 1</li>
                        <li>Bl. D13</li>
                        <li>Sc. 1</li>
                        <li>Email: copyright@junefox.com</li>
                    </ul>
                    <p>
                        Please do not send other inquiries or information to our Designated Agent.
                        Absent prior express permission, our Designated Agent is not authorized to accept or waive
                        service of formal legal process,
                        and any agency relationship beyond that required to accept valid DMCA Notices is expressly
                        disclaimed.
                    </p>
                    <h2> Contacting Us </h2> <p>
                        For any other inquiry, please contact us at <a external=""
                                                                       href="mailto:support@JuneFox.com">support@JuneFox.com</a>.
                    </p> <p>
                        Further information regarding notification and takedown requirements can be found in the
                        DMCA, <a rel="noreferrer" target="_blank"
                                 href="https://www.law.cornell.edu/uscode/text/17/512">here</a>.
                    </p> <p><strong>Abuse Notification:</strong> Abusing the DMCA
                        Notice procedures set forth above, or misrepresenting facts in a DMCA Notice or
                        Counter-notification, can result in legal liability for damages, court costs, and attorneys fees
                        under federal law.
                        See 17 U.S.C. § 512(f). These Notice and Takedown Procedures only apply to claims of copyright
                        infringement by copyright holders and their agents – not to any other kind of abuse,
                        infringement, or legal claim.
                        We will investigate and take action against anyone abusing the DMCA notification or
                        counter-notification procedure.
                        Please ensure that you meet all legal qualifications before submitting a DMCA Notice to our
                        Designated Agent.
                    </p> <h2>
                        Take Down Procedure
                    </h2> <p>
                        This Site implements the following “notification and takedown” procedure upon receipt of any
                        notification of claimed copyright infringement.
                        The Site reserves the right at any time to disable access to or remove any material or activity
                        accessible on the Site that is claimed to be infringing or from which infringing activity is
                        apparent based on facts or circumstances.
                        It is the firm policy of the Site to terminate the account of repeat copyright infringers, when
                        appropriate, and the Site will act expeditiously to remove access to all material that infringes
                        on another’s copyright, according to the procedure set forth in 17 U.S. C. § 512 of the DMCA.
                        The Site’s DMCA Notice Procedures are set forth in the preceding paragraphs.
                        If the Notice does not comply with § 512 of the DMCA but does comply with three requirements for
                        identifying sites that are infringing according to § 512 of the DMCA,
                        the Site shall attempt to contact or take other reasonable steps to contact the complaining
                        party to help that party comply with the notification requirements.
                        When the Designated Agent receives a valid Notice, the Site will expeditiously remove and/or
                        disable access to the infringing material and shall notify the affected user.
                        Then, the affected user may submit a counter-notification to the Designated Agent containing a
                        statement made under penalty of perjury that the user has a good faith belief that the material
                        was removed because of misidentification of the material.
                        After the Designated Agent receives the counter-notification,
                        it will replace the material at issue within ten to fourteen (10-14) days after receipt of the
                        counter-notification unless the Designated Agent receives notice that a court action has been
                        filed by the complaining party seeking an injunction against the infringing activity.
                    </p> <h2>
                        DMCA Counter-Notification Procedure
                    </h2> <p>
                        If the Recipient of a Notice of Claimed Infringement believes that the Notice is erroneous or
                        false,
                        and/or that allegedly infringing material has been wrongly removed in accordance with the
                        procedures outlined above,
                        the Recipient is permitted to submit a counter-notification pursuant to § 512(g)(2)-(3) of the
                        DMCA.
                        A counter-notification is the proper method for the Recipient to dispute the removal or
                        disabling of material pursuant to a Notice.
                        The information that a Recipient provides in a counter-notification must be accurate and
                        truthful, and the Recipient will be liable for any misrepresentations which may cause any claims
                        to be brought against the Site relating to the actions taken in response to the
                        counter-notification.
                    </p> <p>
                        To submit a counter-notification, please provide our Designated Agent the following information:
                    </p>
                    <ol>
                        <li>
                            A specific description of the material that was removed or disabled pursuant to the Notice;
                        </li>
                        <li>
                            A description of where the material was located within the Site or the content (as defined
                            within the Site’s Terms of Use Agreement) before such material was removed and/or disabled
                            (preferably including specific URLs associated with the material);
                        </li>
                        <li>
                            A statement reflecting the Recipient’s belief that the removal or disabling of the material
                            was done erroneously.
                            For convenience, the following format may be used: “I swear, under penalty of perjury, that
                            I have a good faith belief that the referenced material was removed or disabled by the
                            service provider as a result of mistake or misidentification of the material to be removed
                            or disabled.”;
                        </li>
                        <li>
                            The Recipient’s physical address, telephone number, and email address; and
                        </li>
                        <li>
                            A statement that the Recipient consents to the jurisdiction of the Federal District court in
                            and for the judicial district where the Recipient is located,
                            or if the Recipient is outside of the United States, for any judicial district in which the
                            service provider may be found, and that the Recipient will accept service of process from
                            the person who provided the Notice, or that person’s agent.
                        </li>
                    </ol>
                    <p>
                        Written notification containing the above information must be signed and sent to:
                    </p>
                    <ul className="contact-address">
                        <li>Internet Fox SRL</li>
                        <li>MUNICIPIUL BUCUREŞTI, SECTOR 6</li>
                        <li>ALE. BAIUT Nr. 1</li>
                        <li>Bl. D13</li>
                        <li>Sc. 1</li>
                        <li>Email: copyright@junefox.com</li>
                    </ul>
                    <p>
                        Please do not send other inquiries or information to our Designated Agent.
                        Absent prior express permission, our Designated Agent is not authorized to accept or waive
                        service of formal legal process, and any agency relationship beyond that required to accept
                        valid DMCA Notices is expressly disclaimed.
                    </p> <p>
                        All DMCA notices and counter-notifications must be written in the English language.
                        Any attempted notifications written in foreign languages or using foreign characters will be
                        deemed non-compliant and disregarded.
                    </p> <p>
                        After receiving a DMCA-complaint or counter-notification, our Designated Agent will forward it
                        to the Site, and the Site will then provide the counter-notification to the claimant who first
                        sent the original Notice identifying the allegedly infringing content.
                    </p> <p>
                        Thereafter, within ten to fourteen (10-14) days of the Site’s receipt of a counter-notification,
                        the Site will replace or cease disabling access to the disputed material provided that the Site
                        or
                        our Designated Agent have not received notice that the original claimant has filed an action
                        seeking a court order to restrain the Recipient from engaging in infringing activity relating to
                        the material on the Site’s system or network.
                    </p>
                    <h2>Modifications</h2> <p>
                        The Site reserves the right to modify, alter, or add to this policy, and all affected persons
                        should regularly check back to stay current on any such changes.
                    </p> <h2>
                        Customer Service Requests
                    </h2> <p>
                        Please note that the Designated Agent is not associated with the Site in any other capacity but
                        is an attorney with a private law firm. Customer service inquiries,
                        payment questions, and cancellation requests will not receive a response. All such
                        communications must be directed to the Site’s customer service department.
                    </p> <h2>
                        Language
                    </h2> <p>
                        This policy was originally written in English. To the extent any translated version of these
                        this policy conflicts with the English version, the English version controls.
                    </p> <p><b>Last modified:</b> April 2021
                    </p></div>
                <p>
                    © 2022 Internet Fox SRL unless otherwise noted. All rights reserved.
                </p></div>

        </div>
        <BottomMenu/>
    </>;
}
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import Queries from '../../../../params/queries'
import { useFetchApiAction, useResetStateWhenChangeUrlAction } from '../../../../action/main-action'
import { Virtuoso } from 'react-virtuoso'
import Button from '../../../../components/basic/button'
import Mutations from '../../../../params/mutations'
import Constants from '../../../../params/constants'
import Teaser from '../../../../components/message/teaser'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { messageMassTabAtom } from '../../../../state/pages/message'
import { setSeparateState, uniqueList } from '../../../../helpers/utils'

const MassTab = () => {
  const [{loadingMore, list }, setMessageToUser] = useRecoilState(messageMassTabAtom);
  const setList = setSeparateState(setMessageToUser, "list")
  const setLoadingMore = setSeparateState(setMessageToUser, "loadingMore")

  const navigate = useNavigate();
  const limit = Constants.ListLimit;
  const fetchApi = useFetchApiAction();

  const resetStateAction = useResetStateWhenChangeUrlAction()
  const resetMessageMassTab = useResetRecoilState(messageMassTabAtom)

  useEffect(() => {
    resetStateAction.add(resetMessageMassTab)
    let isCancel = false;
    setLoadingMore(-1);
    fetchApi(Queries.Messages.Templates, { limit: {limit} }).then(res => {
      if (isCancel || !res.messenger_templates) return;
      setList(res.messenger_templates);
      setLoadingMore(res.messenger_templates.length === limit ? 1 : 2);
    })
    return () => {
      isCancel = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remove = (record) => {
    const index = list.findIndex(item => item.id === record.id);

    fetchApi(Mutations.Messages.DelTemplate, {id: record.id}).then(res => {
      list.splice(index, 1);
      setList([...list]);
    })
  }

  const loadMore = () => {
    if (loadingMore !== 1) return;
    fetchApi(Queries.Messages.Templates, { limit: {limit,skip: list.length} }).then(res => {
      setList(uniqueList(list.concat(res.messenger_templates)));
      setLoadingMore(res.messenger_templates.length === limit ? 1 : 2);
    })
  }

  
  return <>
    <div style={{ flexGrow: 1 }} className={'jf-messages-inbox-page jf-mass-message'}>
      <Virtuoso
        data={list}
        endReached={loadMore}
        itemContent={(index, item) => {
          const value = JSON.parse(item.messages[0].value);
          const comp = <div className={'jf-messages-value teaser'}
                            style={{width: 'fit-content', alignSelf: 'center', position:'relative'}}>
            <Teaser item={item} value={value} remove={remove} player={false}/>
          </div>;
          return <div style={{ display: 'flex', flexDirection: 'column' }}>
            {comp}
            <div style={{ minHeight: 20 }}/>
          </div>
        }}
      />
    </div>
    <Button className={'medium'}
            onClick={() => navigate('/messages/mass/new')} style={{ margin: '20px auto' }}>New Mass Message</Button>
  </>
}

export default MassTab;
function template(strings, ...keys) {
  return (function(...values) {
    let dict = values[values.length - 1] || {};
    let result = [strings[0]];
    keys.forEach(function(key, i) {
      let value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  });
}

export const Titles = {
  Home: {
    Index: template`Home - ${'site_name'}`,
    Media: template`Home ${'media_type'} - ${'site_name'}`
  },
  Welcome: template`Welcome - ${'site_name'}`,
  SignIn:  template`Sign In - ${'site_name'}`,
  SignUp:  template`Sign Up - ${'site_name'}`,
  ForgotPassword: template`Forgot Password - ${'site_name'}`,
  Search: {
    Index: template`Search - ${'site_name'}`,
    Tab: template`Search ${'tab'} - ${'site_name'}`,
    Media: template`Search ${'tab'} - ${'media_type'}- ${'site_name'}`,
  },
  Profile: {
    Index: template`${'name'} - ${'site_name'}`,
    Media: template`${'name'} ${'media_type'} - ${'site_name'}`,
    Live: template`${'name'} Live - ${'site_name'}`,
  },
  Account: {
    Index: template`${'auth_name'} - ${'site_name'}`,
    Profile: template`${'auth_name'} Profile - ${'site_name'}`,
    Media: template`${'auth_name'} ${'media_type'} - ${'site_name'}`,
    Edit: template`Edit Profile - ${'site_name'}`,
    Password: template`Password - ${'site_name'}`,
    Complete: template`Complete Profile - ${'site_name'}`,
    Earnings: template`Earnings - ${'site_name'}`,
    Purchases: template`Purchases - ${'site_name'}`,
    Subscription: template`Subscription - ${'site_name'}`,
    Settings: template`Settings - ${'site_name'}`,
    About: template`About - ${'site_name'}`,
    TermsOfService: template`Terms of service - ${'site_name'}`,
    PrivacyPolicy: template`Privacy policy - ${'site_name'}`,
    CookiePolicy: template`Cookie policy - ${'site_name'}`,
    Dmca: template`DMCA - ${'site_name'}`,
    RefundPolicy: template`Refund Policy - ${'site_name'}`,
    Support: template`Support - ${'site_name'}`,
    Gender: template`Gender preferences - ${'site_name'}`,
    SettingBasic: template`Setting basic - ${'site_name'}`,
    Email: template`Email notifications - ${'site_name'}`,
    Blocked: template`Blocked accounts - ${'site_name'}`,
    Delete: template`Delete account - ${'site_name'}`,
    MyMedia: template`My media - ${'site_name'}`,
  },
  Studio: {
    Index: template`Studio - ${'site_name'}`,
    Upload: template`Studio upload - ${'site_name'}`,
    Record: template`Studio record - ${'site_name'}`,
    Live: template`Studio live - ${'site_name'}`,
  },
  Sound: template`Sounds - ${'site_name'}`,
  Messages: {
    Inbox: template`Inbox Messages - ${'site_name'}`,
    Mass: template`Mass Messages - ${'site_name'}`,
    Auto: template`Automated Messages - ${'site_name'}`,
  }
};
import React from 'react';
const Flipicon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 20'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="OBJECTS" transform="translate(0.001 0)">
        <g id="Group_1126" data-name="Group 1126" transform="translate(0 0)">
          <path id="Path_5605" data-name="Path 5605" d="M2.382,13.082a.7.7,0,0,1-.487-.159L.2,11.223a.657.657,0,0,1,0-.994.709.709,0,0,1,1.014,0l.527.517V3.459A3.493,3.493,0,0,1,5.231,0H18.559a3.494,3.494,0,0,1,3.527,3.459V3.9a.691.691,0,0,1-1.368,0V3.459a2.139,2.139,0,0,0-2.159-2.107H5.23a2.182,2.182,0,0,0-1.543.606,2.1,2.1,0,0,0-.647,1.5v8.946a.656.656,0,0,1-.426.616A.6.6,0,0,1,2.382,13.082Z" transform="translate(0.023 0)" fill={fill} />
          <path id="Path_5606" data-name="Path 5606" d="M18.536,20.042H5.208A3.5,3.5,0,0,1,1.68,16.573v-.626a.67.67,0,0,1,.277-.719.7.7,0,0,1,.784,0,.67.67,0,0,1,.277.719v.676A2.138,2.138,0,0,0,5.177,18.74H18.536A2.138,2.138,0,0,0,20.7,16.622V7.676a.666.666,0,0,1,.417-.627.689.689,0,0,1,.748.15l1.865,1.789a.657.657,0,0,1,0,.994.709.709,0,0,1-1.014,0L22.023,9.3v7.3a3.5,3.5,0,0,1-3.487,3.449Z" transform="translate(0.047 -0.042)" fill={fill} />
        </g>
        <g id="Ellipse_149" data-name="Ellipse 149" transform="translate(7 5)" fill={fillNone} stroke={fill} strokeWidth="1.5">
          <circle cx="4.5" cy="4.5" r="4.5" stroke={fillNone} />
          <circle cx="4.5" cy="4.5" r="3.75" fill={fillNone} />
        </g>
      </g>
    </svg>
  </>
);
export default Flipicon;
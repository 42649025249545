import React from 'react';
const VideocameraIcon = ({
  style = {},
  fill = '#5990ff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="song" transform="translate(0 -41.664)">
        <path id="Path_6029" data-name="Path 6029" d="M272,209.737a1.995,1.995,0,0,0,3.973,0V189.23L272,186.806Z" transform="translate(-235.974 -140.799)" fill="#4d6aff" />
        <path id="Path_6030" data-name="Path 6030" d="M90,260.948a1.995,1.995,0,0,0,3.973,0V236.8L90,241.653Z" transform="translate(-78.079 -184.738)" fill={fill} />
        <ellipse id="Ellipse_134" data-name="Ellipse 134" cx="7.947" cy="5.454" rx="7.947" ry="5.454" transform="translate(0 70.756)" fill="#66b3ff" />
        <path id="Path_6031" data-name="Path 6031" d="M189.079,331a10.148,10.148,0,0,0-6.093,1.844,4.273,4.273,0,0,0,0,7.22,10.148,10.148,0,0,0,6.093,1.844c4.455,0,7.947-2.4,7.947-5.454S193.534,331,189.079,331Z" transform="translate(-157.026 -267.516)" fill={fill} />
        <path id="Path_6036" data-name="Path 6036" d="M115.464,151.071l-11.424,3.444-12.682,3.828A1.838,1.838,0,0,0,90,160.067v6.158l14.04-4.242,14.04-4.242V152.8A1.966,1.966,0,0,0,115.464,151.071Z" transform="translate(-78.079 -109.31)" fill="#66b3ff" />
        <path id="Path_6037" data-name="Path 6037" d="M210.04,152.8a1.966,1.966,0,0,0-2.615-1.724L196,154.516v7.467l14.04-4.242Z" transform="translate(-170.04 -109.31)" fill={fill} />
      </g>
    </svg>
  </>
);
export default VideocameraIcon;
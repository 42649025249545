import React from 'react';
const SendIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '20',
  className = '',
  viewBox = '0 0 20 20'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="send" d="M19.993.94a.958.958,0,0,0-.052-.265C19.93.644,19.92.613,19.906.582a.985.985,0,0,0-.195-.294.979.979,0,0,0-.294-.195c-.031-.014-.062-.024-.093-.035a.99.99,0,0,0-.263-.052C19.034,0,19.009,0,18.981,0a.991.991,0,0,0-.308.052l-18,6.3A1,1,0,0,0,.594,8.21l7.75,3.446,3.444,7.75A1,1,0,0,0,12.7,20a.281.281,0,0,0,.041,0,1,1,0,0,0,.9-.668l6.3-18A1,1,0,0,0,20,1.018C20,.991,19.994.967,19.993.94ZM15.2,3.388,8.88,9.706l-5.169-2.3Zm-2.6,12.9-2.3-5.169L16.611,4.8Z" transform="translate(0)" fill={fill}/>
    </svg>
  </>
);
export default SendIcon;
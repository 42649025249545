import {usersAtom, profileAtom} from '../state/users'
import {useSetRecoilState, useRecoilValue} from 'recoil';
import {useFetchApiAction} from './main-action';
import {authProfileAtom, authIdAtom} from '../state/auth'
import Queries from '../params/queries'
import {getTab} from '../helpers/utils'
import Mutations from '../params/mutations'
import Constants from '../params/constants'

export function useUserAction(autoSet = true) {
    const setUsers = useSetRecoilState(usersAtom);
    const setProfile = useSetRecoilState(profileAtom);
    const setAuthProfile = useSetRecoilState(authProfileAtom);
    const authId = useRecoilValue(authIdAtom);
    const fetchApi = useFetchApiAction();

    return {
        getProfile: async function (username, mediaType = '') {
            const variables = {username};
            if (mediaType !== '') {
                variables.limit = {
                    limit: Constants.MediaListLimit,
                    order: false
                }
                variables.filter = {
                    username: username
                }
            }
            let query = '';
            switch (getTab(mediaType, true)) {
                case 'video':
                    variables.isAuth = false
                    query = Queries.Profile.Videos;
                    break;
                case 'photo':
                    variables.isAuth = false
                    query = Queries.Profile.Photos;
                    break;
                case 'shop':
                    variables.isAuth = false
                    query = Queries.Profile.Shop;
                    break;
                default:
                    query = Queries.Profile.Default;
                    break;
            }
            const res = await fetchApi(query, variables);
            if (autoSet) {
                setProfile(res && res.profile);
            }
            return res || {};
        },
        getAuthProfile: async function (mediaType = '') {
            const variables = {};
            if (mediaType !== '') {
                variables.limit = {
                    limit: Constants.MediaListLimit,
                    order: false
                }
                variables.filter = {
                    user: authId
                }
            }
            let query = '';
            switch (getTab(mediaType, true)) {
                case 'video':
                    variables.isAuth = true
                    query = Queries.Profile.Videos;
                    break;
                case 'photo':
                    variables.isAuth = true
                    query = Queries.Profile.Photos;
                    break;
                case 'shop':
                    variables.isAuth = true
                    query = Queries.Profile.Shop;
                    break;
                default:
                    query = Queries.Account.Auth;
                    break;
            }
            const res = await fetchApi(query, variables);
            if (autoSet) {
                setAuthProfile((res && res.profile) || {});
            }
            return res || {};
        },
        upProfile: async function (data) {
            return await fetchApi(Mutations.Profile.Update, {account: data})
        },
        deleteUser: async function ({password_current}) {
            return await fetchApi(Mutations.Profile.Delete, {password: password_current})
        },
        creators: async function (params) {
            const res = await fetchApi(Queries.Creator.Search, params)
            if (autoSet) {
                setUsers(res ? res.creators : []);
            }
            return res ? res.creators : []
        },
        blockAccount: async function (data) {
            const res = await fetchApi(Mutations.AccountBlocked.BlockAccount, data);
            return res && res.blockAccount
        },
        getBlockedAccounts: async function (variables) {
            const res = await fetchApi(Queries.BlockedAccounts, variables);
            return res && res.accounts_blocked
        },
        accountMedia: async function (variables) {
            variables.limit.limit = variables.limit.limit || Constants.MediaListLimit;
            let res;
            if (variables.filter.type === "private") {
                delete variables.filter.type;
                res = await fetchApi(Queries.Account.MediaPrivate, variables);
                return res && res.private
            } else {
                res = await fetchApi(Queries.Account.Media, variables);
                return res && res.account_media
            }
        },
        generateRtmp: async function () {
            const res = await fetchApi(Mutations.Profile.GenerateRtmp, {});
            return res && res.generateStudioRtmp
        },
        lives: async (limit) => {
            const res = await fetchApi(Queries.Account.Lives, limit)
            return res;
        }
    }
}
import { atomFamily, atom } from 'recoil'

export const liveBoxChatAtom = atom({
  key: 'liveBoxChat',
  default: {
    'messages':[],
    'text': ''
  }
});

export const liveBoxAuthAtomFamily = atomFamily({
  key: 'liveBoxAuth',
  default: (field) => ({
    'end': false,
    'startCamera': false
  }[field])
});

export const liveBoxUserAtom = atom({
  key: 'liveBoxUser',
  default: {
    'end': false,
  }
});
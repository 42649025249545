import React from 'react';
const HomeIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '28',
  className = '',
  viewBox = '0 0 28 28'
}) => (
  <>
    <svg id="home" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="Path_38" data-name="Path 38" d="M0,0H28V28H0Z" fill="none" />
      <g id="Path_39" data-name="Path 39" transform="translate(0.198 0.655)" fill="none">
        <path d="M11.408,22.144v-6h4.783v6a1.2,1.2,0,0,0,1.2,1.2h3.587a1.2,1.2,0,0,0,1.2-1.2V13.738H24.2a.6.6,0,0,0,.395-1.045l-10-9.042a1.2,1.2,0,0,0-1.6,0L3,12.694A.6.6,0,0,0,3.4,13.738H5.43v8.406a1.2,1.2,0,0,0,1.2,1.2h3.587A1.2,1.2,0,0,0,11.408,22.144Z" stroke="none" />
        <path d="M 20.66899299621582 21.84500312805176 L 20.66899299621582 12.23827266693115 L 21.85695266723633 12.23827266693115 L 13.7994909286499 4.949223518371582 L 5.742032527923584 12.23827266693115 L 6.929992198944092 12.23827266693115 L 6.929992198944092 21.84500312805176 L 9.908202171325684 21.84500312805176 L 9.908202171325684 14.63995265960693 L 17.69077301025391 14.63995265960693 L 17.69077301025391 21.84500312805176 L 20.66899299621582 21.84500312805176 M 20.97334289550781 23.34500312805176 L 17.38642311096191 23.34500312805176 C 16.72881317138672 23.34500312805176 16.19077301025391 22.80462265014648 16.19077301025391 22.14416313171387 L 16.19077301025391 16.13995170593262 L 11.40820217132568 16.13995170593262 L 11.40820217132568 22.14416313171387 C 11.40820217132568 22.80462265014648 10.87016201019287 23.34500312805176 10.21256256103516 23.34500312805176 L 6.625632286071777 23.34500312805176 C 5.968032360076904 23.34500312805176 5.429992198944092 22.80462265014648 5.429992198944092 22.14416313171387 L 5.429992198944092 13.73827266693115 L 3.397402286529541 13.73827266693115 C 2.847402334213257 13.73827266693115 2.596322298049927 13.05379295349121 3.002842426300049 12.69354248046875 L 12.99841213226318 3.651212453842163 C 13.45275115966797 3.24293327331543 14.14623165130615 3.242931842803955 14.60057258605957 3.651212453842163 L 24.59614181518555 12.69354248046875 C 25.01462173461914 13.05379295349121 24.75158309936523 13.73827266693115 24.20158195495605 13.73827266693115 L 22.16899299621582 13.73827266693115 L 22.16899299621582 22.14416313171387 C 22.16899299621582 22.80462265014648 21.63095283508301 23.34500312805176 20.97334289550781 23.34500312805176 Z" stroke="none" fill={fill} />
      </g>
    </svg>


  </>
);
export default HomeIcon;
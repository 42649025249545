import React from 'react';

const JuneFoxIcon2 = ({
                         style = {},
                         fill = '#595d73',
                         fillOpacity = '0.3',
                         fillNone = 'none',
                         width = 330,
                         height = 470,
                         className = '',
                         viewBox = '0 0 30 30'
                     }) => (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <rect width="512" height="512" rx="5" fill="transparent"/>
        <g clipPath="url(#clip0_274_4762)">
            <path fillRule="evenodd" clipRule="evenodd" d="M199.971 280.725C161.467 272.475 120.217 284.581 92.1653 311.524C91.6108 316.481 91.6108 320.875 91.6108 325.286C91.6108 375.887 115.815 423.194 155.973 452.893C136.726 438.039 126.812 413.835 129.568 389.631C132.869 363.226 150.47 341.231 175.775 332.427C180.723 330.226 185.125 329.125 189.528 328.579H191.729C214.832 325.824 235.18 334.082 255.528 343.979C264.879 348.381 274.776 353.875 283.58 352.229C291.276 351.683 297.879 346.18 305.029 341.231C299.526 339.585 292.922 338.476 287.974 335.729C276.977 331.326 268.718 321.984 259.376 314.28C242.876 299.435 223.62 288.429 202.718 281.28C201.617 281.28 201.071 280.734 199.971 280.734V280.725Z" fill="#F2F2F2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M249.256 155.841C236.273 155.344 224.282 160.839 215.296 170.33C216.297 176.818 217.795 182.312 220.79 187.807C225.284 196.297 232.772 203.289 240.261 210.786C235.271 191.803 238.275 171.331 249.256 155.841Z" fill="url(#paint0_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M307.784 405.039C257.175 407.795 208.221 378.08 189.528 328.579C185.126 329.125 180.723 330.226 175.775 332.427C150.47 341.231 132.87 363.226 129.568 389.631C126.821 413.835 136.726 438.039 155.973 452.893C157.62 453.993 159.267 455.64 160.913 456.741C210.414 469.393 262.678 454.54 297.325 417.683C301.173 413.835 304.474 409.441 307.776 405.031L307.784 405.039Z" fill="url(#paint1_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M296.77 333.527C294.023 331.335 292.376 329.125 290.167 326.378C283.572 318.12 280.27 307.122 277.514 296.125C274.221 281.826 272.566 266.972 266.517 253.774C261.569 243.315 253.865 233.972 245.615 224.613C243.414 221.858 240.666 219.11 237.911 216.909C229.661 208.659 221.411 200.955 216.462 191.613C213.161 185.564 211.514 179.507 210.405 172.366C208.204 160.259 208.758 146.507 207.666 133.854C187.31 149.808 171.364 170.711 160.358 194.36C121.317 221.32 96.0129 264.771 92.165 311.524C120.217 284.581 161.467 272.475 199.97 280.725C201.071 280.725 201.617 281.271 202.718 281.271C223.62 288.429 242.867 299.427 259.376 314.272C268.726 321.976 276.977 331.326 287.974 335.72C292.922 338.476 299.526 339.576 305.028 341.223C302.281 338.468 298.979 336.266 296.77 333.519V333.527ZM252.218 282.926C248.37 279.625 245.069 276.323 241.212 273.576C238.465 271.375 235.163 269.728 231.862 267.527C231.862 267.527 243 267.941 248.536 270.051C250.969 270.986 253.517 271.946 256.943 275.603L258.846 277.986C260.377 281.801 261.006 287.883 261.56 291.185C258.259 288.438 254.957 285.69 252.21 282.935L252.218 282.926Z" fill="url(#paint2_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M154.814 297.557C155.965 297.242 188.353 306.733 188.353 306.733C212.416 308.306 235.9 319.882 257.531 330.813C269.579 336.258 280.568 343.656 293.551 342.564C296.828 343.375 301.139 341.389 304.805 341.38C304.88 341.331 304.954 341.273 305.029 341.231C299.526 339.585 292.922 338.476 287.974 335.729C276.977 331.326 268.718 321.984 259.376 314.28C242.876 299.435 223.62 288.429 202.718 281.28C201.617 281.28 201.071 280.734 199.971 280.734C161.467 272.484 120.217 284.59 92.1653 311.533C91.6108 316.489 91.6108 320.883 91.6108 325.294C91.6108 334.645 93.7623 351.079 97.1964 365.601C103.502 337.317 129.013 321.074 145.158 315.339C151.447 313.113 128.343 307.503 128.343 307.503C132.456 306.841 166.854 306.452 166.854 306.452C166.854 306.452 154.823 298.425 154.823 297.565L154.814 297.557Z" fill="#FFD2F6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M297.333 417.683C262.678 454.54 210.422 469.385 160.921 456.741C180.458 475.103 199.648 482.873 210.571 486.34C279.633 508.252 337.963 459.546 338.956 458.569C338.981 458.545 340.793 456.749 340.793 456.749C341.223 456.046 341.926 454.895 342.762 453.455C344.988 449.624 347.628 444.684 350.251 438.751C352.568 433.505 355.679 425.652 358.253 415.796C360.164 408.457 362.026 399.056 362.787 387.993C353.652 392.312 347.148 395.432 344.384 396.905C343.557 397.343 341.074 398.709 337.516 399.884C337.516 399.884 336.945 400.091 336.324 400.256C326.651 402.912 317.474 404.501 307.776 405.047C304.466 409.45 301.173 413.844 297.325 417.7L297.333 417.683Z" fill="url(#paint3_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M362.779 387.985C365.526 350.582 353.428 312.625 328.678 282.372C341.877 324.731 333.635 369.829 307.784 405.039C317.681 404.493 327.032 402.838 336.928 400.082C346.279 396.789 355.083 392.933 362.779 387.985Z" fill="url(#paint4_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M418.336 309.878C411.733 342.332 391.931 370.938 362.779 387.985C361.132 411.642 353.983 435.284 340.784 456.741C387.537 422.085 416.135 368.174 418.336 309.878Z" fill="url(#paint5_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M349.581 221.866C335.282 210.323 321.529 198.216 308.33 186.118C302.836 194.915 296.778 203.173 290.175 211.415C265.433 161.914 267.08 104.156 293.485 55.7475C296.241 65.6526 299.534 75.5493 303.39 84.3538C311.086 100.3 321.537 114.052 332.543 127.805C339.692 136.055 346.842 144.86 353.999 153.656C358.948 159.705 363.35 166.308 368.298 172.366C360.594 172.912 352.899 171.811 345.741 167.963C350.143 185.564 351.79 204.265 349.589 221.866H349.581ZM378.733 163.561C355.63 130.561 322.629 101.4 309.977 63.9976C305.029 49.6986 303.382 34.8451 301.181 19.9917C240.675 92.6039 245.623 199.309 312.732 265.864C318.235 270.812 323.73 276.315 328.678 282.364C353.428 312.617 365.535 350.574 362.779 387.976C391.923 370.93 411.733 342.324 418.337 309.87C422.184 292.815 420.571 264.176 413.934 240.013C408.357 219.723 395.233 187.211 378.725 163.561H378.733Z" fill="url(#paint6_linear_274_4762)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M303.382 84.3539C299.526 75.5577 296.232 65.6526 293.477 55.7476C267.072 104.156 265.425 161.906 290.167 211.415C296.77 203.173 302.819 194.915 308.322 186.119C321.529 198.216 335.273 210.323 349.572 221.866C351.773 204.265 350.127 185.564 345.725 167.963C352.882 171.811 360.578 172.912 368.282 172.366C363.325 166.317 358.931 159.713 353.983 153.656C346.825 144.86 339.684 136.055 332.526 127.805C321.529 114.052 311.078 100.3 303.374 84.3539H303.382Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M294.412 179.755L299.625 157.653C299.625 157.653 308.835 163.801 317.631 171.439C325.211 178.034 329.191 179.846 329.191 179.846C329.191 179.846 326.494 155.75 322.786 146.788L344.127 157.967C344.127 157.967 335.075 147.351 327.652 135.609C320.238 123.866 316.307 114.383 316.307 114.383L340.726 145.125L357.342 166.449C357.342 166.449 352.502 166.855 349.299 166.275C344.227 165.357 339.22 163.147 339.22 163.147C339.22 163.147 344.334 184.207 342.224 206.475C342.224 206.475 317.193 183.768 306.063 178.514L294.188 198.026C294.188 198.026 280.717 167.318 279.79 149.088C279.79 149.088 287.378 168.981 294.404 179.763L294.412 179.755Z" fill="#FFD2F6"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_274_4762" x1="213.955" y1="222.073" x2="238.416" y2="166.275" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDDE3"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_274_4762" x1="241.006" y1="442.011" x2="146.987" y2="323.912" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFD2F6"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_274_4762" x1="282.314" y1="270.961" x2="208.932" y2="244.208" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFD2F6"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <linearGradient id="paint3_linear_274_4762" x1="277.465" y1="482.318" x2="257.092" y2="404.112" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFD2F6"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <linearGradient id="paint4_linear_274_4762" x1="319.659" y1="327.52" x2="338.765" y2="391.733" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDDE3"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <linearGradient id="paint5_linear_274_4762" x1="418.444" y1="370.698" x2="361.877" y2="389.044" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFD2F6"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <linearGradient id="paint6_linear_274_4762" x1="345.079" y1="223.943" x2="389.797" y2="324.847" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFD2F6"/>
                <stop offset="0.49" stopColor="#FEA5FF"/>
                <stop offset="1" stopColor="#F45CFF"/>
            </linearGradient>
            <clipPath id="clip0_274_4762">
                <rect width="328.778" height="472" fill="white" transform="translate(91.6108 20)"/>
            </clipPath>
        </defs>
    </svg>


);

export default JuneFoxIcon2;



import React, { Component } from 'react';
import SVGIcon from '../svg/index'
import { countFormat } from '../../helpers/utils'
import variables from '../../scss/variables.module.scss'
import Tooltip from '../complex/tooltip'

class FavoriteButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      enabled: props.enabled || false,
      count: props.count || 0
    }
  }

  componentDidUpdate(nextProps) {
    const { enabled, count } = this.state
    if (nextProps.enabled !== enabled) {
      this.setState({ enabled })
    }
    if (nextProps.count !== count) {
      this.setState({ count })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      enabled: nextProps.enabled,
      count: nextProps.count,
    };
  }

  onClick = () => {
    const { onClickCallback } = this.props;
    if (onClickCallback) {
      onClickCallback();
    } else {
      const enabled = !this.state.enabled;
      const count = this.state.count + (enabled ? 1 : -1);
      this.setState({enabled, count});
    }
  }

  render () {
    const {width = 24, height = 22, sameFill = false, showRequire} = this.props;
    const style = {
      fillBorder: sameFill ? variables['colors-jf-white'] : variables['colors-jf-forgotpass'],
      fill: variables['colors-jf-white'],
    }
    if (this.state.enabled) {
      style.fill = variables['colors-jf-like'];
      style.fillBorder = variables['colors-jf-like'];
    }
    const btn = <button className={'jf-favorite-btn-icon'} onClick={this.onClick}>
        <SVGIcon name={'like-border-icon'} {...style} width={width} height={height}/>
      </button>
    return (
      <div className="jf-favorite-btn">
        {showRequire ? <Tooltip text={'Require login'}>{btn}</Tooltip> : btn}
        <div className='jf-favorite-btn-count'>{ countFormat(this.state.count) }</div>
      </div>
    )
  }
}

export default FavoriteButton;
import React from 'react';
const BackCloseIcon = ({
  style = {},
  fill = '#f5f5f7',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '30',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg id="back" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_1222" data-name="Rectangle 1222" width="30" height="30" fill={fillNone} />
      <path id="Path_6301" data-name="Path 6301" d="M10.436,24H26.161a2.5,2.5,0,0,0,2.5-2.5V6.5a2.5,2.5,0,0,0-2.5-2.5H10.436a1.25,1.25,0,0,0-.88.362L.727,13.112A1.25,1.25,0,0,0,.719,14.88l.008.008,8.829,8.75A1.25,1.25,0,0,0,10.436,24Z" transform="translate(0.089 1)" fill={fill} fillRule="evenodd" />
      <path id="Path_6302" data-name="Path 6302" d="M13.013,12.78l-1.768-1.768a1.25,1.25,0,0,1,1.768-1.768l1.768,1.768,1.768-1.768a1.25,1.25,0,1,1,1.768,1.768L16.548,12.78l1.768,1.768a1.25,1.25,0,1,1-1.768,1.768L14.78,14.548l-1.768,1.768a1.25,1.25,0,0,1-1.768-1.768Z" transform="translate(2.72 2.22)" fill="#130c18" fillRule="evenodd" />
    </svg>

  </>
);
export default BackCloseIcon;
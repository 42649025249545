import React, { Component } from 'react';
import Header from '../../../components/header.js';
import SVGIcon from '../../../components/svg/SVGIcon.js';
import { Link } from "react-router-dom";

class Otp extends Component {
  render() {
    return (
      <>
        <div className="jf-otp">
          <Header />
          <div className="jf-otp-body">
            <h1 className="jf-body-title">OTP</h1>
            <p className="jf-subtitle">Enter the pin you have received via SMS on</p>
            <div className="jf-form-box">
              <div className="jf-input-box"><input type="text" className="jf-input-otp"></input></div>
              <div className="jf-input-box"><input type="text" className="jf-input-otp"></input></div>
              <div className="jf-input-box"><input type="text" className="jf-input-otp"></input></div>
              <div className="jf-input-box"><input type="text" className="jf-input-otp"></input></div>
            </div>
            <div className="jf-resend-code">Resend code <span className="jf-timer">          00:59</span></div>
          </div>
          <Link to="./loginwith" className="jf-next">
            <SVGIcon name="nextarrow"></SVGIcon>
          </Link>
        </div>
      </>
    );
  }
}

export default Otp;
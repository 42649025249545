import React from 'react';
import Avatar from '../../../components/basic/avatar'
import { countFormat } from '../../../helpers/utils'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { profileAtom } from '../../../state/users'
import { authProfileAtom } from '../../../state/auth'
import SVGIcon from '../../../components/svg/index'
import Button from '../../../components/basic/button'
import { activePopupState } from '../../../state/components/popup'
import variables from '../../../scss/variables.module.scss'
import {useNavigate} from 'react-router-dom';

export const ProfileDetail = ({isOfAuth = true, onClick}) => {
  const profile = useRecoilValue(isOfAuth ? authProfileAtom : profileAtom);
  const setSubscriptionPopup = useSetRecoilState(activePopupState('subscription'));
  const setTipsPopup = useSetRecoilState(activePopupState('tips'));
  const navigate = useNavigate();

  const showSubscription = () => {
    if (profile) {
      setSubscriptionPopup({ active: true, profile })
    }
  }
  const showTips = () => {
    if (profile) {
      setTipsPopup({ active: true, profile })
    }
  }

  const countFollowers = profile ? profile.followers : 0;
  const countPosts = profile ? profile.posts || 0 : 0;

  return <div className={'jf-user-profile-detail'}>
    <Avatar
      live={profile?.live}
      username={profile?.username}
      image={profile && profile.avatar}
      text={profile && profile.fullname}
      width={120}
      height={120}
    />
    <span className={'jf-user-profile-detail-title-1'}>{profile && profile.fullname}</span>
    <span className={'jf-user-profile-detail-title-2'}>@{profile && profile.username}</span>
    <div className={'jf-user-profile-detail-report'}>
      {!isOfAuth && <>
        <button className={'jf-user-profile-detail-report-icon-btn'} onClick={showTips}>
          <SVGIcon name={'money-icon'} fill={variables['colors-jf-black2']} with={29} height={29}/>
        </button>
        <button className={'jf-user-profile-detail-report-icon-btn'}
                onClick={() => navigate('/messages/to/' + profile.username)}>
          <SVGIcon name={'user-comment-icon'} fill={variables['colors-jf-black2']} width={29}/>
        </button>
      </>}
      {isOfAuth ? <Button className={'medium'} onClick={onClick}>
        Edit Profile
      </Button> : <Button className={'medium'} onClick={onClick}>
        {profile && profile.isFollowing ? 'Message' : 'Follow'}
      </Button>}
    </div>
    <div className={'jf-user-profile-detail-content'}>
      <div style={{marginBottom: 17}}><span className={'medium'}>
        {countFormat(countFollowers)}
      </span>&nbsp;Follower{countFollowers < 2 || 's'}&nbsp;
        <span className={'medium'}>{countFormat(countPosts)}</span>&nbsp;Post{countPosts < 2 || 's'}<br/></div>
    </div>
    {!isOfAuth && <Button className={'medium'} style={{width: '100%'}} onClick={showSubscription}>
      Subscribe
    </Button>}
  </div>;
}
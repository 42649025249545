import React, { useEffect } from 'react';
import TopMenu from '../../../components/basic/top-menu'
import {useUserAction} from '../../../action/user-action';
import BottomMenu from '../../../components/basic/bottom-menu'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { authIdAtom, authProfileAtom } from '../../../state/auth'
import Avatar from '../../../components/basic/avatar'
import { countFormat } from '../../../helpers/utils'
import Button from '../../../components/basic/button'
import ItemMenu from '../../../components/basic/item-menu'
import { fetchSignOut } from '../../../helpers/fetch-graphql'

const Account = (props) => {
  const navigate = useNavigate();
  const userAction = useUserAction();
  const [authProfile, setAuthProfile] = useRecoilState(authProfileAtom);
  const setAuthId = useSetRecoilState(authIdAtom);
  const styleItem = { marginLeft: -20, marginRight: -20, width: 'auto' };

  const onClick = () => {
    navigate('/profile');
  }

  const SignOut = () => {
    fetchSignOut().then(res => {
      setAuthId(null);
      setAuthProfile(null);
    })
  }

  useEffect(() => {
    if (!authProfile) {
      userAction.getAuthProfile().then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authProfile && <>
    <TopMenu
      icon={'greater-icon'}
      title={"User"}
      action={() => navigate(-1)}
    />
    <div className={'jf-basic-page'}>
      <div className={'jf-row'}>
        <Avatar
          image={authProfile.avatar}
          text={authProfile.fullname}
          width={96}
          height={96}
        />
        <div className={'jf-ml-20px jf-mt-auto jf-mb-auto'}>
          <div className={'jf-font-20-22 jf-font-b jf-color-black'}>{authProfile.fullname}</div>
          <div className={'jf-font-16-22 jf-font-r jf-color-gray jf-mt-3px'}>
            @{authProfile.username}
            <div className={'jf-mt-3px'}/>
            {countFormat(authProfile.following)} Following
          </div>
        </div>
      </div>
      <Button className={'jf-mt-15px jf-mb-15px'} onClick={onClick}>Profile</Button>
      <ItemMenu list={[
        ...(authProfile?.role?.creator ? [{
          iconLeft: 'earning-icon',
          text: 'Earnings',
          onClick: () => navigate('/account/earnings')
        }] : []),
        {
          iconLeft: 'purchase-icon', text: 'Purchases',
          onClick: () => navigate('/account/purchases')
        },
      ]} style={styleItem}/>
      {authProfile?.role?.creator && (
        <ItemMenu list={[
          { iconLeft: 'media-icon', text: 'My media', onClick: () => navigate('/account/media') },
          { iconLeft: 'consent-icon', text: 'Subscription', onClick: () => navigate('/account/subscription') },
        ]} style={styleItem}/>
      )}
      <ItemMenu list={[
        { iconLeft: 'setting-icon', text: 'Settings', onClick: () => navigate('/account/settings') },
        { iconLeft: 'support-icon', text: 'Support', onClick: () => navigate('/support') },
      ]} style={styleItem}/>
      <ItemMenu list={[
        { iconLeft: 'legal-icon', text: 'About', onClick: () => navigate('/about') },
      ]} style={styleItem}/>
      <ItemMenu list={[
        { iconLeft: 'sign-out-icon', text: 'Sign Out', onClick: SignOut },
      ]} style={styleItem}/>
    </div>
    <BottomMenu/>
  </>;
}

export default Account;
import React  from 'react'
import LiveBoxUser from './user'
import LiveBoxAuth from './auth'
import { useLocation } from 'react-router-dom'

const LiveBox = (props) => {
  const location = useLocation();
  return (
    location?.pathname.indexOf('/studio') === -1 ?
      <LiveBoxUser {...props}/> : <LiveBoxAuth {...props}/>
  );
}

export default LiveBox;
